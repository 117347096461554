import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { styled } from 'styled-components'
import { Footer } from './Footer'
import { Header } from './Header'

export const Scaffold = (props: {
  children: ReactNode
  bgColor?: string
  helmet?: { title: string; path?: string; description?: string }
}) => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].style.backgroundColor =
      props.bgColor ? props.bgColor : '#000000'
  }, [props.bgColor])

  const _initialized =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('initialized')
      : 'false'

  const [initialized, setInitialized] = React.useState(_initialized === 'true')

  React.useEffect(() => {
    if (!initialized) {
      setTimeout(() => {
        sessionStorage.setItem('initialized', 'true')
        setInitialized(true)
      }, 5000)
    }
  }, [initialized])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/for/ping`, {})
  }, [])

  return (
    <>
      {props.helmet && (
        <Helmet>
          <title>{props.helmet.title}</title>
          {props.helmet.path && (
            <link
              rel="canonical"
              href={`https://www.foronered.com/${props.helmet.path}`}
            />
          )}
          {props.helmet.description && (
            <meta name="description" content={props.helmet.description} />
          )}
        </Helmet>
      )}
      <ContentWrapper
        style={{
          background: props.bgColor || '#01031a',
        }}
      >
        <Content
          style={{
            background: props.bgColor || '#01031a',
          }}
        >
          {props.children}
        </Content>
      </ContentWrapper>
      <Header />
      <Footer bgColor={props.bgColor} />
    </>
  )
}

const ContentWrapper = styled.div`
  width: 100%;
`

const Content = styled.div`
  opacity: 0;
  overflow-x: hidden;
  margin: 0 auto;

  max-width: 1400px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
`
