export type WorkT = {
  title: string
  tagline: string
  description: string
  images: Array<{
    src: string
    caption: string
  }>
  text: Array<{
    tag: string
    quote: string
    text: string
  }>
  stats: Array<{
    label: string
    value: string
  }>
}

export enum Industry {
  Cyber = 'Cyber',
  FinTech = 'FinTech',
  Retail = 'Retail',
  Creative = 'Creative',
  Industrial = 'Industrial',
}

export enum Project {
  'hedera' = 'hedera', // 'Hedera',
  'socDaily' = 'soc-daily',
  'parkProject' = 'park-project',
  'githubUnwrapped' = 'github-unwrapped',
  'bobDashboard' = 'bob-dashboard',
  'bobWhitelabel' = 'bob-whitelabel',
  'rapidRange' = 'rapid-range',
  'cyberWorld' = 'cyber-world',
  'cyberQuest' = 'cyber-quest',
  'simspace' = 'simspace',
  'distranFixDetectors' = 'distran-fix-detectors',
  'distranTouchUI' = 'distran-touch-ui',
  'aseSwisscom' = 'ase-swisscom',
  'aseSwisscomConcept' = 'ase-swisscom-concept',
  'aseRetailReports' = 'ase-retail-reports',
  'valdonWebsite' = 'valdon-website',
  'eatByAlex' = 'eat-by-alex',
  'transa' = 'transa',
  'postfinance' = 'postfinance',
  'toopo' = 'toopo',
  'charlesbank' = 'charlesbank',
}

export const mapProjectToIndustry: Record<Project, Industry> = {
  [Project.toopo]: Industry.Creative,
  [Project.hedera]: Industry.FinTech,
  [Project.socDaily]: Industry.Cyber,
  [Project.parkProject]: Industry.Creative,
  [Project.githubUnwrapped]: Industry.Creative,
  [Project.bobDashboard]: Industry.FinTech,
  [Project.bobWhitelabel]: Industry.FinTech,
  [Project.rapidRange]: Industry.Cyber,
  [Project.cyberWorld]: Industry.Cyber,
  [Project.simspace]: Industry.Cyber,
  [Project.distranFixDetectors]: Industry.Industrial,
  [Project.distranTouchUI]: Industry.Industrial,
  [Project.aseSwisscom]: Industry.Retail,
  [Project.aseRetailReports]: Industry.Retail,
  [Project.cyberQuest]: Industry.Cyber,
  [Project.valdonWebsite]: Industry.FinTech,
  [Project.aseSwisscomConcept]: Industry.Creative,
  [Project.eatByAlex]: Industry.Retail,
  [Project.transa]: Industry.Retail,
  [Project.postfinance]: Industry.FinTech,
  [Project.charlesbank]: Industry.FinTech,
}

export const ORDER = [
  Project.rapidRange,
  Project.githubUnwrapped,
  Project.distranFixDetectors,
  Project.distranTouchUI,
  Project.bobDashboard,
  Project.simspace,
  Project.bobWhitelabel,
  Project.eatByAlex,
  Project.aseSwisscomConcept,
  Project.postfinance,
  Project.aseSwisscom,
  Project.aseRetailReports,
  Project.transa,
  Project.charlesbank,
  Project.parkProject,
  Project.socDaily,
  Project.cyberWorld,
  Project.toopo,
  Project.hedera,
  Project.cyberQuest,
  Project.valdonWebsite,
]

export const projectsDisabled: Record<string, boolean> = {
  [Project.hedera]: false,
  [Project.socDaily]: false,
  [Project.parkProject]: false,
  [Project.githubUnwrapped]: false,
  [Project.bobDashboard]: false,
  [Project.bobWhitelabel]: false,
  [Project.rapidRange]: false,
  [Project.cyberWorld]: false,
  [Project.simspace]: false,
  [Project.distranFixDetectors]: false,
  [Project.distranTouchUI]: false,
  [Project.aseSwisscom]: false,
  [Project.aseRetailReports]: false,
  [Project.cyberQuest]: false,
  [Project.valdonWebsite]: false,
  [Project.aseSwisscomConcept]: false,
  [Project.eatByAlex]: false,
  [Project.transa]: false,
  [Project.postfinance]: false,
  [Project.toopo]: false,
}

export const WORK: Record<Project, WorkT> = {
  [Project.charlesbank]: {
    title: 'charlesbank internal tool',
    tagline: 'Pitch deck archive',
    description: `
    Charlesbank approached ForOneRed, looking for a partner to create a library for the pitch decks they receive and keep receiving. Leveraging information extracted from pitch decks with their machine learning algorithm, a web app that visualises that data and allows for sorting, filtering and comparison was built.
    `,
    images: [
      {
        src: '/projects/charlesbank/charlesbank-2.png',
        caption: '',
      },
      {
        src: '/projects/charlesbank/charlesbank-1.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Easy to use UI for data navigation and maintainance.
        `,
        text: `  
        Charlesbank, an experienced private equity firm with offices in Boston and New York, receives numerous pitch decks from companies seeking investment opportunities. Following thorough assessment, only a select few of these companies are chosen for investment. By utilizing Machine Learning technology, pertinent information is automatically extracted from these pitch decks and stored within their system. Organizing key metrics enables investors to conduct comparisons among companies seeking funds and categorize them based on preferences. This streamlined process facilitates quicker and more informed investment decisions while also establishing a comprehensive database of past investment choices.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Fin-Tech',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'July 2021',
        label: 'Launch',
      },
    ],
  },
  [Project.toopo]: {
    title: 'toopo',
    tagline: 'Large custom relief maps',
    description: `
    Toopo is an innovative creation by ForOneRed. The goal is to create detailed, customisable and sustainable 3D-printed relief maps that double as a display of projecting various topographic, forecasting data and more.
      `,
    images: [
      {
        src: '/projects/toopo/toopo-11.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-1.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-2.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-3.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-4.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-5.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-6.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-7.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-8.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-9.png',
        caption: '',
      },
      {
        src: '/projects/toopo/toopo-10.png',
        caption: '',
      },

      {
        src: '/projects/toopo/toopo-12.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Ensuring the accuracy and seamless integration of the modular tiles even on a large scale.
        `,
        text: `  
        The team extracts the 3D data required for the maps from satellite imagery. The data are then being processed and optimised for 3D printing. The modular tiles, which are light and easy to transport, can be customised to fit various applications and environments.
        \n
        Additionally, many forms of data, from geographic information to forecasting patterns, can be projected onto the relief map, offering endless possibilities for visualisation.
        \n
        One of the Toopo maps of Switzerland was being displayed at Transa's store in Europaallee.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Design',
        label: 'Industry',
      },
      {
        value: '3D-print',
        label: 'Type',
      },
      {
        value: '2020',
        label: 'Launch',
      },
    ],
  },
  [Project.postfinance]: {
    title: 'post finance',
    tagline: 'Customer credit application',
    description: `
    Alongside the bob finance team, ForOneRed designed and implemented the frontend portion of the credit application flow for PostFinance. The goal was to create a seamless and user-friendly experience for customers applying for credit.
      `,
    images: [
      {
        src: '/projects/post-finance/post-finance-1.png',
        caption: '',
      },
      {
        src: '/projects/post-finance/post-finance-2.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Create an intuitive and branded-aligned application flow.
        `,
        text: `  
        PostFinance, a leading Swiss financial institution, partnered with bob Finance to develop a seamless credit application flow for its customers. The team was responsible for designing and implementing the frontend portion of the application flow, ensuring a user-friendly and intuitive experience for customers.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Fin-Tech',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'July 2021',
        label: 'Launch',
      },
    ],
  },
  [Project.transa]: {
    title: 'transa map display',
    tagline: 'Window map display',
    description: `
    The Toopo map was the perfect fit for the Shop Window at the Transa store at Europaallee in Zürich. A projector displayed data on the 240cm relief of Switzerland so Transa’s clientele could see infos relevant to outdoor athletes like the weather condition, snowfall or hiking routes displayed on the map.
      `,
    images: [
      {
        src: '/projects/transa/transa-1.png',
        caption: '',
      },
      {
        src: '/projects/transa/transa-3.png',
        caption: '',
      },
      {
        src: '/projects/transa/transa-2.png',
        caption: '',
      },

      {
        src: '/projects/transa/transa-4.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Showing live data on a huge 3d-printed relief of Switzerland and its beautiful alps.
        `,
        text: `  
        Toopo is a custom 3D-Printed relief of Landscapes. As a Swiss agency of course the first map had to be a relief of Switzerland. Also, other landscapes can be produced upon request in 3 standard sizes or custom. In Transa, the biggest size (2.4m) Toopo map was displayed. Toopo is easy to assemble thanks to the rail-construction of the Frame, which hold the 48 Tiles together.
        \n
        The projection on the map shows live data which also can be customised depending on the customer. For Transa, ski- and hiking routes, temperature, avalanche danger, rain and snowfall were displayed. 
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'Jan 2023',
        label: 'Launch',
      },
    ],
  },
  [Project.eatByAlex]: {
    title: 'eat by alex',
    tagline: 'Website focused around nutrition',
    description: `
    Eat by Alex approached the team to design a website for their new nutrition programs, products and a health quiz. The goal was to provide customers with an overview of their options for starting a healthy lifestyle, presented in a colorful and playful interface.
      `,
    images: [
      {
        src: '/projects/eat-by-alex/eat-by-alex-1.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-2.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-3.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-4.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-5.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-6.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-7.png',
        caption: '',
      },
      {
        src: '/projects/eat-by-alex/eat-by-alex-8.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Showcase products and simplifying ordering process
        `,
        text: `  
        Eat by Alex is a company dedicated to promoting a healthy lifestyle through whole-food diets. They offer several programs to support their customers in maintaining these diets. The ForOneRed team designed their website, including detailed pages for the programs, an intuitive ordering process, and an informative main page. The icons and illustrations were also designed in-house, ensuring a cohesive and visually appealing presentation.
        \n
        Additionally, the team designed a health quiz, allowing users to assess their nutritional needs and receive personalised recommendations. This interactive feature enhances user engagement and helps customers make informed decisions about their diet and health.
        \n
        The focus was on presenting information in a compact, user-friendly manner, allowing customers to easily access details about a complex topic.
        \n
        Accessible via the Eat by Alex Website or directly at eatbyalex.com
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'Feb 2024',
        label: 'Launch',
      },
    ],
  },
  [Project.aseSwisscomConcept]: {
    title: 'swisscom store concept',
    tagline: 'Waiting queue concept',
    description: `
    A concept design for a waiting queue solution, transforming the store environment and its visitors into a virtual world through a 3D model. This innovative approach aims to reduce waiting times and enhance the overall customer experience.
      `,
    images: [
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-video-1.mp4',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-1.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-2.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-3.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-4.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-5.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-6.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-concept/ase-swisscom-concept-video-2.mp4',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Integrating real-time customer tracking data with a virtual 3D model.
        `,
        text: `  
        In response to the need for enhancing customer experience and operational efficiency, the team developed a concept for a waiting queue solution tailored for Swisscom stores.
        \n
        The concept integrates a 3D model that transforms the physical store environment and its visitors into a playful virtual world. This virtual representation is displayed on a screen in the store, offering customers an engaging visual experience.
        \n
        Upon entering the store, customers select a service using a service tablet. Depending on the chosen service, an icon appears over their virtual avatar within the 3D model, allowing Swisscom employees to identify and address each customer's specific needs promptly. Leveraging the ASE Pedestrian Analytics System installed in the stores, customer movements are tracked, ensuring the virtual avatars move in sync with their real-world counterparts.
        \n
        By turning the waiting period into an interactive activity, the concept aims to reduce perceived wait times and elevate the overall customer journey and service quality.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Concept design',
        label: 'Type',
      },
      {
        value: 'July 2023',
        label: 'Launch',
      },
    ],
  },
  [Project.bobWhitelabel]: {
    title: 'whitelabel financing',
    tagline: 'Whitelabel solution for credit flows',
    description: `
    Bob Whitelabel is a white label solution around Bob Finance’s credit application flow. Companies interested in offering alternative financing solutions to their customers have the option to use Bob’s financing services and customise them to adhere to the brand design guidelines.
      `,
    images: [
      {
        src: '/projects/bob-whitelabel/bob-whitelabel-2.png',
        caption: '',
      },
      {
        src: '/projects/bob-whitelabel/bob-whitelabel-1.png',
        caption: '',
      },

      {
        src: '/projects/bob-whitelabel/bob-whitelabel-3.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        A customizable credit application flow that allows brands to adhere to corporate design guidelines.
        `,
        text: `  
        Bob Finance a credit institute based in Zurich, Switzerland offers personal financings/loans to their customers. They sell their services either themselves or through a trusted partner, like Breitling or Post Finance, who is not allowed to hand out loans but wants to offer their customers an alternative financing solution.
        \n
        Bob Finance's fully customisable white label platform enables partners to seamlessly integrate Bob Finance's credit application flow into their own service offerings, aligning with their corporate design guidelines. This customisation allows partners to maintain brand consistency and present the financing solution as their own, enhancing customer trust and satisfaction. A win-win scenario for Bob Finance and their partners.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Fin-tech',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'Dec 2021',
        label: 'Launch',
      },
    ],
  },
  [Project.valdonWebsite]: {
    title: 'valdon website',
    tagline: 'Landing page for a consulting company',
    description: `
    Designing and developing a user-friendly website that communicates Valdon Group's expertise and services.
      `,
    images: [
      {
        src: '/projects/valdon/valdon-1.png',
        caption: '',
      },
      {
        src: '/projects/valdon/valdon-2.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Create an intuitive and brand-aligned website.
        `,
        text: `  
        ForOneRed's task was to develop a website for Valdon Group, a Swiss-German fintech company situated in Zürich. Valdon Group specializes in translating cutting-edge academic knowledge and research into consultancy and software services.
        \n
        The end result was a user-friendly website that effectively communicates Valdon Group's expertise, services, and mission to its target audience. The focus was on creating a visually appealing and informative website that showcases the company's innovative approach and commitment to excellence in fintech solutions.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Technology',
        label: 'Industry',
      },
      {
        value: 'Website',
        label: 'Type',
      },
      {
        value: 'June 2022',
        label: 'Launch',
      },
    ],
  },
  [Project.cyberQuest]: {
    title: 'cyber quest',
    tagline: 'Cyber education game on the web',
    description: `
    Designed and developed a web app cyberpunk-themed game where users choose characters representing different cyber security roles, complete quests to earn rewards, and advance levels. The goal was to playfully strengthen the skills of cyber security analysts.
      `,
    images: [
      {
        src: '/projects/cyber-quest/cyber-quest-1.png',
        caption: '',
      },
      {
        src: '/projects/cyber-quest/cyber-quest-2.png',
        caption: '',
      },
      {
        src: '/projects/cyber-quest/cyber-quest-3.png',
        caption: '',
      },
      {
        src: '/projects/cyber-quest/cyber-quest-4.png',
        caption: '',
      },
      {
        src: '/projects/cyber-quest/cyber-quest-5.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Visualising and explaining the complexity of the cyber security topics in an easy and engaging way.
        `,
        text: `  
        For One Red led the development and design of a playful and educational environment where users defend against cyber attacks through quests set in a cyberpunk backdrop. By completing quests and answering questions on cyber security topics, users earn rewards and level up.
        \n
        Conducted user research to pinpoint knowledge gaps within the target audience. Through interviews and prototype tests, the team identified the effectiveness of small code challenges and security quests in fostering a deeper understanding of cyber security.
        \n
        By combining engaging gameplay with educational content an interactive experience was created, that broadened users' understanding of cyber security.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Game',
        label: 'Type',
      },
      {
        value: 'May 2022',
        label: 'Launch',
      },
    ],
  },
  [Project.aseRetailReports]: {
    title: 'ase retail reports',
    tagline: 'Retail dashboard tool',
    description: `
    For ASE AG an analytics tool was designed, to visualise key mall metrics for retail managers in a user friendly way. By providing a centralized platform for data aggregation, visualization, and analysis, the application enables retail managers to gain actionable insights and make informed decisions to optimize mall-, region-, country performance.
      `,
    images: [
      {
        src: '/projects/ase-dashboard/ase-dashboard-4.png',
        caption: '',
      },
      {
        src: '/projects/ase-dashboard/ase-dashboard-3.png',
        caption: '',
      },
      {
        src: '/projects/ase-dashboard/ase-dashboard-1.png',
        caption: '',
      },
      {
        src: '/projects/ase-dashboard/ase-dashboard-2.png',
        caption: '',
      },

      {
        src: '/projects/ase-dashboard/ase-dashboard-5.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        How can a reporting application be designed for web and mobile use, that allows managers of different granularities to quickly oversee their malls & understand most important metrics at first glance?
        `,
        text: `  
        For ASE, a leading expert in the fields of passenger flow and transport, the ForOneRed team redesigned their analytics tool for retail managers. The design meets the needs of retail managers seeking streamlined data analytics for their mall operations.
        \n
        It offers a high-level overview and in-depth analysis of mall performance metrics, helping managers to quickly grasp important insights at a glance and saving time and effort in data interpretation. With access to actionable insights and real time data, retail managers can stay ahead of competitors by adapting quickly to market dynamics. 
        
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'March 2024',
        label: 'Launch',
      },
    ],
  },
  [Project.aseSwisscom]: {
    title: 'ase swisscom',
    tagline: 'Staffing optimization tool',
    description: `
    A tailored dashboard solution was developed. It displays various metrics including employee and visitor distribution, visitor trends over time, waiting times, and predictions for peak and off-peak periods.
      `,
    images: [
      {
        src: '/projects/ase-swisscom-dashboard/ase-swisscom-dashboard-1.png',
        caption: '',
      },
      {
        src: '/projects/ase-swisscom-dashboard/ase-swisscom-dashboard-2.png',
        caption: '',
      },
    ],

    text: [
      {
        tag: 'Challenge',
        quote: `
        Integrating and synchronising data from multiple devices across different store locations.
        `,
        text: `  
        Swisscom, a leading telecommunications company in Switzerland, is addressing staffing uncertainties at its physical store locations. Partnering with ASE (Analysis Simulation Engineering) AG, pedestrian flow data analysis devices are strategically installed in selected stores to gain valuable insights into staffing needs at various times.
        \n
        Leveraging this data, For One Red developed a customized dashboard solution, empowering operators with real-time metrics on employee and visitor distribution, visitor trends, waiting times, and peak/off-peak predictions. Moreover, the dashboard extends beyond analytics, allowing operators to monitor employee tag battery activity and voltage status for enhanced management capabilities.
        
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Retail',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'April 2024',
        label: 'Launch',
      },
    ],
  },
  [Project.distranTouchUI]: {
    title: 'imaging camera ui',
    tagline: 'Redesign the interface of the Ultra Pro',
    description: `
    For their most used Camera, the Ultra Pro, the team redesigned their Camera UI and UX. In addition new features are integrated to the interface, such as image tagging and image grouping. 
      `,
    images: [
      {
        src: '/projects/distran/distran-camera-1.png',
        caption: 'Screenshot of the Distran Web-UI for fixed detectors.',
      },
      {
        src: '/projects/distran/distran-camera-2.png',
        caption: 'Screenshot of the Distran Web-UI for fixed detectors.',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote: `
        Ensure the interface is more functional and intuitive for users while adhering to the constraints imposed by the camera's software capabilities.
        `,
        text: `  
        The team was entrusted on the redesign of Distran’s Ultra Pro camera interface, a cutting-edge technology used by industry leaders like NASA.
        \n
        The mission is to enhance the user experience and functionality of this camera incorporating new features such as image tagging and grouping. Image tagging allows users to document and track images better, so they can be used for reporting later on. Grouping allows users to create collections of similar photos who all inherit the same tags, allowing users to group after severity and other criteria.
        To achieve this, the process began with thorough user research and testing concepts, to ensure the redesign aligned with user needs and preferences.
        \n
        The goal is to deliver a revamped interface that empowers users to maximize the capabilities of the Ultra Pro camera with ease and efficiency.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Engineering',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'July 2024',
        label: 'Launch',
      },
    ],
  },
  [Project.distranFixDetectors]: {
    title: 'fixed detectors web ui',
    tagline: 'Leak-detection Web Interface',
    description: `
    Designed and implemented a Web-UI for stationary cameras for an engineering site to monitor leak activity. It allows operators to quickly manage high-risk alerts and take action.
      `,
    images: [
      {
        src: '/projects/distran/distran-web-1.png',
        caption: 'Screenshot of the Distran Web-UI for fixed detectors.',
      },
      // {
      //   src: '/projects/distran/distran-web-2.png',
      //   caption: '',
      // },
    ],
    text: [
      {
        tag: 'Challenge',
        quote: `Building a functional Web-UI from 0 to 1 and integrating the new product with Distran's existing infrastructure and product range.`,
        text: `  
        Distran, a Swiss company based in Zurich, specialises in the development and manufacturing of ultrasonic cameras for gas leak detection.
        \n
        The ForOneRed design team undertook the task of designing a Web-UI tailored for stationary cameras deployed at engineering sites, aimed at monitoring gas leak activity. The interface offers operators a seamless experience, allowing them to access real-time camera feeds for gas leak detection within facilities, efficient management of alerts, and playback recorded data.
        \n
        The successful MVP is currently used regularly by 30 users of global clients and will be released as a new Distran product in July.
        /n
        In addition, a design system for this client was established, with an extensive component library and design documentation.
        
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Engineering',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'July 2024',
        label: 'Launch',
      },
    ],
  },
  [Project.hedera]: {
    title: 'Hedera',
    tagline: 'Decentralized launchpad for Hedera projects',
    description: `
    Developed Hedera Hashgraph’s IDO (Initial DEX Offering) platform, a decentralized launchpad for projects within its ecosystem. The goal is to streamline capital raising and community engagement efforts, empowering early-stage projects to seamlessly launch their applications on the Hedera network.
      `,
    images: [
      {
        src: '/projects/hedera/hedera-6.png',
        caption: '',
      },

      // {
      //   src: '/projects/hedera/hedera-1.png',
      //   caption: '',
      // },
      {
        src: '/projects/hedera/hedera-3.png',
        caption: '',
      },
      {
        src: '/projects/hedera/hedera-4.png',
        caption: '',
      },
      {
        src: '/projects/hedera/hedera-5.png',
        caption: '',
      },
      {
        src: '/projects/hedera/hedera-2.png',
        caption: '.',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'A futuristic UX design for a crowdfunding platform built on the blockchain.',
        text: `
                 
        Hedera Hashgraph, a prominent public distributed ledger system renowned for its efficiency, security, and scalability, has recognized the need for a decentralized launchpad tailored specifically for projects within its ecosystem. With the rising demand for innovative blockchain-based solutions, the development of such a launchpad becomes paramount in fostering the growth of early-stage projects while maintaining the principles of decentralization and transparency.
        \n
        This envisioned decentralized launchpad serves as a foundational platform for projects built on the Hedera network, offering a comprehensive suite of services aimed at facilitating their development, fundraising, and community engagement efforts. The primary objectives of this launchpad are to provide a seamless avenue for early-stage projects to raise capital, gain widespread community traction, and successfully launch their applications on the robust infrastructure provided by Hedera Hashgraph.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C & B2B',
        label: 'Market',
      },
      {
        value: 'Finance',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'Apr 2020',
        label: 'Launch',
      },
    ],
  },
  [Project.socDaily]: {
    title: 'soc cyber daily',
    tagline: 'Cyber security micro learning platform',
    description: `
    This application provides users with daily challenges, comparable to Wordle, using content from SimSpace’s learning platform. Users can enjoy a fun and interactive way to test and improve their cybersecurity knowledge and skills.
      `,
    images: [
      {
        src: '/projects/soc-daily/soc-daily-1.png',
        caption: '',
      },
      {
        src: '/projects/soc-daily/soc-daily-2.png',
        caption: '',
      },
      {
        src: '/projects/soc-daily/soc-daily-3.png',
        caption: '',
      },
      {
        src: '/projects/soc-daily/soc-daily-4.png',
        caption: '',
      },
      {
        src: '/projects/soc-daily/soc-daily-5.png',
        caption: '',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote: 'Bite-sized daily challenges akin to sharpen your cyber skills.',
        text: `
                 
        For SimSpace, a leading American cybersecurity company specialising in cyber training, the team designed and developed a web application. This application leverages the content from their learning platform to deliver a unique daily challenge, reminiscent of the popular game Wordle. Users engage with the challenge, testing their cybersecurity knowledge and skills in a fun and interactive manner.
        \n
        The web app provides a dynamic and engaging way for users to enhance their understanding of cybersecurity concepts while enjoying a daily mental exercise.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C & B2B',
        label: 'Market',
      },
      {
        value: 'Finance',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'Apr 2020',
        label: 'Launch',
      },
    ],
  },
  [Project.parkProject]: {
    title: 'railway park project',
    tagline: 'Zürich city park concept',
    description: `
    A gray railroad track stretches over 3.8km through the middle of Zürich. Ugly, but nevertheless it characterises the cityscape. Reimagine: This eyesore could be enhanced by something with ecological, social and aesthetic value, like a huge park. The "Zürich Gleis Park" concept imagines a park above the railroad tracks that would form the new centre of Zürich as a meeting place, relaxation oasis and green biotope.
      `,
    images: [
      {
        src: '/projects/park-project/park-project-1.png',
        caption: 'Visualisation of the park - view from Zürich Hauptbahnhof.',
      },
      // {
      //   src: '/projects/park-project/slideshow.mp4',
      //   caption: 'The rails before and after the Park',
      // },
      {
        src: '/projects/park-project/park-project-2.png',
        caption: 'Top view of main entrance into the Park at Zürich HB.',
      },
      {
        src: '/projects/park-project/park-project-3.png',
        caption:
          'The Kiosk generated by Midjourney as a template to build it in 3d and render the picture.',
      },
      {
        src: '/projects/park-project/park-project-4.png',
        caption: 'The View Gallery where Trains pass through the park.',
      },
      {
        src: '/projects/park-project/park-project-5.png',
        caption: 'The Gallery Gardens with a train passing through.',
      },
      {
        src: '/projects/park-project/park-project-6.png',
        caption: 'The concrete benches with a view through the Layer Hill.',
      },
      {
        src: '/projects/park-project/park-project-7.png',
        caption: 'Our Book about the Zürich Gleis Park.',
      },
      {
        src: '/projects/park-project/park-project-8.png',
        caption: 'The theatre close to Hardbrücke.',
      },
      {
        src: '/projects/park-project/park-project-9.png',
        caption: 'A river trough the park.',
      },
      {
        src: '/projects/park-project/park-project-10.png',
        caption: 'The skate park at Hardbrücke',
      },
      {
        src: '/projects/park-project/park-project-11.png',
        caption: 'The “terrace” Bar at the lake.',
      },
      {
        src: '/projects/park-project/park-project-12.png',
        caption: 'A sketch for the Layer Hill and its implementation.',
      },
      {
        src: '/projects/park-project/park-project-13.png',
        caption: 'Pavillon Lake at Europaallee.',
      },
      {
        src: '/projects/park-project/park-project-14.png',
        caption: 'Bridge sketch.',
      },
      {
        src: '/projects/park-project/park-project-15.png',
        caption: 'Concept sketch.',
      },
      {
        src: '/projects/park-project/park-project-16.png',
        caption: 'Concept model.',
      },
      {
        src: '/projects/park-project/park-project-17.png',
        caption: 'Book cover illustration.',
      },
      {
        src: '/projects/park-project/park-project-18.png',
        caption: 'The theatre close to Hardbrücke.',
      },
      {
        src: '/projects/park-project/park-project-19.png',
        caption: 'A sketch for the Layer Hill and the concrete Benches.',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'Enhance the quality of live, the ecological footprint and the cityscape of Zürich.',
        text: `
        The “Zürich Gleis Park” project aims to showcase innovative solutions for the urban planning of Zürich, creating a space that benefits everyone. The project uses various media, including 3D renderings, VR simulations, and 3D printing, to bring the vision to life. The entire park spans 3.5 km, stretching from Zürich Hauptbahnhof to Zürich Altstetten. Different elements are designed for each part of the park, such as a viewtower, lake, bouldering wall, skatepark, and many more. \n In collaboration with Lucid, the projects were displayed in an exhibition at Photobastei under the name “Zürich Reimagined.”
              `,
      },
    ],
    stats: [
      {
        value: 'Concept',
        label: 'Market',
      },
      {
        value: 'Urban planning',
        label: 'Industry',
      },
      {
        value: '3D Model',
        label: 'Type',
      },
      {
        value: 'Oct 2023',
        label: 'Launch',
      },
      {
        value: '6 months',
        label: 'Duration',
      },
    ],
  },
  [Project.githubUnwrapped]: {
    title: 'github unwrapped',
    tagline: 'Year in review campaign for GitHub users',
    description: `
    Entrusted as the external design & dev team for GitHubUnwrapped 2023. The objective was to creatively capture and present users' GitHub year in review in a manner that was both unique and engaging. The team's innovative approach aimed to offer users a fun and insightful way to revisit their journey in the platform.
      `,
    images: [
      {
        src: '/projects/github/github-1.mp4',
        caption: '',
      },
      {
        src: '/projects/github/github-2.png',
        caption: '',
      },
      {
        src: '/projects/github/github-3.png',
        caption: '',
      },
      {
        src: '/projects/github/github-4.png',
        caption: '',
      },
      {
        src: '/projects/github/github-5.png',
        caption: '',
      },
      {
        src: '/projects/github/github-6.png',
        caption: '',
      },
      {
        src: '/projects/github/github-7.png',
        caption: '',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote: `
        Create visuals that accurately represented each user's activity on GitHub while prioritising rendering efficiency for large-scale video production.
        `,
        text: `
                 
        The multifaceted approach encompassed conceptualising, designing, and executing every aspect of the design process. This included crafting detailed storyboards to outline the narrative flow, guiding the art direction, developing a cohesive visual language inspired by GitHub's design ethos to ensure consistency and familiarity for the users.
        \n
        By leveraging the capabilities of Remotion to seamlessly create dynamic animations programatically an amazing project emerged.
        \n
        This campaign required not only technical expertise but also a keen understanding of user data visualisation and storytelling to deliver compelling, one-of-a-kind experience for each user.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Tech',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'Dec 2023',
        label: 'Launch',
      },
    ],
  },
  [Project.bobDashboard]: {
    title: 'internal dashboard',
    tagline: 'Streamline financing application processing',
    description: `
    As an external extension to Bob Finance’s software development team, the project involved building a tool to improve the workflow of customer service agents.. 
      `,
    images: [
      {
        src: '/projects/bob-dashboard/bob-dashboard-1.png',
        caption:
          'Screenshot of the bob Finance customer credit application flow.',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'Centralise Product and Customer data to optimise business processes.',
        text: `     
        As a company focused on personal financings/loans, Bob Finance has a range of products that follow different processes and regulatory restrictions.  
        The team created a central hub, the customer service portal, which aggregates information on all customers and their services and makes them available to the customer support team. This not only allows the agents to resolve matters more efficiently by having the relevant information at hand when they need it but also provides an overview of the current business.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Finance',
        label: 'Industry',
      },
      {
        value: 'Web App',
        label: 'Type',
      },
      {
        value: 'Sept 2019',
        label: 'Launch',
      },
      {
        value: '4 years',
        label: 'Duration',
      },
    ],
  },
  [Project.rapidRange]: {
    title: 'rapid range',
    tagline: 'Cyber range designer tool',
    description: `
    Being SimSpace's external innovation team. In other words - build something new.. from scratch. The result? A fully fledged virtual network designer that allows users to visually build networks like never before.
      `,
    images: [
      {
        src: '/projects/rapid-range/rapid-range-1.png',
        caption: 'Screenshot of the designer tool',
      },
      {
        src: '/projects/rapid-range/rapid-range-2.png',
        caption: '',
      },
      {
        src: '/projects/rapid-range/rapid-range-3.png',
        caption: '',
      },
      {
        src: '/projects/rapid-range/rapid-range-4.png',
        caption: '',
      },
      {
        src: '/projects/rapid-range/rapid-range-5.png',
        caption: '',
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'An intuitive and interactive tool for users to design networks.',
        text: `        
          Collaborating with SimSpace, the team embarked on a groundbreaking initiative to create a virtual network designer from the ground up. This dynamic tool goes beyond conventional approaches, offering users an unprecedented visual experience in constructing networks. With an intuitive interface, users can seamlessly design and customize networks, fostering a more efficient and user-friendly approach to network architecture. The fully-fledged virtual network designer supports a range of industries, providing a versatile solution for diverse networking needs. Its adaptability extends to both seasoned professionals and those new to network design, offering a learning curve that accommodates various skill levels. The result is a cutting-edge product that not only meets the initial innovation task but also sets a new standard in the field of virtual network design.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Cyber Security',
        label: 'Industry',
      },
      {
        value: 'Web app',
        label: 'Type',
      },
      {
        value: 'Sept 2023',
        label: 'Launch',
      },
    ],
  },
  [Project.cyberWorld]: {
    title: 'cyber world',
    tagline: 'Global cyber archive',
    description: `
      The cybersecurity prototype enables users to explore an archive of cyber attacks, providing valuable insights into diverse threats. The user-friendly interface facilitates easy navigation and analysis of historical attacks through detailed case studies and visualizations. By offering a proactive understanding of attack vectors and mitigation strategies, the prototype serves as a practical resource to enhance users' cybersecurity awareness.
      `,
    images: [
      {
        src: '/projects/cyber-world/cyber-world-1.png',
        caption: `Global archive of cyber attacks.`,
      },
      {
        src: '/projects/cyber-world/cyber-world-2.png',
        caption: ``,
      },
      {
        src: '/projects/cyber-world/cyber-world-3.png',
        caption: ``,
      },
      {
        src: '/projects/cyber-world/cyber-world-4.png',
        caption: ``,
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'Design a fun and interactive way for users to explore an archive of cyber attacks.',
        text: `          
        The innovative cybersecurity prototype introduces a unique platform for users to explore an extensive archive of cyber attacks. This interactive tool empowers users to gain valuable insights into diverse cyber threats, fostering a deeper understanding of evolving security challenges. The prototype offers a user-friendly interface, allowing individuals to navigate and analyze historical cyber attacks with ease. Through detailed case studies and interactive visualizations, users can dissect attack vectors, methodologies, and mitigation strategies, enhancing their overall cybersecurity awareness. This hands-on approach facilitates a proactive stance against potential threats, equipping users with knowledge to bolster their digital defenses. As cyber threats continue to evolve, our prototype serves as a valuable resource, arming users with the information needed to stay ahead in the ever-changing landscape of cybersecurity.
              `,
      },
    ],
    stats: [
      {
        value: 'B2C',
        label: 'Market',
      },
      {
        value: 'Cyber Security',
        label: 'Industry',
      },
      {
        value: 'Web app',
        label: 'Type',
      },
    ],
  },
  [Project.simspace]: {
    title: 'simspace',
    tagline: 'Corporate brand and website',
    description: `
    Undertook a redesign initiative for a cybersecurity client, focusing on a balanced blend of modern aesthetics and effective communication of security features. The revamped brand identity now portrays strength and trust, aligning with the client's position as a leader in the cybersecurity sector. The website redesign emphasizes user-friendly navigation and educational content, providing a secure and informative online experience.
      `,
    images: [
      {
        src: '/projects/simspace/simspace-1.png',
        caption: ``,
      },
      {
        src: '/projects/simspace/simspace-2.png',
        caption: ``,
      },
      {
        src: '/projects/simspace/simspace-3.png',
        caption: ``,
      },
      {
        src: '/projects/simspace/simspace-4.png',
        caption: ``,
      },
      {
        src: '/projects/simspace/simspace-5.png',
        caption: ``,
      },
    ],
    text: [
      {
        tag: 'Challenge',
        quote:
          'Modernize the brand identity and website of a leading cyber security company.',
        text: `
        Understanding the critical nature of their industry, the team executed a comprehensive redesign strategy that seamlessly married modern aesthetics with robust security communication. The revitalized brand identity reflects strength, trust, and innovation, positioning SimSpace as a leader in the cybersecurity landscape. The website redesign not only boasts an intuitive user interface but also emphasizes user education on cybersecurity best practices. Leveraging cutting-edge design elements, it was ensured that the website is not only visually compelling but also aligns with the client's commitment to cutting-edge technology. The result is a sophisticated and secure online platform that reinforces the client's authority in the cybersecurity domain, providing a memorable and trustworthy digital experience for their audience.
              `,
      },
    ],
    stats: [
      {
        value: 'B2B',
        label: 'Market',
      },
      {
        value: 'Cyber Security',
        label: 'Industry',
      },
      {
        value: 'Static Website',
        label: 'Type',
      },
      {
        value: 'Jan 2021',
        label: 'Launch',
      },
    ],
  },
}
