import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'
import { P } from './Text'

export const SectionHeader = (props: {
  title: string
  description: string
  arrowRightLink?: string
}) => {
  return (
    <SectionHeaderWrapper>
      <div
        style={{
          width: 48,
          height: 1,
          background: 'white',
          marginTop: 64,
        }}
      />

      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </SectionHeaderWrapper>
  )
}

const SectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  ${DESKTOP_STYLE} {
    padding: 64px 0;
  }

  ${TABLET_STYLE} {
    padding: 48px 0;
  }

  ${MOBILE_STYLE} {
    padding: 32px 0;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-transform: uppercase;

  ${DESKTOP_STYLE} {
    font-size: 14px;
    letter-spacing: 0em;
  }

  ${TABLET_STYLE} {
    font-size: 14px;
    letter-spacing: -0.04em;
  }

  ${MOBILE_STYLE} {
    font-size: 14px;
    letter-spacing: -0.04em;
  }
`

const Description = styled(P)`
  ${DESKTOP_STYLE} {
    max-width: 500px;
  }

  ${TABLET_STYLE} {
    max-width: 500px;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }
`
