import React, { ReactNode } from 'react'
import { ThemeProvider as _ThemeProvider } from 'styled-components'
import { useViewport, Viewport } from './ViewportProvider'

const mapViewportToGutter: Record<Viewport, number> = {
  [Viewport.Mobile]: 24,
  [Viewport.Tablet]: 32,
  [Viewport.Desktop]: 64,
}

export const ThemeProvider = (props: { children: ReactNode }) => {
  const viewport = useViewport()

  return (
    <_ThemeProvider
      theme={{
        gutter: mapViewportToGutter[viewport],
        colors: {
          primary: 'rgba(237, 36, 21, 0.8)',
          secondary: 'rgba(237, 36, 21, 0.4)',
          border: 'rgba(255, 255, 255, 0.1)',
          bg1: 'rgb(23,24,29)',
          bg2: '#1a1c21',
          bg3: 'rgba(255,255,255,0.02)',
          textPrimary: 'white',
          textSecondary: 'rgba(255,255,255,0.7)',
        },
      }}
    >
      {props.children}
    </_ThemeProvider>
  )
}
