// Country Vertex Points
export const country = [
  {
    name: 'Afghanistan',
    vertexPoint: [
      ['m', 1369.9, 333.8],
      [-5.4, 0],
      [-3.8, -0.5],
      [-2.5, 2.9],
      [-2.1, 0.7],
      [-1.5, 1.3],
      [-2.6, -2.1],
      [-1, -5.4],
      [-1.6, -0.3],
      [0, -2],
      [-3.2, -1.5],
      [-1.7, 2.3],
      [0.2, 2.6],
      [-0.6, 0.9],
      [-3.2, -0.1],
      [-0.9, 3],
      [-2.1, -1.3],
      [-3.3, 2.1],
      [-1.8, -0.8],
      [-4.3, -1.4],
      [-2.9, 0],
      [-1.6, -0.2],
      [-2.9, -1.7],
      [-0.3, 2.3],
      [-4.1, 1.2],
      [0.1, 5.2],
      [-2.5, 2],
      [-4, 0.9],
      [-0.4, 3],
      [-3.9, 0.8],
      [-5.9, -2.4],
      [-0.5, 8],
      [-0.5, 4.7],
      [2.5, 0.9],
      [-1.6, 3.5],
      [2.7, 5.1],
      [1.1, 4],
      [4.3, 1.1],
      [1.1, 4],
      [-3.9, 5.8],
      [9.6, 3.2],
      [5.3, -0.9],
      [3.3, 0.8],
      [0.9, -1.4],
      [3.8, 0.5],
      [6.6, -2.6],
      [-0.8, -5.4],
      [2.3, -3.6],
      [4, 0],
      [0.2, -1.7],
      [4, -0.9],
      [2.1, 0.6],
      [1.7, -1.8],
      [-1.1, -3.8],
      [1.5, -3.8],
      [3, -1.6],
      [-3, -4.2],
      [5.1, 0.2],
      [0.9, -2.3],
      [-0.8, -2.5],
      [2, -2.7],
      [-1.4, -3.2],
      [-1.9, -2.8],
      [2.4, -2.8],
      [5.3, -1.3],
      [5.8, -0.8],
      [2.4, -1.2],
      [2.8, -0.7],
      [-1.4, -1.9],
      'z',
    ],
  },
  {
    name: 'Albania',
    vertexPoint: [
      ['m', 1077.5, 300.5],
      [-2, 3.1],
      [0.5, 1.9],
      [0, 0],
      [1, 1],
      [-0.5, 1.9],
      [-0.1, 4.3],
      [0.7, 3],
      [3, 2.1],
      [0.2, 1.4],
      [1, 0.4],
      [2.1, -3],
      [0.1, -2.1],
      [1.6, -0.9],
      [0, -1.6],
      [-2.3, -1.6],
      [-0.9, -2.6],
      [0.4, -2.1],
      [0, 0],
      [-0.5, -2.3],
      [-1.3, -0.6],
      [-1.3, -1.6],
      [-1.3, 0.5],
      [-0.4, -1.2],
      'z',
    ],
  },
  {
    name: 'Algeria',
    vertexPoint: [
      ['m', 1021, 336.9],
      [-3.6, 0.4],
      [-2.2, -1.5],
      [-5.6, 0],
      [-4.9, 2.6],
      [-2.7, -1],
      [-8.7, 0.5],
      [-8.9, 1.2],
      [-5, 2],
      [-3.4, 2.6],
      [-5.7, 1.2],
      [-5.1, 3.5],
      [2, 4.1],
      [0.3, 3.9],
      [1.8, 6.7],
      [1.4, 1.4],
      [-1, 2.5],
      [-7, 1],
      [-2.5, 2.4],
      [-3.1, 0.5],
      [-0.3, 4.7],
      [-6.3, 2.5],
      [-2.1, 3.2],
      [-4.4, 1.7],
      [-5.4, 1],
      [-8.9, 4.7],
      [-0.1, 7.5],
      [0, 0.4],
      [-0.1, 1.2],
      [20.3, 15.5],
      [18.4, 13.9],
      [18.6, 13.8],
      [1.3, 3],
      [3.4, 1.8],
      [2.6, 1.1],
      [0.1, 4],
      [6.1, -0.6],
      [7.8, -2.8],
      [15.8, -12.5],
      [18.6, -12.2],
      [-2.5, -4],
      [-4.3, -2.9],
      [-2.6, 1.2],
      [-2, -3.6],
      [-0.2, -2.7],
      [-3.4, -4.7],
      [2.1, -2.6],
      [-0.5, -4],
      [0.6, -3.5],
      [-0.5, -2.9],
      [0.9, -5.2],
      [-0.4, -3],
      [-1.9, -5.6],
      [-2.6, -11.3],
      [-3.4, -2.6],
      [0, -1.5],
      [-4.5, -3.8],
      [-0.6, -4.8],
      [3.2, -3.6],
      [1.1, -5.3],
      [-1, -6.2],
      [1, -3.3],
      'z',
    ],
  },
  {
    name: 'Andorra',
    vertexPoint: [
      ['m', 985.4, 301.7],
      [0.1, -0.2],
      [0.1, -0.2],
      [0, -0.1],
      [-0.2, -0.1],
      [-0.7, -0.2],
      [-0.3, -0.1],
      [-0.2, 0.1],
      [-0.2, 0.2],
      [-0.1, 0.3],
      [0.1, 0.1],
      [0, 0.2],
      [0, 0.2],
      [0.1, 0.2],
      [0.2, 0],
      [0.2, 0],
      [0.3, -0.1],
      [0.5, -0.3],
      [0.1, 0],
      'z',
    ],
  },
  {
    name: 'Angola',
    vertexPoint: [
      ['m', 1068.3, 609.6],
      [-16.6, -0.1],
      [-1.9, 0.7],
      [-1.7, -0.1],
      [-2.3, 0.9],
      [-0.5, 1.2],
      [2.8, 4],
      [1.1, 4.3],
      [1.6, 6.1],
      [-1.7, 2.6],
      [-0.3, 1.3],
      [1.3, 3.8],
      [1.5, 3.9],
      [1.6, 2.2],
      [0.3, 3.6],
      [-0.7, 4.8],
      [-1.8, 2.8],
      [-3.3, 4.2],
      [-1.3, 2.6],
      [-1.9, 5.7],
      [-0.3, 2.7],
      [-2, 5.9],
      [-0.9, 5.5],
      [0.5, 4],
      [2.7, -1.2],
      [3.3, -1],
      [3.6, 0.1],
      [3.2, 2.9],
      [0.9, -0.4],
      [22.5, -0.3],
      [3.7, 3],
      [13.4, 0.9],
      [10.3, -2.5],
      [-3.5, -4],
      [-3.6, -5.2],
      [0.8, -20.3],
      [11.6, 0.1],
      [-0.5, -2.2],
      [0.9, -2.4],
      [-0.9, -3],
      [0.7, -3],
      [-0.5, -2],
      [-2.6, -0.4],
      [-3.5, 1],
      [-2.4, -0.2],
      [-1.4, 0.6],
      [0.5, -7.6],
      [-1.9, -2.3],
      [-0.3, -4],
      [0.9, -3.8],
      [-1.2, -2.4],
      [0, -4],
      [-6.8, 0],
      [0.5, -2.3],
      [-2.9, 0],
      [-0.3, 1.1],
      [-3.4, 0.3],
      [-1.5, 3.7],
      [-0.9, 1.6],
      [-3, -0.9],
      [-1.9, 0.9],
      [-3.7, 0.5],
      [-2.1, -3.3],
      [-1.3, -2.1],
      [-1.6, -3.8],
      [-1.3, -4.7],
      'z',
      ['m', -21.8, -1.3],
      [0.2, -2.7],
      [0.9, -1.7],
      [2, -1.3],
      [-2, -2.2],
      [-1.8, 1.1],
      [-2.2, 2.7],
      [1.4, 4.8],
      [1.5, -0.7],
      'z',
    ],
  },
  {
    name: 'Antigua and Barbuda',
    vertexPoint: [
      ['m', 634.3, 463.8],
      [0.2, -0.1],
      [0, -0.1],
      [0, -0.2],
      [-0.1, -0.1],
      [-0.1, -0.2],
      [-0.4, -0.2],
      [-0.5, 0.5],
      [0, 0.2],
      [0.1, 0.3],
      [0.6, 0.1],
      [0.2, -0.2],
      'z',
      ['m', 0.2, -3.5],
      [0, -0.5],
      [-0.1, -0.2],
      [-0.3, 0],
      [-0.1, -0.1],
      [-0.1, 0],
      [-0.1, 0.1],
      [0.1, 0.6],
      [0.5, 0.3],
      [0.1, -0.2],
      'z',
    ],
  },
  {
    name: 'Anguilla',
    vertexPoint: [
      ['m', 627.9, 456.2],
      [0.1, -0.2],
      [-0.2, -0.1],
      [-0.8, 0.5],
      [0, 0.1],
      [0.9, -0.3],
      'z',
    ],
  },
  {
    name: 'Aruba',
    vertexPoint: [
      ['m', 586.6, 492.9],
      [-0.1, -0.1],
      [-0.3, -0.6],
      [-0.3, -0.3],
      [-0.1, 0.1],
      [-0.1, 0.3],
      [0.3, 0.3],
      [0.3, 0.4],
      [0.3, 0.1],
      [0, -0.2],
      'z',
    ],
  },
  {
    name: 'Argentina',
    vertexPoint: [
      ['m', 669.8, 920.7],
      [0.9, -3],
      [-7.3, -1.5],
      [-7.7, -3.6],
      [-4.3, -4.6],
      [-3, -2.8],
      [5.9, 13.5],
      [5, 0],
      [2.9, 0.2],
      [3.3, 2.1],
      [4.3, -0.3],
      'z',
      ['m', -50.4, -208.1],
      [-7.4, -1.5],
      [-4, 5.7],
      [0.9, 1.6],
      [-1.1, 6.6],
      [-5.6, 3.2],
      [1.6, 10.6],
      [-0.9, 2],
      [2, 2.5],
      [-3.2, 4],
      [-2.6, 5.9],
      [-0.9, 5.8],
      [1.7, 6.2],
      [-2.1, 6.5],
      [4.9, 10.9],
      [1.6, 1.2],
      [1.3, 5.9],
      [-1.6, 6.2],
      [1.4, 5.4],
      [-2.9, 4.3],
      [1.5, 5.9],
      [3.3, 6.3],
      [-2.5, 2.4],
      [0.3, 5.7],
      [0.7, 6.4],
      [3.3, 7.6],
      [-1.6, 1.2],
      [3.6, 7.1],
      [3.1, 2.3],
      [-0.8, 2.6],
      [2.8, 1.3],
      [1.3, 2.3],
      [-1.8, 1.1],
      [1.8, 3.7],
      [1.1, 8.2],
      [-0.7, 5.3],
      [1.8, 3.2],
      [-0.1, 3.9],
      [-2.7, 2.7],
      [3.1, 6.6],
      [2.6, 2.2],
      [3.1, -0.4],
      [1.8, 4.6],
      [3.5, 3.6],
      [12, 0.8],
      [4.8, 0.9],
      [2.2, 0.4],
      [-4.7, -3.6],
      [-4.1, -6.3],
      [0.9, -2.9],
      [3.5, -2.5],
      [0.5, -7.2],
      [4.7, -3.5],
      [-0.2, -5.6],
      [-5.2, -1.3],
      [-6.4, -4.5],
      [-0.1, -4.7],
      [2.9, -3.1],
      [4.7, -0.1],
      [0.2, -3.3],
      [-1.2, -6.1],
      [2.9, -3.9],
      [4.1, -1.9],
      [-2.5, -3.2],
      [-2.2, 2],
      [-4, -1.9],
      [-2.5, -6.2],
      [1.5, -1.6],
      [5.6, 2.3],
      [5, -0.9],
      [2.5, -2.2],
      [-1.8, -3.1],
      [-0.1, -4.8],
      [-2, -3.8],
      [5.8, 0.6],
      [10.2, -1.3],
      [6.9, -3.4],
      [3.3, -8.3],
      [-0.3, -3.2],
      [-3.9, -2.8],
      [-0.1, -4.5],
      [-7.8, -5.5],
      [-0.3, -3.3],
      [-0.4, -4.2],
      [0.9, -1.4],
      [-1.1, -6.3],
      [0.3, -6.5],
      [0.5, -5.1],
      [5.9, -8.6],
      [5.3, -6.2],
      [3.3, -2.6],
      [4.2, -3.5],
      [-0.5, -5.1],
      [-3.1, -3.7],
      [-2.6, 1.2],
      [-0.3, 5.7],
      [-4.3, 4.8],
      [-4.2, 1.1],
      [-6.2, -1],
      [-5.7, -1.8],
      [4.2, -9.6],
      [-1.1, -2.8],
      [-5.9, -2.5],
      [-7.2, -4.7],
      [-4.6, -1],
      [-11.2, -10.4],
      [-1, -1.3],
      [-6.3, -0.3],
      [-1.6, 5.1],
      [-3.7, -4.6],
      'z',
    ],
  },
  {
    name: 'Armenia',
    vertexPoint: [
      ['m', 1219, 325.1],
      [-0.9, -4.4],
      [-2.5, -1.1],
      [-2.5, -1.7],
      [1, -2],
      [-3.1, -2.2],
      [0.7, -1.5],
      [-2.2, -1.1],
      [-1.4, -1.7],
      [-6.9, 1],
      [1.3, 2.2],
      [0, 3.1],
      [4.2, 1.5],
      [2.4, 1.9],
      [1, -0.2],
      [1.8, 1.7],
      [2.3, 0],
      [0.2, 1],
      [2.8, 3.7],
      [1.8, -0.2],
      'z',
    ],
  },
  {
    name: 'Australia',
    vertexPoint: [
      ['m', 1726.7, 832],
      [-3, -0.5],
      [-1.9, 2.9],
      [-0.6, 5.4],
      [-2.1, 4],
      [-0.5, 5.3],
      [3, 0.2],
      [0.8, 0.3],
      [6.6, -4.3],
      [0.6, 1.7],
      [4, -4.9],
      [3.2, -2.2],
      [4.5, -7.3],
      [-2.8, -0.5],
      [-4.8, 1.2],
      [-3.4, 0.9],
      [-3.6, -2.2],
      'z',
      ['m', 50.1, -172.3],
      [0.5, -2.3],
      [0.1, -3.6],
      [-1.6, -3.2],
      [0.1, -2.7],
      [-1.3, -0.8],
      [0.1, -3.9],
      [-1.2, -3.2],
      [-2.3, 2.4],
      [-0.4, 1.8],
      [-1.5, 3.5],
      [-1.8, 3.4],
      [0.6, 2.1],
      [-1.2, 1.3],
      [-1.5, 4.8],
      [0.1, 3.7],
      [-0.7, 1.8],
      [0.3, 3.1],
      [-2.6, 5],
      [-1.3, 3.5],
      [-1.7, 2.9],
      [-1.7, 3.4],
      [-4.1, 2.1],
      [-4.9, -2.1],
      [-0.5, -2],
      [-2.5, -1.6],
      [-1.6, 0],
      [-3.3, -3.8],
      [-2.5, -2.2],
      [-3.9, -2],
      [-3.9, -3.5],
      [-0.1, -1.8],
      [2.5, -3.1],
      [2.1, -3.2],
      [-0.3, -2.6],
      [1.9, -0.2],
      [2.5, -2.5],
      [2, -3.4],
      [-2.2, -3.2],
      [-1.5, 1.2],
      [-2, -0.5],
      [-3.5, 1.8],
      [-3.2, -2],
      [-1.7, 0.7],
      [-4.5, -1.6],
      [-2.7, -2.7],
      [-3.5, -1.5],
      [-3.1, 0.9],
      [3.9, 2.1],
      [-0.3, 3.2],
      [-4.8, 1.2],
      [-2.8, -0.7],
      [-3.6, 2.2],
      [-2.9, 3.7],
      [0.6, 1.5],
      [-2.7, 1.7],
      [-3.4, 5.1],
      [0.6, 3.5],
      [-3.4, -0.6],
      [-3.5, 0],
      [-2.5, -3.8],
      [-3.7, -2.9],
      [-2.8, 0.8],
      [-2.6, 0.9],
      [-0.3, 1.6],
      [-2.4, -0.7],
      [-0.3, 1.8],
      [-3, 1.1],
      [-1.7, 2.5],
      [-3.5, 3.1],
      [-1.4, 4.8],
      [-2.3, -1.3],
      [-2.2, 3.1],
      [1.5, 3],
      [-2.6, 1.2],
      [-1.4, -5.5],
      [-4.8, 5.4],
      [-0.8, 3.5],
      [-0.7, 2.5],
      [-3.8, 3.3],
      [-2, 3.4],
      [-3.5, 2.8],
      [-6.1, 1.9],
      [-3.1, -0.2],
      [-1.5, 0.6],
      [-1.1, 1.4],
      [-3.5, 0.7],
      [-4.7, 2.4],
      [-1.4, -0.8],
      [-2.6, 0.5],
      [-4.6, 2.3],
      [-3.2, 2.7],
      [-4.8, 2.1],
      [-3.1, 4.4],
      [0.4, -4.8],
      [-3.1, 4.6],
      [-0.1, 3.7],
      [-1.3, 3.2],
      [-1.5, 1.5],
      [-1.3, 3.7],
      [0.9, 1.9],
      [0.1, 2],
      [1.6, 5],
      [-0.7, 3.3],
      [-1, -2.5],
      [-2.3, -1.8],
      [0.4, 5.9],
      [-1.7, -2.8],
      [0.1, 2.8],
      [1.8, 5],
      [-0.6, 5],
      [1.7, 2.5],
      [-0.4, 1.9],
      [0.9, 4.1],
      [-1.3, 3.6],
      [-0.3, 3.6],
      [0.7, 6.5],
      [-0.7, 3.7],
      [-2.2, 4.4],
      [-0.6, 2.3],
      [-1.5, 1.5],
      [-2.9, 0.8],
      [-1.5, 3.7],
      [2.4, 1.2],
      [4, 4.1],
      [3.6, 0],
      [3.8, 0.3],
      [3.3, -2.1],
      [3.4, -1.8],
      [1.4, 0.3],
      [4.5, -3.4],
      [3.8, -0.3],
      [4.1, -0.7],
      [4.2, 1.2],
      [3.6, -0.6],
      [4.6, -0.2],
      [3, -2.6],
      [2.3, -3.3],
      [5.2, -1.5],
      [6.9, -3.2],
      [5, 0.4],
      [6.9, -2.1],
      [7.8, -2.3],
      [9.8, -0.6],
      [4, 3.1],
      [3.7, 0.2],
      [5.3, 3.8],
      [-1.6, 1.5],
      [1.8, 2.4],
      [1.3, 4.6],
      [-1.6, 3.4],
      [2.9, 2.6],
      [4.3, -5.1],
      [4.3, -2.1],
      [6.7, -5.5],
      [-1.6, 4.7],
      [-3.4, 3.2],
      [-2.5, 3.7],
      [-4.4, 3.5],
      [5.2, -1.2],
      [4.7, -4.4],
      [-0.9, 4.8],
      [-3.2, 3.1],
      [4.7, 0.8],
      [1.3, 2.6],
      [-0.4, 3.3],
      [-1.5, 4.9],
      [1.4, 4],
      [4, 1.9],
      [2.8, 0.4],
      [2.4, 1],
      [3.5, 1.8],
      [7.2, -4.7],
      [3.5, -1.2],
      [-2.7, 3.4],
      [2.6, 1.1],
      [2.7, 2.8],
      [4.7, -2.7],
      [3.8, -2.5],
      [6.3, -2.7],
      [6, -0.2],
      [4.2, -2.3],
      [0.9, -2],
      [3, -4.5],
      [3.9, -4.8],
      [3.6, -3.2],
      [4.4, -5.6],
      [3.3, -3.1],
      [4.4, -5],
      [5.4, -3.1],
      [5, -5.8],
      [3.1, -4.5],
      [1.4, -3.6],
      [3.8, -5.7],
      [2.1, -2.9],
      [2.5, -5.7],
      [-0.7, -5.4],
      [1.7, -3.9],
      [1.1, -3.7],
      [0, -5.1],
      [-2.8, -5.1],
      [-1.9, -2.5],
      [-2.9, -3.9],
      [0.7, -6.7],
      [-1.5, 1],
      [-1.6, -2.8],
      [-2.5, 1.4],
      [-0.6, -6.9],
      [-2.2, -4],
      [1, -1.5],
      [-3.1, -2.8],
      [-3.2, -3],
      [-5.3, -3.3],
      [-0.9, -4.3],
      [1.3, -3.3],
      [-0.4, -5.5],
      [-1.3, -0.7],
      [-0.2, -3.2],
      [-0.2, -5.5],
      [1.1, -2.8],
      [-2.3, -2.5],
      [-1.4, -2.7],
      [-3.9, 2.4],
      [-1.2, -5],
      'z',
    ],
  },
  {
    name: 'Austria',
    vertexPoint: [
      ['m', 1060.2, 264],
      [-2.3, -1.2],
      [-2.3, 0.3],
      [-4, -1.9],
      [-1.7, 0.5],
      [-2.6, 2.5],
      [-3.8, -2],
      [-1.5, 2.9],
      [-1.7, 0.8],
      [1, 4],
      [-0.4, 1.1],
      [-1.7, -1.3],
      [-2.4, -0.2],
      [-3.4, 1.2],
      [-4.4, -0.3],
      [-0.6, 1.6],
      [-2.6, -1.7],
      [-1.5, 0.3],
      [0.2, 1.1],
      [-0.7, 1.6],
      [2.3, 1.1],
      [2.6, 0.2],
      [3.1, 0.9],
      [0.5, -1.2],
      [4.8, -1.1],
      [1.3, 2.2],
      [7.2, 1.6],
      [4.2, 0.4],
      [2.4, -1.4],
      [4.3, -0.1],
      [0.9, -1.1],
      [1.3, -4],
      [-1.1, -1.3],
      [2.8, 0],
      [0.2, -2.6],
      [-0.7, -2.1],
      [0.3, -0.8],
      'z',
    ],
  },
  {
    name: 'Azerbaijan',
    vertexPoint: [
      ['m', 1210.1, 318.9],
      [-1, 0.2],
      [1.2, 2.4],
      [3.2, 2.9],
      [3.7, 0.9],
      [-2.8, -3.7],
      [-0.2, -1],
      [-2.3, 0],
      [-1.8, -1.7],
      'z',
      ['m', 10.4, -9.3],
      [-4.3, -3.8],
      [-1.5, -0.2],
      [-1.1, 0.9],
      [3.2, 3.4],
      [-0.6, 0.7],
      [-2.8, -0.4],
      [-4.2, -1.8],
      [-1.1, 1],
      [1.4, 1.7],
      [2.2, 1.1],
      [-0.7, 1.5],
      [3.1, 2.2],
      [-1, 2],
      [2.5, 1.7],
      [2.5, 1.1],
      [0.9, 4.4],
      [5.3, -4.7],
      [1.9, -0.5],
      [1.9, 1.9],
      [-1.2, 3.1],
      [3.8, 3.4],
      [1.3, -0.3],
      [-0.8, -3.2],
      [1.7, -1.5],
      [0.4, -2.2],
      [-0.1, -5],
      [4.2, -0.5],
      [-2, -1.7],
      [-2.5, -0.2],
      [-3.5, -4.5],
      [-3.4, -3.2],
      [0, 0],
      [-2.6, 2.5],
      [-0.5, 1.5],
      [-2.4, -0.4],
      'z',
    ],
  },
  {
    name: 'Burundi',
    vertexPoint: [
      ['m', 1148.2, 590],
      [-0.3, -2.5],
      [0, 0],
      [-3, -0.4],
      [-1.7, 3.6],
      [-3.5, -0.5],
      [1.4, 2.9],
      [0.1, 1.1],
      [2, 6.1],
      [-0.1, 0.3],
      [0.6, -0.1],
      [2.1, -2.3],
      [2.2, -3.3],
      [1.4, -1.4],
      [0, -2],
      [-1.2, -1.5],
      'z',
    ],
  },
  {
    name: 'Belgium',
    vertexPoint: [
      ['m', 1000.7, 246.2],
      [-4.4, 1.3],
      [-3.6, -0.5],
      [0, 0],
      [-3.8, 1.2],
      [0.7, 2.2],
      [2.2, 0.1],
      [2.4, 2.4],
      [3.4, 2.9],
      [2.5, -0.4],
      [4.4, 2.8],
      [0.4, -3.5],
      [1.3, -0.2],
      [0.4, -4.2],
      [-2.8, -1.4],
      [-3.1, -2.7],
      'z',
    ],
  },
  {
    name: 'Benin',
    vertexPoint: [
      ['m', 996.9, 498],
      [-4.3, -3.7],
      [-2, 0],
      [-1.9, 1.9],
      [-1.2, 1.9],
      [-2.7, 0.6],
      [-1.2, 2.8],
      [-1.9, 0.7],
      [-0.7, 3.3],
      [1.7, 1.9],
      [2, 2.3],
      [0.2, 3.1],
      [1.1, 1.3],
      [-0.2, 14.6],
      [1.4, 4.4],
      [4.6, -0.8],
      [0.3, -10.2],
      [-0.1, -4.1],
      [1, -4],
      [1.7, -1.9],
      [2.7, -4],
      [-0.6, -1.7],
      [1.1, -2.5],
      [-1.2, -3.8],
      [0.2, -2.1],
      'z',
    ],
  },
  {
    name: 'Burkina Faso',
    colour: '',
    vertexPoint: [
      ['m', 978.8, 477.2],
      [-3.6, 0],
      [-1.4, -1.2],
      [-3, 0.9],
      [-5.2, 2.6],
      [-1.1, 2],
      [-4.3, 2.9],
      [-0.8, 1.6],
      [-2.3, 1.3],
      [-2.7, -0.9],
      [-1.6, 1.6],
      [-0.8, 4.4],
      [-4.5, 5.2],
      [0.2, 2.2],
      [-1.6, 2.7],
      [0.4, 3.7],
      [2.5, 1.4],
      [1, 2.1],
      [2.5, 1.3],
      [1.9, -1.6],
      [2.7, -0.2],
      [3.8, 1.6],
      [-0.8, -4.8],
      [0.2, -3.6],
      [9.7, -0.3],
      [2.4, 0.5],
      [1.8, -1],
      [2.6, 0.5],
      [4.9, 0.1],
      [1.9, -0.7],
      [1.2, -2.8],
      [2.7, -0.6],
      [1.2, -1.9],
      [0.1, -4.4],
      [-6.4, -1.4],
      [-0.2, -3.1],
      [-3.1, -4.1],
      [-0.8, -2.9],
      [0.5, -3.1],
      'z',
    ],
  },
  {
    name: 'Bahamas',
    vertexPoint: [
      ['m', 574.4, 437.3],
      [0.2, -0.6],
      [-0.3, -0.1],
      [-0.5, 0.7],
      [-0.6, 0.3],
      [-0.3, 0],
      [-0.7, -0.3],
      [-0.5, 0],
      [-0.4, 0.5],
      [-0.6, 0.1],
      [0.1, 0.1],
      [0, 0.2],
      [-0.2, 0.3],
      [0, 0.2],
      [0.1, 0.3],
      [1.5, -0.1],
      [1.3, -0.2],
      [0.7, -0.9],
      [0.2, -0.5],
      'z',
      ['m', 0.8, -2],
      [-0.4, -0.3],
      [-0.4, 0.3],
      [0.1, 0.3],
      [0.7, -0.3],
      'z',
      ['m', 0, -5.8],
      [-0.4, -0.2],
      [-0.3, 0.5],
      [0.3, 0.1],
      [0.7, -0.1],
      [0.5, 0.1],
      [0.5, 0.4],
      [0.3, -0.2],
      [-0.1, -0.1],
      [-0.4, -0.3],
      [-0.6, -0.1],
      [-0.2, 0],
      [-0.3, -0.1],
      'z',
      ['m', -6.6, 1.3],
      [0.7, -0.6],
      [0.7, -0.3],
      [0.9, -1.1],
      [-0.1, -0.9],
      [0.2, -0.4],
      [-0.6, 0.1],
      [-0.1, 0.3],
      [-0.1, 0.3],
      [0.3, 0.4],
      [0, 0.2],
      [-0.2, 0.4],
      [-0.3, 0.1],
      [-0.1, 0.2],
      [-0.3, 0.1],
      [-0.4, 0.5],
      [-0.8, 0.6],
      [-0.2, 0.3],
      [0.4, -0.2],
      'z',
      ['m', 1.2, -3.2],
      [-0.6, -0.2],
      [-0.2, -0.4],
      [-0.4, -0.1],
      [-0.1, 0.2],
      [0, 0.2],
      [0.1, 0.4],
      [0.2, -0.1],
      [0.8, 0.4],
      [0.4, -0.3],
      [-0.2, -0.1],
      'z',
      ['m', -4.1, -1.1],
      [0, -0.7],
      [-0.4, -0.5],
      [-0.6, -0.4],
      [-0.1, -1.2],
      [-0.3, -0.7],
      [-0.2, -0.6],
      [-0.4, -0.8],
      [0, 0.5],
      [0.1, 0.1],
      [0.1, 0.6],
      [0.4, 0.9],
      [0.1, 0.4],
      [-0.1, 0.4],
      [-0.4, 0.1],
      [-0.1, 0.2],
      [0.5, 0.3],
      [0.8, 0.3],
      [0.5, 1.3],
      [0.1, -0.2],
      'z',
      ['m', -4.1, -3.5],
      [-0.5, -0.3],
      [-0.2, -0.3],
      [-0.7, -0.7],
      [-0.3, -0.1],
      [-0.2, 0.4],
      [0.4, 0.1],
      [0.9, 0.7],
      [0.4, 0.2],
      [0.2, 0],
      'z',
      ['m', 7.3, -4],
      [-0.1, -0.3],
      [-0.1, 0],
      [-0.3, 0.1],
      [-0.3, 0.9],
      [0.3, 0],
      [0.5, -0.7],
      'z',
      ['m', -17.6, -1.1],
      [-0.2, -0.3],
      [-0.3, 0.2],
      [-0.5, 0],
      [-0.2, 0.1],
      [-0.4, 0],
      [-0.3, 0.2],
      [0.4, 0.8],
      [0.3, 0.3],
      [0.1, 1],
      [0.2, 0.1],
      [-0.1, 0.7],
      [1.1, 0.1],
      [0.4, -0.8],
      [0, -0.3],
      [0, -0.1],
      [0, -0.2],
      [0, -0.2],
      [0, -0.9],
      [-0.3, -0.5],
      [-0.4, 0.6],
      [-0.4, -0.3],
      [0.6, -0.4],
      [0, -0.1],
      'z',
      ['m', 12.9, 0.3],
      [-1, -1.4],
      [0, -0.2],
      [-0.5, -1.5],
      [-0.3, -0.1],
      [-0.1, 0.1],
      [-0.1, 0.2],
      [0.4, 0.4],
      [0, 0.4],
      [0.3, 0.2],
      [0.4, 1.1],
      [0.4, 0.4],
      [-0.1, 0.3],
      [-0.4, 0.3],
      [-0.1, 0.2],
      [0.1, 0],
      [0.6, -0.1],
      [0.4, 0],
      [0, -0.3],
      'z',
      ['m', -10.5, -5.2],
      [0.5, -0.2],
      [0, 0],
      [-0.3, -0.2],
      [-0.7, 0],
      [-0.4, 0.1],
      [-0.2, 0.2],
      [0.1, 0.1],
      [0.4, 0.1],
      [0.6, -0.1],
      'z',
      ['m', -2.4, 2],
      [-0.5, -0.6],
      [-0.3, -0.9],
      [-0.2, -0.4],
      [0.1, -0.5],
      [-0.3, -0.4],
      [-0.6, -0.4],
      [-0.3, 0.1],
      [0.1, 1.1],
      [-0.2, 0.6],
      [-0.8, 1.1],
      [0.1, 0.4],
      [0, 0],
      [0.1, 0.2],
      [-0.5, 0.4],
      [0, -0.3],
      [-0.6, 0.1],
      [0.3, 0.5],
      [0.6, 0.4],
      [0.3, 0.1],
      [0.3, -0.2],
      [0, 0.5],
      [0.3, 0.4],
      [0.1, 0.4],
      [0.3, -0.3],
      [0.6, -0.2],
      [0.2, -0.2],
      [0.7, -0.4],
      [0, -0.2],
      [0.1, -0.6],
      [0.1, -0.7],
      'z',
      ['m', 6.7, -5],
      [-0.3, -0.5],
      [-0.1, 0.1],
      [-0.1, 0.4],
      [-0.3, 0.4],
      [0.5, -0.1],
      [0.4, 0.1],
      [0.6, 0.5],
      [0.7, 0.2],
      [0.3, 0.6],
      [0.6, 0.6],
      [0, 0.6],
      [-0.4, 0.6],
      [-0.1, 0.7],
      [-0.6, 0.1],
      [0.1, 0.1],
      [0.3, 0.3],
      [0.1, 0.4],
      [0.2, 0.2],
      [0, -0.7],
      [0.3, -0.8],
      [0.4, -1.3],
      [-0.1, -0.3],
      [-0.3, -0.3],
      [-0.7, -0.9],
      [-0.7, -0.3],
      [-0.8, -0.7],
      'z',
      ['m', -8.8, -7.9],
      [-0.5, -0.4],
      [-0.2, 0.4],
      [0, 0.1],
      [-0.1, 0.3],
      [-0.5, 0.4],
      [-0.5, 0.1],
      [-0.7, -0.6],
      [-0.2, -0.1],
      [0.8, 1.1],
      [0.3, 0.1],
      [0.4, 0],
      [0.9, -0.3],
      [1.6, -0.5],
      [1.7, -0.2],
      [0.1, -0.2],
      [-0.1, -0.3],
      [-0.8, 0.2],
      [-1, -0.1],
      [-0.2, 0.2],
      [-0.4, 0],
      [-0.6, -0.2],
      'z',
      ['m', 6.1, 5.2],
      [0.2, -0.3],
      [0.4, -1.8],
      [0.8, -0.6],
      [0.1, -1.2],
      [-0.5, -0.5],
      [-0.4, -0.2],
      [-0.1, -0.2],
      [0.1, -0.2],
      [-0.2, -0.1],
      [-0.3, -0.2],
      [-0.4, -0.6],
      [-0.4, -0.4],
      [-0.7, -0.1],
      [-0.6, -0.1],
      [-0.4, -0.1],
      [-0.5, 0.3],
      [0.8, 0],
      [1.5, 0.3],
      [0.7, 1.5],
      [0.5, 0.4],
      [0.1, 0.4],
      [-0.2, 0.4],
      [0, 0.4],
      [-0.3, 0.5],
      [-0.1, 0.8],
      [-0.3, 0.4],
      [-0.7, 0.5],
      [0.4, 0.2],
      [0.3, 0.6],
      [0.2, -0.1],
      'z',
    ],
  },
  {
    name: 'Bermuda',
    vertexPoint: [
      ['m', 630.2, 366.8],
      [0.4, -0.6],
      [-0.1, 0],
      [-0.5, 0.5],
      [-0.6, 0.2],
      [0.1, 0.1],
      [0.1, 0],
      [0.6, -0.2],
      'z',
    ],
  },
  {
    name: 'Barbados',
    vertexPoint: [
      ['m', 644.9, 488.9],
      [0.4, -0.4],
      [-0.3, -0.3],
      [-0.6, -0.8],
      [-0.3, 0.1],
      [0, 1],
      [0.1, 0.3],
      [0.5, 0.3],
      [0.2, -0.2],
      'z',
    ],
  },
  {
    name: 'Bangladesh',
    vertexPoint: [
      ['m', 1486.5, 431.9],
      [-4.5, -10.1],
      [-1.5, 0.1],
      [-0.2, 4],
      [-3.5, -3.3],
      [1.1, -3.6],
      [2.4, -0.4],
      [1.6, -5.3],
      [-3.4, -1.1],
      [-5, 0.1],
      [-5.4, -0.9],
      [-1.2, -4.4],
      [-2.7, -0.4],
      [-4.8, -2.7],
      [-1.2, 4.3],
      [4.6, 3.4],
      [-3.1, 2.4],
      [-0.8, 2.3],
      [3.7, 1.7],
      [-0.4, 3.8],
      [2.6, 4.8],
      [1.6, 5.2],
      [2.2, 0.6],
      [1.7, 0.7],
      [0.6, -1.2],
      [2.5, 1.3],
      [1.3, -3.5],
      [-0.9, -2.6],
      [5.1, 0.2],
      [2.8, 3.7],
      [1.5, 3.1],
      [0.8, 3.2],
      [2, 3.3],
      [-1.1, -5.1],
      [2.1, 1],
      [-0.5, -4.6],
      'z',
    ],
  },
  {
    name: 'Bulgaria',
    vertexPoint: [
      ['m', 1121.6, 294.3],
      [-3, -0.7],
      [-4, -2.2],
      [-5.8, 1.4],
      [-2.3, 1.6],
      [-7.5, -0.3],
      [-4, -1],
      [-1.9, 0.5],
      [-1.8, -2.6],
      [-1.1, 1.4],
      [0.7, 2.3],
      [2.8, 2.6],
      [-1.7, 1.9],
      [-0.7, 2],
      [0.6, 0.7],
      [-0.7, 0.9],
      [2.8, 2],
      [0.8, 4.1],
      [3.8, 0.2],
      [3.9, -1.7],
      [3.9, 2.1],
      [4.6, -0.6],
      [-0.3, -3],
      [5, -2],
      [4.5, 0.8],
      [-2.1, -3.5],
      [1.3, -4.4],
      [2.2, -2.5],
      'z',
    ],
  },
  {
    name: 'Bosnia and Herz.',
    vertexPoint: [
      ['m', 1062.2, 284.9],
      [-2.3, 0.1],
      [-1, 1.3],
      [-1.9, -1.4],
      [-0.9, 2.5],
      [2.7, 2.9],
      [1.3, 1.9],
      [2.5, 2.3],
      [2, 1.4],
      [2.2, 2.5],
      [4.7, 2.4],
      [0.4, -3.4],
      [1.5, -1.4],
      [0.9, -0.6],
      [1.2, -0.3],
      [0.5, -2.9],
      [-2.7, -2.3],
      [1, -2.7],
      [-1.8, 0],
      [0, 0],
      [-2.4, -1.4],
      [-3.5, 0.1],
      [-4.4, -1],
      'z',
    ],
  },
  {
    name: 'Belarus',
    vertexPoint: [
      ['m', 1112.8, 219.4],
      [-5.2, -1.5],
      [-4.6, 2.3],
      [-2.6, 1],
      [0.9, 2.6],
      [-3.5, 2],
      [-0.5, 3.4],
      [-4.8, 2.2],
      [-4.6, 0],
      [0.6, 2.7],
      [1.7, 2.3],
      [0.3, 2.4],
      [-2.7, 1.2],
      [1.9, 2.9],
      [0.5, 2.7],
      [2.2, -0.3],
      [2.4, -1.6],
      [3.7, -0.2],
      [5, 0.5],
      [5.6, 1.5],
      [3.8, 0.1],
      [2, 0.9],
      [1.6, -1.1],
      [1.5, 1.5],
      [4.3, -0.3],
      [2, 0.6],
      [-0.2, -3.1],
      [1.2, -1.4],
      [4.1, -0.3],
      [0, 0],
      [-2, -3.9],
      [-1.5, -2],
      [0.8, -0.6],
      [3.9, 0.2],
      [1.6, -1.3],
      [-1.7, -1.6],
      [-3.4, -1.1],
      [0.1, -1.1],
      [-2.2, -1.1],
      [-3.7, -3.9],
      [0.6, -1.6],
      [-1, -2.9],
      [-4.8, -1.4],
      [-2.3, 0.7],
      [-1, -1.4],
      'z',
    ],
  },
  {
    name: 'Belize',
    vertexPoint: [
      ['m', 482.5, 471.1],
      [1.4, -2.2],
      [1, -0.2],
      [1.3, -1.7],
      [1, -3.2],
      [-0.3, -0.6],
      [0.9, -2.3],
      [-0.4, -1],
      [1.3, -2.7],
      [0.3, -1.8],
      [-1.1, 0],
      [0.1, -0.9],
      [-1, 0],
      [-2.5, 3.9],
      [-0.9, -0.8],
      [-0.7, 0.3],
      [-0.1, 1],
      [-0.7, 5],
      [-1.2, 7.2],
      [1.6, 0],
      'z',
    ],
  },
  {
    name: 'Bolivia',
    vertexPoint: [
      ['m', 655.7, 700.5],
      [1.6, -1.3],
      [-0.8, -3.6],
      [1.3, -2.8],
      [0.5, -5],
      [-1.6, -4],
      [-3.2, -1.7],
      [-0.8, -2.6],
      [0.6, -3.6],
      [-10.7, -0.3],
      [-2.7, -7.4],
      [1.6, -0.1],
      [-0.3, -2.8],
      [-1.2, -1.8],
      [-0.5, -3.7],
      [-3.3, -1.9],
      [-3.5, 0.1],
      [-2.5, -1.9],
      [-3.8, -1.2],
      [-2.4, -2.4],
      [-6.3, -1],
      [-6.4, -5.7],
      [0.3, -4.3],
      [-0.9, -2.5],
      [0.4, -4.7],
      [-7.3, 1.1],
      [-2.8, 2.3],
      [-4.8, 2.6],
      [-1.1, 1.9],
      [-2.9, 0.2],
      [-4.2, -0.6],
      [5.5, 10.3],
      [-1.1, 2.1],
      [0.1, 4.5],
      [0.3, 5.4],
      [-1.9, 3.2],
      [1.2, 2.4],
      [-1.1, 2.1],
      [2.8, 5.3],
      [-2.8, 6.9],
      [3.1, 4.3],
      [1.2, 4.6],
      [3.2, 2.7],
      [-1.1, 6.2],
      [3.7, 7.1],
      [3.1, 8.8],
      [3.8, -0.9],
      [4, -5.7],
      [7.4, 1.5],
      [3.7, 4.6],
      [1.6, -5.1],
      [6.3, 0.3],
      [1, 1.3],
      [1.5, -7.6],
      [-0.2, -3.4],
      [2.1, -5.6],
      [9.5, -1.9],
      [5.1, 0.1],
      [5.4, 3.3],
      [0.3, 1.9],
      'z',
    ],
  },
  {
    name: 'Brazil',
    vertexPoint: [
      ['m', 659, 560.1],
      [-1.4, 0.2],
      [-3.1, -0.5],
      [-1.8, 1.7],
      [-2.6, 1.1],
      [-1.7, 0.2],
      [-0.7, 1.3],
      [-2.7, -0.3],
      [-3.5, -3],
      [-0.3, -2.9],
      [-1.4, -3.3],
      [1, -5.4],
      [1.6, -2.2],
      [-1.2, -3],
      [-1.9, -0.9],
      [0.8, -2.8],
      [-1.3, -1.5],
      [-2.9, 0.3],
      [0.7, 1.8],
      [-2.1, 2.4],
      [-6.4, 2.4],
      [-4, 1],
      [-1.7, 1.5],
      [-4.4, -1.6],
      [-4.2, -0.8],
      [-1, 0.6],
      [2.4, 1.6],
      [-0.3, 4.3],
      [0.7, 4],
      [4.8, 0.5],
      [0.3, 1.4],
      [-4.1, 1.8],
      [-0.7, 2.7],
      [-2.3, 1],
      [-4.2, 1.5],
      [-1.1, 1.9],
      [-4.4, 0.5],
      [-3, -3.4],
      [-1.1, 0.8],
      [-1, -3.8],
      [-1.6, -2],
      [-1.9, 2.2],
      [-10.9, -0.1],
      [0, 3.9],
      [3.3, 0.7],
      [-0.2, 2.4],
      [-1.1, -0.6],
      [-3.2, 1],
      [0, 4.6],
      [2.5, 2.4],
      [0.9, 3.6],
      [-0.1, 2.8],
      [-2.2, 17.4],
      [-5.1, -0.3],
      [-0.7, 1],
      [-4.6, 1.2],
      [-6.2, 4.3],
      [-0.4, 3],
      [-1.3, 2.2],
      [0.7, 3.4],
      [-3.3, 1.9],
      [0.1, 2.7],
      [-1.5, 1.1],
      [2.6, 5.8],
      [3.3, 3.8],
      [-1, 2.8],
      [3.7, 0.3],
      [2.3, 3.4],
      [4.9, 0.2],
      [4.4, -3.8],
      [0.2, 9.7],
      [2.6, 0.7],
      [3, -1.1],
      [4.2, 0.6],
      [2.9, -0.2],
      [1.1, -1.9],
      [4.8, -2.6],
      [2.8, -2.3],
      [7.3, -1.1],
      [-0.4, 4.7],
      [0.9, 2.5],
      [-0.3, 4.3],
      [6.4, 5.7],
      [6.3, 1],
      [2.4, 2.4],
      [3.8, 1.2],
      [2.5, 1.9],
      [3.5, -0.1],
      [3.3, 1.9],
      [0.5, 3.7],
      [1.2, 1.8],
      [0.3, 2.8],
      [-1.6, 0.1],
      [2.7, 7.4],
      [10.7, 0.3],
      [-0.6, 3.6],
      [0.8, 2.6],
      [3.2, 1.7],
      [1.6, 4],
      [-0.5, 5],
      [-1.3, 2.8],
      [0.8, 3.6],
      [-1.6, 1.3],
      [1.9, 3.6],
      [0.4, 8.6],
      [6, 1.2],
      [2.1, -1.2],
      [3.9, 1.7],
      [1.2, 1.9],
      [1, 5.8],
      [0.9, 2.5],
      [2, 0.3],
      [2, -1.1],
      [2.1, 1.2],
      [0.3, 3.5],
      [-0.3, 3.8],
      [-0.7, 3.6],
      [2.6, -1.2],
      [3.1, 3.7],
      [0.5, 5.1],
      [-4.2, 3.5],
      [-3.3, 2.6],
      [-5.3, 6.2],
      [-5.9, 8.6],
      [3.4, -0.7],
      [6.2, 4.9],
      [1.9, -0.2],
      [6.2, 4.1],
      [4.8, 3.5],
      [3.8, 4.3],
      [-1.9, 3],
      [2.1, 3.7],
      [2.9, -3.7],
      [1.5, -6],
      [3.2, -3],
      [3.9, -5],
      [4.5, -11.2],
      [3.4, -3.5],
      [0.8, -3.1],
      [0.3, -6.4],
      [-1.3, -3.5],
      [0.3, -4.8],
      [4.1, -6.3],
      [6, -5.1],
      [6, -1.8],
      [3.6, -2.9],
      [8.5, -2.4],
      [5.9, 0],
      [1.1, -3.8],
      [4.2, -2.8],
      [0.6, -6.5],
      [5.1, -8.3],
      [0.5, -8.5],
      [1.6, -2.6],
      [0.3, -4.1],
      [1.1, -9.9],
      [-1, -11.9],
      [1.4, -4.7],
      [1.4, -0.1],
      [3.9, -5.5],
      [3.3, -7.2],
      [7.7, -8.8],
      [2.7, -4.2],
      [2, -10.5],
      [-1, -3.9],
      [-2, -8.1],
      [-2.1, -2],
      [-4.8, -0.2],
      [-4.3, -1.9],
      [-7.3, -7.1],
      [-8.4, -5.3],
      [-8.4, 0.3],
      [-10.9, -3.4],
      [-6.5, 2],
      [0.8, -3.5],
      [-2.7, -3.8],
      [-9.4, -3.8],
      [-7.1, -2.3],
      [-4.2, 4.1],
      [-0.3, -6.3],
      [-9.9, -1],
      [-1.7, -2],
      [4.2, -5.2],
      [-0.1, -4.4],
      [-3, -1],
      [-3, -11.2],
      [-1.3, -3.5],
      [-1.9, 0.3],
      [-3.5, 5.8],
      [-1.8, 4.7],
      [-2.1, 2.4],
      [-2.7, 0.5],
      [-0.8, -1.8],
      [-1.2, -0.3],
      [-1.8, 1.8],
      [-2.4, -1.3],
      [-3.2, -1.4],
      [-2.7, 0.7],
      [-2.3, -0.6],
      [-0.5, 1.8],
      [0.9, 1.3],
      [-0.5, 1.3],
      [-3.1, -0.5],
      'z',
    ],
  },
  {
    name: 'Brunei',
    vertexPoint: [
      ['m', 1617.8, 543.4],
      [2.7, 3.3],
      [1.1, -2.2],
      [2.7, 0.2],
      [0.1, -4.1],
      [0.1, -3.1],
      [-4.6, 3.5],
      [-2.1, 2.4],
      'z',
    ],
  },
  {
    name: 'Bhutan',
    vertexPoint: [
      ['m', 1474.7, 395.5],
      [-2.7, -1.8],
      [-2.9, -0.1],
      [-4.2, -1.5],
      [-2.6, 1.6],
      [-2.6, 4.8],
      [0.3, 1.2],
      [5.5, 2.5],
      [3.2, -1],
      [4.7, 0.4],
      [4.4, -0.2],
      [-0.4, -3.9],
      [-2.7, -2],
      'z',
    ],
  },
  {
    name: 'Botswana',
    vertexPoint: [
      ['m', 1116.7, 685],
      [-1, -0.5],
      [-3.2, 1.5],
      [-1.6, 0],
      [-3.7, 2.5],
      [-2, -2.6],
      [-8.6, 2.2],
      [-4.1, 0.2],
      [-0.9, 22.7],
      [-5.4, 0.2],
      [-0.6, 18.5],
      [1.4, 1],
      [3, 6.1],
      [-0.7, 3.8],
      [1.1, 2.3],
      [4, -0.7],
      [2.8, -2.8],
      [2.7, -1.9],
      [1.5, -3.1],
      [2.7, -1.5],
      [2.3, 0.8],
      [2.5, 1.8],
      [4.4, 0.3],
      [3.6, -1.5],
      [0.6, -2],
      [1.2, -3],
      [3, -0.5],
      [1.7, -2.4],
      [2, -4.3],
      [5.2, -4.7],
      [8, -4.7],
      [-3.4, -2.9],
      [-4.2, -0.9],
      [-1.5, -4.1],
      [0.1, -2.2],
      [-2.3, -0.7],
      [-6, -7],
      [-1.6, -3.7],
      [-1.1, -1.1],
      [-1.9, -5.1],
      'z',
    ],
  },
  {
    name: 'Canada',
    vertexPoint: [
      ['m', 659, 276.7],
      [-0.7, -3],
      [-2.5, 1.9],
      [0.5, 2.1],
      [5.6, 2.6],
      [1.9, -0.4],
      [3.3, -2.5],
      [-4.7, 0.1],
      [-3.4, -0.8],
      'z',
      ['m', 14.4, -15.9],
      [0.2, -1.1],
      [-4.1, -2.6],
      [-5.9, -1.6],
      [-1.9, 0.6],
      [3.5, 2.9],
      [5.7, 1.9],
      [2.5, -0.1],
      'z',
      ['m', -305.3, 3.7],
      [0.2, -3.4],
      [-3.2, -2.6],
      [-0.4, -2.9],
      [-0.1, -2.1],
      [-4.1, -0.7],
      [-2.4, -0.9],
      [-4.1, -1.4],
      [-1.4, 1.5],
      [-0.6, 3.3],
      [4.3, 1.1],
      [-0.4, 1.8],
      [2.9, 2.2],
      [0, 2.2],
      [6.3, 2.8],
      [3, -0.9],
      'z',
      ['m', 336.1, -13.5],
      [3.9, -3.8],
      [1.4, -1.7],
      [-2.1, -0.3],
      [-4.9, 2.2],
      [-4.2, 3.5],
      [-8.1, 9.8],
      [-5.3, 3.7],
      [1.6, 1.7],
      [-3.8, 2.2],
      [0.2, 1.9],
      [9.6, 0.1],
      [5.4, -0.3],
      [4.4, 1.5],
      [-4.4, 2.9],
      [2.9, 0.2],
      [7.3, -5.4],
      [1.2, 0.8],
      [-2.5, 5.1],
      [3, 1.2],
      [2.3, -0.2],
      [3.5, -5.5],
      [-0.5, -3.9],
      [0.3, -3.3],
      [-3.7, 1.1],
      [2.8, -4.6],
      [-4.3, -1.9],
      [-2.7, 1.5],
      [-3.9, -1.7],
      [2.4, -2.1],
      [-2.9, -1.3],
      [-3.8, 2],
      [4.9, -5.4],
      'z',
      ['m', -356.8, -21.2],
      [-1.9, 2],
      [-1.4, 2.6],
      [0.9, 1.9],
      [-0.6, 2.8],
      [0.7, 2.8],
      [1.9, 0],
      [-0.2, -4.9],
      [7.1, -6.9],
      [-4.9, 0.5],
      [-1.6, -0.8],
      'z',
      ['m', 280.9, -47],
      [-0.4, -1.2],
      [-1.7, -0.1],
      [-2.8, 1.7],
      [-0.4, 0.4],
      [0.1, 1.7],
      [1.7, 0.5],
      [3.5, -3],
      'z',
      ['m', -9.6, -3.2],
      [0.8, -1.1],
      [-6, -0.1],
      [-4.9, 2.7],
      [0, 1.5],
      [3, 0.2],
      [7.1, -3.2],
      'z',
      ['m', -3.1, -16.6],
      [-2.7, -0.5],
      [-5, 5.2],
      [-3.6, 4.4],
      [-5.7, 2.8],
      [6.3, -0.6],
      [-0.8, 3.4],
      [8.2, -3],
      [6.2, -3],
      [0.8, 2.6],
      [5.9, 1.3],
      [4.9, -1.8],
      [-1.9, -1.8],
      [-3.4, 0.4],
      [1.3, -2.7],
      [-3.7, -1.7],
      [-3.4, -1.9],
      [-1.5, -1.5],
      [-2.8, 0.9],
      [0.9, -2.5],
      'z',
      ['m', 44.6, -8.2],
      [3.7, -1.7],
      [1, -0.7],
      [1.4, -2.3],
      [-2.3, -1.5],
      [-4.2, 0.7],
      [-3.8, 3.1],
      [-0.7, 2.6],
      [4.9, -0.2],
      'z',
      ['m', -73.8, -10.7],
      [-0.8, -2],
      [-0.3, -1],
      [-1.6, -1],
      [-3, -1.5],
      [-4.9, 2.3],
      [-5, 1.7],
      [3.5, 2.4],
      [3.8, -0.6],
      [4.1, 1.6],
      [4.2, -1.9],
      'z',
      ['m', 22.4, -2.1],
      [-6.6, -1],
      [5.7, -2.6],
      [-0.4, -6],
      [-1.9, -2.3],
      [-4.5, -0.8],
      [-8.1, 3.8],
      [-5.5, 5.8],
      [2.9, 2.1],
      [1.6, 3.3],
      [-6.3, 5.5],
      [-3.2, -0.2],
      [-6.2, 4.4],
      [4.2, -5.2],
      [-4.8, -1.8],
      [-4.5, 0.9],
      [-2.4, 3.4],
      [-5.9, -0.1],
      [-7.2, 0.8],
      [-5.1, -2.4],
      [-5, 0.4],
      [-1.5, -2.9],
      [-2.1, -1.3],
      [-3.8, 0.5],
      [-5.2, 0.3],
      [-4.4, 1.8],
      [2, 2.3],
      [-7, 2.8],
      [-1.4, -3.3],
      [-4.4, 1],
      [-11.8, 0.6],
      [-6.4, -1.2],
      [8.5, -2.6],
      [-2.8, -2.8],
      [-4.4, 0.4],
      [-4.7, -1],
      [-7.5, -1.9],
      [-3.8, -2.3],
      [-4.5, -0.3],
      [-3.3, 1.6],
      [-5.9, 0.9],
      [3.9, -4.1],
      [-9.4, 3.6],
      [-1.4, -4.7],
      [-2.1, -0.6],
      [-3.8, 2.5],
      [-4.5, 1.2],
      [-0.2, -2.2],
      [-8.2, 1.4],
      [-8.8, 2.3],
      [-5.2, -0.6],
      [-7, 1.6],
      [-6.2, 2.3],
      [-3.7, -0.5],
      [-3.3, -2.6],
      [-5.9, -1.3],
      [0, 0],
      [-24.3, 20.2],
      [-35.4, 32.4],
      [4.2, 0.1],
      [2.7, 1.6],
      [0.6, 2.6],
      [0.2, 3.9],
      [7.6, -3.3],
      [6.4, -1.9],
      [-0.5, 3],
      [0.7, 2.4],
      [1.7, 2.7],
      [-1.1, 4.2],
      [-1.5, 6.8],
      [4.6, 3.8],
      [-3.1, 3.7],
      [-5.1, 2.9],
      [0, 0],
      [-2.5, 3.1],
      [2.1, 4.4],
      [-3.1, 4.9],
      [4.1, 2.6],
      [-3.6, 3.7],
      [-1.3, 5.5],
      [6.9, 2.5],
      [1.6, 2.7],
      [5.4, 6.1],
      [0.7, 0],
      [13.9, 0],
      [14.6, 0],
      [4.8, 0],
      [15, 0],
      [14.5, 0],
      [14.7, 0],
      [14.8, 0],
      [16.7, 0],
      [16.8, 0],
      [10.1, 0],
      [1.3, -2.4],
      [1.6, 0],
      [-0.8, 3.4],
      [1, 1],
      [3.2, 0.4],
      [4.6, 1],
      [3.8, 1.9],
      [4.4, -0.8],
      [5.3, 1.6],
      [0, 0],
      [3.2, -2.4],
      [3.2, -1],
      [1.8, -1.5],
      [1.5, -0.8],
      [4, 1.2],
      [3.3, 0.2],
      [0.8, 0.8],
      [0.1, 3.5],
      [5.2, 1],
      [-1.7, 1.7],
      [1.2, 1.9],
      [-1.9, 2.3],
      [1.8, 0.8],
      [-1.9, 2.1],
      [0, 0],
      [1.2, 0.2],
      [1.3, -0.9],
      [0.5, 1.4],
      [3.4, 0.7],
      [3.8, 0.1],
      [3.8, 0.6],
      [4, 1.2],
      [0.8, 2],
      [1.4, 4.7],
      [-2.4, 2],
      [-3.8, -0.8],
      [-1, -3.8],
      [-0.9, 3.9],
      [-3.8, 3.4],
      [-0.8, 2.9],
      [-1.1, 1.7],
      [-4.1, 2],
      [0, 0],
      [-3.7, 3.4],
      [-2, 2.2],
      [2.7, 0.4],
      [4.5, -2],
      [2.9, -1.7],
      [1.6, -0.3],
      [2.6, 0.6],
      [1.7, -0.9],
      [2.8, -0.8],
      [4.7, -0.8],
      [0, 0],
      [0, 0],
      [0.3, -1.8],
      [-0.3, 0.1],
      [-1.7, 0.3],
      [-1.8, -0.6],
      [2.3, -2.1],
      [1.9, -0.7],
      [3.9, -0.9],
      [4.6, -0.9],
      [1.8, 1.2],
      [1.9, -1.4],
      [1.9, -0.8],
      [0.9, 0.4],
      [0.1, 0.1],
      [6.7, -4.2],
      [2.7, -1.2],
      [7.7, 0],
      [9.3, 0],
      [1, -1.6],
      [1.7, -0.3],
      [2.5, -0.9],
      [2.7, -2.8],
      [3.2, -4.9],
      [5.5, -4.7],
      [1.1, 1.7],
      [3.7, -1.1],
      [1.5, 1.8],
      [-2.8, 8.5],
      [2.1, 3.5],
      [5.9, -0.8],
      [8.1, -0.2],
      [-10.4, 5.1],
      [-1.5, 5.2],
      [3.7, 0.5],
      [7.1, -4.5],
      [5.8, -2.4],
      [12.2, -3.7],
      [7.5, -4.1],
      [-2.6, -2.2],
      [1, -4.5],
      [-7.1, 7],
      [-8.6, 0.8],
      [-5.5, -3.1],
      [-0.1, -4.6],
      [0.6, -6.8],
      [6.1, -4.1],
      [-3.3, -3.1],
      [-7.6, 0.6],
      [-12.1, 5.2],
      [-10.9, 8.2],
      [-4.6, 1],
      [7.8, -5.7],
      [10.1, -8.3],
      [7.2, -2.7],
      [5.7, -4.4],
      [5.2, -0.5],
      [7.3, 0.1],
      [10, 1.3],
      [8.6, -1],
      [7.8, -5.1],
      [8.7, -2.2],
      [4.2, -2.1],
      [4.2, -2.3],
      [2, -6.8],
      [-1.1, -2.3],
      [-3.4, -0.8],
      [0, -5.1],
      [-2.3, -1.9],
      [-6.9, -1.6],
      [-2.8, -3.4],
      [-4.8, -3.4],
      [3.4, -3.7],
      [-2, -7.1],
      [-2.6, -7.5],
      [-1, -5.2],
      [-4.3, 2.7],
      [-7.4, 6.5],
      [-8.1, 3.2],
      [-1.6, -3.4],
      [-3.7, -1],
      [2.2, -7.3],
      [2.6, -4.9],
      [-7.7, -0.5],
      [-0.1, -2.2],
      [-3.6, -3.3],
      [-3, -2],
      [-4.5, 1.5],
      [-4.2, -0.5],
      [-6.6, -1.6],
      [-3.9, 1.3],
      [-3.8, 9],
      [-1, 5.3],
      [-8.8, 6.1],
      [3.1, 4.5],
      [0.5, 5],
      [-1.7, 4],
      [-4.7, 4.1],
      [-7.5, 4.2],
      [-9, 2.8],
      [1.7, 3.2],
      [-2.2, 9.6],
      [-5.6, 6.3],
      [-4.6, 1.9],
      [-4.4, -5.8],
      [-0.1, -6.8],
      [1.7, -6],
      [3.6, -5.2],
      [-4.8, -0.6],
      [-7.5, -0.4],
      [-3.6, -2.5],
      [-4.8, -1.6],
      [-1.7, -2.9],
      [-3.3, -2.2],
      [-7, -2.6],
      [-7.1, 1.2],
      [0.7, -4.5],
      [1.5, -5.5],
      [-6, -1],
      [4.9, -6.8],
      [4.9, -4.6],
      [9.4, -6.5],
      [8.6, -4.6],
      [5.6, -0.7],
      [2.9, -3.7],
      [5.1, -2.4],
      [6.4, -0.4],
      [7.7, -3.8],
      [2.9, -2.4],
      [7.4, -4.7],
      [3.2, -2.8],
      [3.2, 1.7],
      [6.5, -0.9],
      [10.8, -3.8],
      [2.3, -2.7],
      [-0.8, -2.9],
      [5, -2.9],
      [1.7, -2.7],
      [-3.5, -2.6],
      [-5.4, -0.8],
      [-5.5, -0.4],
      [-4.6, 5.9],
      [-6.5, 4.6],
      [-7.2, 4],
      [-1.3, -3.7],
      [4.2, -4],
      [-2.2, -3.5],
      [-8.7, 4.2],
      [4.3, -5.5],
      'z',
      ['m', -75.5, -18.9],
      [-2.8, -1],
      [-14.1, 3.2],
      [-5.1, 2],
      [-7.8, 3.9],
      [5.4, 1.4],
      [6.2, -0.1],
      [-11.5, 2.1],
      [0, 1.9],
      [5.6, 0.1],
      [9, -0.4],
      [6.5, 1.2],
      [-6.2, 1],
      [-5.5, -0.3],
      [-7.1, 0.9],
      [-3.3, 0.6],
      [0.6, 4.2],
      [4.2, -0.6],
      [4.1, 1.5],
      [-0.3, 2.5],
      [7.8, -0.5],
      [11.2, -0.8],
      [9.4, -1.8],
      [5, -0.4],
      [5.7, 1.5],
      [6.7, 0.8],
      [3.1, -1.9],
      [-0.7, -2.1],
      [7, -0.4],
      [2.6, -2.4],
      [-5, -2.5],
      [-4.2, -2.6],
      [2.4, -3.6],
      [2.7, -5.1],
      [-2.2, -2],
      [-3, -0.9],
      [-4.2, 0.8],
      [-2.8, 5.3],
      [-4.3, 2.1],
      [2.2, -5.1],
      [-1.7, -1.7],
      [-7.3, 2.7],
      [-2.6, -2.6],
      [-10.4, 1.5],
      [4.7, -2.4],
      'z',
      ['m', 39.1, -1.5],
      [-1.7, -1.1],
      [-5.4, 0.2],
      [-2.1, 0.7],
      [2.2, 3.6],
      [7, -3.4],
      'z',
      ['m', 107.7, 1.6],
      [-4.4, -2.8],
      [-8.4, -0.5],
      [-2.1, 0.3],
      [-1.7, 1.8],
      [2, 2.8],
      [0.9, 0.3],
      [4.8, -0.7],
      [4.1, 0.1],
      [4.1, 0.1],
      [0.7, -1.4],
      'z',
      ['m', -39.4, -0.3],
      [5.7, -3.2],
      [-11.2, 1.3],
      [-5.8, 2.1],
      [-7.1, 4.6],
      [-3.3, 5.2],
      [5.6, 0.1],
      [-6.1, 2.3],
      [1.8, 1.9],
      [5.9, 0.8],
      [7.3, 1.5],
      [13.8, 1.2],
      [7.9, -0.6],
      [3.2, -1.6],
      [2, 1.8],
      [3.3, 0.3],
      [2, 3.3],
      [-3.5, 1.4],
      [7.1, 1.8],
      [4.6, 2.6],
      [0.5, 1.9],
      [-0.4, 2.4],
      [-8.6, 5.4],
      [-3.2, 2.7],
      [0.2, 2],
      [-9.2, 0.7],
      [-8, 0.1],
      [-5.4, 4.2],
      [2.4, 1.9],
      [13, -0.9],
      [0.9, -1.6],
      [4.7, 2.7],
      [4.7, 2.9],
      [-2.4, 1.6],
      [3.8, 2.8],
      [7.6, 3.3],
      [10.7, 2.3],
      [0.3, -2],
      [-2.8, -3.5],
      [-3.5, -4.9],
      [8.5, 4.6],
      [4.7, 1.5],
      [3.6, -4.1],
      [0, -5.6],
      [-1, -1.5],
      [-4.4, -2.5],
      [-2.7, -3.3],
      [2.3, -3.2],
      [5.8, -0.7],
      [3.8, 5.4],
      [4, 2.4],
      [10.7, -6.5],
      [3.3, -3.9],
      [-6.4, -0.3],
      [-3.2, -5.1],
      [-5.9, -1.2],
      [-7.7, -3.5],
      [9, -2.5],
      [-0.8, -5],
      [-2.2, -2.1],
      [-8.3, -2.1],
      [-1.9, -3.3],
      [-8.2, 1.2],
      [1.1, -2.3],
      [-3.6, -2.5],
      [-6.8, -2.6],
      [-5.2, 2.1],
      [-9, 1.5],
      [3.3, -3.4],
      [-2.3, -5.3],
      [-11.6, 2.1],
      [-7.1, 4.1],
      [-0.3, -3.2],
      'z',
      ['m', -50, -3.4],
      [-7.1, 2.4],
      [0.9, 3.4],
      [-7.4, -0.7],
      [-1.7, 1.7],
      [5.8, 3.9],
      [0.9, 2],
      [3.4, 0.5],
      [8.4, -2],
      [5.1, -4.7],
      [-3.8, -2.2],
      [6, -2.4],
      [0.5, -1.5],
      [-7.5, 0.6],
      [-3.5, -1],
      'z',
      ['m', 22.3, 5.4],
      [5.6, -1],
      [10, -4.5],
      [-6.1, -1.2],
      [-7.8, -0.2],
      [-5.2, 1.4],
      [-4.2, 2.1],
      [-2.5, 2.6],
      [-1.8, 4.5],
      [4.3, 0.2],
      [7.7, -3.9],
      'z',
      ['m', -114.7, 7.2],
      [2.6, -2.3],
      [9.1, -3.6],
      [13.8, -3.6],
      [6.4, -1.3],
      [-1.6, -2.1],
      [-1.9, -1.5],
      [-9.4, -0.2],
      [-4.1, -1.1],
      [-14, 0.8],
      [-0.3, 3.1],
      [-7.6, 3.3],
      [-7.4, 3.8],
      [-4.3, 2.2],
      [5.9, 2.7],
      [-0.6, 2.3],
      [13.4, -2.5],
      'z',
      ['m', 124.1, -18.3],
      [0.3, -1.6],
      [-1.4, -1.7],
      [-6.9, 1.3],
      [-4.4, 2.2],
      [3.2, 1.3],
      [5.1, 0.4],
      [4.1, -1.9],
      'z',
      ['m', -8.7, -8.6],
      [-1.1, 0.7],
      [-4.8, -0.3],
      [-7.6, 1.6],
      [-3.8, -0.1],
      [-4.3, 3.8],
      [6.6, -0.4],
      [-3.4, 2.9],
      [3.2, 0.8],
      [6.8, -0.5],
      [5.8, -3.7],
      [2.8, -2.5],
      [-0.2, -2.3],
      'z',
      ['m', -39.1, 2.5],
      [1.8, -2.3],
      [-3.1, -0.5],
      [-5.7, 1.7],
      [-0.7, 4.7],
      [-6.1, -0.4],
      [-2.8, -2.9],
      [-8.2, -1.6],
      [-5.4, 1.4],
      [-11.6, 4.8],
      [4.1, 0.8],
      [17.8, -0.5],
      [-10.6, 2.2],
      [-1.5, 1.6],
      [5.9, -0.1],
      [12.2, -2.2],
      [13.8, -0.8],
      [5.1, -2.3],
      [2.3, -2.4],
      [-3.7, -0.2],
      [-4.3, 0.8],
      [0.7, -1.8],
      'z',
      ['m', 55.2, -4.3],
      [-7.1, -0.3],
      [-3.8, 2],
      [2.6, 1.5],
      [7, 0.6],
      [1.4, 2.1],
      [-2.2, 2.4],
      [-1.5, 2.8],
      [8.5, 1.6],
      [5.5, 0.6],
      [8, -0.1],
      [11.6, -0.8],
      [4.3, 0.6],
      [6.7, -1],
      [3.5, -1.4],
      [1, -2],
      [-2.3, -1.9],
      [-5.8, -0.3],
      [-8, 0.4],
      [-7, 1.1],
      [-5.1, -0.4],
      [-4.8, -0.3],
      [-1.2, -1.1],
      [-3.1, -1.1],
      [2.8, -1.9],
      [-1.4, -1.6],
      [-7.3, 0.1],
      [-2.3, -1.6],
      'z',
      ['m', -75, -2.6],
      [-6, 0.7],
      [-5.5, -0.1],
      [-12.1, 3.1],
      [-11.6, 3.7],
      [0, 0],
      [3.6, 1],
      [7, -0.7],
      [9.8, -2.1],
      [3.8, -0.3],
      [5.2, -1.6],
      [5.8, -3.7],
      'z',
      ['m', 80.5, 0.6],
      [1, -0.5],
      [-1.5, -0.9],
      [-7.2, -0.1],
      [-0.6, 1.3],
      [6.4, 0.3],
      [1.9, -0.1],
      'z',
      ['m', -58.4, -0.8],
      [3.2, -1.4],
      [-4.1, -0.8],
      [-5.9, 0.5],
      [-5.1, 1.5],
      [3.3, 1.5],
      [8.6, -1.3],
      'z',
      ['m', 7.8, -4.2],
      [-3.3, -0.9],
      [-1.6, -0.2],
      [-5.7, 1.3],
      [-1, 0.7],
      [6, 0],
      [5.6, -0.9],
      'z',
      ['m', 46.4, 2.5],
      [3, -1.7],
      [-2.3, -1.6],
      [-1.7, -0.3],
      [-4.4, -0.1],
      [-2.1, 1.8],
      [-0.7, 1.8],
      [1.6, 1.1],
      [6.6, -1],
      'z',
      ['m', -13.7, -1.2],
      [0.1, -2.2],
      [-7.4, -1.7],
      [-6.1, -0.6],
      [-2.1, 1.7],
      [2.8, 1.1],
      [-5.3, 1.4],
      [7.7, 0.2],
      [4, 1.5],
      [5.2, 0.5],
      [1.1, -1.9],
      'z',
      ['m', 53.7, -6.1],
      [0.6, -2.8],
      [-4.7, -0.8],
      [-4.7, -0.9],
      [-1.6, -2.2],
      [-8.2, 0.2],
      [0.3, 0.9],
      [-3.9, 0.3],
      [-4.1, 1.3],
      [-4.9, 1.9],
      [-0.3, 1.9],
      [2, 1.5],
      [6.5, 0],
      [-4.3, 1.2],
      [-2.1, 1.6],
      [1.6, 1.9],
      [6.7, 0.6],
      [6.8, -0.4],
      [10.5, -3.4],
      [6.4, -1.3],
      [-2.6, -1.5],
      'z',
      ['m', 78.5, -13.8],
      [-7, -0.2],
      [-6.9, -0.3],
      [-10.2, 0.6],
      [-1.4, -0.4],
      [-10.3, 0.2],
      [-6.4, 0.4],
      [-5.1, 0.6],
      [-5, 2],
      [-2.3, -1],
      [-3.9, -0.2],
      [-6.7, 1.4],
      [-7.4, 0.6],
      [-4.1, 0.1],
      [-6, 0.8],
      [-1.1, 1.3],
      [2.5, 1.2],
      [0.8, 1.6],
      [4.4, 1.5],
      [12.4, -0.3],
      [7.2, 0.5],
      [-7.2, 1.5],
      [-2.2, -0.4],
      [-9.3, -0.2],
      [-1.1, 2.2],
      [3, 1.7],
      [-2.8, 1.6],
      [-7.5, 1.1],
      [-4.9, 1.7],
      [4.8, 0.9],
      [1.7, 3],
      [-7.5, -2],
      [-2.5, 0.3],
      [-2, 3.4],
      [-8, 1.1],
      [-2, 2.3],
      [6.7, 0.3],
      [4.9, 0.6],
      [11.7, -0.8],
      [8.4, 1.4],
      [12.6, -3],
      [1, -1.1],
      [-6.4, 0.2],
      [0.5, -1.1],
      [6.5, -1.4],
      [3.6, -1.9],
      [6.8, -1.3],
      [5, -1.6],
      [-0.8, -2.2],
      [3.3, -0.8],
      [-4.3, -0.6],
      [11.1, -0.4],
      [3.2, -0.9],
      [7.9, -0.8],
      [9.3, -3.5],
      [6.8, -1.1],
      [10.3, -2.5],
      [-7.4, 0],
      [3.9, -0.9],
      [9, -0.8],
      [9.7, -1.6],
      [1.1, -1.1],
      [-5.2, -1],
      [-6.7, -0.4],
      [-8.5, -0.3],
      'z',
    ],
  },
  {
    name: 'Canary Islands',
    vertexPoint: [
      ['m', 879.6, 395.2],
      [-0.2, -0.2],
      [-0.7, 0.5],
      [-0.6, 0],
      [0.1, 0.2],
      [0.1, 0.2],
      [0.7, 0.4],
      [0.6, -1.1],
      'z',
      ['m', 13.5, -2.1],
      [0, -0.1],
      [-0.1, 0],
      [-0.1, 0.1],
      [-1.3, -0.1],
      [-0.2, 0.6],
      [-0.5, 0.4],
      [0, 0.7],
      [0.5, 0.7],
      [0.3, 0.1],
      [0.5, 0.1],
      [0.7, -0.4],
      [0.2, -0.4],
      [0.1, -0.8],
      [-0.1, -0.4],
      [0, -0.5],
      'z',
      ['m', -9.7, 0.8],
      [0.5, -0.4],
      [0, -0.2],
      [-0.1, -0.3],
      [-0.5, -0.3],
      [-0.2, 0],
      [-0.2, 0.2],
      [-0.2, 0.4],
      [0.3, 0.5],
      [0.2, 0.1],
      [0.2, 0],
      'z',
      ['m', 4.7, -2.3],
      [1.2, -1],
      [0, -0.3],
      [-1, 0.1],
      [-1.1, 1],
      [-0.3, 0.1],
      [-1, 0.1],
      [-0.5, 0],
      [-0.4, 0.2],
      [0.2, 0.3],
      [0.4, 1],
      [0.7, 0.9],
      [0.6, -0.2],
      [0.3, -0.2],
      [0.4, -0.6],
      [0.5, -1.4],
      'z',
      ['m', 11.6, 1.3],
      [1.5, -0.5],
      [0.3, -1],
      [0.3, -1.1],
      [0, -0.7],
      [-0.2, -0.3],
      [-0.1, 0],
      [-0.4, 0],
      [-0.3, 0.2],
      [-0.1, 0.6],
      [-0.7, 1.3],
      [-0.5, 1.2],
      [-0.7, 0.6],
      [-0.7, 0.2],
      [0.1, 0.1],
      [0.7, 0.1],
      [0.8, -0.7],
      'z',
      ['m', -19.7, -2],
      [0.5, -0.5],
      [0.1, -0.3],
      [-0.1, -0.5],
      [0.2, -0.2],
      [-0.1, -0.4],
      [-0.3, -0.4],
      [-0.7, 0],
      [-0.4, 0.6],
      [0.6, 1.2],
      [0.1, 0.5],
      [0.1, 0],
      'z',
      ['m', 22.4, -2.7],
      [0.9, -0.3],
      [0.5, -0.3],
      [0.1, -0.9],
      [0.2, -0.3],
      [-0.2, -0.3],
      [-0.2, 0.2],
      [-0.2, 0.4],
      [-0.6, 0.2],
      [-0.8, 0.4],
      [-0.2, 0.3],
      [-0.2, 0.9],
      [0.4, 0.1],
      [0.3, -0.4],
      'z',
    ],
  },
  {
    name: 'Central African Republic',
    vertexPoint: [
      ['m', 1110.5, 517.3],
      [-0.5, -0.3],
      [-2, -1.8],
      [-0.3, -2],
      [0.8, -2.6],
      [0, -2.6],
      [-3.3, -4],
      [-0.7, -2.7],
      [-3.5, 1.1],
      [-2.8, 2.5],
      [-4, 7],
      [-5.2, 2.9],
      [-5.4, -0.4],
      [-1.6, 0.6],
      [0.6, 2.3],
      [-2.9, 2.2],
      [-2.3, 2.5],
      [-7.1, 2.4],
      [-1.4, -1.4],
      [-0.9, -0.2],
      [-1, 1.7],
      [-4.7, 0.4],
      [-2.7, 6.5],
      [-1.4, 1.1],
      [-0.4, 5],
      [0.6, 2.7],
      [-0.4, 1.9],
      [2.6, 3.3],
      [0.5, 2.3],
      [2.1, 3.2],
      [2.6, 2.1],
      [0.3, 2.9],
      [0.6, 1.8],
      [2.9, -5.9],
      [3.3, -3.4],
      [3.8, 1.1],
      [3.6, 0.4],
      [0.5, -4.5],
      [2.2, -3.2],
      [3, -2],
      [4.6, 2.1],
      [3.6, 2.4],
      [4.1, 0.6],
      [4.2, 1.2],
      [1.6, -3.8],
      [0.8, -0.5],
      [2.6, 0.6],
      [6.2, -3.1],
      [2.2, 1.3],
      [1.8, -0.2],
      [0.9, -1.5],
      [2, -0.6],
      [4.3, 0.7],
      [3.6, 0.1],
      [1.8, -0.6],
      [-0.9, -2.1],
      [-4.2, -2.5],
      [-1.5, -3.8],
      [-2.4, -2.7],
      [-3.8, -3.4],
      [-0.1, -2],
      [-3.1, -2.6],
      [-3.8, -2.5],
      'z',
    ],
  },
  {
    name: 'Chad',
    vertexPoint: [
      ['m', 1108.4, 447.6],
      [-22.4, -12.2],
      [-22.3, -12.2],
      [-5.4, 3.5],
      [1.6, 9.9],
      [2, 1.6],
      [0.2, 2.1],
      [2.3, 2.2],
      [-1.1, 2.7],
      [-1.8, 12.9],
      [-0.2, 8.3],
      [-6.9, 6],
      [-2.3, 8.4],
      [2.4, 2.3],
      [0, 4.1],
      [3.6, 0.2],
      [-0.5, 2.9],
      [2.2, 4.1],
      [0.5, 4.2],
      [-0.2, 4.3],
      [3.1, 5.8],
      [-3.1, -0.1],
      [-1.6, 0.4],
      [-2.5, -0.6],
      [-1.2, 3],
      [3.3, 3.7],
      [2.5, 1.1],
      [0.8, 2.6],
      [1.8, 4.4],
      [-0.9, 1.7],
      [4.7, -0.4],
      [1, -1.7],
      [0.9, 0.2],
      [1.4, 1.4],
      [7.1, -2.4],
      [2.3, -2.5],
      [2.9, -2.2],
      [-0.6, -2.3],
      [1.6, -0.6],
      [5.4, 0.4],
      [5.2, -2.9],
      [4, -7],
      [2.8, -2.5],
      [3.5, -1.1],
      [0, -1.6],
      [-2.1, -1.8],
      [-0.1, -3.7],
      [-1.2, -2.5],
      [-2, 0.4],
      [0.5, -2.4],
      [1.4, -2.6],
      [-0.7, -2.7],
      [1.8, -1.9],
      [-1.2, -1.5],
      [1.4, -3.9],
      [2.4, -4.7],
      [4.8, 0.4],
      [-1.1, -25.2],
      'z',
    ],
  },
  {
    name: 'Czech Republic',
    vertexPoint: [
      ['m', 1049.4, 248.5],
      [-2.1, 0.6],
      [-1.4, -0.7],
      [-1.1, 1.2],
      [-3.4, 1.2],
      [-1.7, 1.5],
      [-3.4, 1.3],
      [1, 1.9],
      [0.7, 2.6],
      [2.6, 1.5],
      [2.9, 2.6],
      [3.8, 2],
      [2.6, -2.5],
      [1.7, -0.5],
      [4, 1.9],
      [2.3, -0.3],
      [2.3, 1.2],
      [0.6, -1.4],
      [2.2, 0.1],
      [1.6, -0.6],
      [0.1, -0.6],
      [0.9, -0.3],
      [0.2, -1.4],
      [1.1, -0.3],
      [0.6, -1.1],
      [1.5, 0],
      [-2.6, -3.1],
      [-3.6, -0.3],
      [-0.7, -2],
      [-3.4, -0.6],
      [-0.6, 1.5],
      [-2.7, -1.2],
      [0.1, -1.7],
      [-3.7, -0.6],
      [-2.4, -1.9],
      'z',
    ],
  },
  {
    name: 'Chile',
    vertexPoint: [
      ['m', 648.4, 905.2],
      [-3.7, -0.7],
      [-3.3, 2.5],
      [0.2, 4.1],
      [-1.2, 2.8],
      [-7.2, -2.2],
      [-8.6, -4],
      [-4.5, -1.3],
      [9.7, 6.8],
      [6.3, 3.2],
      [7.5, 3.4],
      [5.3, 0.9],
      [4.3, 1.8],
      [3, 0.5],
      [2.3, 0.1],
      [3.2, -1.8],
      [0.5, -2.4],
      [-2.9, -0.2],
      [-5, 0],
      [-5.9, -13.5],
      'z',
      ['m', -47.3, -196.3],
      [-3.7, -7.1],
      [1.1, -6.2],
      [-3.2, -2.7],
      [-1.2, -4.6],
      [-3.1, -4.3],
      [-1.2, 3.3],
      [-2.7, 1.6],
      [2.1, 9],
      [1.5, 10.4],
      [-0.1, 14.2],
      [0, 13.2],
      [0.9, 12.3],
      [-1.9, 7.8],
      [2.1, 7.8],
      [-0.5, 5.3],
      [3.2, 9.5],
      [-0.1, 9.5],
      [-1.2, 10.2],
      [-0.6, 10.5],
      [-2.1, 0.2],
      [2.4, 7.3],
      [3.3, 6.3],
      [-1.1, 4.3],
      [1.9, 11.6],
      [1.5, 8.8],
      [3.5, 0.9],
      [-1.1, -7.7],
      [4, 1.6],
      [1.8, 12.7],
      [-6.4, -2.1],
      [2, 10.2],
      [-2.7, 5.5],
      [8.2, 1.8],
      [-3.4, 4.8],
      [0.2, 6],
      [5, 10.6],
      [4.2, 4.1],
      [0.2, 3.6],
      [3.3, 3.8],
      [7.5, 3.5],
      [0, 0],
      [7.4, 4.2],
      [6.2, 2],
      [2, -0.1],
      [-1.8, -5.7],
      [3.4, -2.2],
      [1.7, -1.5],
      [4.2, 0],
      [-4.8, -0.9],
      [-12, -0.8],
      [-3.5, -3.6],
      [-1.8, -4.6],
      [-3.1, 0.4],
      [-2.6, -2.2],
      [-3.1, -6.6],
      [2.7, -2.7],
      [0.1, -3.9],
      [-1.8, -3.2],
      [0.7, -5.3],
      [-1.1, -8.2],
      [-1.8, -3.7],
      [1.8, -1.1],
      [-1.3, -2.3],
      [-2.8, -1.3],
      [0.8, -2.6],
      [-3.1, -2.3],
      [-3.6, -7.1],
      [1.6, -1.2],
      [-3.3, -7.6],
      [-0.7, -6.4],
      [-0.3, -5.7],
      [2.5, -2.4],
      [-3.3, -6.3],
      [-1.5, -5.9],
      [2.9, -4.3],
      [-1.4, -5.4],
      [1.6, -6.2],
      [-1.3, -5.9],
      [-1.6, -1.2],
      [-4.9, -10.9],
      [2.1, -6.5],
      [-1.7, -6.2],
      [0.9, -5.8],
      [2.6, -5.9],
      [3.2, -4],
      [-2, -2.5],
      [0.9, -2],
      [-1.6, -10.6],
      [5.6, -3.2],
      [1.1, -6.6],
      [-0.9, -1.6],
      [-3.8, 0.9],
      [-3.1, -8.8],
      'z',
    ],
  },
  {
    name: 'China',
    vertexPoint: [
      ['m', 1587.2, 453.3],
      [0.6, -3.6],
      [2, -2.8],
      [-1.6, -2.5],
      [-3.2, -0.1],
      [-5.8, 1.8],
      [-2.2, 2.8],
      [1, 5.5],
      [4.9, 2],
      [4.3, -3.1],
      'z',
      ['m', 13.2, -196.5],
      [-6.1, -6.1],
      [-4.4, -3.7],
      [-3.8, -2.7],
      [-7.7, -6.1],
      [-5.9, -2.3],
      [-8.5, -1.8],
      [-6.2, 0.2],
      [-5.1, 1.1],
      [-1.7, 3],
      [3.7, 1.5],
      [2.5, 3.3],
      [-1.2, 2],
      [0.1, 6.5],
      [1.9, 2.7],
      [-4.4, 3.9],
      [-7.3, -2.3],
      [0.6, 4.6],
      [0.3, 6.2],
      [2.7, 2.6],
      [2.4, -0.8],
      [5.4, 1],
      [2.5, -2.3],
      [5.1, 2],
      [7.2, 4.3],
      [0.7, 2.2],
      [-4.3, -0.7],
      [-6.8, 0.8],
      [-2.4, 1.8],
      [-1.4, 4.1],
      [-6.3, 2.4],
      [-3.1, 3.3],
      [-5.9, -1.3],
      [-3.2, -0.5],
      [-0.4, 4],
      [2.9, 2.3],
      [1.9, 2.1],
      [-2.5, 2],
      [-1.9, 3.3],
      [-4.9, 2.2],
      [-7.5, 0.2],
      [-7.2, 2.2],
      [-4.4, 3.3],
      [-3.2, -2],
      [-6.2, 0.1],
      [-9.3, -3.8],
      [-5.5, -0.9],
      [-6.4, 0.8],
      [-11.2, -1.3],
      [-5.5, 0.1],
      [-4.7, -3.6],
      [-4.9, -5.7],
      [-3.4, -0.7],
      [-7.9, -3.8],
      [-7.2, -0.9],
      [-6.4, -1],
      [-3, -2.7],
      [-1.3, -7.3],
      [-5.8, -5],
      [-8.1, -2.3],
      [-5.7, -3.3],
      [-3.3, -4.4],
      [-1.7, 0.5],
      [-1.8, 4.2],
      [-3.8, 0.6],
      [2.5, 6.2],
      [-1.6, 2.8],
      [-10.7, -2],
      [1, 11.1],
      [-2, 1.4],
      [-9, 2.4],
      [8.7, 10.7],
      [-2.9, 1.6],
      [1.7, 3.5],
      [-0.2, 1.4],
      [-6.8, 3.4],
      [-1, 2.4],
      [-6.4, 0.8],
      [-0.6, 4],
      [-5.7, -0.9],
      [-3.2, 1.2],
      [-4, 3],
      [1.1, 1.5],
      [-1, 1.5],
      [3, 5.9],
      [1.6, -0.6],
      [3.5, 1.4],
      [0.6, 2.5],
      [1.8, 3.7],
      [1.4, 1.9],
      [4.7, 3],
      [2.9, 5],
      [9.4, 2.6],
      [7.6, 7.5],
      [0.8, 5.2],
      [3, 3.3],
      [0.6, 3.3],
      [-4.1, -0.9],
      [3.2, 7],
      [6.2, 4],
      [8.5, 4.4],
      [1.9, -1.5],
      [4.7, 2],
      [6.4, 4.1],
      [3.2, 0.9],
      [2.5, 3.1],
      [4.5, 1.2],
      [5, 2.8],
      [6.4, 1.5],
      [6.5, 0.6],
      [3, -1.4],
      [1.5, 5.1],
      [2.6, -4.8],
      [2.6, -1.6],
      [4.2, 1.5],
      [2.9, 0.1],
      [2.7, 1.8],
      [4.2, -0.8],
      [3.9, -4.8],
      [5.3, -4],
      [4.9, 1.5],
      [3.2, -2.6],
      [3.5, 3.9],
      [-1.2, 2.7],
      [6.1, 0.9],
      [3, -0.4],
      [2.7, 3.7],
      [2.7, 1.5],
      [1.3, 4.9],
      [0.8, 5.3],
      [-4.1, 5.3],
      [0.7, 7.5],
      [5.6, -1],
      [2.3, 5.8],
      [3.7, 1.3],
      [-0.8, 5.2],
      [4.5, 2.4],
      [2.5, 1.2],
      [3.8, -1.8],
      [0.6, 2.6],
      [0.7, 1.5],
      [2.9, 0.1],
      [-1.9, -7.2],
      [2.7, -1],
      [2.7, -1.5],
      [4.3, 0],
      [5.3, -0.7],
      [4.1, -3.4],
      [3, 2.4],
      [5.2, 1.1],
      [-0.2, 3.7],
      [3, 2.6],
      [5.9, 1.6],
      [2.4, -1],
      [7.7, 2],
      [-0.9, 2.5],
      [2.2, 4.6],
      [3, -0.4],
      [0.8, -6.7],
      [5.6, -0.9],
      [7.2, -3.2],
      [2.5, -3.2],
      [2.3, 2.1],
      [2.8, -2.9],
      [6.1, -0.7],
      [6.6, -5.3],
      [6.3, -5.9],
      [3.3, -7.6],
      [2.3, -8.4],
      [2.1, -6.9],
      [2.8, -0.5],
      [-0.1, -5.1],
      [-0.8, -5.1],
      [-3.8, -2],
      [-2.5, -3.4],
      [2.8, -1.7],
      [-1.6, -4.7],
      [-5.4, -4.9],
      [-5.4, -5.8],
      [-4.6, -6.3],
      [-7.1, -3.5],
      [0.9, -4.6],
      [3.8, -3.2],
      [1, -3.5],
      [6.7, -1.8],
      [-2.4, -3.4],
      [-3.4, -0.2],
      [-5.8, -2.5],
      [-3.9, 4.6],
      [-4.9, -1.9],
      [-1.5, -2.9],
      [-4.7, -1],
      [-4.7, -4.4],
      [1.2, -3],
      [5, -0.3],
      [1.2, -4.1],
      [3.6, -4.4],
      [3.4, -2.2],
      [4.4, 3.3],
      [-1.9, 4.2],
      [2.3, 2.5],
      [-1.4, 3],
      [4.8, -1.8],
      [2.4, -2.9],
      [6.3, -1.9],
      [2.1, -4],
      [3.8, -3.4],
      [1, -4.4],
      [3.6, 2],
      [4.6, 0.2],
      [-2.7, -3.3],
      [6.3, -2.6],
      [-0.1, -3.5],
      [5.5, 3.6],
      [0, 0],
      [-1.9, -3.1],
      [2.5, -0.1],
      [-3.8, -7.3],
      [-4.7, -5.3],
      [2.9, -2.2],
      [6.8, 1.1],
      [-0.6, -6],
      [-2.8, -6.8],
      [0.4, -2.3],
      [-1.3, -5.6],
      [-6.9, 1.8],
      [-2.6, 2.5],
      [-7.5, 0],
      [-6, -5.8],
      [-8.9, -4.5],
      [-9.9, -1.9],
      'z',
    ],
  },
  {
    name: 'Ivory Coast',
    vertexPoint: [
      ['m', 946.5, 506.2],
      [-2.3, 0.9],
      [-1.3, 0.8],
      [-0.9, -2.7],
      [-1.6, 0.7],
      [-1, -0.1],
      [-1, 1.9],
      [-4.3, -0.1],
      [-1.6, -1],
      [-0.7, 0.6],
      [-1.1, 0.5],
      [-0.5, 2.2],
      [1.3, 2.6],
      [1.3, 5.1],
      [-2, 0.8],
      [-0.6, 0.9],
      [0.4, 1.2],
      [-0.3, 2.8],
      [-0.9, 0],
      [-0.3, 1.8],
      [0.6, 3.1],
      [-1.2, 2.8],
      [1.6, 1.8],
      [1.8, 0.4],
      [2.3, 2.7],
      [0.2, 2.5],
      [-0.5, 0.8],
      [-0.5, 5.2],
      [1.1, 0.2],
      [5.6, -2.4],
      [3.9, -1.8],
      [6.6, -1.1],
      [3.6, -0.1],
      [3.9, 1.3],
      [2.6, -0.1],
      [0.2, -2.5],
      [-2.4, -5.5],
      [1.5, -7.2],
      [2.3, -5.3],
      [-1.4, -9.1],
      [-3.8, -1.6],
      [-2.7, 0.2],
      [-1.9, 1.6],
      [-2.5, -1.3],
      [-1, -2.1],
      [-2.5, -1.4],
      'z',
    ],
  },
  {
    name: 'Cameroon',
    vertexPoint: [
      ['m', 1060.1, 502.9],
      [0.2, -4.3],
      [-0.5, -4.2],
      [-2.2, -4.1],
      [-1.6, 0.4],
      [-0.2, 2],
      [2.3, 2.6],
      [-0.6, 1.1],
      [-0.3, 2.1],
      [-4.6, 5],
      [-1.5, 4],
      [-0.7, 3.3],
      [-1.2, 1.4],
      [-1.1, 4.5],
      [-3, 2.6],
      [-0.8, 3.2],
      [-1.2, 2.6],
      [-0.5, 2.6],
      [-3.9, 2.2],
      [-3.2, -2.6],
      [-2.1, 0.1],
      [-3.3, 3.7],
      [-1.6, 0.1],
      [-2.7, 6.1],
      [-1.4, 4.5],
      [0, 1.8],
      [1.4, 0.9],
      [1.1, 2.8],
      [2.6, 1.1],
      [2.2, 4.2],
      [-0.8, 5],
      [9.2, 0.2],
      [2.6, -0.4],
      [3.4, 0.8],
      [3.4, -0.8],
      [0.7, 0.3],
      [7.1, 0.3],
      [4.5, 1.7],
      [4.5, 1.5],
      [0.4, -3.5],
      [-0.6, -1.8],
      [-0.3, -2.9],
      [-2.6, -2.1],
      [-2.1, -3.2],
      [-0.5, -2.3],
      [-2.6, -3.3],
      [0.4, -1.9],
      [-0.6, -2.7],
      [0.4, -5],
      [1.4, -1.1],
      [2.7, -6.5],
      [0.9, -1.7],
      [-1.8, -4.4],
      [-0.8, -2.6],
      [-2.5, -1.1],
      [-3.3, -3.7],
      [1.2, -3],
      [2.5, 0.6],
      [1.6, -0.4],
      [3.1, 0.1],
      [-3.1, -5.8],
      'z',
    ],
  },
  {
    name: 'Cape Verde',
    vertexPoint: [
      ['m', 841.4, 477.6],
      [0.1, -0.4],
      [-0.2, -0.6],
      [-0.3, -0.1],
      [-0.6, 0.4],
      [-0.1, 0.3],
      [0.1, 0.3],
      [0.3, 0.3],
      [0.3, 0.1],
      [0.4, -0.3],
      'z',
      ['m', 6.3, -1.7],
      [0.4, -0.2],
      [0, -0.7],
      [-0.1, -0.3],
      [-0.4, 0],
      [-0.2, 0.4],
      [0, 0.1],
      [0, 0.4],
      [0.2, 0.3],
      [0.1, 0],
      'z',
      ['m', -1.4, 0.8],
      [-0.5, -0.9],
      [-0.3, -0.1],
      [-0.6, -0.7],
      [0, -0.3],
      [-0.3, -0.1],
      [0, 0.2],
      [0, 0.4],
      [-0.2, 0.5],
      [0, 0.5],
      [0.4, 0.8],
      [0.4, 0.2],
      [0.7, 0.1],
      [0.4, -0.6],
      'z',
      ['m', 3.1, -7.8],
      [0, 0.5],
      [-0.3, 0.7],
      [0.5, 0.3],
      [0.3, 0.1],
      [0.6, -0.4],
      [0.2, -0.5],
      [-0.1, -0.3],
      [-0.3, -0.3],
      [-0.3, -0.1],
      [-0.1, 0.1],
      [-0.5, -0.1],
      'z',
      ['m', -6.4, -2.5],
      [-1, -0.1],
      [-0.6, -0.2],
      [-0.1, 0],
      [0, 0.3],
      [0.4, 0.8],
      [0.2, -0.5],
      [0.2, -0.1],
      [0.8, 0.2],
      [0.4, -0.1],
      [-0.1, -0.1],
      [-0.2, -0.2],
      'z',
      ['m', 6.7, -0.2],
      [-0.1, -0.5],
      [0, -0.7],
      [-0.2, 0],
      [-0.3, 0.2],
      [0.1, 0.7],
      [0.1, 0.1],
      [0.2, 0.5],
      [0.2, -0.3],
      'z',
      ['m', -11.1, -1],
      [0, -0.2],
      [-0.3, -0.5],
      [-0.3, 0.1],
      [-0.4, 0.2],
      [-0.1, 0.3],
      [0.4, 0.2],
      [0.2, 0],
      [0.5, -0.1],
      'z',
      ['m', -1.5, -0.9],
      [0.8, -0.6],
      [0.2, -0.3],
      [-0.2, -0.5],
      [-0.5, -0.1],
      [-1.2, 0.6],
      [-0.1, 0.2],
      [0.1, 0.3],
      [0.1, 0.5],
      [0.2, 0.1],
      [0.6, -0.2],
      'z',
    ],
  },
  {
    name: 'Cayman Is.',
    vertexPoint: [
      ['m', 527, 449.1],
      [-0.1, -0.3],
      [-0.1, 0.1],
      [0, 0.6],
      [0.5, 0],
      [0.2, 0],
      [0.3, -0.2],
      [0.6, 0],
      [-0.1, -0.2],
      [-0.8, -0.1],
      [-0.1, 0.1],
      [-0.2, 0.1],
      [-0.2, -0.1],
      'z',
      ['m', 8, -2.3],
      [0, 0],
      [-0.1, -0.1],
      [-0.1, 0],
      [-0.3, 0.1],
      [-0.1, 0],
      [-0.1, 0],
      [-0.1, 0.1],
      [-0.1, 0.1],
      [0.2, 0],
      [0.4, -0.2],
      [0.2, 0],
      [0.1, 0],
      'z',
      ['m', 0.8, -0.1],
      [0.5, -0.2],
      [0, 0],
      [-0.1, -0.1],
      [-0.1, 0],
      [-0.1, 0.1],
      [-0.1, 0],
      [-0.5, 0.3],
      [0.2, 0],
      [0.2, -0.1],
      'z',
    ],
  },
  {
    name: 'Comoros',
    vertexPoint: [
      ['m', 1221.1, 650.5],
      [-0.4, -0.4],
      [-0.4, 0],
      [0, 0.2],
      [0.1, 0.4],
      [1.1, 0.2],
      [-0.4, -0.4],
      'z',
      ['m', 3.9, -1.5],
      [-0.1, 0],
      [-0.2, 0.1],
      [-0.1, 0.2],
      [-0.1, 0.3],
      [-0.3, 0],
      [-0.2, 0],
      [-0.4, 0],
      [0.8, 0.5],
      [0.5, 0.5],
      [0.2, 0.2],
      [0.1, -0.2],
      [0.1, -0.7],
      [-0.3, -0.9],
      'z',
      ['m', -5.6, -1.1],
      [0.2, -0.3],
      [-0.2, -0.7],
      [-0.4, -0.8],
      [0.1, -1.4],
      [-0.2, -0.2],
      [-0.3, 0],
      [-0.1, 0.1],
      [-0.1, 0.3],
      [-0.3, 2],
      [0.4, 0.6],
      [0.3, 0.1],
      [0.5, 0.4],
      [0.1, -0.1],
      'z',
    ],
  },
  {
    name: 'Curaçao',
    vertexPoint: [
      ['m', 595.9, 494.9],
      [0, -0.6],
      [-0.9, -0.4],
      [0, 0.3],
      [0.1, 0.2],
      [0.3, 0.1],
      [0.1, 0.2],
      [-0.1, 0.6],
      [0.2, 0.3],
      [0.3, -0.7],
      'z',
    ],
  },
  {
    name: 'Cyprus',
    vertexPoint: [
      ['m', 1149.9, 348.4],
      [-0.3, -0.1],
      [-0.5, 0.2],
      [-0.4, 0.4],
      [-0.4, 0.3],
      [-0.5, -0.3],
      [0.2, 0.9],
      [0.6, 1.1],
      [0.2, 0.3],
      [0.3, 0.2],
      [1.1, 0.3],
      [0.3, 0],
      [0.6, 0],
      [0.2, 0.1],
      [0.2, 0.4],
      [0.4, 0],
      [0, -0.1],
      [0, -0.3],
      [0.2, -0.2],
      [0.3, -0.2],
      [0.3, 0],
      [0.6, -0.1],
      [0.6, -0.2],
      [0.5, -0.4],
      [0.9, -1],
      [0.3, 0],
      [0.3, 0],
      [0.6, 0],
      [0.6, -0.1],
      [-0.2, -0.4],
      [-0.1, -0.1],
      [-0.4, -0.5],
      [-0.2, -0.4],
      [0.1, -0.6],
      [2.5, -1.9],
      [0.5, -0.5],
      [-0.8, 0.2],
      [-0.6, 0.4],
      [-0.4, 0.2],
      [-0.7, 0.4],
      [-2.3, 0.8],
      [-0.8, 0.1],
      [-0.8, 0],
      [-1, -0.1],
      [-0.9, -0.2],
      [0, 0.7],
      [-0.2, 0.6],
      [-0.6, 0.2],
      [-0.3, -0.1],
      'z',
    ],
  },
  {
    name: 'Democratic Republic of Congo',
    vertexPoint: [
      ['m', 1124.9, 539.4],
      [-4.3, -0.7],
      [-2, 0.6],
      [-0.9, 1.5],
      [-1.8, 0.2],
      [-2.2, -1.3],
      [-6.2, 3.1],
      [-2.6, -0.6],
      [-0.8, 0.5],
      [-1.6, 3.8],
      [-4.2, -1.2],
      [-4.1, -0.6],
      [-3.6, -2.4],
      [-4.6, -2.1],
      [-3, 2],
      [-2.2, 3.2],
      [-0.5, 4.5],
      [-0.3, 3.8],
      [-1.6, 3.4],
      [-1.1, 4],
      [-0.7, 5.6],
      [0.3, 3.6],
      [-0.9, 2.2],
      [-0.2, 2.4],
      [-0.6, 2],
      [-3.7, 3.1],
      [-2.6, 3.2],
      [-2.5, 6.2],
      [0.2, 5.3],
      [-1.4, 2],
      [-3.3, 3.1],
      [-3.4, 4],
      [-2, -1.1],
      [-0.4, -1.8],
      [-3.1, -0.1],
      [-1.9, 2.4],
      [-1.5, -0.6],
      [-2, 1.3],
      [-0.9, 1.7],
      [-0.2, 2.7],
      [-1.5, 0.7],
      [0.8, 2],
      [2.3, -0.9],
      [1.7, 0.1],
      [1.9, -0.7],
      [16.6, 0.1],
      [1.3, 4.7],
      [1.6, 3.8],
      [1.3, 2.1],
      [2.1, 3.3],
      [3.7, -0.5],
      [1.9, -0.9],
      [3, 0.9],
      [0.9, -1.6],
      [1.5, -3.7],
      [3.4, -0.3],
      [0.3, -1.1],
      [2.9, 0],
      [-0.5, 2.3],
      [6.8, 0],
      [0, 4],
      [1.2, 2.4],
      [-0.9, 3.8],
      [0.3, 4],
      [1.9, 2.3],
      [-0.5, 7.6],
      [1.4, -0.6],
      [2.4, 0.2],
      [3.5, -1],
      [2.6, 0.4],
      [1.9, 0.1],
      [0.3, 2],
      [2.6, -0.1],
      [3.5, 0.6],
      [1.8, 2.8],
      [4.5, 0.9],
      [3.4, -2],
      [1.2, 3.4],
      [4.3, 0.8],
      [2, 2.8],
      [2.1, 3.5],
      [4.3, 0],
      [-0.3, -6.9],
      [-1.5, 1.2],
      [-3.9, -2.5],
      [-1.4, -1.1],
      [0.8, -6.4],
      [1.2, -7.5],
      [-1.2, -2.8],
      [1.6, -4.1],
      [1.6, -0.7],
      [7.5, -1.1],
      [1, 0.3],
      [0.2, -1.1],
      [-1.5, -1.7],
      [-0.7, -3.5],
      [-3.4, -3.5],
      [-1.8, -4.5],
      [1, -2.7],
      [-1.5, -3.6],
      [1.1, -10.2],
      [0.1, 0.1],
      [-0.1, -1.1],
      [-1.4, -2.9],
      [0.6, -3.5],
      [0.8, -0.4],
      [0.2, -3.8],
      [1.6, -1.8],
      [0.1, -4.8],
      [1.3, -2.4],
      [0.3, -5.1],
      [1.2, -3],
      [2.1, -3.3],
      [2.2, -1.7],
      [1.8, -2.3],
      [-2.3, -0.8],
      [0.3, -7.5],
      [0, 0],
      [-5, -4.2],
      [-1.4, -2.7],
      [-3.1, 1.3],
      [-2.6, -0.4],
      [-1.5, 1.1],
      [-2.5, -0.8],
      [-3.5, -5.2],
      [-1.8, 0.6],
      [-3.6, -0.1],
      'z',
    ],
  },
  {
    name: 'Congo',
    vertexPoint: [
      ['m', 1080.3, 549.9],
      [-3.6, -0.4],
      [-3.8, -1.1],
      [-3.3, 3.4],
      [-2.9, 5.9],
      [-0.4, 3.5],
      [-4.5, -1.5],
      [-4.5, -1.7],
      [-7.1, -0.3],
      [-0.4, 2.8],
      [1.5, 3.3],
      [4.2, -0.5],
      [1.4, 1.2],
      [-2.4, 7.4],
      [2.7, 3.8],
      [0.6, 4.9],
      [-0.8, 4.3],
      [-1.7, 3],
      [-4.9, -0.3],
      [-3, -3],
      [-0.5, 2.8],
      [-3.8, 0.8],
      [-1.9, 1.6],
      [2.1, 4.2],
      [-4.3, 3.5],
      [4.6, 6.7],
      [2.2, -2.7],
      [1.8, -1.1],
      [2, 2.2],
      [1.5, 0.6],
      [1.9, -2.4],
      [3.1, 0.1],
      [0.4, 1.8],
      [2, 1.1],
      [3.4, -4],
      [3.3, -3.1],
      [1.4, -2],
      [-0.2, -5.3],
      [2.5, -6.2],
      [2.6, -3.2],
      [3.7, -3.1],
      [0.6, -2],
      [0.2, -2.4],
      [0.9, -2.2],
      [-0.3, -3.6],
      [0.7, -5.6],
      [1.1, -4],
      [1.6, -3.4],
      [0.3, -3.8],
      'z',
    ],
  },
  {
    name: 'Colombia',
    vertexPoint: [
      ['m', 578.3, 497.2],
      [1.2, -2.1],
      [-1.3, -1.7],
      [-2, -0.4],
      [-2.9, 3.1],
      [-2.3, 1.4],
      [-4.6, 3.2],
      [-4.3, -0.5],
      [-0.5, 1.3],
      [-3.6, 0.1],
      [-3.3, 3],
      [-1.4, 5.4],
      [-0.1, 2.1],
      [-2.4, 0.7],
      [-4.4, 4.4],
      [-2.9, -0.2],
      [-0.7, 0.9],
      [1.1, 3.8],
      [-1.1, 1.9],
      [-1.8, -0.5],
      [-0.9, 3.1],
      [2.2, 3.4],
      [0.6, 5.4],
      [-1.2, 1.6],
      [1.1, 5.9],
      [-1.2, 3.7],
      [2, 1.5],
      [-2.2, 3.3],
      [-2.5, 4],
      [-2.8, 0.4],
      [-1.4, 2.3],
      [0.2, 3.2],
      [-2.1, 0.5],
      [0.8, 2],
      [5.6, 3.6],
      [1, -0.1],
      [1.4, 2.7],
      [4.7, 0.9],
      [1.6, -1],
      [2.8, 2.1],
      [2.4, 1.5],
      [1.5, -0.6],
      [3.7, 3],
      [1.8, 3],
      [2.7, 1.7],
      [3.4, 6.7],
      [4.2, 0.8],
      [3, -1.7],
      [2.1, 1.1],
      [3.3, -0.6],
      [4.4, 3],
      [-3.5, 6.5],
      [1.7, 0.1],
      [2.9, 3.4],
      [2.2, -17.4],
      [0.1, -2.8],
      [-0.9, -3.6],
      [-2.5, -2.4],
      [0, -4.6],
      [3.2, -1],
      [1.1, 0.6],
      [0.2, -2.4],
      [-3.3, -0.7],
      [0, -3.9],
      [10.9, 0.1],
      [1.9, -2.2],
      [1.6, 2],
      [1, 3.8],
      [1.1, -0.8],
      [-1.7, -6.4],
      [-1.4, -2.2],
      [-2, -1.4],
      [2.9, -3.1],
      [-0.2, -1.5],
      [-1.5, -1.9],
      [-1, -4.2],
      [0.5, -4.6],
      [1.3, -2.1],
      [1.2, -3.4],
      [-2, -1.1],
      [-3.2, 0.7],
      [-4, -0.3],
      [-2.3, 0.7],
      [-3.8, -5.5],
      [-3.2, -0.8],
      [-7.2, 0.6],
      [-1.3, -2.2],
      [-1.3, -0.6],
      [-0.2, -1.3],
      [0.8, -2.4],
      [-0.4, -2.5],
      [-1.1, -1.4],
      [-0.6, -2.9],
      [-2.9, -0.5],
      [1.8, -3.7],
      [0.9, -4.5],
      [1.8, -2.4],
      [2.2, -1.8],
      [1.6, -3.2],
      [3.7, -1.1],
      'z',
    ],
  },
  {
    name: 'Costa Rica',
    vertexPoint: [
      ['m', 509.1, 502.6],
      [-1.4, 1.3],
      [-1.7, -0.4],
      [-0.8, -1.3],
      [-1.7, -0.5],
      [-1.4, 0.8],
      [-3.5, -1.7],
      [-0.9, 0.8],
      [-1.4, 1.2],
      [1.5, 0.9],
      [-0.9, 2],
      [-0.1, 2],
      [0.7, 1.3],
      [1.7, 0.6],
      [1.2, 1.8],
      [1.2, -1.6],
      [-0.3, -1.8],
      [1.4, 1.1],
      [0.3, 1.9],
      [1.9, 0.8],
      [2.1, 1.3],
      [1.5, 1.5],
      [0.1, 1.4],
      [-0.7, 1.1],
      [1.1, 1.3],
      [2.9, 1.4],
      [0.4, -1.2],
      [0.5, -1.3],
      [-0.1, -1.2],
      [0.8, -0.7],
      [-1.1, -1],
      [0.1, -2.5],
      [2.2, -0.6],
      [-2.4, -2.7],
      [-2, -2.6],
      [-1.2, -3.4],
      'z',
    ],
  },
  {
    name: 'Cuba',
    vertexPoint: [
      ['m', 539, 427.3],
      [-4.9, -2.1],
      [-4.3, -0.1],
      [-4.7, -0.5],
      [-1.4, 0.7],
      [-4.2, 0.6],
      [-3, 1.3],
      [-2.7, 1.4],
      [-1.5, 2.3],
      [-3.1, 2],
      [2.2, 0.6],
      [2.9, -0.7],
      [0.9, -1.6],
      [2.3, -0.1],
      [4.4, -3.3],
      [5.4, 0.3],
      [-2.3, 1.6],
      [1.8, 1.3],
      [7, 1],
      [1.5, 1.3],
      [4.9, 1.7],
      [3.2, -0.2],
      [0.8, 3.6],
      [1.7, 1.8],
      [3.5, 0.4],
      [2.1, 1.7],
      [-4.1, 3.5],
      [7.9, -0.6],
      [3.8, 0.5],
      [3.7, -0.3],
      [3.8, -0.8],
      [0.8, -1.5],
      [-3.9, -2.6],
      [-4, -0.3],
      [0.6, -1.7],
      [-3.1, -1.3],
      [-1.9, 0],
      [-3, -2.8],
      [-4.2, -4],
      [-1.8, -1.5],
      [-5.2, 0.8],
      [-1.9, -2.4],
      'z',
    ],
  },
  {
    name: 'Germany',
    vertexPoint: [
      ['m', 1043.6, 232.3],
      [-2.4, -1.9],
      [-5.5, -2.4],
      [-2.5, 1.7],
      [-4.7, 1.1],
      [-0.1, -2.1],
      [-4.9, -1.4],
      [-0.2, -2.3],
      [-3, 0.9],
      [-3.6, -0.8],
      [0.4, 3.4],
      [1.2, 2.2],
      [-3, 3],
      [-1, -1.3],
      [-3.9, 0.3],
      [-0.9, 1.3],
      [1, 2],
      [-1, 5.6],
      [-1.1, 2.3],
      [-2.9, 0],
      [1.1, 6.4],
      [-0.4, 4.2],
      [1, 1.4],
      [-0.2, 2.7],
      [2.4, 1.6],
      [7.1, 1.2],
      [-2.3, 4.2],
      [-0.5, 4.5],
      [4.2, 0],
      [1, -1.4],
      [5.4, 1.9],
      [1.5, -0.3],
      [2.6, 1.7],
      [0.6, -1.6],
      [4.4, 0.3],
      [3.4, -1.2],
      [2.4, 0.2],
      [1.7, 1.3],
      [0.4, -1.1],
      [-1, -4],
      [1.7, -0.8],
      [1.5, -2.9],
      [-2.9, -2.6],
      [-2.6, -1.5],
      [-0.7, -2.6],
      [-1, -1.9],
      [3.4, -1.3],
      [1.7, -1.5],
      [3.4, -1.2],
      [1.1, -1.2],
      [1.4, 0.7],
      [2.1, -0.6],
      [-2.3, -3.9],
      [0.1, -2.1],
      [-1.4, -3.3],
      [-2, -2.2],
      [1.2, -1.6],
      [-1.4, -3.1],
      'z',
    ],
  },
  {
    name: 'Guadeloupe',
    vertexPoint: [
      ['m', 636.4, 471.1],
      [0.2, -0.2],
      [0, -0.3],
      [-0.2, -0.3],
      [-0.2, 0.1],
      [-0.2, 0.3],
      [0, 0.3],
      [0.1, 0.1],
      [0.3, 0],
      'z',
      ['m', -1.9, -0.8],
      [0.2, -0.2],
      [0, -1.2],
      [0.1, -0.3],
      [-0.2, -0.1],
      [-0.2, -0.2],
      [-0.6, -0.2],
      [-0.1, 0.1],
      [-0.2, 0.3],
      [0.1, 1.5],
      [0.2, 0.5],
      [0.2, 0.1],
      [0.5, -0.3],
      'z',
      ['m', 1.6, -1.4],
      [0.8, -0.2],
      [-0.9, -0.6],
      [-0.2, -0.4],
      [0, -0.3],
      [-0.4, -0.3],
      [-0.2, 0.2],
      [-0.1, 0.3],
      [0.1, 0.5],
      [-0.3, 0.4],
      [0.1, 0.4],
      [0.4, 0.1],
      [0.7, -0.1],
      'z',
    ],
  },
  {
    name: 'Djibouti',
    vertexPoint: [
      ['m', 1217.8, 499.2],
      [-2.5, -1.7],
      [3.1, -1.5],
      [0.1, -2.7],
      [-1.4, -1.9],
      [-1.6, 1.5],
      [-2.4, -0.5],
      [-1.9, 2.8],
      [-1.8, 3],
      [0.5, 1.7],
      [0.2, 2],
      [3.1, 0.1],
      [1.3, -0.5],
      [1.3, 1.1],
      [2, -3.4],
      'z',
    ],
  },
  {
    name: 'Denmark',
    vertexPoint: [
      ['m', 1035.9, 221.2],
      [-1.7, -3],
      [-6.7, 2],
      [0.9, 2.5],
      [5.1, 3.4],
      [2.4, -4.9],
      'z',
      ['m', -8.6, -5.1],
      [-2.6, -0.9],
      [-0.7, -1.6],
      [1.3, -2],
      [-0.1, -3],
      [-3.6, 1.6],
      [-1.5, 1.7],
      [-4, 0.4],
      [-1.2, 1.7],
      [-0.7, 1.6],
      [0.4, 6.1],
      [2.1, 3.4],
      [3.6, 0.8],
      [3, -0.9],
      [-1.5, -3],
      [3.1, -4.3],
      [1.4, 0.7],
      [1, -2.3],
      'z',
    ],
  },
  {
    name: 'Dominican Rep.',
    vertexPoint: [
      ['m', 579.6, 457.4],
      [0, 1.8],
      [1.4, 1],
      [2.6, -4.4],
      [2, -0.9],
      [0.6, 1.6],
      [2.2, -0.4],
      [1.1, -1.2],
      [1.8, 0.3],
      [2.6, -0.2],
      [2.5, 1.3],
      [2.3, -2.6],
      [-2.5, -2.3],
      [-2.4, -0.2],
      [0.3, -1.9],
      [-3, 0.1],
      [-0.8, -2.2],
      [-1.4, 0.1],
      [-3.1, -1.6],
      [-4.4, -0.1],
      [-0.8, 1.1],
      [0.2, 3.5],
      [-0.7, 2.4],
      [-1.5, 1.1],
      [1.2, 1.9],
      [-0.2, 1.8],
      'z',
    ],
  },
  {
    name: 'Dominica',
    vertexPoint: [
      ['m', 635.8, 475.1],
      [0.3, -0.7],
      [-0.1, -1],
      [-0.2, -0.4],
      [-0.8, -0.3],
      [0, 0.2],
      [-0.1, 0.5],
      [0.3, 0.8],
      [0.1, 1.1],
      [0.5, -0.2],
      'z',
    ],
  },
  {
    name: 'Ecuador',
    vertexPoint: [
      ['m', 553.1, 573.1],
      [-2.4, -1.5],
      [-2.8, -2.1],
      [-1.6, 1],
      [-4.7, -0.9],
      [-1.4, -2.7],
      [-1, 0.1],
      [-5.6, -3.6],
      [-3.9, 2.5],
      [-3.1, 1.4],
      [0.4, 2.6],
      [-2.2, 4.1],
      [-1, 3.9],
      [-1.9, 1],
      [1, 5.8],
      [-1.1, 1.8],
      [3.4, 2.7],
      [2.1, -2.9],
      [1.3, 2.8],
      [-2.9, 4.7],
      [0.7, 2.7],
      [-1.5, 1.5],
      [0.2, 2.3],
      [2.3, -0.5],
      [2.3, 0.7],
      [2.5, 3.2],
      [3.1, -2.6],
      [0.9, -4.3],
      [3.3, -5.5],
      [6.7, -2.5],
      [6, -6.7],
      [1.7, -4.1],
      [-0.8, -4.9],
      'z',
    ],
  },
  {
    name: 'Egypt',
    vertexPoint: [
      ['m', 1129.7, 374.8],
      [-5.5, -1.9],
      [-5.3, -1.7],
      [-7.1, 0.2],
      [-1.8, 3],
      [1.1, 2.7],
      [-1.2, 3.9],
      [2, 5.1],
      [1.3, 22.7],
      [1, 23.4],
      [22.1, 0],
      [21.4, 0],
      [21.8, 0],
      [-1, -1.3],
      [-6.8, -5.7],
      [-0.4, -4.2],
      [1, -1.1],
      [-5.3, -7],
      [-2, -3.6],
      [-2.3, -3.5],
      [-4.8, -9.9],
      [-3.9, -6.4],
      [-2.8, -6.7],
      [0.5, -0.6],
      [4.6, 9.1],
      [2.7, 2.9],
      [2, 2],
      [1.2, -1.1],
      [1.2, -3.3],
      [0.7, -4.8],
      [1.3, -2.5],
      [-0.7, -1.7],
      [-3.9, -9.2],
      [0, 0],
      [-2.5, 1.6],
      [-4.2, -0.4],
      [-4.4, -1.5],
      [-1.1, 2.1],
      [-1.7, -3.2],
      [-3.9, -0.8],
      [-4.7, 0.6],
      [-2.1, 1.8],
      [-3.9, 2],
      [-2.6, -1],
      'z',
    ],
  },
  {
    name: 'Eritrea',
    vertexPoint: [
      ['m', 1198.1, 474],
      [-3.2, -3.1],
      [-1.8, -5.9],
      [-3.7, -7.3],
      [-2.6, 3.6],
      [-4, 1],
      [-1.6, 2],
      [-0.4, 4.2],
      [-1.9, 9.4],
      [0.7, 2.5],
      [6.5, 1.3],
      [1.5, -4.7],
      [3.5, 2.9],
      [3.2, -1.5],
      [1.4, 1.3],
      [3.9, 0.1],
      [4.9, 2.5],
      [1.6, 2.2],
      [2.5, 2.1],
      [2.5, 3.7],
      [2, 2.1],
      [2.4, 0.5],
      [1.6, -1.5],
      [-2.8, -1.9],
      [-1.9, -2.2],
      [-3.2, -3.7],
      [-3.2, -3.6],
      [-7.9, -6],
      'z',
    ],
  },
  {
    name: 'Estonia',
    vertexPoint: [
      ['m', 1093.2, 197.5],
      [-5.5, 0.9],
      [-5.4, 1.6],
      [0.9, 3.4],
      [3.3, 2.1],
      [1.5, -0.8],
      [0.1, 3.5],
      [3.7, -1],
      [2.1, 0.7],
      [4.4, 2.2],
      [3.8, 0],
      [1.6, -1.9],
      [-2.5, -5.5],
      [2.6, -3.4],
      [-0.9, -1],
      [0, 0],
      [-4.6, 0.2],
      [-5.1, -1],
      'z',
    ],
  },
  {
    name: 'Ethiopia',
    vertexPoint: [
      ['m', 1187.6, 477],
      [-1.5, 4.7],
      [-6.5, -1.3],
      [-0.7, 5.5],
      [-2.1, 6.2],
      [-3.2, 3.2],
      [-2.3, 4.8],
      [-0.5, 2.6],
      [-2.6, 1.8],
      [-1.4, 6.7],
      [0, 0.7],
      [0.2, 5],
      [-0.8, 2],
      [-3, 0.1],
      [-1.8, 3.6],
      [3.4, 0.5],
      [2.9, 3.1],
      [1, 2.5],
      [2.6, 1.5],
      [3.5, 6.9],
      [2.9, 1.1],
      [0, 3.6],
      [2, 2.1],
      [3.9, 0],
      [7.2, 5.4],
      [1.8, 0],
      [1.3, -0.1],
      [1.2, 0.7],
      [3.8, 0.5],
      [1.6, -2.7],
      [5.1, -2.6],
      [2.3, 2.1],
      [3.8, 0],
      [1.5, -2],
      [3.6, -0.1],
      [4.9, -4.5],
      [7.4, -0.3],
      [15.4, -19.1],
      [-4.8, 0.1],
      [-18.5, -7.6],
      [-2.2, -2.2],
      [-2.1, -3.1],
      [-2.2, -3.5],
      [1.1, -2.3],
      [-1.3, -1.1],
      [-1.3, 0.5],
      [-3.1, -0.1],
      [-0.2, -2],
      [-0.5, -1.7],
      [1.8, -3],
      [1.9, -2.8],
      [-2, -2.1],
      [-2.5, -3.7],
      [-2.5, -2.1],
      [-1.6, -2.2],
      [-4.9, -2.5],
      [-3.9, -0.1],
      [-1.4, -1.3],
      [-3.2, 1.5],
      [-3.5, -2.9],
      'z',
    ],
  },
  {
    name: 'Finland',
    vertexPoint: [
      ['m', 1093.4, 144.4],
      [0.8, -3.8],
      [-5.7, -2.1],
      [-5.8, 1.8],
      [-1.1, 3.9],
      [-3.4, 2.4],
      [-4.7, -1.3],
      [-5.3, 0.3],
      [-5.1, -2.9],
      [-2.1, 1.4],
      [5.9, 2.7],
      [7.2, 3.7],
      [1.7, 8.4],
      [1.9, 2.2],
      [6.4, 2.6],
      [0.9, 2.3],
      [-2.6, 1.2],
      [-8.7, 6.1],
      [-3.3, 3.6],
      [-1.5, 3.3],
      [2.9, 5.2],
      [-0.1, 5.7],
      [4.7, 1.9],
      [3.1, 3.1],
      [7.1, -1.2],
      [7.5, -2.1],
      [8, -0.5],
      [0, 0],
      [7.9, -7.4],
      [3.3, -3.3],
      [0.9, -2.9],
      [-7.3, -3.9],
      [0.9, -3.7],
      [-4.9, -4.1],
      [1.7, -4.8],
      [-6.4, -6.3],
      [2.8, -4.1],
      [-7.2, -3.7],
      [-0.4, -3.7],
      'z',
    ],
  },
  {
    name: 'Fiji',
    vertexPoint: [
      ['m', 1976.7, 674.4],
      [-3.7, 2],
      [-1.9, 0.3],
      [-3.1, 1.3],
      [0.2, 2.4],
      [3.9, -1.3],
      [3.9, -1.6],
      [0.7, -3.1],
      'z',
      ['m', -11, 8.1],
      [-1.6, 1],
      [-2.3, -0.8],
      [-2.7, 2.2],
      [-0.2, 2.8],
      [2.9, 0.8],
      [3.6, -0.9],
      [1.8, -3.3],
      [-1.5, -1.8],
      'z',
    ],
  },
  {
    name: 'Gabon',
    vertexPoint: [
      ['m', 1050.2, 557.7],
      [-0.7, -0.3],
      [-3.4, 0.8],
      [-3.4, -0.8],
      [-2.6, 0.4],
      [0, 7.6],
      [-8.2, 0],
      [-1.9, 0.3],
      [-1.1, 4.8],
      [-1.3, 4.6],
      [-1.3, 2],
      [-0.2, 2.1],
      [3.4, 6.6],
      [3.7, 5.3],
      [5.8, 6.4],
      [4.3, -3.5],
      [-2.1, -4.2],
      [1.9, -1.6],
      [3.8, -0.8],
      [0.5, -2.8],
      [3, 3],
      [4.9, 0.3],
      [1.7, -3],
      [0.8, -4.3],
      [-0.6, -4.9],
      [-2.7, -3.8],
      [2.4, -7.4],
      [-1.4, -1.2],
      [-4.2, 0.5],
      [-1.5, -3.3],
      [0.4, -2.8],
      'z',
    ],
  },
  {
    name: 'United Arab Emirates',
    vertexPoint: [
      ['m', 1283.9, 408.6],
      [-1.3, -2.2],
      [-3, 3.9],
      [-3.7, 4.1],
      [-3.3, 4.3],
      [-3.3, -0.2],
      [-4.6, -0.2],
      [-4.2, 1],
      [-0.3, -1.7],
      [-1, 0.3],
      [0.4, 1.5],
      [2.6, 6.4],
      [16.8, 3.2],
      [1, -1.3],
      [-0.1, -2.6],
      [1.4, -2.6],
      [-0.3, -2.6],
      [2.4, -1.3],
      [-1.1, -0.8],
      [0.1, -4.2],
      [2.8, 0],
      [-1.3, -5],
      'z',
    ],
  },
  {
    name: 'United Kingdom',
    vertexPoint: [
      ['m', 950, 227.5],
      [-4.9, -3.7],
      [-3.9, 0.3],
      [0.8, 3.2],
      [-1.1, 3.2],
      [2.9, -0.1],
      [3.5, 1.3],
      [2.7, -4.2],
      'z',
      ['m', 13, -24.3],
      [-5.5, 0.5],
      [-3.6, -0.4],
      [-3.7, 4.8],
      [-1.9, 6.1],
      [2.2, 3],
      [0.1, 5.8],
      [2.6, -2.8],
      [1.4, 1.6],
      [-1.7, 2.7],
      [1, 1.6],
      [5.7, 1.1],
      [0.1, 0],
      [3.1, 3.8],
      [-0.8, 3.5],
      [0, 0],
      [-7.1, -0.6],
      [-1, 4],
      [2.6, 3.3],
      [-5.1, 1.9],
      [1.3, 2.4],
      [7.5, 1],
      [0, 0],
      [-4.3, 1.3],
      [-7.3, 6.5],
      [2.5, 1.2],
      [3.5, -2.3],
      [4.5, 0.7],
      [3.3, -2.9],
      [2.2, 1.2],
      [8.3, -1.7],
      [6.5, 0.1],
      [4.3, -3.3],
      [-1.9, -3.1],
      [2.4, -1.8],
      [0.5, -3.9],
      [-5.8, -1.2],
      [-1.3, -2.3],
      [-2.9, -6.9],
      [-3.2, -1],
      [-4.1, -7.1],
      [-0.4, -0.6],
      [-4.8, -0.4],
      [4.2, -5.3],
      [1.3, -4.9],
      [-5, 0],
      [-4.7, 0.8],
      [5, -6.4],
      'z',
    ],
  },
  {
    name: 'Georgia',
    vertexPoint: [
      ['m', 1200, 300.2],
      [-7.5, -2.9],
      [-7.7, -1],
      [-4.5, -1.1],
      [-0.5, 0.7],
      [2.2, 1.9],
      [3, 0.7],
      [3.4, 2.3],
      [2.1, 4.2],
      [-0.3, 2.7],
      [5.4, -0.3],
      [5.6, 3],
      [6.9, -1],
      [1.1, -1],
      [4.2, 1.8],
      [2.8, 0.4],
      [0.6, -0.7],
      [-3.2, -3.4],
      [1.1, -0.9],
      [-3.5, -1.4],
      [-2.1, -2.5],
      [-5.1, -1.3],
      [-2.9, 1],
      [-1.1, -1.2],
      'z',
    ],
  },
  {
    name: 'Ghana',
    vertexPoint: [
      ['m', 976.8, 502.1],
      [-2.6, -0.5],
      [-1.8, 1],
      [-2.4, -0.5],
      [-9.7, 0.3],
      [-0.2, 3.6],
      [0.8, 4.8],
      [1.4, 9.1],
      [-2.3, 5.3],
      [-1.5, 7.2],
      [2.4, 5.5],
      [-0.2, 2.5],
      [5, 1.8],
      [5, -1.9],
      [3.2, -2.1],
      [8.7, -3.8],
      [-1.2, -2.2],
      [-1.5, -4],
      [-0.4, -3.2],
      [1.2, -5.7],
      [-1.4, -2.3],
      [-0.6, -5.1],
      [0.1, -4.6],
      [-2.4, -3.3],
      [0.4, -1.9],
      'z',
    ],
  },
  {
    name: 'Guinea',
    vertexPoint: [
      ['m', 912.4, 493],
      [-0.8, 0.4],
      [-3, -0.5],
      [-0.4, 0.7],
      [-1.3, 0.1],
      [-4, -1.5],
      [-2.7, -0.1],
      [-0.1, 2.1],
      [-0.6, 0.7],
      [0.4, 2.1],
      [-0.8, 0.9],
      [-1.3, 0],
      [-1.4, 1],
      [-1.7, -0.1],
      [-2.6, 3.1],
      [1.6, 1.1],
      [0.8, 1.4],
      [0.7, 2.8],
      [1.3, 1.2],
      [1.5, 0.9],
      [2.1, 2.5],
      [2.4, 3.7],
      [3, -2.8],
      [0.7, -1.7],
      [1, -1.4],
      [1.5, -0.2],
      [1.3, -1.2],
      [4.5, 0],
      [1.5, 2.3],
      [1.2, 2.7],
      [-0.2, 1.8],
      [0.9, 1.7],
      [0, 2.3],
      [1.5, -0.3],
      [1.2, -0.2],
      [1.5, -0.7],
      [2.3, 3.9],
      [-0.4, 2.6],
      [1.1, 1.3],
      [1.6, 0.1],
      [1.1, -2.6],
      [1.6, 0.2],
      [0.9, 0],
      [0.3, -2.8],
      [-0.4, -1.2],
      [0.6, -0.9],
      [2, -0.8],
      [-1.3, -5.1],
      [-1.3, -2.6],
      [0.5, -2.2],
      [1.1, -0.5],
      [-1.7, -1.8],
      [0.3, -1.9],
      [-0.7, -0.7],
      [-1.2, 0.6],
      [0.2, -2.1],
      [1.2, -1.6],
      [-2.3, -2.7],
      [-0.6, -1.7],
      [-1.3, -1.4],
      [-1.1, -0.2],
      [-1.3, 0.9],
      [-1.8, 0.8],
      [-1.6, 1.4],
      [-2.4, -0.5],
      [-1.5, -1.6],
      [-0.9, -0.2],
      [-1.5, 0.8],
      [-0.9, 0],
      [-0.3, -2.3],
      'z',
    ],
  },
  {
    name: 'Gambia',
    vertexPoint: [
      ['m', 882.8, 488.5],
      [5, 0.1],
      [1.4, -0.9],
      [1, 0],
      [2.1, -1.5],
      [2.4, 1.4],
      [2.4, 0.1],
      [2.4, -1.5],
      [-1.1, -1.8],
      [-1.8, 1.1],
      [-1.8, -0.1],
      [-2.1, -1.5],
      [-1.8, 0.1],
      [-1.3, 1.5],
      [-6.1, 0.2],
      [-0.7, 2.8],
      'z',
    ],
  },
  {
    name: 'Guinea-Bissau',
    vertexPoint: [
      ['m', 900.2, 492.1],
      [-10.3, -0.3],
      [-1.5, 0.7],
      [-1.8, -0.2],
      [-3, 1.1],
      [0.3, 1.3],
      [1.7, 1.4],
      [0, 0.9],
      [1.2, 1.8],
      [2.4, 0.5],
      [2.9, 2.6],
      [2.6, -3.1],
      [1.7, 0.1],
      [1.4, -1],
      [1.3, 0],
      [0.8, -0.9],
      [-0.4, -2.1],
      [0.6, -0.7],
      [0.1, -2.1],
      'z',
    ],
  },
  {
    name: 'Eq. Guinea',
    vertexPoint: [
      ['m', 1040.1, 557.8],
      [-9.2, -0.2],
      [-1.9, 7.2],
      [1, 0.9],
      [1.9, -0.3],
      [8.2, 0],
      [0, -7.6],
      'z',
    ],
  },
  {
    name: 'Greece',
    vertexPoint: [
      ['m', 1101.9, 344.9],
      [-0.8, 2.8],
      [6.6, 1.2],
      [0, 1.1],
      [7.6, -0.6],
      [0.5, -1.9],
      [-2.8, 0.8],
      [0, -1.1],
      [-3.9, -0.5],
      [-4.1, 0.4],
      [-3.1, -2.2],
      'z',
      ['m', 11.5, -37.4],
      [-2.7, -1.6],
      [0.3, 3],
      [-4.6, 0.6],
      [-3.9, -2.1],
      [-3.9, 1.7],
      [-3.8, -0.2],
      [-1, 0.2],
      [-0.7, 1.1],
      [-2.8, -0.1],
      [-1.9, 1.3],
      [-3.3, 0.6],
      [0, 1.6],
      [-1.6, 0.9],
      [-0.1, 2.1],
      [-2.1, 3],
      [0.5, 1.9],
      [2.9, 3.6],
      [2.3, 3],
      [1.3, 4.3],
      [2.3, 5.1],
      [4.6, 2.9],
      [3.4, -0.1],
      [-2.4, -5.7],
      [3.3, -0.7],
      [-1.9, -3.3],
      [5, 1.7],
      [-0.4, -3.7],
      [-2.7, -1.8],
      [-3.2, -3],
      [1.8, -1.4],
      [-2.8, -3],
      [-1.6, -3.8],
      [0.9, -1.3],
      [3, 3.2],
      [2.9, 0],
      [2.5, -1],
      [-3.9, -3.6],
      [6.1, -1.6],
      [2.7, 0.6],
      [3.2, 0.2],
      [1.1, -0.7],
      [1.2, -3.9],
      'z',
    ],
  },
  {
    name: 'Greenland',
    vertexPoint: [
      ['m', 887.4, 76.3],
      [-26, -0.4],
      [-11.8, 0.3],
      [-5, 1.3],
      [-11.5, -0.1],
      [-12.7, 2.1],
      [-1.6, 1.7],
      [6.7, 2.1],
      [-6.2, -1.3],
      [-4.5, -0.3],
      [-7, -1.4],
      [-10.6, 2.1],
      [-2.7, -1.2],
      [-10.4, 0],
      [-10.9, 0.6],
      [-8.9, 1],
      [-0.2, 1.8],
      [-5.3, 0.5],
      [-14.6, 2.9],
      [-4.6, 1.7],
      [8.1, 1.5],
      [-2.8, 1.6],
      [-14.9, 2.2],
      [-15.5, 2.2],
      [-2.2, 1.7],
      [6.4, 2],
      [14.5, 1.2],
      [-7.5, 0.2],
      [-10.9, 1.5],
      [3.8, 3.1],
      [3, 1.5],
      [9.4, -0.3],
      [10.1, -0.2],
      [7.6, 0.3],
      [8, 2.9],
      [-1.4, 2.1],
      [3.6, 1.9],
      [1.4, 5.3],
      [1, 3.6],
      [1.4, 1.9],
      [-7, 4.8],
      [2.6, 1.3],
      [4.4, -0.8],
      [2.6, 1.8],
      [5.3, 3.4],
      [-7.5, -1.4],
      [-3.8, 0],
      [-3, 2.8],
      [-1.5, 3.6],
      [4.2, 1.8],
      [4, -0.8],
      [2.6, -0.8],
      [5.5, -1.9],
      [-2.8, 4.2],
      [-2.6, 2.3],
      [-7.1, 2],
      [-7, 6.3],
      [2, 2],
      [-3.4, 4],
      [3.7, 5.2],
      [-1.5, 5],
      [0.7, 3.7],
      [4.8, 7.1],
      [0.8, 5.6],
      [3.1, 3.2],
      [8.9, 0],
      [5, 4.7],
      [6.5, -0.3],
      [4.1, -5.7],
      [3.5, -4.8],
      [-0.3, -4.4],
      [8.6, -4.6],
      [3.3, -3.7],
      [1.4, -3.9],
      [4.7, -3.5],
      [6.5, -1.3],
      [6.1, -1.4],
      [3, -0.2],
      [10.2, -3.9],
      [7.4, -5.7],
      [4.8, -2.1],
      [4.6, -0.1],
      [12.5, -1.8],
      [12.1, -4.3],
      [11.9, -4.6],
      [-5.5, -0.3],
      [-10.6, -0.2],
      [5.3, -2.8],
      [-0.5, -3.6],
      [4.2, 3],
      [2.7, 2.1],
      [7.3, -1],
      [-0.6, -4.3],
      [-4.5, -3.1],
      [-5, -1.3],
      [2.4, -1.4],
      [7.2, 2.1],
      [0.5, -2.3],
      [-4.1, -3.4],
      [5.4, 0],
      [5.6, -0.8],
      [1.7, -1.8],
      [-4, -2.1],
      [8.6, -0.3],
      [-4, -4.3],
      [4.1, -0.5],
      [0.1, -4.2],
      [-6.2, -2.5],
      [6.4, -1.6],
      [5.8, -0.1],
      [-3.6, -3.2],
      [1.1, -5.1],
      [3.6, -2.9],
      [4.9, -3.2],
      [-8, -0.2],
      [11.3, -0.7],
      [2.2, -1],
      [14.6, -2.9],
      [-1.6, -1.7],
      [-10, -0.8],
      [-16.9, 1.5],
      [-9.2, 1.5],
      [4.5, -2.3],
      [-2.3, -1.4],
      [-7, 1.2],
      [-9.7, -1.4],
      [-12.1, 0.5],
      [-1.4, -0.7],
      [18.3, -0.4],
      [12.9, -0.2],
      [6.6, -1.4],
      [-19.7, -2.9],
      'z',
    ],
  },
  {
    name: 'Guatemala',
    vertexPoint: [
      ['m', 482.8, 458.9],
      [-5.1, -0.1],
      [-5.2, 0],
      [-0.4, 3.6],
      [-2.6, 0],
      [1.8, 2.1],
      [1.9, 1.5],
      [0.5, 1.4],
      [0.8, 0.4],
      [-0.4, 2.1],
      [-7.1, 0],
      [-3.3, 5.2],
      [0.7, 1.2],
      [-0.8, 1.5],
      [-0.4, 1.9],
      [2.7, 2.6],
      [2.5, 1.3],
      [3.4, 0.1],
      [2.8, 1.1],
      [0.2, -1],
      [2.1, -1.6],
      [1.1, -0.7],
      [-0.2, -0.7],
      [1.4, -0.4],
      [1.3, -1.6],
      [-0.3, -1.3],
      [0.5, -1.2],
      [2.8, -1.8],
      [2.8, -2.4],
      [-1.5, -0.8],
      [-0.6, 0.9],
      [-1.7, -1.1],
      [-1.6, 0],
      [1.2, -7.2],
      [0.7, -5],
      'z',
    ],
  },
  {
    name: 'Guyana',
    vertexPoint: [
      ['m', 656.1, 534.2],
      [-2.1, -2.3],
      [-2.9, -3.1],
      [-2.1, -0.1],
      [-0.1, -3.3],
      [-3.3, -4.1],
      [-3.6, -2.4],
      [-4.6, 3.8],
      [-0.6, 2.3],
      [1.9, 2.3],
      [-1.5, 1.2],
      [-3.4, 1.1],
      [0, 2.9],
      [-1.6, 1.8],
      [3.7, 4.8],
      [2.9, -0.3],
      [1.3, 1.5],
      [-0.8, 2.8],
      [1.9, 0.9],
      [1.2, 3],
      [-1.6, 2.2],
      [-1, 5.4],
      [1.4, 3.3],
      [0.3, 2.9],
      [3.5, 3],
      [2.7, 0.3],
      [0.7, -1.3],
      [1.7, -0.2],
      [2.6, -1.1],
      [1.8, -1.7],
      [3.1, 0.5],
      [1.4, -0.2],
      [-3.3, -5.6],
      [-0.7, -3.5],
      [-1.8, -0.1],
      [-2.4, -4.6],
      [1.1, -3.3],
      [-0.3, -1.5],
      [3.5, -1.6],
      [1, -5.7],
      'z',
    ],
  },
  {
    name: 'Honduras',
    vertexPoint: [
      ['m', 514.1, 476.8],
      [-1.3, -1.8],
      [-1.9, -1],
      [-1.5, -1.4],
      [-1.6, -1.2],
      [-0.8, -0.1],
      [-2.5, -0.9],
      [-1.1, 0.5],
      [-1.5, 0.2],
      [-1.3, -0.4],
      [-1.7, -0.4],
      [-0.8, 0.7],
      [-1.8, 0.7],
      [-2.6, 0.2],
      [-2.5, -0.6],
      [-0.9, 0.4],
      [-0.5, -0.6],
      [-1.6, 0.1],
      [-1.3, 1.1],
      [-0.6, -0.2],
      [-2.8, 2.4],
      [-2.8, 1.8],
      [-0.5, 1.2],
      [0.3, 1.3],
      [-1.3, 1.6],
      [1.5, 0.5],
      [1.1, 1.3],
      [1.6, 1],
      [0.1, 0.9],
      [2.5, -0.8],
      [1.1, 0.5],
      [0.7, 0.7],
      [-0.6, 2.5],
      [1.7, 0.6],
      [0.7, 2],
      [1.8, -0.3],
      [0.8, -1.5],
      [0.8, 0],
      [0.2, -3.1],
      [1.3, -0.2],
      [1.2, 0],
      [1.4, -1.7],
      [1.5, 1.3],
      [0.6, -0.8],
      [1.1, -0.7],
      [2.1, -1.8],
      [0.3, -1.3],
      [0.5, 0.1],
      [0.8, -1.5],
      [0.6, -0.2],
      [0.9, 0.9],
      [1.1, 0.3],
      [1.3, -0.8],
      [1.4, 0],
      [2, -0.8],
      [0.9, -0.9],
      [1.9, 0.2],
      'z',
    ],
  },
  {
    name: 'Croatia',
    vertexPoint: [
      ['m', 1065, 280.4],
      [-4, -2.6],
      [-1.6, -0.8],
      [-3.9, 1.7],
      [-0.3, 2.5],
      [-1.7, 0.6],
      [0.2, 1.7],
      [-2, -0.1],
      [-1.8, -1],
      [-0.8, 1],
      [-3.5, -0.2],
      [-0.2, 0.1],
      [0, 2.2],
      [1.7, 2],
      [1.3, -2.6],
      [3.3, 1],
      [0.3, 2],
      [2.5, 2.6],
      [-1, 0.5],
      [4.6, 4.5],
      [4.8, 1.8],
      [3.1, 2.2],
      [5, 2.3],
      [0, 0],
      [0.5, -1],
      [-4.7, -2.4],
      [-2.2, -2.5],
      [-2, -1.4],
      [-2.5, -2.3],
      [-1.3, -1.9],
      [-2.7, -2.9],
      [0.9, -2.5],
      [1.9, 1.4],
      [1, -1.3],
      [2.3, -0.1],
      [4.4, 1],
      [3.5, -0.1],
      [2.4, 1.4],
      [0, 0],
      [1.7, -2.3],
      [-1.7, -1.8],
      [-1.5, -2.4],
      [0, 0],
      [-1.8, 0.9],
      [-4.2, -1.2],
      'z',
    ],
  },
  {
    name: 'Haiti',
    vertexPoint: [
      ['m', 580.6, 446.7],
      [-4.6, -1],
      [-3.4, -0.2],
      [-1.4, 1.7],
      [3.4, 1],
      [-0.3, 2.4],
      [2.2, 2.8],
      [-2.1, 1.4],
      [-4.2, -0.5],
      [-5, -0.9],
      [-0.7, 2.1],
      [2.8, 1.9],
      [2.7, -1.1],
      [3.3, 0.4],
      [2.7, -0.4],
      [3.6, 1.1],
      [0.2, -1.8],
      [-1.2, -1.9],
      [1.5, -1.1],
      [0.7, -2.4],
      [-0.2, -3.5],
      'z',
    ],
  },
  {
    name: 'Hungary',
    vertexPoint: [
      ['m', 1079.1, 263.8],
      [-1.6, 0.4],
      [-1, 1.5],
      [-2.2, 0.7],
      [-0.6, -0.4],
      [-2.3, 1],
      [-1.9, 0.2],
      [-0.3, 1.2],
      [-4.1, 0.8],
      [-1.9, -0.7],
      [-2.6, -1.6],
      [-0.2, 2.6],
      [-2.8, 0],
      [1.1, 1.3],
      [-1.3, 4],
      [0.8, 0.1],
      [1.2, 2.1],
      [1.6, 0.8],
      [4, 2.6],
      [4.2, 1.2],
      [1.8, -0.9],
      [0, 0],
      [3.7, -1.6],
      [3.2, 0.2],
      [3.8, -1.1],
      [2.6, -4.3],
      [1.9, -4.2],
      [2.9, -1.3],
      [-0.6, -1.6],
      [-2.9, -1.7],
      [-1, 0.6],
      [-5.5, -1.9],
      'z',
    ],
  },
  {
    name: 'Indonesia',
    vertexPoint: [
      ['m', 1651.9, 637.3],
      [0.5, -1.7],
      [-1.8, -1.9],
      [-2.8, -2],
      [-5.3, 1.3],
      [7, 4.4],
      [2.4, -0.1],
      'z',
      ['m', 20.9, -0.6],
      [4, -4.8],
      [0.1, -1.9],
      [-0.5, -1.3],
      [-5.7, 2.6],
      [-2.8, 3.9],
      [-0.7, 2.1],
      [0.6, 0.8],
      [5, -1.4],
      'z',
      ['m', -35.6, -13],
      [-1.6, 2.2],
      [-3.1, 0.1],
      [-2.2, 3.6],
      [3, 0.1],
      [3.9, -0.9],
      [6.6, -1.2],
      [-1.2, -2.8],
      [-3.5, 0.6],
      [-1.9, -1.7],
      'z',
      ['m', 28.1, 0],
      [-5.2, 2.3],
      [-3.8, 0.5],
      [-3.4, -1.9],
      [-4.5, 1.3],
      [-0.2, 2.3],
      [7.4, 0.8],
      [8.6, -1.8],
      [1.1, -3.5],
      'z',
      ['m', -79.5, -8.4],
      [-0.7, -2.3],
      [-2.3, -0.5],
      [-4.4, -2.4],
      [-6.8, -0.4],
      [-4.1, 6.1],
      [5.1, 0.4],
      [0.8, 2.8],
      [10, 2.6],
      [2.4, -0.8],
      [4.1, 0.6],
      [6.3, 2.4],
      [5.2, 1.2],
      [5.8, 0.5],
      [5.1, -0.2],
      [5.9, 2.5],
      [6.6, -2.4],
      [-6.6, -3.8],
      [-8.3, -1.1],
      [-1.8, -4.1],
      [-10.3, -3.1],
      [-1.3, 2.6],
      [-10.7, -0.6],
      'z',
      ['m', 146.6, -3.6],
      [0.2, -3],
      [-1.2, -1.9],
      [-1.3, 2.2],
      [-1.2, 2.2],
      [0.3, 4.8],
      [3.2, -4.3],
      'z',
      ['m', -41, -17.5],
      [-1.4, -2.1],
      [-5.7, 0.3],
      [1, 2.7],
      [3.9, 1.2],
      [2.2, -2.1],
      'z',
      ['m', 18.1, -2.4],
      [-6.1, -1.8],
      [-6.9, 0.3],
      [-1.5, 3.5],
      [3.9, 0.2],
      [3.2, -0.4],
      [4.6, 0.5],
      [4.7, 2.6],
      [-1.9, -4.9],
      'z',
      ['m', 21, -12.3],
      [-0.8, -2.4],
      [-9, -2.6],
      [-2.9, 2.1],
      [-7.6, 1.5],
      [2.3, 3.2],
      [5, 1.2],
      [2.1, 3.7],
      [8.3, 0.1],
      [0.4, 1.6],
      [-4, -0.1],
      [-6.2, 2.3],
      [4.2, 3.1],
      [-0.1, 2.8],
      [1.2, 2.3],
      [2.1, -0.5],
      [1.8, -3.1],
      [8.2, 5.9],
      [4.6, 0.5],
      [10.6, 5.4],
      [2.3, 5.3],
      [1, 6.9],
      [-3.7, 1.8],
      [-2.8, 5.2],
      [7.1, -0.2],
      [1.6, -1.8],
      [5.5, 1.3],
      [4.6, 5.2],
      [1.5, -20.8],
      [1, -20.7],
      [-6, -1.2],
      [-4.1, -2.3],
      [-4.7, -2.2],
      [-5, 0],
      [-6.6, 3.8],
      [-4.9, 6.8],
      [-5.7, -3.8],
      [-1.3, -10.3],
      'z',
      ['m', -50, -16.4],
      [-1, -1.4],
      [-5.5, 4.6],
      [-6.5, 0.3],
      [-7.1, -0.9],
      [-4.4, -1.9],
      [-4.7, 4.8],
      [-1.2, 2.6],
      [-2.9, 9.6],
      [-0.9, 5],
      [-2.4, 4.2],
      [1.6, 4.3],
      [2.3, 0.1],
      [0.6, 6.1],
      [-1.9, 5.9],
      [2.3, 1.9],
      [3.6, -1],
      [0.3, -9.1],
      [-0.2, -7.4],
      [3.8, -1.9],
      [-0.7, 6.2],
      [3.9, 3.7],
      [-0.8, 2.5],
      [1.3, 1.7],
      [5.6, -2.4],
      [-3, 5.2],
      [2.1, 2.2],
      [3.1, -1.9],
      [0.3, -4.1],
      [-4.7, -7.4],
      [1.1, -2.2],
      [-5.1, -8.1],
      [5, -2.5],
      [2.6, -3.7],
      [2.4, 0.9],
      [0.5, -2.9],
      [-10.5, 2.1],
      [-3.1, 2.9],
      [-5, -5.6],
      [0.9, -4.8],
      [4.9, -1],
      [9.3, -0.3],
      [5.4, 1.3],
      [4.3, -1.3],
      [4.4, -6.3],
      'z',
      ['m', 19.4, 1.9],
      [-0.6, -2.6],
      [-3.3, -0.6],
      [-0.5, -3.5],
      [-1.8, 2.3],
      [-1, 5.1],
      [1.7, 8.2],
      [2.2, 4],
      [1.6, -0.8],
      [-2.3, -3.3],
      [0.9, -3.9],
      [2.9, 0.6],
      [0.2, -5.5],
      'z',
      ['m', -60.9, -4.5],
      [0.9, -2.9],
      [-4.3, -6],
      [3, -5.8],
      [-5, -1],
      [-6.4, 0],
      [-1.7, 7.2],
      [-2, 2.2],
      [-2.7, 8.9],
      [-4.5, 1.3],
      [-5.4, -1.8],
      [-2.7, 0.6],
      [-3.2, 3.2],
      [-3.6, -0.4],
      [-3.6, 1.2],
      [-3.9, -3.5],
      [-1, -4.3],
      [-3.3, 4.2],
      [-0.6, 5.9],
      [0.8, 5.6],
      [2.6, 5.4],
      [2.8, 1.8],
      [0.7, 8.5],
      [4.6, 0.8],
      [3.6, -0.4],
      [2, 3.1],
      [6.7, -2.3],
      [2.8, 2],
      [4, 0.4],
      [2, 3.9],
      [6.5, -2.9],
      [0.8, 2.3],
      [2.5, -9.7],
      [0.3, -6.4],
      [5.5, -4.3],
      [-0.2, -5.8],
      [1.8, -4.3],
      [6.7, -0.8],
      [-6.5, -5.9],
      'z',
      ['m', -68.7, 48.9],
      [0.7, -9.8],
      [1.7, -8],
      [-2.6, -4],
      [-4.1, -0.5],
      [-1.9, -3.6],
      [-0.9, -4.4],
      [-2, -0.2],
      [-3.2, -2.2],
      [2.3, -5.2],
      [-4.3, -2.9],
      [-3.3, -5.3],
      [-4.8, -4.4],
      [-5.7, -0.1],
      [-5.5, -6.8],
      [-3.2, -2.7],
      [-4.5, -4.3],
      [-5.2, -6.2],
      [-8.8, -1.2],
      [-3.6, -0.3],
      [0.6, 3.2],
      [6.1, 7],
      [4.4, 3.6],
      [3.1, 5.5],
      [5.1, 4],
      [2.2, 4.9],
      [1.7, 5.5],
      [4.9, 5.3],
      [4.1, 8.9],
      [2.7, 4.8],
      [4.1, 5.2],
      [2.2, 3.8],
      [7, 5.2],
      [4.5, 5.3],
      [6.2, -0.1],
      'z',
    ],
  },
  {
    name: 'India',
    vertexPoint: [
      ['m', 1414.1, 380.1],
      [-8.5, -4.4],
      [-6.2, -4],
      [-3.2, -7],
      [4.1, 0.9],
      [-0.6, -3.3],
      [-3, -3.3],
      [-0.8, -5.2],
      [-7.6, -7.5],
      [-3.7, 5.4],
      [-5.7, 1],
      [-8.5, -1.6],
      [-1.9, 2.8],
      [3.2, 5.6],
      [2.9, 4.3],
      [5, 3.1],
      [-3.7, 3.7],
      [1, 4.5],
      [-3.9, 6.3],
      [-2.1, 6.5],
      [-4.5, 6.7],
      [-6.4, -0.5],
      [-4.9, 6.6],
      [4, 2.9],
      [1.3, 4.9],
      [3.5, 3.2],
      [1.8, 5.5],
      [-12, 0],
      [-3.2, 4.2],
      [7.1, 5.4],
      [1.9, 2.5],
      [-2.4, 2.3],
      [8, 7.7],
      [4, 0.8],
      [7.6, -3.8],
      [1.7, 5.9],
      [0.8, 7.8],
      [2.5, 8.1],
      [3.6, 12.3],
      [5.8, 8.8],
      [1.3, 3.9],
      [2, 8],
      [3.4, 6.1],
      [2.2, 3],
      [2.5, 6.4],
      [3.1, 8.9],
      [5.5, 6],
      [2.2, -1.8],
      [1.7, -4.4],
      [5, -1.8],
      [-1.8, -2.1],
      [2.2, -4.8],
      [2.9, -0.3],
      [-0.7, -10.8],
      [1.9, -6.1],
      [-0.7, -5.3],
      [-1.9, -8.2],
      [1.2, -4.9],
      [2.5, -0.3],
      [4.8, -2.3],
      [2.6, -1.6],
      [-0.3, -2.9],
      [5, -4.2],
      [3.7, -4],
      [5.3, -7.5],
      [7.4, -4.2],
      [2.4, -3.8],
      [-0.9, -4.8],
      [6.6, -1.3],
      [3.7, 0.1],
      [0.5, -2.4],
      [-1.6, -5.2],
      [-2.6, -4.8],
      [0.4, -3.8],
      [-3.7, -1.7],
      [0.8, -2.3],
      [3.1, -2.4],
      [-4.6, -3.4],
      [1.2, -4.3],
      [4.8, 2.7],
      [2.7, 0.4],
      [1.2, 4.4],
      [5.4, 0.9],
      [5, -0.1],
      [3.4, 1.1],
      [-1.6, 5.3],
      [-2.4, 0.4],
      [-1.1, 3.6],
      [3.5, 3.3],
      [0.2, -4],
      [1.5, -0.1],
      [4.5, 10.1],
      [2.4, -1.5],
      [-0.9, -2.7],
      [0.9, -2.1],
      [-0.9, -6.6],
      [4.6, 1.4],
      [1.5, -5.2],
      [-0.3, -3.1],
      [2.1, -5.4],
      [-0.9, -3.6],
      [6.1, -4.4],
      [4.1, 1.1],
      [-1.3, -3.9],
      [1.6, -1.2],
      [-0.9, -2.4],
      [-6.1, -0.9],
      [1.2, -2.7],
      [-3.5, -3.9],
      [-3.2, 2.6],
      [-4.9, -1.5],
      [-5.3, 4],
      [-3.9, 4.8],
      [-4.2, 0.8],
      [2.7, 2],
      [0.4, 3.9],
      [-4.4, 0.2],
      [-4.7, -0.4],
      [-3.2, 1],
      [-5.5, -2.5],
      [-0.3, -1.2],
      [-1.5, -5.1],
      [-3, 1.4],
      [0.1, 2.7],
      [1.5, 4.1],
      [-0.1, 2.5],
      [-4.6, 0.1],
      [-6.8, -1.5],
      [-4.3, -0.6],
      [-3.8, -3.2],
      [-7.6, -0.9],
      [-7.7, -3.5],
      [-5.8, -3.1],
      [-5.7, -2.5],
      [0.9, -5.9],
      [2.8, -2.9],
      'z',
    ],
  },
  {
    name: 'Ireland',
    vertexPoint: [
      ['m', 947.3, 231.7],
      [-3.5, -1.3],
      [-2.9, 0.1],
      [1.1, -3.2],
      [-0.8, -3.2],
      [-3.7, 2.8],
      [-6.7, 4.7],
      [2.1, 6.1],
      [-4.2, 6.4],
      [6.7, 0.9],
      [8.7, -3.6],
      [3.9, -5.4],
      [-0.7, -4.3],
      'z',
    ],
  },
  {
    name: 'Iran',
    vertexPoint: [
      ['m', 1213.5, 324.4],
      [-3.2, -2.9],
      [-1.2, -2.4],
      [-3.3, 1.8],
      [2.9, 7.3],
      [-0.7, 2],
      [3.7, 5.2],
      [0, 0],
      [4.7, 7.8],
      [3.7, 1.9],
      [1, 3.8],
      [-2.3, 2.2],
      [-0.5, 5],
      [4.6, 6.1],
      [7, 3.4],
      [3.5, 4.9],
      [-0.2, 4.6],
      [1.7, 0],
      [0.5, 3.3],
      [3.4, 3.4],
      [1.7, -2.5],
      [3.7, 2.1],
      [2.8, -1],
      [5.1, 8.4],
      [4.3, 6.1],
      [5.5, 1.8],
      [6.1, 4.9],
      [6.9, 2.1],
      [5.1, -3.1],
      [4, -1.1],
      [2.8, 1.1],
      [3.2, 7.8],
      [6.3, 0.8],
      [6.1, 1.5],
      [10.5, 1.9],
      [1.2, -7.4],
      [7.4, -3.3],
      [-0.9, -2.9],
      [-2.7, -1],
      [-1, -5.7],
      [-5.6, -2.7],
      [-2.8, -3.9],
      [-3.2, -3.3],
      [3.9, -5.8],
      [-1.1, -4],
      [-4.3, -1.1],
      [-1.1, -4],
      [-2.7, -5.1],
      [1.6, -3.5],
      [-2.5, -0.9],
      [0.5, -4.7],
      [0.5, -8],
      [-1.6, -5.5],
      [-3.9, -0.2],
      [-7.3, -5.7],
      [-4.3, -0.7],
      [-6.5, -3.3],
      [-3.8, -0.6],
      [-2.1, 1.2],
      [-3.5, -0.2],
      [-3, 3.7],
      [-4.4, 1.2],
      [-0.2, 1.6],
      [-7.9, 1.7],
      [-7.6, -1.1],
      [-4.3, -3.3],
      [-5.2, -1.3],
      [-2.5, -4.8],
      [-1.3, 0.3],
      [-3.8, -3.4],
      [1.2, -3.1],
      [-1.9, -1.9],
      [-1.9, 0.5],
      [-5.3, 4.7],
      [-1.8, 0.2],
      [-3.7, -0.9],
      'z',
    ],
  },
  {
    name: 'Iraq',
    vertexPoint: [
      ['m', 1207.3, 334.9],
      [-6.2, -0.9],
      [-2.1, 1],
      [-2.1, 4.1],
      [-2.7, 1.6],
      [1.2, 4.7],
      [-0.9, 7.8],
      [-11, 6.7],
      [3.1, 7.7],
      [6.7, 1.7],
      [8.5, 4.5],
      [16.7, 12.7],
      [10.2, 0.5],
      [3.2, -6.1],
      [3.7, 0.5],
      [3.2, 0.4],
      [-3.4, -3.4],
      [-0.5, -3.3],
      [-1.7, 0],
      [0.2, -4.6],
      [-3.5, -4.9],
      [-7, -3.4],
      [-4.6, -6.1],
      [0.5, -5],
      [2.3, -2.2],
      [-1, -3.8],
      [-3.7, -1.9],
      [-4.7, -7.8],
      [0, 0],
      [-2.3, 1.1],
      [-2.1, -1.6],
      'z',
    ],
  },
  {
    name: 'Iceland',
    vertexPoint: [
      ['m', 915.7, 158.6],
      [-6.9, -0.4],
      [-7.3, 2.9],
      [-5.1, -1.5],
      [-6.9, 3],
      [-5.9, -3.8],
      [-6.5, 0.8],
      [-3.6, 3.7],
      [8.7, 1.3],
      [-0.1, 1.6],
      [-7.8, 1.1],
      [8.8, 2.7],
      [-4.6, 2.5],
      [11.7, 1.8],
      [5.6, 0.8],
      [3.9, -1],
      [12.9, -3.9],
      [6.1, -4.2],
      [-4.4, -3.8],
      [1.4, -3.6],
      'z',
    ],
  },
  {
    name: 'Israel',
    vertexPoint: [
      ['m', 1167.8, 360.5],
      [-1.4, 0.1],
      [-0.4, 1.1],
      [-1.8, 0],
      [-0.1, 0.1],
      [-0.6, 1.6],
      [-0.6, 4.8],
      [-1.1, 2.9],
      [0.4, 0.4],
      [-1.4, 2.1],
      [0, 0],
      [3.9, 9.2],
      [0.7, 1.7],
      [1.7, -10.2],
      [-0.4, -2.4],
      [-2.4, 0.8],
      [0.1, -1.7],
      [1.2, -0.8],
      [-1.4, -0.7],
      [0.7, -4.3],
      [2, 0.9],
      [0.7, -2],
      [-0.1, 0],
      [0.6, -1],
      [-0.3, -2.6],
      'z',
    ],
  },
  {
    name: 'Italy',
    vertexPoint: [
      ['m', 1057.8, 328.6],
      [-4, 0.5],
      [-5.2, 0.7],
      [-6.2, -0.6],
      [-0.6, 3.4],
      [7.5, 3.3],
      [2.7, 0.7],
      [4.2, 2.4],
      [0.9, -3.3],
      [-0.9, -2],
      [1.6, -5.1],
      'z',
      ['m', -33.7, -18.9],
      [-2.5, 1.9],
      [-2.8, -0.3],
      [1.3, 3.6],
      [0.4, 7.6],
      [2.1, 1.7],
      [2, -2.1],
      [2.4, 0.4],
      [0.4, -8.4],
      [-3.3, -4.4],
      'z',
      ['m', 14.3, -34.3],
      [-1.3, -2.2],
      [-4.8, 1.1],
      [-0.5, 1.2],
      [-3.1, -0.9],
      [-0.3, 2.5],
      [-2.1, 1.1],
      [-3.8, -0.8],
      [-0.9, 2.5],
      [-2.4, 0.2],
      [-0.9, -1],
      [-2.7, 2.1],
      [-2.4, 0.3],
      [-2.2, -1.3],
      [-0.2, 1.7],
      [1.6, 2.4],
      [-1.7, 1.8],
      [1.5, 4.8],
      [2.7, 0.8],
      [-0.5, 2.7],
      [2.1, -0.5],
      [2.8, -2.8],
      [2.3, -0.9],
      [4.2, 2.1],
      [2.6, 0.7],
      [1.9, 6],
      [3.6, 3.6],
      [4.9, 4],
      [4.2, 2.8],
      [3.9, 0.4],
      [2.3, 2.5],
      [3.4, 1.2],
      [1.7, 2.7],
      [2.2, 0.8],
      [1.8, 3.2],
      [2.3, 3.7],
      [-1.1, 1.3],
      [-0.8, 3.5],
      [0.1, 2],
      [2.1, -0.5],
      [2.5, -5.6],
      [2.1, -0.4],
      [0.4, -3.3],
      [-3.9, -2.3],
      [1.9, -4.1],
      [4.5, 1],
      [3.1, 3],
      [0.8, -2.3],
      [-0.6, -1.2],
      [-4.7, -3.2],
      [-3.9, -1.9],
      [-4.8, -2.3],
      [1.4, -1.2],
      [-1.4, -1.4],
      [-4, 0.1],
      [-6, -5],
      [-2.9, -5.1],
      [-4.9, -3.1],
      [-1.9, -3.1],
      [0.5, -1.8],
      [-0.4, -3],
      [3.9, -2.2],
      [4.1, 0.9],
      [-1.4, -2.7],
      [0.3, -3],
      [-7.2, -1.6],
      'z',
    ],
  },
  {
    name: 'Jamaica',
    vertexPoint: [
      ['m', 550.7, 458.5],
      [3.9, -0.1],
      [-0.8, -1.8],
      [-2.7, -1.5],
      [-3.7, -0.6],
      [-1.2, -0.2],
      [-2.4, 0.4],
      [-0.8, 1.5],
      [2.9, 2.3],
      [3, 1],
      [1.8, -1],
      'z',
    ],
  },
  {
    name: 'Jordan',
    vertexPoint: [
      ['m', 1186.6, 367.6],
      [-3.1, -7.7],
      [-9.6, 6.7],
      [-6.3, -2.5],
      [-0.7, 2],
      [0.4, 3.9],
      [-0.6, 1.9],
      [0.4, 2.4],
      [-1.7, 10.2],
      [0.3, 0.9],
      [6.1, 1],
      [2.1, -2],
      [1.1, -2.3],
      [4, -0.8],
      [0.7, -2.2],
      [1.7, -1],
      [-6.1, -6.4],
      [10.4, -3.1],
      [0.9, -1],
      'z',
    ],
  },
  {
    name: 'Japan',
    vertexPoint: [
      ['m', 1692.5, 354.9],
      [-4.5, -1.3],
      [-1.1, 2.7],
      [-3.3, -0.8],
      [-1.3, 3.8],
      [1.2, 3],
      [4.2, 1.8],
      [-0.1, -3.7],
      [2.1, -1.5],
      [3.1, 2.1],
      [1.3, -3.9],
      [-1.6, -2.2],
      'z',
      ['m', 24.4, -19.3],
      [-3.6, -6.7],
      [1.3, -6.4],
      [-2.8, -5.2],
      [-8.1, -8.7],
      [-4.8, 1.2],
      [0.2, 3.9],
      [5.1, 7.1],
      [1, 7.9],
      [-1.7, 2.5],
      [-4.5, 6.5],
      [-5, -3.1],
      [0, 11.5],
      [-6.3, -1.3],
      [-9.6, 1.9],
      [-1.9, 4.4],
      [-3.9, 3.3],
      [-1.1, 4],
      [-4.3, 2],
      [4, 4.3],
      [4.1, 1.9],
      [0.9, 5.7],
      [3.5, 2.5],
      [2.5, -2.7],
      [-0.8, -10.8],
      [-7.3, -4.7],
      [6.1, -0.1],
      [5, -3],
      [8.6, -1.4],
      [2.4, 4.8],
      [4.6, 2.4],
      [4.4, -7.3],
      [9.1, -0.4],
      [5.4, -3],
      [0.6, -4.6],
      [-2.5, -3.2],
      [-0.6, -5.2],
      'z',
      ['m', -11.8, -44.2],
      [-5.3, -2.1],
      [-10.4, -6.4],
      [1.9, 4.8],
      [4.3, 8.5],
      [-5.2, 0.4],
      [0.6, 4.7],
      [4.6, 6.1],
      [5.7, 0],
      [-1.6, -6.8],
      [10.8, 4.2],
      [0.4, -6.1],
      [6.4, -1.7],
      [-6, -6.9],
      [-1.7, 2.6],
      [-4.5, -1.3],
      'z',
    ],
  },
  {
    name: 'Kazakhstan',
    vertexPoint: [
      ['m', 1308.8, 223.8],
      [-9, -1.3],
      [-3.1, 2.5],
      [-10.8, 2.2],
      [-1.7, 1.5],
      [-16.8, 2.1],
      [-1.4, 2.1],
      [5, 4.1],
      [-3.9, 1.6],
      [1.5, 1.7],
      [-3.6, 2.9],
      [9.4, 4.2],
      [-0.2, 3],
      [-6.9, -0.3],
      [-0.8, 1.8],
      [-7.3, -3.2],
      [-7.6, 0.2],
      [-4.3, 2.5],
      [-6.6, -2.4],
      [-11.9, -4.3],
      [-7.5, 0.2],
      [-8.1, 6.6],
      [0.7, 4.6],
      [-6, -3.6],
      [-2.1, 6.8],
      [1.7, 1.2],
      [-1.7, 4.7],
      [5.3, 4.3],
      [3.6, -0.2],
      [4.2, 4.1],
      [0.2, 3.2],
      [2.8, 1],
      [4.4, -1.3],
      [5, -2.7],
      [4.7, 1.5],
      [4.9, -0.3],
      [1.9, 3.9],
      [0.6, 6],
      [-4.6, -0.9],
      [-4, 1],
      [0.9, 4.5],
      [-5, -0.6],
      [0.6, 2],
      [3.2, 1.6],
      [3.7, 5.5],
      [6.4, 2.1],
      [1.5, 2.1],
      [-0.7, 2.6],
      [0.7, 1.5],
      [1.8, -2],
      [5.5, -1.3],
      [3.8, 1.7],
      [4.9, 4.9],
      [2.5, -0.3],
      [-6.2, -22.8],
      [11.9, -3.6],
      [1.1, 0.5],
      [9.1, 4.5],
      [4.8, 2.3],
      [6.5, 5.5],
      [5.7, -0.9],
      [8.6, -0.5],
      [7.5, 4.5],
      [1.5, 6.2],
      [2.5, 0.1],
      [2.6, 5],
      [6.6, 0.2],
      [2.3, 3],
      [1.9, 0],
      [0.9, -4.5],
      [5.4, -4.3],
      [2.5, -1.2],
      [0.3, -2.7],
      [3.1, -0.8],
      [9.1, 2.1],
      [-0.5, -3.6],
      [2.5, -1.3],
      [8.1, 2.6],
      [1.6, -0.7],
      [8.6, 0.2],
      [7.8, 0.6],
      [3.3, 2.2],
      [3.5, 0.9],
      [-1.7, -3.5],
      [2.9, -1.6],
      [-8.7, -10.7],
      [9, -2.4],
      [2, -1.4],
      [-1, -11.1],
      [10.7, 2],
      [1.6, -2.8],
      [-2.5, -6.2],
      [3.8, -0.6],
      [1.8, -4.2],
      [-4.3, -3.8],
      [-6, 0.9],
      [-3.3, -2.6],
      [-3.9, -1.2],
      [-4.1, -3.6],
      [-3.2, -1.1],
      [-6.2, 1.6],
      [-8.3, -3.6],
      [-1.1, 3.3],
      [-18.1, -15.5],
      [-8.3, -4.7],
      [0.8, -1.9],
      [-9.1, 5.7],
      [-4.4, 0.4],
      [-1.2, -3.3],
      [-7, -2.1],
      [-4.3, 1.5],
      [-4.3, -6.3],
      'z',
    ],
  },
  {
    name: 'Kenya',
    vertexPoint: [
      ['m', 1211.7, 547.2],
      [-3.8, 0],
      [-2.3, -2.1],
      [-5.1, 2.6],
      [-1.6, 2.7],
      [-3.8, -0.5],
      [-1.2, -0.7],
      [-1.3, 0.1],
      [-1.8, 0],
      [-7.2, -5.4],
      [-3.9, 0],
      [-2, -2.1],
      [0, -3.6],
      [-2.9, -1.1],
      [-3.8, 4.2],
      [-3.4, 3.8],
      [2.7, 4.4],
      [0.7, 3.2],
      [2.6, 7.3],
      [-2.1, 4.7],
      [-2.7, 4.2],
      [-1.6, 2.6],
      [0, 0.3],
      [1.4, 2.4],
      [-0.4, 4.7],
      [20.2, 13],
      [0.4, 3.7],
      [8, 6.3],
      [2.2, -2.1],
      [1.2, -4.2],
      [1.8, -2.6],
      [0.9, -4.5],
      [2.1, -0.4],
      [1.4, -2.7],
      [4, -2.5],
      [-3.3, -5.3],
      [-0.2, -23.2],
      [4.8, -7.2],
      'z',
    ],
  },
  {
    name: 'Kyrgyzstan',
    vertexPoint: [
      ['m', 1387.2, 302.6],
      [-3.5, -0.9],
      [-3.3, -2.2],
      [-7.8, -0.6],
      [-8.6, -0.2],
      [-1.6, 0.7],
      [-8.1, -2.6],
      [-2.5, 1.3],
      [0.5, 3.6],
      [-9.1, -2.1],
      [-3.1, 0.8],
      [-0.3, 2.7],
      [1.8, 0.6],
      [-3.1, 4.1],
      [4.6, 2.3],
      [3.2, -1.6],
      [7.1, 3.3],
      [-5.2, 4.5],
      [-4.1, -0.6],
      [-1.4, 2],
      [-5.9, -1.1],
      [0.6, 3.7],
      [5.4, -0.5],
      [7.1, 2],
      [9.5, -0.9],
      [1, -1.5],
      [-1.1, -1.5],
      [4, -3],
      [3.2, -1.2],
      [5.7, 0.9],
      [0.6, -4],
      [6.4, -0.8],
      [1, -2.4],
      [6.8, -3.4],
      [0.2, -1.4],
      'z',
    ],
  },
  {
    name: 'Cambodia',
    vertexPoint: [
      ['m', 1574.8, 481.8],
      [-5.2, -2.3],
      [-2, 4.3],
      [-4.9, -2.4],
      [-5.3, -1],
      [-7.1, 1.3],
      [-3, 5.2],
      [2.1, 7.7],
      [3.4, 6.6],
      [2.6, 3.3],
      [4.7, 0.9],
      [4.7, -2.5],
      [5.8, -0.5],
      [-2.8, -3.8],
      [8.9, -4.9],
      [-0.1, -7.7],
      [-1.8, -4.2],
      'z',
    ],
  },
  {
    name: 'South Korea',
    vertexPoint: [
      ['m', 1637.3, 331.7],
      [6.2, 5.5],
      [-3.4, 1.1],
      [5.2, 6.8],
      [1.1, 4.8],
      [2.1, 3.5],
      [4.5, -0.5],
      [3.2, -2.7],
      [4.2, -1.2],
      [0.5, -3.6],
      [-3.4, -7.5],
      [-3.3, -4.2],
      [-8.2, -7.6],
      [0.1, 1.6],
      [-2.1, 0.4],
      [-3.5, 0.3],
      [-0.7, 2.9],
      [-2.4, -0.2],
      [-0.1, 0.6],
      'z',
    ],
  },
  {
    name: 'Kuwait',
    vertexPoint: [
      ['m', 1235.6, 381.4],
      [-3.7, -0.5],
      [-3.2, 6.1],
      [4.9, 0.6],
      [1.7, 3.1],
      [3.8, -0.2],
      [-2.4, -4.8],
      [0.3, -1.5],
      [-1.4, -2.8],
      'z',
    ],
  },
  {
    name: 'Laos',
    vertexPoint: [
      ['m', 1574.8, 481.8],
      [0.2, -6.4],
      [-2, -4.5],
      [-4.8, -4.4],
      [-4.3, -5.6],
      [-5.7, -7.5],
      [-7.3, -3.8],
      [1.3, -2.3],
      [3.3, -1.7],
      [-3, -5.5],
      [-6.8, -0.1],
      [-3.4, -5.7],
      [-4, -5.1],
      [-2.7, 1],
      [1.9, 7.2],
      [-2.9, -0.1],
      [-0.7, -1.5],
      [-4.1, 4.1],
      [-0.8, 2.4],
      [2.6, 1.9],
      [0.9, 3.8],
      [3.8, 0.3],
      [-0.4, 6.7],
      [1, 5.7],
      [5.3, -3.8],
      [1.8, 1.2],
      [3.2, -0.2],
      [0.8, -2.2],
      [4.3, 0.4],
      [4.9, 5.2],
      [1.3, 6.3],
      [5.2, 5.5],
      [0.5, 5.4],
      [-1.5, 2.9],
      [4.9, 2.4],
      [2, -4.3],
      [5.2, 2.3],
      'z',
    ],
  },
  {
    name: 'Lebanon',
    vertexPoint: [
      ['m', 1167.8, 360.5],
      [0.9, -3.5],
      [2.6, -2.4],
      [-1.2, -2.5],
      [-2.4, -0.3],
      [-0.1, 0.2],
      [-2.1, 4.5],
      [-1.3, 5.2],
      [1.8, 0],
      [0.4, -1.1],
      [1.4, -0.1],
      'z',
    ],
  },
  {
    name: 'Liberia',
    vertexPoint: [
      ['m', 929.4, 523.3],
      [-1.6, -0.2],
      [-1.1, 2.6],
      [-1.6, -0.1],
      [-1.1, -1.3],
      [0.4, -2.6],
      [-2.3, -3.9],
      [-1.5, 0.7],
      [-1.2, 0.2],
      [-2.6, 3],
      [-2.6, 3.4],
      [-0.3, 1.9],
      [-1.3, 2],
      [3.7, 4.1],
      [4.8, 3.5],
      [5.1, 4.8],
      [5.7, 3.1],
      [1.5, -0.1],
      [0.5, -5.2],
      [0.5, -0.8],
      [-0.2, -2.5],
      [-2.3, -2.7],
      [-1.8, -0.4],
      [-1.6, -1.8],
      [1.2, -2.8],
      [-0.6, -3.1],
      [0.3, -1.8],
      'z',
    ],
  },
  {
    name: 'Libya',
    vertexPoint: [
      ['m', 1111.8, 371.4],
      [-1.5, -2.1],
      [-5.4, -0.8],
      [-1.8, -1.1],
      [-2, 0],
      [-2, -2.8],
      [-7.3, -1.3],
      [-3.6, 0.8],
      [-3.7, 3],
      [-1.5, 3.1],
      [1.5, 4.8],
      [-2.4, 3],
      [-2.5, 1.6],
      [-5.9, -3.1],
      [-7.7, -2.7],
      [-4.9, -1.2],
      [-2.8, -5.7],
      [-7.2, -2.8],
      [-4.5, -1.1],
      [-2.2, 0.6],
      [-6.4, -2.2],
      [-0.1, 4.9],
      [-2.6, 1.8],
      [-1.5, 2],
      [-3.7, 2.5],
      [0.7, 2.6],
      [-0.4, 2.7],
      [-2.6, 1.4],
      [1.9, 5.6],
      [0.4, 3],
      [-0.9, 5.2],
      [0.5, 2.9],
      [-0.6, 3.5],
      [0.5, 4],
      [-2.1, 2.6],
      [3.4, 4.7],
      [0.2, 2.7],
      [2, 3.6],
      [2.6, -1.2],
      [4.3, 2.9],
      [2.5, 4],
      [8.8, 2.8],
      [3.1, 3.5],
      [3.9, -2.4],
      [5.4, -3.5],
      [22.3, 12.2],
      [22.4, 12.2],
      [0, -2.7],
      [6.3, 0],
      [-0.5, -12.7],
      [-1, -23.4],
      [-1.3, -22.7],
      [-2, -5.1],
      [1.2, -3.9],
      [-1.1, -2.7],
      [1.8, -3],
      'z',
    ],
  },
  {
    name: 'Sri Lanka',
    vertexPoint: [
      ['m', 1432.2, 532.7],
      [2.3, -1.8],
      [0.6, -6.6],
      [-3, -6.6],
      [-2.9, -4.5],
      [-4.1, -3.5],
      [-1.9, 10.3],
      [1.4, 9.1],
      [2.8, 5.1],
      [4.8, -1.5],
      'z',
    ],
  },
  {
    name: 'Lesotho',
    vertexPoint: [
      ['m', 1128.1, 766.5],
      [1.1, -2],
      [3.1, -1],
      [1.1, -2.1],
      [1.9, -3.1],
      [-1.7, -1.9],
      [-2.3, -2],
      [-2.6, 1.3],
      [-3.1, 2.5],
      [-3.2, 4],
      [3.7, 4.9],
      [2, -0.6],
      'z',
    ],
  },
  {
    name: 'Lithuania',
    vertexPoint: [
      ['m', 1100.4, 221.2],
      [-5, -2.9],
      [-2.5, -0.4],
      [-0.9, -1.3],
      [-4.4, 0.6],
      [-7.9, -0.4],
      [-5, 1.9],
      [1.7, 5],
      [5, 1.1],
      [2.2, 0.9],
      [-0.2, 1.7],
      [0.6, 1.5],
      [2.5, 0.6],
      [1.4, 1.9],
      [4.6, 0],
      [4.8, -2.2],
      [0.5, -3.4],
      [3.5, -2],
      [-0.9, -2.6],
      'z',
    ],
  },
  {
    name: 'Luxembourg',
    vertexPoint: [
      ['m', 1007, 258.6],
      [0.2, -2.7],
      [-1, -1.4],
      [-1.3, 0.2],
      [-0.4, 3.5],
      [1.1, 0.5],
      [1.4, -0.1],
      'z',
    ],
  },
  {
    name: 'Latvia',
    vertexPoint: [
      ['m', 1102.1, 210.1],
      [-3.8, 0],
      [-4.4, -2.2],
      [-2.1, -0.7],
      [-3.7, 1],
      [-0.2, 4.6],
      [-3.6, 0.1],
      [-4.4, -4.5],
      [-4, 2.1],
      [-1.7, 3.7],
      [0.5, 4.5],
      [5, -1.9],
      [7.9, 0.4],
      [4.4, -0.6],
      [0.9, 1.3],
      [2.5, 0.4],
      [5, 2.9],
      [2.6, -1],
      [4.6, -2.3],
      [-2.1, -3.6],
      [-1, -2.8],
      [-2.4, -1.4],
      'z',
    ],
  },
  {
    name: 'Morocco',
    vertexPoint: [
      ['m', 965.2, 348.4],
      [-2.3, -0.1],
      [-5.5, -1.4],
      [-5, 0.4],
      [-3.1, -2.7],
      [-3.9, 0],
      [-1.8, 3.9],
      [-3.7, 6.7],
      [-4, 2.6],
      [-5.4, 2.9],
      [-3.5, 4.3],
      [-0.9, 3.4],
      [-2.1, 5.4],
      [1.1, 7.9],
      [-4.7, 5.3],
      [-2.7, 1.7],
      [-4.4, 4.4],
      [-5.1, 0.7],
      [-2.8, 2.4],
      [-0.1, 0.1],
      [-3.6, 6.5],
      [-3.7, 2.3],
      [-2.1, 4],
      [-0.2, 3.3],
      [-1.6, 3.8],
      [-1.9, 1],
      [-3.1, 4],
      [-2, 4.5],
      [0.3, 2.2],
      [-1.9, 3.3],
      [-2.2, 1.7],
      [-0.3, 3],
      [0.1, 0],
      [12.4, -0.5],
      [0.7, -2.3],
      [2.3, -2.9],
      [2, -8.8],
      [7.8, -6.8],
      [2.8, -8.1],
      [1.7, -0.4],
      [1.9, -5],
      [4.6, -0.7],
      [1.9, 0.9],
      [2.5, 0],
      [1.8, -1.5],
      [3.4, -0.2],
      [-0.1, -3.4],
      [0, 0],
      [0.8, 0],
      [0.1, -7.5],
      [8.9, -4.7],
      [5.4, -1],
      [4.4, -1.7],
      [2.1, -3.2],
      [6.3, -2.5],
      [0.3, -4.7],
      [3.1, -0.5],
      [2.5, -2.4],
      [7, -1],
      [1, -2.5],
      [-1.4, -1.4],
      [-1.8, -6.7],
      [-0.3, -3.9],
      [-2, -4.1],
      'z',
    ],
  },
  {
    name: 'Moldova',
    vertexPoint: [
      ['m', 1118.5, 283.3],
      [1.2, -0.7],
      [0.5, -2.1],
      [1.1, -2],
      [-0.5, -1.1],
      [1, -0.5],
      [0.6, 0.9],
      [3, 0.2],
      [1.2, -0.5],
      [-1, -0.6],
      [0.2, -1],
      [-2, -1.5],
      [-1.1, -2.6],
      [-1.9, -1.1],
      [0, -2.1],
      [-2.5, -1.6],
      [-2, -0.3],
      [-3.9, -1.9],
      [-3.2, 0.6],
      [-1.1, 0.9],
      [1.6, 0.6],
      [1.8, 1.9],
      [1.9, 2.6],
      [3.4, 3.7],
      [0.6, 2.7],
      [-0.2, 2.7],
      [1.3, 2.8],
      'z',
    ],
  },
  {
    name: 'Madagascar',
    vertexPoint: [
      ['m', 1255.7, 658.4],
      [-1.1, -4.2],
      [-1.4, -2.7],
      [-1.8, -2.7],
      [-2, 2.8],
      [-0.3, 3.8],
      [-3.3, 4.5],
      [-2.3, -0.8],
      [0.6, 2.7],
      [-1.8, 3.2],
      [-4.8, 3.9],
      [-3.4, 3.7],
      [-2.4, 0],
      [-2.2, 1.2],
      [-3.1, 1.3],
      [-2.8, 0.2],
      [-1, 4.1],
      [-2.2, 3.5],
      [0.1, 5.9],
      [0.8, 4],
      [1.1, 3],
      [-0.8, 4.1],
      [-2.9, 4.8],
      [-0.2, 2.1],
      [-2.6, 1.1],
      [-1.3, 4.6],
      [0.2, 4.6],
      [1.6, 5],
      [-0.1, 5.7],
      [1.2, 3.3],
      [4.2, 2.3],
      [3, 1.7],
      [5, -2.7],
      [4.6, -1.5],
      [3.1, -7.4],
      [2.8, -8.9],
      [4.3, -12],
      [3.3, -8.8],
      [2.7, -7.4],
      [0.8, -5.4],
      [1.6, -1.5],
      [0.7, -2.7],
      [-0.8, -4.7],
      [1.2, -1.9],
      [1.6, 3.8],
      [1.1, -1.9],
      [0.8, -3.1],
      [-1.3, -2.9],
      [-0.5, -7.7],
      'z',
    ],
  },
  {
    name: 'Mexico',
    vertexPoint: [
      ['m', 444.4, 407.8],
      [-3.6, -1.4],
      [-3.9, -2],
      [-0.8, -3],
      [-0.2, -4.5],
      [-2.4, -3.6],
      [-1, -3.7],
      [-1.6, -4.4],
      [-3.1, -2.5],
      [-4.4, 0.1],
      [-4.8, 5],
      [-4, -1.9],
      [-2.2, -1.9],
      [-0.4, -3.5],
      [-0.8, -3.3],
      [-2.4, -2.8],
      [-2.1, -2],
      [-1.3, -2.2],
      [-9.3, 0],
      [-0.8, 2.6],
      [-4.3, 0],
      [-10.7, 0],
      [-10.7, -4.4],
      [-7.1, -3.1],
      [1, -1.3],
      [-7, 0.7],
      [-6.3, 0.5],
      [0.2, 5.7],
      [0.7, 5.1],
      [0.7, 4.1],
      [0.8, 4],
      [2.6, 1.8],
      [2.9, 4.5],
      [-1, 2.9],
      [-2.7, 2.3],
      [-2.1, -0.3],
      [-0.6, 0.5],
      [2.3, 3.7],
      [2.9, 1.5],
      [1, 1.7],
      [0.9, -0.9],
      [3.1, 2.9],
      [2.1, 2],
      [0.1, 3.4],
      [-1.2, 4.7],
      [2.5, 1.6],
      [3.3, 3.1],
      [2.9, 3.6],
      [0.7, 3.9],
      [1, 0],
      [2.7, -2.3],
      [0.4, -1.2],
      [-1.5, -2.8],
      [-1.6, -2.9],
      [-2.6, -0.2],
      [0.4, -3.4],
      [-0.9, -3],
      [-1, -2.8],
      [-0.5, -5.9],
      [-2.6, -3.2],
      [-0.6, -2.3],
      [-1.2, -1.6],
      [0, -4.1],
      [-1, 0.1],
      [-0.1, -2.2],
      [-0.7, -0.5],
      [-0.4, -1.4],
      [-2.7, -4.4],
      [-1.1, -2.6],
      [1, -4.8],
      [0.1, -3],
      [1.8, -2.6],
      [2.4, 1.7],
      [1.9, -0.2],
      [3.1, 2.5],
      [-0.9, 2.4],
      [0.4, 4.9],
      [1.5, 4.7],
      [-0.4, 2],
      [1.7, 3.1],
      [2.3, 3.4],
      [2.7, 0.5],
      [0.3, 4.4],
      [2.4, 3.1],
      [2.5, 1.5],
      [-1.8, 4],
      [0.7, 1.5],
      [4.1, 2.6],
      [1.9, 4],
      [4.5, 4.9],
      [3.8, 6.4],
      [1.3, 3.2],
      [0, 2.5],
      [1.4, 2.9],
      [-0.3, 2.2],
      [-1.6, 1.6],
      [0.3, 1.8],
      [-1.9, 0.7],
      [0.8, 3.1],
      [2.2, 4],
      [5.3, 3.6],
      [1.9, 2.9],
      [5.4, 2],
      [3, 0.4],
      [1.2, 1.7],
      [4.2, 3],
      [5.9, 3],
      [4, 0.9],
      [4.8, 2.9],
      [4, 1.2],
      [3.7, 1.7],
      [2.9, -0.7],
      [4.8, -2.4],
      [3.1, -0.4],
      [4.4, 1.6],
      [2.6, 2.1],
      [5.5, 6.9],
      [0.4, -1.9],
      [0.8, -1.5],
      [-0.7, -1.2],
      [3.3, -5.2],
      [7.1, 0],
      [0.4, -2.1],
      [-0.8, -0.4],
      [-0.5, -1.4],
      [-1.9, -1.5],
      [-1.8, -2.1],
      [2.6, 0],
      [0.4, -3.6],
      [5.2, 0],
      [5.1, 0.1],
      [0.1, -1],
      [0.7, -0.3],
      [0.9, 0.8],
      [2.5, -3.9],
      [1, 0],
      [1.2, -0.1],
      [1.2, 1.6],
      [2, -5],
      [1.2, -2.7],
      [-0.9, -1.1],
      [1.8, -3.9],
      [3.5, -3.8],
      [0.6, -3.1],
      [-1.2, -1.3],
      [-3.4, 0.5],
      [-4.8, -0.2],
      [-6, 1.5],
      [-4, 1.7],
      [-1.2, 1.8],
      [-1.2, 5.4],
      [-1.8, 3.7],
      [-3.9, 2.6],
      [-3.6, 1.1],
      [-4.3, 1.1],
      [-4.3, 0.6],
      [-5.1, 1.8],
      [-1.9, -2.6],
      [-5.6, -1.7],
      [-1.8, -3.2],
      [-0.7, -3.6],
      [-3, -4.7],
      [-0.4, -5],
      [-1.2, -3.1],
      [-0.5, -3.4],
      [1.1, -3.1],
      [1.8, -8.6],
      [1.8, -4.5],
      [3.1, -5.6],
      [-2.1, 0.2],
      'z',
    ],
  },
  {
    name: 'Macedonia',
    vertexPoint: [
      ['m', 1094, 304.8],
      [-2.8, -2],
      [-2.4, 0.1],
      [-1.7, 0.4],
      [-1.1, 0.2],
      [-2.9, 1],
      [-0.1, 1.2],
      [-0.7, 0],
      [0, 0],
      [-0.4, 2.1],
      [0.9, 2.6],
      [2.3, 1.6],
      [3.3, -0.6],
      [1.9, -1.3],
      [2.8, 0.1],
      [0.7, -1.1],
      [1, -0.2],
      [-0.8, -4.1],
      'z',
    ],
  },
  {
    name: 'Mali',
    vertexPoint: [
      ['m', 1000.3, 450.3],
      [-6.1, 0.6],
      [-0.1, -4],
      [-2.6, -1.1],
      [-3.4, -1.8],
      [-1.3, -3],
      [-18.6, -13.8],
      [-18.4, -13.9],
      [-8.4, 0.1],
      [2.4, 27.4],
      [2.4, 27.5],
      [1, 0.8],
      [-1.3, 4.4],
      [-22.3, 0.1],
      [-0.9, 1.4],
      [-2.1, -0.4],
      [-3.2, 1.3],
      [-3.8, -1.8],
      [-1.8, 0.2],
      [-1, 3.7],
      [-1.9, 1.2],
      [0.2, 3.9],
      [1.1, 3.7],
      [2.1, 1.8],
      [0.4, 2.4],
      [-0.3, 2],
      [0.3, 2.3],
      [0.9, 0],
      [1.5, -0.8],
      [0.9, 0.2],
      [1.5, 1.6],
      [2.4, 0.5],
      [1.6, -1.4],
      [1.8, -0.8],
      [1.3, -0.9],
      [1.1, 0.2],
      [1.3, 1.4],
      [0.6, 1.7],
      [2.3, 2.7],
      [-1.2, 1.6],
      [-0.2, 2.1],
      [1.2, -0.6],
      [0.7, 0.7],
      [-0.3, 1.9],
      [1.7, 1.8],
      [0.7, -0.6],
      [1.6, 1],
      [4.3, 0.1],
      [1, -1.9],
      [1, 0.1],
      [1.6, -0.7],
      [0.9, 2.7],
      [1.3, -0.8],
      [2.3, -0.9],
      [-0.4, -3.7],
      [1.6, -2.7],
      [-0.2, -2.2],
      [4.5, -5.2],
      [0.8, -4.4],
      [1.6, -1.6],
      [2.7, 0.9],
      [2.3, -1.3],
      [0.8, -1.6],
      [4.3, -2.9],
      [1.1, -2],
      [5.2, -2.6],
      [3, -0.9],
      [1.4, 1.2],
      [3.6, 0],
      [3.6, -0.3],
      [2, -2.2],
      [7.6, -0.6],
      [4.9, -1],
      [0.5, -3.9],
      [3, -4.3],
      [-0.1, -14.6],
      'z',
    ],
  },
  {
    name: 'Myanmar',
    vertexPoint: [
      ['m', 1533.9, 435.8],
      [-0.6, -2.6],
      [-3.8, 1.8],
      [-2.5, -1.2],
      [-4.5, -2.4],
      [0.8, -5.2],
      [-3.7, -1.3],
      [-2.3, -5.8],
      [-5.6, 1],
      [-0.7, -7.5],
      [4.1, -5.3],
      [-0.8, -5.3],
      [-1.3, -4.9],
      [-2.7, -1.5],
      [-2.7, -3.7],
      [-3, 0.4],
      [0.9, 2.4],
      [-1.6, 1.2],
      [1.3, 3.9],
      [-4.1, -1.1],
      [-6.1, 4.4],
      [0.9, 3.6],
      [-2.1, 5.4],
      [0.3, 3.1],
      [-1.5, 5.2],
      [-4.6, -1.4],
      [0.9, 6.6],
      [-0.9, 2.1],
      [0.9, 2.7],
      [-2.4, 1.5],
      [0.5, 4.6],
      [-2.1, -1],
      [1.1, 5.1],
      [4.6, 5.2],
      [3.4, 0.9],
      [-0.4, 2.2],
      [5.4, 7.4],
      [1.9, 5.9],
      [-0.9, 7.9],
      [3.6, 1.5],
      [3.2, 0.6],
      [5.8, -4.6],
      [3.2, -3.1],
      [3.1, 5.2],
      [2, 8.1],
      [2.6, 7.6],
      [2.6, 3.3],
      [0.2, 6.9],
      [2.2, 3.8],
      [-1.3, 4.8],
      [0.9, 4.8],
      [2.2, -6.6],
      [2.6, -5.9],
      [-2.8, -5.8],
      [-0.2, -3],
      [-1, -3.5],
      [-4.2, -5.1],
      [-1.7, -3.2],
      [1.7, -1.1],
      [1.4, -5.6],
      [-2.9, -4.2],
      [-4.1, -4.6],
      [-3.5, -5.6],
      [2.2, -1.1],
      [1.5, -6.9],
      [3.9, -0.3],
      [2.8, -2.8],
      [3, -1.4],
      [0.8, -2.4],
      [4.1, -4.1],
      'z',
    ],
  },
  {
    name: 'Montenegro',
    vertexPoint: [
      ['m', 1080, 299.8],
      [0.4, -0.6],
      [-2, -1.2],
      [-1.8, -0.7],
      [-0.8, -0.8],
      [-1.5, -1.1],
      [-0.9, 0.6],
      [-1.5, 1.4],
      [-0.4, 3.4],
      [-0.5, 1],
      [0, 0],
      [2.3, 1.2],
      [1.6, 2.1],
      [1.1, 0.4],
      [0, 0],
      [-0.5, -1.9],
      [2, -3.1],
      [0.4, 1.2],
      [1.3, -0.5],
      [0.8, -1.4],
      'z',
    ],
  },
  {
    name: 'Mongolia',
    vertexPoint: [
      ['m', 1473.7, 252.1],
      [-3.7, -4.6],
      [-6.6, -1.5],
      [-4.8, -0.8],
      [-6.9, -2.5],
      [-1.3, 6.4],
      [4, 3.6],
      [-2.4, 4.3],
      [-7.9, -1.6],
      [-5, -0.2],
      [-4.7, -2.9],
      [-5.1, -0.1],
      [-5.3, -1.9],
      [-5.9, 2.9],
      [-6.6, 5.4],
      [-4.7, 1],
      [3.3, 4.4],
      [5.7, 3.3],
      [8.1, 2.3],
      [5.8, 5],
      [1.3, 7.3],
      [3, 2.7],
      [6.4, 1],
      [7.2, 0.9],
      [7.9, 3.8],
      [3.4, 0.7],
      [4.9, 5.7],
      [4.7, 3.6],
      [5.5, -0.1],
      [11.2, 1.3],
      [6.4, -0.8],
      [5.5, 0.9],
      [9.3, 3.8],
      [6.2, -0.1],
      [3.2, 2],
      [4.4, -3.3],
      [7.2, -2.2],
      [7.5, -0.2],
      [4.9, -2.2],
      [1.9, -3.3],
      [2.5, -2],
      [-1.9, -2.1],
      [-2.9, -2.3],
      [0.4, -4],
      [3.2, 0.5],
      [5.9, 1.3],
      [3.1, -3.3],
      [6.3, -2.4],
      [1.4, -4.1],
      [2.4, -1.8],
      [6.8, -0.8],
      [4.3, 0.7],
      [-0.7, -2.2],
      [-7.2, -4.3],
      [-5.1, -2],
      [-2.5, 2.3],
      [-5.4, -1],
      [-2.4, 0.8],
      [-2.7, -2.6],
      [-0.3, -6.2],
      [-0.6, -4.6],
      [-5.5, 0.5],
      [-3.9, -2.1],
      [-3.3, -0.7],
      [-4.5, 4.4],
      [-5.8, 1],
      [-3.6, 1.6],
      [-6.7, -1],
      [-4.5, 0],
      [-4.9, -3.1],
      [-6.5, -3],
      [-5.4, -0.8],
      [-5.7, 0.8],
      [-3.9, 1.1],
      [-8.4, -2.6],
      'z',
    ],
  },
  {
    name: 'Mozambique',
    vertexPoint: [
      ['m', 1203, 640.7],
      [-0.8, -2.9],
      [0, 0],
      [0, 0],
      [-4.6, 3.7],
      [-6.2, 2.5],
      [-3.3, -0.1],
      [-2.1, 1.9],
      [-3.9, 0.1],
      [-1.4, 0.8],
      [-6.7, -1.8],
      [-2.1, 0.3],
      [-1.6, 6],
      [0.7, 7.3],
      [0.3, 0],
      [1.9, 2],
      [2.2, 4.6],
      [0.1, 8.2],
      [-2.5, 1.3],
      [-1.9, 4.5],
      [-3.4, -4],
      [-0.2, -4.5],
      [1.3, -2.9],
      [-0.3, -2.6],
      [-2.1, -1.6],
      [-1.6, 0.6],
      [-3, -3],
      [-17.1, 5.2],
      [0.3, 4.5],
      [0.3, 2.4],
      [4.6, -0.1],
      [2.6, 1.3],
      [1.1, 1.6],
      [2.6, 0.5],
      [2.8, 2],
      [-0.3, 8.1],
      [-1.3, 4.4],
      [-0.5, 4.7],
      [0.8, 1.9],
      [-0.8, 3.7],
      [-0.9, 0.6],
      [-1.6, 4.6],
      [-6.2, 7.2],
      [2.2, 9],
      [1.1, 4.5],
      [-1.4, 7.1],
      [0.4, 2.3],
      [0.6, 2.9],
      [0.3, 2.8],
      [4.1, 0],
      [0.7, -3.3],
      [-1.4, -0.5],
      [-0.3, -2.6],
      [2.6, -2.4],
      [6.8, -3.4],
      [4.6, -2.2],
      [2.5, -2.3],
      [0.9, -2.6],
      [-1.2, -1.1],
      [1.1, -3],
      [0.5, -6.2],
      [-1, 0.3],
      [0, -1.9],
      [-0.8, -3.7],
      [-2.4, -4.8],
      [0.7, -4.6],
      [2.3, -1.4],
      [4.1, -4.6],
      [2.2, -1.1],
      [6.7, -6.8],
      [6.4, -3.1],
      [5.2, -2.5],
      [3.7, -3.9],
      [2.4, -4.4],
      [1.9, -4.6],
      [-0.9, -3.1],
      [0.2, -9.9],
      [-0.4, -5.6],
      [0.4, -6.3],
      'z',
    ],
  },
  {
    name: 'Mauritania',
    vertexPoint: [
      ['m', 949.8, 413.3],
      [-20.3, -15.5],
      [-0.2, 9.7],
      [-17.9, -0.3],
      [-0.2, 16.3],
      [-5.2, 0.5],
      [-1.4, 3.3],
      [0.9, 9.2],
      [-21.6, -0.1],
      [-1.2, 2.2],
      [2.8, 2.7],
      [1.4, 3],
      [-0.7, 3.2],
      [0.6, 3.2],
      [0.5, 6.3],
      [-0.8, 5.9],
      [-1.7, 3.2],
      [0.4, 3.4],
      [2, -2],
      [2.7, 0.5],
      [2.8, -1.4],
      [3.1, 0],
      [2.6, 1.8],
      [3.7, 1.7],
      [3.2, 4.7],
      [3.6, 4.4],
      [1.9, -1.2],
      [1, -3.7],
      [1.8, -0.2],
      [3.8, 1.8],
      [3.2, -1.3],
      [2.1, 0.4],
      [0.9, -1.4],
      [22.3, -0.1],
      [1.3, -4.4],
      [-1, -0.8],
      [-2.4, -27.5],
      [-2.4, -27.4],
      [8.4, -0.1],
      'z',
    ],
  },
  {
    name: 'Malawi',
    vertexPoint: [
      ['m', 1169.2, 661.5],
      [0.1, -2.3],
      [-1.2, -1.9],
      [0.1, -2.8],
      [-1.5, -4.7],
      [1.7, -3.5],
      [-0.1, -7.7],
      [-1.9, -4.1],
      [0.2, -0.7],
      [0, 0],
      [-1.1, -1.7],
      [-5.4, -1.2],
      [2.6, 2.8],
      [1.2, 5.4],
      [-1, 1.8],
      [-1.2, 5.1],
      [0.9, 5.3],
      [-1.8, 2.2],
      [-1.9, 5.9],
      [2.9, 1.7],
      [3, 3],
      [1.6, -0.6],
      [2.1, 1.6],
      [0.3, 2.6],
      [-1.3, 2.9],
      [0.2, 4.5],
      [3.4, 4],
      [1.9, -4.5],
      [2.5, -1.3],
      [-0.1, -8.2],
      [-2.2, -4.6],
      [-1.9, -2],
      [-0.3, 0],
      [0, 0.8],
      [1.1, 0.3],
      [1, 3.4],
      [-0.2, 0.8],
      [-1.9, -2.5],
      [-1, 1.6],
      [-0.8, -1.4],
      'z',
    ],
  },
  {
    name: 'Malaysia',
    vertexPoint: [
      ['m', 1543.6, 532.7],
      [-4.7, -2.8],
      [-0.9, 1.1],
      [1.4, 2.7],
      [-0.4, 4.7],
      [2.1, 3.4],
      [1, 5.3],
      [3.4, 4.3],
      [0.8, 3.2],
      [6.7, 5],
      [5.4, 4.8],
      [4, -0.5],
      [0.1, -2.1],
      [-2.3, -5.6],
      [-2.1, -1.8],
      [-0.5, -3.8],
      [-0.6, -2.1],
      [0.5, -2.9],
      [-0.5, -4.3],
      [-2.6, -4.3],
      [-3.5, -3.8],
      [-1.3, -0.6],
      [-1.7, 2.6],
      [-3.7, 0.8],
      [-0.6, -3.3],
      'z',
      ['m', 99, 11],
      [-1.2, -3.1],
      [3.8, -0.4],
      [0.3, -2.4],
      [-4.8, -2],
      [-3.8, -1.7],
      [-0.4, -2.8],
      [-3.1, -3.2],
      [-2.3, 0],
      [-2.5, 5],
      [-4.1, 4.4],
      [-0.1, 3.1],
      [-0.1, 4.1],
      [-2.7, -0.2],
      [-1.1, 2.2],
      [-2.7, -3.3],
      [-2.6, 4],
      [-3.8, 5],
      [-6.7, 1.4],
      [-2.4, 1.2],
      [-0.9, 5.4],
      [-4.4, 1.2],
      [-4.1, -2.2],
      [1, 4.3],
      [3.9, 3.5],
      [3.6, -1.2],
      [3.6, 0.4],
      [3.2, -3.2],
      [2.7, -0.6],
      [5.4, 1.8],
      [4.5, -1.3],
      [2.7, -8.9],
      [2, -2.2],
      [1.7, -7.2],
      [6.4, 0],
      [5, 1],
      [4, -2.1],
      'z',
    ],
  },
  {
    name: 'Namibia',
    vertexPoint: [
      ['m', 1105.4, 683.7],
      [-10.3, 2.5],
      [-13.4, -0.9],
      [-3.7, -3],
      [-22.5, 0.3],
      [-0.9, 0.4],
      [-3.2, -2.9],
      [-3.6, -0.1],
      [-3.3, 1],
      [-2.7, 1.2],
      [0.2, 4.9],
      [4.4, 6.2],
      [1.1, 4],
      [2.8, 7.7],
      [2.7, 5.2],
      [2.1, 2.6],
      [0.6, 3.5],
      [0, 7.6],
      [1.6, 9.8],
      [1.2, 4.6],
      [1, 6.2],
      [1.9, 4.7],
      [3.9, 4.8],
      [2.7, -3.2],
      [2.1, 1.8],
      [0.8, 2.7],
      [2.4, 0.5],
      [3.3, 1.2],
      [2.9, -0.5],
      [5, -3.2],
      [1.1, -23.6],
      [0.6, -18.5],
      [5.4, -0.2],
      [0.9, -22.7],
      [4.1, -0.2],
      [8.6, -2.2],
      [2, 2.6],
      [3.7, -2.5],
      [1.6, 0],
      [3.2, -1.5],
      [0, -0.5],
      [-2.1, -1.4],
      [-3.6, -0.4],
      [-4.6, 1.5],
      'z',
    ],
  },
  {
    name: 'Niger',
    vertexPoint: [
      ['m', 1051.3, 425.6],
      [-8.8, -2.8],
      [-18.6, 12.2],
      [-15.8, 12.5],
      [-7.8, 2.8],
      [0.1, 14.6],
      [-3, 4.3],
      [-0.5, 3.9],
      [-4.9, 1],
      [-7.6, 0.6],
      [-2, 2.2],
      [-3.6, 0.3],
      [-0.5, 3.1],
      [0.8, 2.9],
      [3.1, 4.1],
      [0.2, 3.1],
      [6.4, 1.4],
      [-0.1, 4.4],
      [1.9, -1.9],
      [2, 0],
      [4.3, 3.7],
      [0.3, -5.7],
      [1.6, -2.6],
      [0.8, -3.6],
      [1.4, -1.4],
      [6, -0.8],
      [5.6, 2.4],
      [2.1, 2.4],
      [2.9, 0.1],
      [2.6, -1.5],
      [6.8, 3.3],
      [2.8, -0.2],
      [3.3, -2.7],
      [3.3, 0.2],
      [1.6, -0.9],
      [3, 0.4],
      [4.3, 1.8],
      [4.3, -3.5],
      [1.3, 0.2],
      [3.9, 7],
      [1, -0.2],
      [0.2, -2],
      [1.6, -0.4],
      [0.5, -2.9],
      [-3.6, -0.2],
      [0, -4.1],
      [-2.4, -2.3],
      [2.3, -8.4],
      [6.9, -6],
      [0.2, -8.3],
      [1.8, -12.9],
      [1.1, -2.7],
      [-2.3, -2.2],
      [-0.2, -2.1],
      [-2, -1.6],
      [-1.6, -9.9],
      [-3.9, 2.4],
      [-3.1, -3.5],
      'z',
    ],
  },
  {
    name: 'Nigeria',
    vertexPoint: [
      ['m', 1055.8, 492.7],
      [-1, 0.2],
      [-3.9, -7],
      [-1.3, -0.2],
      [-4.3, 3.5],
      [-4.3, -1.8],
      [-3, -0.4],
      [-1.6, 0.9],
      [-3.3, -0.2],
      [-3.3, 2.7],
      [-2.8, 0.2],
      [-6.8, -3.3],
      [-2.6, 1.5],
      [-2.9, -0.1],
      [-2.1, -2.4],
      [-5.6, -2.4],
      [-6, 0.8],
      [-1.4, 1.4],
      [-0.8, 3.6],
      [-1.6, 2.6],
      [-0.3, 5.7],
      [-0.2, 2.1],
      [1.2, 3.8],
      [-1.1, 2.5],
      [0.6, 1.7],
      [-2.7, 4],
      [-1.7, 1.9],
      [-1, 4],
      [0.1, 4.1],
      [-0.3, 10.2],
      [4.9, 0],
      [4.3, 0],
      [3.9, 4.2],
      [1.9, 4.6],
      [3, 3.9],
      [4.5, 0.2],
      [2.2, -1.4],
      [2.1, 0.3],
      [5.8, -2.3],
      [1.4, -4.5],
      [2.7, -6.1],
      [1.6, -0.1],
      [3.3, -3.7],
      [2.1, -0.1],
      [3.2, 2.6],
      [3.9, -2.2],
      [0.5, -2.6],
      [1.2, -2.6],
      [0.8, -3.2],
      [3, -2.6],
      [1.1, -4.5],
      [1.2, -1.4],
      [0.7, -3.3],
      [1.5, -4],
      [4.6, -5],
      [0.3, -2.1],
      [0.6, -1.1],
      [-2.3, -2.6],
      'z',
    ],
  },
  {
    name: 'Nicaragua',
    vertexPoint: [
      ['m', 514.1, 476.8],
      [-1.9, -0.2],
      [-0.9, 0.9],
      [-2, 0.8],
      [-1.4, 0],
      [-1.3, 0.8],
      [-1.1, -0.3],
      [-0.9, -0.9],
      [-0.6, 0.2],
      [-0.8, 1.5],
      [-0.5, -0.1],
      [-0.3, 1.3],
      [-2.1, 1.8],
      [-1.1, 0.7],
      [-0.6, 0.8],
      [-1.5, -1.3],
      [-1.4, 1.7],
      [-1.2, 0],
      [-1.3, 0.2],
      [-0.2, 3.1],
      [-0.8, 0],
      [-0.8, 1.5],
      [-1.8, 0.3],
      [-0.4, 0.4],
      [-0.9, -1],
      [-0.7, 1],
      [2.6, 2.9],
      [2.2, 2],
      [1, 2.1],
      [2.5, 2.6],
      [1.8, 2],
      [0.9, -0.8],
      [3.5, 1.7],
      [1.4, -0.8],
      [1.7, 0.5],
      [0.8, 1.3],
      [1.7, 0.4],
      [1.4, -1.3],
      [-0.8, -1.1],
      [-0.1, -1.7],
      [1.2, -1.6],
      [-0.2, -1.7],
      [0.7, -2.7],
      [0.9, -0.7],
      [0.1, -2.8],
      [-0.2, -1.7],
      [0.4, -2.8],
      [0.9, -2.5],
      [1.4, -2.2],
      [-0.3, -2.3],
      [0.4, -1.4],
      [0.6, -0.6],
      'z',
    ],
  },
  {
    name: 'Netherlands',
    vertexPoint: [
      ['m', 1005.5, 243.9],
      [2.9, 0],
      [1.1, -2.3],
      [1, -5.6],
      [-1, -2],
      [-3.9, -0.2],
      [-6.5, 2.6],
      [-3.9, 8.9],
      [-2.5, 1.7],
      [0, 0],
      [3.6, 0.5],
      [4.4, -1.3],
      [3.1, 2.7],
      [2.8, 1.4],
      [-1.1, -6.4],
      'z',
    ],
  },
  {
    name: 'Norway',
    vertexPoint: [
      ['m', 1088.8, 133.1],
      [-6.9, 1.1],
      [-7.3, -0.3],
      [-5.1, 4.4],
      [-6.7, -0.3],
      [-8.5, 2.3],
      [-10.1, 6.8],
      [-6.4, 4],
      [-8.8, 10.7],
      [-7.1, 7.8],
      [-8.1, 5.8],
      [-11.2, 4.8],
      [-3.9, 3.6],
      [1.9, 13.4],
      [1.9, 6.3],
      [6.4, 3],
      [6, -1.4],
      [8.5, -6.8],
      [3.3, 3.6],
      [1.7, -3.3],
      [3.4, -4],
      [0.9, -6.9],
      [-3.1, -2.9],
      [-1, -7.6],
      [2.3, -5.3],
      [4.3, 0.1],
      [1.3, -2.2],
      [-1.8, -1.9],
      [5.7, -7.9],
      [3.4, -6.1],
      [2.2, -3.9],
      [4, 0.1],
      [0.6, -3.1],
      [7.9, 0.9],
      [0, -3.5],
      [2.5, -0.3],
      [2.1, -1.4],
      [5.1, 2.9],
      [5.3, -0.3],
      [4.7, 1.3],
      [3.4, -2.4],
      [1.1, -3.9],
      [5.8, -1.8],
      [5.7, 2.1],
      [-0.8, 3.8],
      [3.2, -0.5],
      [6.4, -2.2],
      [0, 0],
      [-5.4, -3.3],
      [4.8, -1.4],
      [-13.6, -3.9],
      'z',
      ['m', -22.6, -33.3],
      [-5.6, -1],
      [-1.9, -1.7],
      [-7.2, 0.9],
      [2.6, 1.5],
      [-2.2, 1.2],
      [6.7, 1.1],
      [7.6, -2],
      'z',
      ['m', -25.4, -8.3],
      [-4.8, -1.6],
      [-5.1, 0.2],
      [-1, 1.5],
      [-5, 0],
      [-2.2, -1.5],
      [-9.3, 1.6],
      [3.2, 3.5],
      [7.6, 3.8],
      [5.7, 1.4],
      [-3, 1.7],
      [8.4, 2.9],
      [4.4, -0.2],
      [0.9, -3.9],
      [3, -0.9],
      [1.2, -3.4],
      [8.5, -1.8],
      [-12.5, -3.3],
      'z',
      ['m', 24.2, -3.1],
      [-9.1, -1],
      [-3.2, 1.2],
      [-5.3, -1],
      [-10.4, 1.2],
      [4.3, 2],
      [5.1, 0],
      [0.9, 1.3],
      [10.6, 0.7],
      [10.1, -0.5],
      [4.3, -2.4],
      [-7.3, -1.5],
      'z',
    ],
  },
  {
    name: 'Nepal',
    vertexPoint: [
      ['m', 1455.2, 394.8],
      [-6.5, -0.6],
      [-6.4, -1.5],
      [-5, -2.8],
      [-4.5, -1.2],
      [-2.5, -3.1],
      [-3.2, -0.9],
      [-6.4, -4.1],
      [-4.7, -2],
      [-1.9, 1.5],
      [-2.8, 2.9],
      [-0.9, 5.9],
      [5.7, 2.5],
      [5.8, 3.1],
      [7.7, 3.5],
      [7.6, 0.9],
      [3.8, 3.2],
      [4.3, 0.6],
      [6.8, 1.5],
      [4.6, -0.1],
      [0.1, -2.5],
      [-1.5, -4.1],
      [-0.1, -2.7],
      'z',
    ],
  },
  {
    name: 'New Zealand',
    vertexPoint: [
      ['m', 1868.6, 832.8],
      [0.9, -2.6],
      [-5.8, 2.9],
      [-3.4, 3.4],
      [-3.2, 1.6],
      [-5.9, 4.6],
      [-5.6, 3.2],
      [-7, 3.2],
      [-5.5, 2.4],
      [-4.3, 1.1],
      [-11.3, 6.1],
      [-6.4, 4.6],
      [-1.1, 2.3],
      [5.1, 0.4],
      [1.5, 2.1],
      [4.5, 0.1],
      [4, -1.8],
      [6.3, -2.8],
      [8.1, -6.2],
      [4.7, -4.1],
      [6.2, -2.3],
      [4, -0.1],
      [0.6, -2.9],
      [4.6, -2.5],
      [7, -4.5],
      [4.2, -2.9],
      [2.1, -2.6],
      [0.5, -2.6],
      [-5.6, 2.5],
      [0.8, -2.6],
      'z',
      ['m', 28.8, -30.5],
      [1.9, -5.7],
      [-3.1, -1.7],
      [-0.8, -3.6],
      [-2.3, 0.5],
      [-0.4, 4.6],
      [0.8, 5.7],
      [0.9, 2.7],
      [-0.9, 1.1],
      [-0.6, 4.4],
      [-2.4, 4.1],
      [-4.2, 5],
      [-5.3, 2.2],
      [-1.7, 2.4],
      [3.7, 2.5],
      [-0.8, 3.5],
      [-6.9, 5.1],
      [1.4, 0.9],
      [-0.4, 1.6],
      [5.9, -2.5],
      [5.9, -4.2],
      [4.5, -3.4],
      [1.6, -1.2],
      [1.5, -2.7],
      [2.8, -2],
      [3.8, 0.2],
      [4.2, -3.8],
      [5.1, -5.7],
      [-2.1, -0.8],
      [-4.6, 2.5],
      [-3.2, -0.5],
      [-2.9, -2.1],
      [2.3, -4.9],
      [-1.2, -1.8],
      [-2.9, 4.4],
      [0.4, -6.8],
      'z',
    ],
  },
  {
    name: 'Oman',
    vertexPoint: [
      ['m', 1301, 437.8],
      [2.1, -2],
      [0.8, -1.8],
      [1.6, -3.8],
      [-0.1, -1.4],
      [-2.1, -0.8],
      [-1.6, -2.1],
      [-2.9, -3.7],
      [-3.3, -1.1],
      [-4.1, -0.9],
      [-3.3, -2.3],
      [-2.9, -4.3],
      [-2.8, 0],
      [-0.1, 4.2],
      [1.1, 0.8],
      [-2.4, 1.3],
      [0.3, 2.6],
      [-1.4, 2.6],
      [0.1, 2.6],
      [2.9, 4.5],
      [-2.6, 12.7],
      [-16.1, 6.4],
      [5.2, 10.5],
      [2.1, 4.4],
      [2.5, -0.3],
      [3.6, -2.2],
      [3.1, 0.6],
      [2.5, -1.8],
      [-0.2, -2.5],
      [2.1, -1.6],
      [3.4, 0],
      [1.2, -1.3],
      [0.2, -3.1],
      [3.3, -2.4],
      [2.6, 0],
      [0.4, -0.8],
      [-1, -4.2],
      [0.6, -3.2],
      [1, -1.5],
      [2.5, 0.3],
      [1.7, -4.4],
      'z',
      ['m', -16.6, -30.4],
      [0.2, -2.6],
      [-0.7, -0.6],
      [-1.3, 2.2],
      [1.3, 2.2],
      [0.5, -1.2],
      'z',
    ],
  },
  {
    name: 'Pakistan',
    vertexPoint: [
      ['m', 1388.3, 346.3],
      [-9.4, -2.6],
      [-2.9, -5],
      [-4.7, -3],
      [-2.8, 0.7],
      [-2.4, 1.2],
      [-5.8, 0.8],
      [-5.3, 1.3],
      [-2.4, 2.8],
      [1.9, 2.8],
      [1.4, 3.2],
      [-2, 2.7],
      [0.8, 2.5],
      [-0.9, 2.3],
      [-5.1, -0.2],
      [3, 4.2],
      [-3, 1.6],
      [-1.5, 3.8],
      [1.1, 3.8],
      [-1.7, 1.8],
      [-2.1, -0.6],
      [-4, 0.9],
      [-0.2, 1.7],
      [-4, 0],
      [-2.3, 3.6],
      [0.8, 5.4],
      [-6.6, 2.6],
      [-3.8, -0.5],
      [-0.9, 1.4],
      [-3.3, -0.8],
      [-5.3, 0.9],
      [-9.6, -3.2],
      [3.2, 3.3],
      [2.8, 3.9],
      [5.6, 2.7],
      [1, 5.7],
      [2.7, 1],
      [0.9, 2.9],
      [-7.4, 3.3],
      [-1.2, 7.4],
      [7.6, -0.9],
      [8.9, -0.1],
      [9.9, -1.2],
      [4.9, 4.8],
      [2.1, 4.6],
      [4.2, 1.6],
      [3.2, -4.2],
      [12, 0],
      [-1.8, -5.5],
      [-3.5, -3.2],
      [-1.3, -4.9],
      [-4, -2.9],
      [4.9, -6.6],
      [6.4, 0.5],
      [4.5, -6.7],
      [2.1, -6.5],
      [3.9, -6.3],
      [-1, -4.5],
      [3.7, -3.7],
      [-5, -3.1],
      [-2.9, -4.3],
      [-3.2, -5.6],
      [1.9, -2.8],
      [8.5, 1.6],
      [5.7, -1],
      [3.7, -5.4],
      'z',
    ],
  },
  {
    name: 'Panama',
    vertexPoint: [
      ['m', 543.5, 517],
      [-2, -1.8],
      [-1.7, -1.9],
      [-2.5, -1.1],
      [-3.1, -0.2],
      [0.3, -0.6],
      [-3.1, -0.4],
      [-2, 1.9],
      [-3.5, 1.3],
      [-2.5, 1.6],
      [-2.7, 0.5],
      [-1.5, -1.6],
      [-0.5, 0.5],
      [-2.3, -0.3],
      [0.2, -1.3],
      [-1.9, -2.3],
      [-2.2, 0.6],
      [-0.1, 2.5],
      [1.1, 1],
      [-0.8, 0.7],
      [0.1, 1.2],
      [-0.5, 1.3],
      [-0.4, 1.2],
      [0.6, 1],
      [0.3, -1.4],
      [2.4, 0],
      [1.4, 0.7],
      [2.3, 0.5],
      [1, 2.5],
      [1.8, 0.4],
      [0.8, -1.1],
      [0.8, 3.8],
      [2.6, -0.3],
      [0.9, -0.9],
      [1.5, -0.9],
      [-2.5, -3.4],
      [0.6, -1.3],
      [1.3, -0.3],
      [2.3, -1.6],
      [1.2, -2.2],
      [2.5, -0.4],
      [2.7, 1.8],
      [1, 2.1],
      [1.4, 0.4],
      [-1.5, 1.7],
      [1, 3.5],
      [1.8, 1.8],
      [0.9, -3.1],
      [1.8, 0.5],
      [1.1, -1.9],
      [-1.1, -3.8],
      [0.7, -0.9],
      'z',
    ],
  },
  {
    name: 'Peru',
    vertexPoint: [
      ['m', 584.3, 599.5],
      [-2.9, -3.4],
      [-1.7, -0.1],
      [3.5, -6.5],
      [-4.4, -3],
      [-3.3, 0.6],
      [-2.1, -1.1],
      [-3, 1.7],
      [-4.2, -0.8],
      [-3.4, -6.7],
      [-2.7, -1.7],
      [-1.8, -3],
      [-3.7, -3],
      [-1.5, 0.6],
      [0.8, 4.9],
      [-1.7, 4.1],
      [-6, 6.7],
      [-6.7, 2.5],
      [-3.3, 5.5],
      [-0.9, 4.3],
      [-3.1, 2.6],
      [-2.5, -3.2],
      [-2.3, -0.7],
      [-2.3, 0.5],
      [-0.2, -2.3],
      [1.5, -1.5],
      [-0.7, -2.7],
      [-4.4, 4],
      [-1.6, 4.5],
      [3, 6.1],
      [-1.7, 2.8],
      [4.1, 2.6],
      [4.5, 4.1],
      [2, 4.7],
      [2.4, 2.9],
      [6, 12.7],
      [6.2, 11.7],
      [5.4, 8.4],
      [-0.8, 1.8],
      [2.8, 5.3],
      [4.6, 3.9],
      [10.7, 6.9],
      [11.6, 6.4],
      [0.7, 2.6],
      [5.9, 3.7],
      [2.7, -1.6],
      [1.2, -3.3],
      [2.8, -6.9],
      [-2.8, -5.3],
      [1.1, -2.1],
      [-1.2, -2.4],
      [1.9, -3.2],
      [-0.3, -5.4],
      [-0.1, -4.5],
      [1.1, -2.1],
      [-5.5, -10.3],
      [-3, 1.1],
      [-2.6, -0.7],
      [-0.2, -9.7],
      [-4.4, 3.8],
      [-4.9, -0.2],
      [-2.3, -3.4],
      [-3.7, -0.3],
      [1, -2.8],
      [-3.3, -3.8],
      [-2.6, -5.8],
      [1.5, -1.1],
      [-0.1, -2.7],
      [3.3, -1.9],
      [-0.7, -3.4],
      [1.3, -2.2],
      [0.4, -3],
      [6.2, -4.3],
      [4.6, -1.2],
      [0.7, -1],
      [5.1, 0.3],
      'z',
    ],
  },
  {
    name: 'Philippines',
    vertexPoint: [
      ['m', 1684.6, 518.6],
      [-0.6, -2.3],
      [-0.8, -3.2],
      [-4.8, -3],
      [0.8, 4.9],
      [-3.9, 0.2],
      [-0.7, 2.8],
      [-4.2, 1.7],
      [-2.2, -2.8],
      [-2.8, 2.4],
      [-3.4, 1.7],
      [-1.9, 5.4],
      [1.1, 1.9],
      [3.9, -3.6],
      [2.7, 0.3],
      [1.5, -2.7],
      [3.8, 3],
      [-1.5, 3.1],
      [1.9, 4.6],
      [6.8, 3.7],
      [1.4, -3],
      [-2.1, -4.7],
      [2.4, -3.2],
      [2.5, 6.4],
      [1.5, -5.8],
      [-0.6, -3.5],
      [-0.8, -4.3],
      'z',
      ['m', -14.5, -11.8],
      [0, -6.1],
      [-3.6, 6.1],
      [0.5, -4.2],
      [-3, 0.3],
      [-0.3, 4],
      [-1.2, 1.8],
      [-1, 1.7],
      [3.8, 4.4],
      [1.6, -1.9],
      [1.4, -4],
      [1.8, -2.1],
      'z',
      ['m', -30.1, 6.1],
      [2.6, -4.4],
      [3.4, -3.5],
      [-1.5, -5.2],
      [-2.4, 6.3],
      [-2.9, 4.4],
      [-3.8, 4],
      [-2.4, 4.4],
      [7, -6],
      'z',
      ['m', 17.4, -16.4],
      [1.2, 3],
      [-0.1, 3.3],
      [0.5, 2.9],
      [3.3, -1.9],
      [2.4, -2.7],
      [-0.2, -2.6],
      [-3.6, 0],
      [-3.5, -2],
      'z',
      ['m', 20, -1.7],
      [-1.8, -2.4],
      [-5.4, -0.1],
      [4, 4.8],
      [0.3, 2.4],
      [-3.3, -0.5],
      [1.2, 3.9],
      [1.7, 0.3],
      [0.7, 4.5],
      [2.5, -1.4],
      [-1.7, -4],
      [-0.4, -2.1],
      [4.5, 1.7],
      [-2.3, -7.1],
      'z',
      ['m', -22.9, -5.8],
      [-2.2, -2.3],
      [-4.8, -0.2],
      [3.4, 4.8],
      [2.8, 3.2],
      [0.8, -5.5],
      'z',
      ['m', -6.4, -34.6],
      [-3.3, 0],
      [-0.9, 5.8],
      [1.1, 9.9],
      [-2.6, -2],
      [1.2, 6],
      [1.2, 2.8],
      [3.3, 3.7],
      [0.4, -2.3],
      [1.8, 1.4],
      [-1.5, 1.7],
      [0.1, 2.6],
      [2.9, 1.4],
      [5, -0.9],
      [4, 3.8],
      [1.1, -2.4],
      [2.5, 3.4],
      [4.8, 3.1],
      [0.2, -2.9],
      [-2, -1.6],
      [0.1, -3.4],
      [-7.5, -3.6],
      [-2.3, 0.8],
      [-3.1, -0.7],
      [-2, -5.1],
      [0.1, -5.1],
      [3, -2.1],
      [0.6, -5.3],
      [-2.7, -4.6],
      [0.4, -2.6],
      [-0.7, -1.6],
      [-1.5, 1.6],
      [-3.7, -1.8],
      'z',
    ],
  },
  {
    name: 'Papua New Guinea',
    vertexPoint: [
      ['m', 1850.7, 615.6],
      [0.9, -1.8],
      [-2.4, -2.2],
      [-2.5, -4],
      [-1.6, -1.5],
      [-0.5, -1.9],
      [-0.8, 0.7],
      [0.9, 4.8],
      [2.2, 4],
      [2.2, 2.5],
      [1.6, -0.6],
      'z',
      ['m', -21.2, -8.6],
      [2.1, -3.9],
      [0.4, -3.5],
      [-1.1, -1],
      [-3.4, 0.1],
      [0.4, 3.7],
      [-3.3, 2.3],
      [-1.7, 2.2],
      [-3.2, 0.5],
      [-0.4, -3.4],
      [-0.8, 0.1],
      [-1, 3.1],
      [-3.1, 0.5],
      [-5, -0.9],
      [-0.6, 1.9],
      [3.1, 1.8],
      [4.5, 1.9],
      [2.9, 0],
      [3, -1.5],
      [3.2, -1.6],
      [1, -1.8],
      [3, -0.5],
      'z',
      ['m', -27.8, 12.2],
      [-0.9, -4.3],
      [5.2, -0.7],
      [-1.1, -3.3],
      [-9.1, -4],
      [-0.6, -3.7],
      [-2.9, -3.2],
      [-3.7, -3.3],
      [-10.2, -3.6],
      [-9.6, -4.4],
      [-1, 20.7],
      [-1.5, 20.8],
      [5.7, 0.2],
      [3.1, 1.1],
      [4.6, -2.2],
      [-0.3, -4.7],
      [3.6, -2.1],
      [4.9, -1.8],
      [7, 2.8],
      [2.4, 5.6],
      [2.9, 3.5],
      [3.9, 4],
      [5.5, 1],
      [4.8, 0.7],
      [1.1, 1.6],
      [3.8, -0.4],
      [0.8, -1.8],
      [-5.6, -2.7],
      [1.8, -1.2],
      [-4.2, -1.1],
      [0.5, -2.8],
      [-3.2, 0.2],
      [-3, -6.8],
      [-4.7, -4.1],
      'z',
      ['m', 34.7, -18.4],
      [-0.5, -3.3],
      [-2, -2.1],
      [-2.1, -2.6],
      [-2.3, -1.5],
      [-1.9, -1.4],
      [-2.9, -1.8],
      [-1.6, 1.5],
      [3.9, 1.9],
      [3.1, 2.7],
      [2.4, 2.1],
      [1.2, 2.4],
      [0.8, 3.8],
      [1.9, -1.7],
      'z',
    ],
  },
  {
    name: 'Poland',
    vertexPoint: [
      ['m', 1069.4, 228.3],
      [-4.6, -0.1],
      [-0.5, -1.4],
      [-4.8, -1.1],
      [-5.7, 2.1],
      [-7.1, 2.8],
      [-3.1, 1.7],
      [1.4, 3.1],
      [-1.2, 1.6],
      [2, 2.2],
      [1.4, 3.3],
      [-0.1, 2.1],
      [2.3, 3.9],
      [2.4, 1.9],
      [3.7, 0.6],
      [-0.1, 1.7],
      [2.7, 1.2],
      [0.6, -1.5],
      [3.4, 0.6],
      [0.7, 2],
      [3.6, 0.3],
      [2.6, 3.1],
      [0.3, 0.4],
      [1.9, -0.9],
      [2.7, 2.2],
      [2.8, -1.3],
      [2.4, 0.6],
      [3.4, -0.8],
      [4.9, 2.3],
      [1.1, 0.4],
      [-1.6, -2.8],
      [3.8, -5.1],
      [2.3, -0.7],
      [0.3, -1.8],
      [-3.1, -5.3],
      [-0.5, -2.7],
      [-1.9, -2.9],
      [2.7, -1.2],
      [-0.3, -2.4],
      [-1.7, -2.3],
      [-0.6, -2.7],
      [-1.4, -1.9],
      [-2.5, -0.6],
      [-8.7, 0.1],
      [-5.9, -0.7],
      'z',
    ],
  },
  {
    name: 'North Korea',
    vertexPoint: [
      ['m', 1644.7, 302.3],
      [0, 0],
      [-5.5, -3.6],
      [0.1, 3.5],
      [-6.3, 2.6],
      [2.7, 3.3],
      [-4.6, -0.2],
      [-3.6, -2],
      [-1, 4.4],
      [-3.8, 3.4],
      [-2.1, 4],
      [3.3, 1.7],
      [3.4, 0.7],
      [0.8, 1],
      [0.4, 3.5],
      [1.1, 1.2],
      [-0.9, 0.7],
      [-0.1, 2.9],
      [1.9, 1],
      [1.6, 0.6],
      [0.8, 1.2],
      [1.3, -0.5],
      [0, -1.3],
      [3.1, 1.3],
      [0.1, -0.6],
      [2.4, 0.2],
      [0.7, -2.9],
      [3.5, -0.3],
      [2.1, -0.4],
      [-0.1, -1.6],
      [-4.3, -2.8],
      [-2.6, -1],
      [0.2, -0.7],
      [-1.2, -2.8],
      [1.3, -1.7],
      [2.9, -1],
      [1, -1.9],
      [0.3, -1.1],
      [1.9, -1.4],
      [-2.8, -4.5],
      [0.3, -2.1],
      [0.9, -2],
      [2.2, 0.3],
      [0, 0],
      [0, 0],
      [0, 0],
      [-1.4, -1.1],
      'z',
    ],
  },
  {
    name: 'Portugal',
    vertexPoint: [
      ['m', 937.6, 335.9],
      [-0.4, -2.1],
      [2, -2.5],
      [0.8, -1.7],
      [-1.8, -1.9],
      [1.6, -4.3],
      [-2, -3.8],
      [2.2, -0.5],
      [0.3, -3],
      [0.9, -0.9],
      [0.2, -4.9],
      [2.4, -1.7],
      [-1.3, -3.1],
      [-3, -0.2],
      [-0.9, 0.8],
      [-3, 0],
      [-1.2, -3.1],
      [-2.1, 0.9],
      [-1.9, 1.6],
      [0.1, 2.1],
      [0.9, 2.2],
      [0.1, 2.7],
      [-1.3, 3.8],
      [-0.4, 2.5],
      [-2.2, 2.3],
      [-0.6, 4.2],
      [1.2, 2.4],
      [2.3, 0.6],
      [0.4, 4],
      [-1, 5.1],
      [2.8, -0.7],
      [2.7, 0.9],
      [2.2, -1.7],
      'z',
    ],
  },
  {
    name: 'Paraguay',
    vertexPoint: [
      ['m', 655.7, 700.5],
      [-0.3, -1.9],
      [-5.4, -3.3],
      [-5.1, -0.1],
      [-9.5, 1.9],
      [-2.1, 5.6],
      [0.2, 3.4],
      [-1.5, 7.6],
      [11.2, 10.4],
      [4.6, 1],
      [7.2, 4.7],
      [5.9, 2.5],
      [1.1, 2.8],
      [-4.2, 9.6],
      [5.7, 1.8],
      [6.2, 1],
      [4.2, -1.1],
      [4.3, -4.8],
      [0.3, -5.7],
      [0.7, -3.6],
      [0.3, -3.8],
      [-0.3, -3.5],
      [-2.1, -1.2],
      [-2, 1.1],
      [-2, -0.3],
      [-0.9, -2.5],
      [-1, -5.8],
      [-1.2, -1.9],
      [-3.9, -1.7],
      [-2.1, 1.2],
      [-6, -1.2],
      [-0.4, -8.6],
      [-1.9, -3.6],
      'z',
    ],
  },
  {
    name: 'Palestine',
    vertexPoint: [
      ['m', 1166.9, 366.1],
      [-2, -0.9],
      [-0.7, 4.3],
      [1.4, 0.7],
      [-1.2, 0.8],
      [-0.1, 1.7],
      [2.4, -0.8],
      [0.6, -1.9],
      [-0.4, -3.9],
      'z',
    ],
  },
  {
    name: 'Qatar',
    vertexPoint: [
      ['m', 1258, 415.5],
      [0.8, -3.8],
      [-0.5, -3.7],
      [-1.9, -2],
      [-1.4, 0.7],
      [-1.1, 3.3],
      [0.8, 4.7],
      [1.8, 1.2],
      [1.5, -0.4],
      'z',
    ],
  },
  {
    name: 'Romania',
    vertexPoint: [
      ['m', 1108.1, 266.3],
      [-2.1, 0],
      [-1, 1.5],
      [-3.6, 0.6],
      [-1.6, 0.9],
      [-2.4, -1.5],
      [-3.2, 0],
      [-3.2, -0.7],
      [-1.9, 1.3],
      [-2.9, 1.3],
      [-1.9, 4.2],
      [-2.6, 4.3],
      [-3.8, 1.1],
      [2.9, 2.5],
      [0.8, 1.9],
      [3.2, 1.5],
      [0.7, 2.5],
      [3.1, 1.8],
      [1.4, -1.3],
      [1.4, 0.7],
      [-1.1, 1.1],
      [1, 1],
      [1.8, 2.6],
      [1.9, -0.5],
      [4, 1],
      [7.5, 0.3],
      [2.3, -1.6],
      [5.8, -1.4],
      [4, 2.2],
      [3, 0.7],
      [0.4, -7.4],
      [1.6, 0.5],
      [2.3, -1.3],
      [-0.4, -1.6],
      [-2.4, -1.1],
      [-2.2, 1],
      [-2.4, -1.1],
      [-1.3, -2.8],
      [0.2, -2.7],
      [-0.6, -2.7],
      [-3.4, -3.7],
      [-1.9, -2.6],
      [-1.8, -1.9],
      [-1.6, -0.6],
      'z',
    ],
  },
  {
    name: 'Russia',
    vertexPoint: [
      ['m', 1332.3, 95.1],
      [-4.5, -4],
      [-13.6, -4.1],
      [-9.4, -2.1],
      [-6.2, 0.9],
      [-5.3, 2.9],
      [5.8, 0.8],
      [6.6, 3.2],
      [8, 1.7],
      [11.5, 1.3],
      [7.1, -0.6],
      'z',
      ['m', -178.7, -7.3],
      [0.9, -0.6],
      [-5.7, -0.9],
      [-2.8, 0.7],
      [-1.3, 1],
      [-1.5, -1.2],
      [-5.2, 0.1],
      [-6.2, 0.8],
      [7.7, 0.1],
      [-1.1, 1.3],
      [4.4, 1],
      [3.6, -0.7],
      [0.1, -0.7],
      [2.9, -0.3],
      [4.2, -0.6],
      'z',
      ['m', 200.5, 9.9],
      [-1.5, -1.8],
      [-12.5, -2.6],
      [-3, -0.3],
      [-2.2, 0.5],
      [1.2, 6],
      [18, -1.8],
      'z',
      ['m', 15.2, 6.3],
      [-9.2, -0.7],
      [3.4, -1.2],
      [-8.2, -1.5],
      [-6.1, 1.9],
      [-1, 2],
      [1.5, 2.1],
      [-6.9, -0.1],
      [-5.3, 2.6],
      [-4.3, -1.1],
      [-9.3, 0.5],
      [0.3, 1.3],
      [-9.2, 0.7],
      [-4.9, 2.4],
      [-4.2, 0.2],
      [-1.2, 3.3],
      [5.5, 2.6],
      [-7.7, 0.7],
      [-9.5, -0.3],
      [-5.8, 1.1],
      [4.8, 5.4],
      [6.9, 4.3],
      [-9.6, -3],
      [-7.9, 0.3],
      [-5.1, 2],
      [4.5, 3.8],
      [-4.9, -1],
      [-2.1, -5],
      [-4.2, -2.8],
      [-1.8, 0.1],
      [3.6, 3.7],
      [-4.6, 3.5],
      [8.1, 4.2],
      [0.4, 5.4],
      [2.9, 2.9],
      [4.7, 0.5],
      [0.4, 3.5],
      [4.4, 3.1],
      [-1.9, 2.6],
      [0.5, 2.7],
      [-3.7, 1.4],
      [-0.5, 2],
      [-5.3, -0.8],
      [3.5, -7.8],
      [-0.5, -3.6],
      [-6.7, -3.3],
      [-3.8, -7.3],
      [-3.7, -3.7],
      [-3.6, -1.6],
      [0.8, -4.2],
      [-2.9, -2.9],
      [-11.3, -1.4],
      [-2.1, 1],
      [0.5, 4.7],
      [-4.3, 4.7],
      [1.2, 1.7],
      [4.7, 4.1],
      [0.1, 2.6],
      [5.3, 0.5],
      [0.8, 1.1],
      [5.8, 2.9],
      [-1, 2.8],
      [-18.5, -6.1],
      [-6.6, -1.7],
      [-12.8, -1.6],
      [-1.2, 1.7],
      [5.9, 3.1],
      [-2.7, 3.6],
      [-6.4, -3.2],
      [-5, 2.2],
      [-7.6, 0.1],
      [-2.1, 1.9],
      [-5.3, -0.6],
      [2.5, -3.3],
      [-3.2, -0.2],
      [-12.3, 4.6],
      [-7.6, 2.6],
      [0.4, 3.5],
      [-6, 1.2],
      [-4, -1.9],
      [-1.2, -3],
      [5, -0.7],
      [-3.6, -3],
      [-12.2, -1.8],
      [4.3, 3.4],
      [-0.8, 3.2],
      [4.7, 3.3],
      [-1.1, 3.8],
      [-4.6, -1.9],
      [-4, -0.3],
      [-8, 5.4],
      [4.2, 4.1],
      [-3.2, 1.4],
      [-11.4, -3.5],
      [-2.1, 2.1],
      [3.3, 2.4],
      [0.2, 2.7],
      [-3.8, -1.4],
      [-6, -1.7],
      [-1.9, -5.8],
      [-1, -2.6],
      [-8, -4],
      [2.9, -0.7],
      [20.1, 4.2],
      [6.4, -1.5],
      [3.7, -2.9],
      [-1.6, -3.6],
      [-4, -2.6],
      [-17.6, -6.1],
      [-11.6, -1.3],
      [-7.6, -3.2],
      [-3.6, 1.8],
      [0, 0],
      [-6.4, 2.2],
      [-3.2, 0.5],
      [0.4, 3.7],
      [7.2, 3.7],
      [-2.8, 4.1],
      [6.4, 6.3],
      [-1.7, 4.8],
      [4.9, 4.1],
      [-0.9, 3.7],
      [7.3, 3.9],
      [-0.9, 2.9],
      [-3.3, 3.3],
      [-7.9, 7.4],
      [0, 0],
      [5.3, 2.8],
      [-4.5, 3.2],
      [0, 0],
      [0.9, 1],
      [-2.6, 3.4],
      [2.5, 5.5],
      [-1.6, 1.9],
      [2.4, 1.4],
      [1, 2.8],
      [2.1, 3.6],
      [5.2, 1.5],
      [1, 1.4],
      [2.3, -0.7],
      [4.8, 1.4],
      [1, 2.9],
      [-0.6, 1.6],
      [3.7, 3.9],
      [2.2, 1.1],
      [-0.1, 1.1],
      [3.4, 1.1],
      [1.7, 1.6],
      [-1.6, 1.3],
      [-3.9, -0.2],
      [-0.8, 0.6],
      [1.5, 2],
      [2, 3.9],
      [0, 0],
      [1.8, 0.2],
      [1, -1.4],
      [1.5, 0.3],
      [4.8, -0.5],
      [3.8, 3.4],
      [-0.9, 1.3],
      [0.7, 1.9],
      [4, 0.2],
      [2.2, 2.7],
      [0.2, 1.2],
      [6.6, 2.2],
      [3.5, -1],
      [3.6, 2.9],
      [2.9, -0.1],
      [7.6, 2],
      [0.4, 1.9],
      [-1.3, 3.2],
      [1.8, 3.4],
      [-0.3, 2.1],
      [-4.7, 0.5],
      [-2.2, 1.7],
      [0.4, 2.8],
      [4.2, -1],
      [0.4, 1.3],
      [-6.8, 2.6],
      [3.2, 2.4],
      [-3.2, 5.2],
      [-3.4, 1],
      [5, 3.6],
      [6.2, 2.4],
      [7.4, 5.1],
      [0.5, -0.7],
      [4.5, 1.1],
      [7.7, 1],
      [7.5, 2.9],
      [1.1, 1.2],
      [2.9, -1],
      [5.1, 1.3],
      [2.1, 2.5],
      [3.5, 1.4],
      [1.5, 0.2],
      [4.3, 3.8],
      [2.4, 0.4],
      [0.5, -1.5],
      [2.6, -2.5],
      [0, 0],
      [-7.3, -7.3],
      [-0.4, -4.1],
      [-5.9, -5.9],
      [3.5, -6.3],
      [4.6, -1.1],
      [1.4, -3.7],
      [-2.8, -1],
      [-0.2, -3.2],
      [-4.2, -4.1],
      [-3.6, 0.2],
      [-5.3, -4.3],
      [1.7, -4.7],
      [-1.7, -1.2],
      [2.1, -6.8],
      [6, 3.6],
      [-0.7, -4.6],
      [8.1, -6.6],
      [7.5, -0.2],
      [11.9, 4.3],
      [6.6, 2.4],
      [4.3, -2.5],
      [7.6, -0.2],
      [7.3, 3.2],
      [0.8, -1.8],
      [6.9, 0.3],
      [0.2, -3],
      [-9.4, -4.2],
      [3.6, -2.9],
      [-1.5, -1.7],
      [3.9, -1.6],
      [-5, -4.1],
      [1.4, -2.1],
      [16.8, -2.1],
      [1.7, -1.5],
      [10.8, -2.2],
      [3.1, -2.5],
      [9, 1.3],
      [4.3, 6.3],
      [4.3, -1.5],
      [7, 2.1],
      [1.2, 3.3],
      [4.4, -0.4],
      [9.1, -5.7],
      [-0.8, 1.9],
      [8.3, 4.7],
      [18.1, 15.5],
      [1.1, -3.3],
      [8.3, 3.6],
      [6.2, -1.6],
      [3.2, 1.1],
      [4.1, 3.6],
      [3.9, 1.2],
      [3.3, 2.6],
      [6, -0.9],
      [4.3, 3.8],
      [1.7, -0.5],
      [4.7, -1],
      [6.6, -5.4],
      [5.9, -2.9],
      [5.3, 1.9],
      [5.1, 0.1],
      [4.7, 2.9],
      [5, 0.2],
      [7.9, 1.6],
      [2.4, -4.3],
      [-4, -3.6],
      [1.3, -6.4],
      [6.9, 2.5],
      [4.8, 0.8],
      [6.6, 1.5],
      [3.7, 4.6],
      [8.4, 2.6],
      [3.9, -1.1],
      [5.7, -0.8],
      [5.4, 0.8],
      [6.5, 3],
      [4.9, 3.1],
      [4.5, 0],
      [6.7, 1],
      [3.6, -1.6],
      [5.8, -1],
      [4.5, -4.4],
      [3.3, 0.7],
      [3.9, 2.1],
      [5.5, -0.5],
      [7.3, 2.3],
      [4.4, -3.9],
      [-1.9, -2.7],
      [-0.1, -6.5],
      [1.2, -2],
      [-2.5, -3.3],
      [-3.7, -1.5],
      [1.7, -3],
      [5.1, -1.1],
      [6.2, -0.2],
      [8.5, 1.8],
      [5.9, 2.3],
      [7.7, 6.1],
      [3.8, 2.7],
      [4.4, 3.7],
      [6.1, 6.1],
      [9.9, 1.9],
      [8.9, 4.5],
      [6, 5.8],
      [7.5, 0],
      [2.6, -2.5],
      [6.9, -1.8],
      [1.3, 5.6],
      [-0.4, 2.3],
      [2.8, 6.8],
      [0.6, 6],
      [-6.8, -1.1],
      [-2.9, 2.2],
      [4.7, 5.3],
      [3.8, 7.3],
      [-2.5, 0.1],
      [1.9, 3.1],
      [0, 0],
      [1.4, 1.1],
      [0, 0],
      [0, 0],
      [0, 0],
      [-0.4, -2],
      [4, -4.5],
      [5.1, 3],
      [3.2, -0.1],
      [4.4, -3.6],
      [1, -3.7],
      [2.1, -7.1],
      [1.9, -7.2],
      [-1.3, -4.3],
      [1, -9],
      [-5.2, -9.9],
      [-5.5, -7.3],
      [-1.3, -6.2],
      [-4.7, -5.1],
      [-12.7, -6.7],
      [-5.6, -0.4],
      [-0.3, 3],
      [-5.8, -1.3],
      [-5.7, -3.8],
      [-8, -0.7],
      [4.9, -14.1],
      [3.5, -11.5],
      [13.1, -1.8],
      [14.9, 1],
      [2.5, -2.8],
      [7.9, 0.8],
      [4.3, 4.3],
      [6.4, -0.6],
      [8.4, -1.6],
      [-7.7, -3.5],
      [0, -9.8],
      [9.1, -1.9],
      [12.1, 7.1],
      [3.6, -6.4],
      [-3.2, -4.7],
      [4.7, -0.5],
      [6.5, 8.1],
      [-2.4, 4.6],
      [-0.8, 6],
      [0.3, 7.5],
      [-5.7, 1.3],
      [2.8, 2.7],
      [-0.1, 3.6],
      [6.4, 8.3],
      [16, 13.4],
      [10.5, 8.8],
      [5.7, 4.3],
      [1.6, -5.7],
      [-4.5, -6.2],
      [5.7, -1.5],
      [-5.4, -6.9],
      [5, -3.1],
      [-4.7, -2.6],
      [-3.4, -5],
      [4.1, -0.2],
      [-9, -8.6],
      [-6.7, -1.4],
      [-2.9, -2.4],
      [-1.1, -5.6],
      [-3.1, -3.9],
      [7, 0.8],
      [1.3, -2.5],
      [4.7, 2.2],
      [6.1, -4.6],
      [11.4, 4],
      [-1.7, -2.6],
      [2, -3.6],
      [1.5, -4],
      [3.1, -0.7],
      [6.5, -4.3],
      [9.8, 1.2],
      [-0.9, -1.5],
      [-3.8, -2.3],
      [-4.1, -1.6],
      [-9.1, -4.6],
      [-8.1, -3],
      [6.1, 0.4],
      [2, -2.5],
      [0, 0],
      [-32.9, -21.9],
      [-9.4, -2.3],
      [-15.7, -2.6],
      [-7.9, 0.3],
      [-15.2, -1.4],
      [1.8, 2.3],
      [8.5, 3.4],
      [-2.5, 1.8],
      [-14.2, -4.8],
      [-6.8, 0.6],
      [-9.2, -1.1],
      [-7, 0.2],
      [-3.9, 1.1],
      [-7.2, -1.6],
      [-5.1, -3.8],
      [-6.5, -2.2],
      [-9.2, -0.9],
      [-14.7, 1],
      [-16.1, -4],
      [-7.8, -3],
      [-40.1, -3.4],
      [-2.1, 2.2],
      [9.3, 4.8],
      [-7.5, -0.7],
      [-1, 1.5],
      [-9.7, -1.6],
      [-5, 1.4],
      [-9.3, -2.4],
      [3, 5.5],
      [-8.9, -2.1],
      [-10, -4.1],
      [-0.4, -2.2],
      [-6, -3.3],
      [-9.8, -2.6],
      [-6.1, 0],
      [-9.3, -0.9],
      [4.7, 3.9],
      [-17.2, -0.8],
      [-3.9, -2.3],
      [-13.3, -0.9],
      [-5.3, 0.8],
      [-0.1, 1.3],
      [-5.8, -3.2],
      [-2.3, 0.9],
      [-7.2, -1.2],
      [-5.6, -0.7],
      [1.1, -1.5],
      [6.6, -2.8],
      [2.3, -1.5],
      [-2.4, -2.5],
      [-5.5, -1.9],
      [-11.5, -2.3],
      [-10.8, -0.1],
      [-1.9, 1.2],
      [-4.1, -2.4],
      'z',
      ['m', -162.2, 31.6],
      [-9.9, -4.3],
      [-3.1, -4.3],
      [3.3, -4.9],
      [2.8, -5],
      [8.6, -4.7],
      [9.8, -2.4],
      [11.3, -2.4],
      [1.3, -1.5],
      [-4.2, -1.9],
      [-6.6, 0.6],
      [-4.9, 1.8],
      [-11.7, 0.9],
      [-10.1, 3.1],
      [-6.8, 2.7],
      [2.5, 2.2],
      [-6.6, 4.4],
      [3.9, 0.7],
      [-5.4, 4.3],
      [1.6, 2.8],
      [-3.4, 1.1],
      [1.9, 2.8],
      [7.9, 1.4],
      [2.2, 2.3],
      [13.4, 0.7],
      [2.2, -0.4],
      'z',
      ['m', 314, -24.7],
      [-17.9, -2.6],
      [-10.2, -0.2],
      [-3.4, 0.9],
      [3.4, 3.4],
      [12.4, 3.2],
      [4.5, -1.2],
      [14.2, 0.2],
      [-3, -3.7],
      'z',
      ['m', 25.2, 2.3],
      [-11.7, -1.3],
      [-8.2, -0.7],
      [1.7, 1.6],
      [10.3, 2],
      [6.8, 0.4],
      [1.1, -2],
      'z',
      ['m', -12.5, 9.5],
      [-2.5, -1.4],
      [-8.3, -1.9],
      [-4.1, 0.5],
      [-0.8, 2],
      [1.1, 0.2],
      [8.8, 0.6],
      [5.8, 0],
      'z',
      ['m', 162.6, 12.3],
      [-6, -3.6],
      [-1.4, 2.2],
      [3.5, 1.6],
      [3.9, -0.2],
      'z',
      ['m', -612.4, 93.9],
      [-0.6, -1.5],
      [0.2, -1.7],
      [-2.2, -0.9],
      [-5, -1.1],
      [-6.3, 2],
      [-0.7, 2.6],
      [5.9, 0.7],
      [8.7, -0.1],
      'z',
      ['m', 589.7, 21.8],
      [-7.2, -6.2],
      [-5.1, -6],
      [-6.8, -5.8],
      [-4.9, -4],
      [-1.3, 0.8],
      [4.4, 2.8],
      [-1.9, 2.8],
      [6.8, 8.3],
      [7.8, 6],
      [6.4, 8.3],
      [2.4, 4.6],
      [5.5, 6.8],
      [3.8, 6],
      [4.6, 5.2],
      [-0.1, -4.8],
      [6.5, 3.8],
      [-3, -4.4],
      [-9.5, -6.3],
      [-3.7, -9],
      [8.9, 2],
      [-13.6, -10.9],
      'z',
    ],
  },
  {
    name: 'Rwanda',
    vertexPoint: [
      ['m', 1147.6, 579.4],
      [-3.3, 1.9],
      [-1.4, -0.6],
      [-1.6, 1.8],
      [-0.2, 3.8],
      [-0.8, 0.4],
      [-0.6, 3.5],
      [3.5, 0.5],
      [1.7, -3.6],
      [3, 0.4],
      [0, 0],
      [1.6, -0.8],
      [0.4, -3.7],
      [-2.3, -3.6],
      'z',
    ],
  },
  {
    name: 'Sahara',
    vertexPoint: [
      ['m', 929.6, 396.2],
      [-0.8, 0],
      [0, 0],
      [0.1, 3.4],
      [-3.4, 0.2],
      [-1.8, 1.5],
      [-2.5, 0],
      [-1.9, -0.9],
      [-4.6, 0.7],
      [-1.9, 5],
      [-1.7, 0.4],
      [-2.8, 8.1],
      [-7.8, 6.8],
      [-2, 8.8],
      [-2.3, 2.9],
      [-0.7, 2.3],
      [-12.4, 0.5],
      [-0.1, 0],
      [-0.3, 2.7],
      [1.2, -2.2],
      [21.6, 0.1],
      [-0.9, -9.2],
      [1.4, -3.3],
      [5.2, -0.5],
      [0.2, -16.3],
      [17.9, 0.3],
      [0.2, -9.7],
      [0.1, -1.2],
      [0, -0.4],
      'z',
    ],
  },
  {
    name: 'Saudi Arabia',
    vertexPoint: [
      ['m', 1228.7, 387],
      [-10.2, -0.5],
      [-16.7, -12.7],
      [-8.5, -4.5],
      [-6.7, -1.7],
      [-0.9, 1],
      [-10.4, 3.1],
      [6.1, 6.4],
      [-1.7, 1],
      [-0.7, 2.2],
      [-4, 0.8],
      [-1.1, 2.3],
      [-2.1, 2],
      [-6.1, -1],
      [-0.5, 2.5],
      [0, 2.2],
      [-0.6, 3.5],
      [2.7, 0],
      [3.2, 4.4],
      [3.7, 5.1],
      [2.5, 4.7],
      [1.7, 1.5],
      [1.7, 3.3],
      [-0.2, 1.4],
      [2.1, 3.7],
      [3, 1.3],
      [2.8, 2.5],
      [3.6, 7],
      [0, 3.8],
      [0.9, 4.4],
      [4, 6.1],
      [2.5, 1],
      [4.1, 4.4],
      [1.9, 5.2],
      [3.2, 5.3],
      [3, 2.3],
      [0.6, 2.5],
      [1.8, 1.9],
      [0.9, 2.8],
      [2.3, -2.1],
      [-0.7, -2.7],
      [1.2, -3.1],
      [2.4, 1.7],
      [1.5, -0.6],
      [6.4, -0.2],
      [1, 0.7],
      [5.4, 0.6],
      [2.1, -0.3],
      [1.6, 2.1],
      [2.5, -1],
      [3.5, -6.7],
      [5, -2.9],
      [15.7, -2.4],
      [16.1, -6.4],
      [2.6, -12.7],
      [-2.9, -4.5],
      [-1, 1.3],
      [-16.8, -3.2],
      [-2.6, -6.4],
      [-0.4, -1.5],
      [-1.2, -2.4],
      [-1.5, 0.4],
      [-1.8, -1.2],
      [-1, -1.6],
      [-0.9, -2.1],
      [-1.7, -1.8],
      [-1, -2.1],
      [0.4, -2.1],
      [-0.6, -2.7],
      [-4, -2.6],
      [-1.2, -2.3],
      [-2.9, -1.4],
      [-2.7, -5.5],
      [-3.8, 0.2],
      [-1.7, -3.1],
      [-4.9, -0.6],
      'z',
    ],
  },
  {
    name: 'Sudan',
    vertexPoint: [
      ['m', 1180.8, 468.5],
      [0.4, -4.2],
      [1.6, -2],
      [4, -1],
      [2.6, -3.6],
      [-3.1, -2.4],
      [-2.2, -1.6],
      [-2.5, -7.6],
      [-1.1, -6.5],
      [1.1, -1.2],
      [-2.1, -6.2],
      [-21.8, 0],
      [-21.4, 0],
      [-22.1, 0],
      [0.5, 12.7],
      [-6.3, 0],
      [0, 2.7],
      [1.1, 25.2],
      [-4.8, -0.4],
      [-2.4, 4.7],
      [-1.4, 3.9],
      [1.2, 1.5],
      [-1.8, 1.9],
      [0.7, 2.7],
      [-1.4, 2.6],
      [-0.5, 2.4],
      [2, -0.4],
      [1.2, 2.5],
      [0.1, 3.7],
      [2.1, 1.8],
      [0, 1.6],
      [0.7, 2.7],
      [3.3, 4],
      [0, 2.6],
      [-0.8, 2.6],
      [0.3, 2],
      [2, 1.8],
      [0.5, 0.3],
      [1.7, -0.7],
      [1.9, -1.2],
      [1.3, -5.7],
      [1.5, -2.9],
      [4, -0.9],
      [1, 1.8],
      [3, 3.7],
      [1.5, 0.5],
      [2, -1.1],
      [4.1, 0.3],
      [0.8, 1.3],
      [5.5, 0],
      [0.2, -1.3],
      [2.9, -1.2],
      [0.5, -1.9],
      [2.1, -1.3],
      [4.8, 3.7],
      [2.8, -0.7],
      [2.7, -4.5],
      [3, -3.5],
      [-0.6, -3.9],
      [-1.4, -1.8],
      [3.4, -0.3],
      [0.3, -1.5],
      [2.6, 0.5],
      [-0.5, 4.7],
      [0.8, 4.6],
      [2.9, 2.5],
      [0.7, 2.2],
      [0, 3.1],
      [0.8, 0.1],
      [0, -0.7],
      [1.4, -6.7],
      [2.6, -1.8],
      [0.5, -2.6],
      [2.3, -4.8],
      [3.2, -3.2],
      [2.1, -6.2],
      [0.7, -5.5],
      [-0.7, -2.5],
      [1.9, -9.4],
      'z',
    ],
  },
  {
    name: 'South Sudan',
    vertexPoint: [
      ['m', 1166, 508.7],
      [-0.7, -2.2],
      [-2.9, -2.5],
      [-0.8, -4.6],
      [0.5, -4.7],
      [-2.6, -0.5],
      [-0.3, 1.5],
      [-3.4, 0.3],
      [1.4, 1.8],
      [0.6, 3.9],
      [-3, 3.5],
      [-2.7, 4.5],
      [-2.8, 0.7],
      [-4.8, -3.7],
      [-2.1, 1.3],
      [-0.5, 1.9],
      [-2.9, 1.2],
      [-0.2, 1.3],
      [-5.5, 0],
      [-0.8, -1.3],
      [-4.1, -0.3],
      [-2, 1.1],
      [-1.5, -0.5],
      [-3, -3.7],
      [-1, -1.8],
      [-4, 0.9],
      [-1.5, 2.9],
      [-1.3, 5.7],
      [-1.9, 1.2],
      [-1.7, 0.7],
      [3.8, 2.5],
      [3.1, 2.6],
      [0.1, 2],
      [3.8, 3.4],
      [2.4, 2.7],
      [1.5, 3.8],
      [4.2, 2.5],
      [0.9, 2.1],
      [3.5, 5.2],
      [2.5, 0.8],
      [1.5, -1.1],
      [2.6, 0.4],
      [3.1, -1.3],
      [1.4, 2.7],
      [5, 4.2],
      [0, 0],
      [2.3, -1.7],
      [3.5, 1.4],
      [4.5, -1.5],
      [4, 0.1],
      [3.4, -3],
      [3.4, -3.8],
      [3.8, -4.2],
      [-3.5, -6.9],
      [-2.6, -1.5],
      [-1, -2.5],
      [-2.9, -3.1],
      [-3.4, -0.5],
      [1.8, -3.6],
      [3, -0.1],
      [0.8, -2],
      [-0.2, -5],
      [-0.8, -0.1],
      [0, -3.1],
      'z',
    ],
  },
  {
    name: 'Senegal',
    vertexPoint: [
      ['m', 908.9, 479.2],
      [-3.6, -4.4],
      [-3.2, -4.7],
      [-3.7, -1.7],
      [-2.6, -1.8],
      [-3.1, 0],
      [-2.8, 1.4],
      [-2.7, -0.5],
      [-2, 2],
      [-1.3, 3.3],
      [-2.8, 4.4],
      [-2.5, 1.2],
      [2.7, 2.3],
      [2.2, 5],
      [6.1, -0.2],
      [1.3, -1.5],
      [1.8, -0.1],
      [2.1, 1.5],
      [1.8, 0.1],
      [1.8, -1.1],
      [1.1, 1.8],
      [-2.4, 1.5],
      [-2.4, -0.1],
      [-2.4, -1.4],
      [-2.1, 1.5],
      [-1, 0],
      [-1.4, 0.9],
      [-5, -0.1],
      [0.8, 4.9],
      [3, -1.1],
      [1.8, 0.2],
      [1.5, -0.7],
      [10.3, 0.3],
      [2.7, 0.1],
      [4, 1.5],
      [1.3, -0.1],
      [0.4, -0.7],
      [3, 0.5],
      [0.8, -0.4],
      [0.3, -2],
      [-0.4, -2.4],
      [-2.1, -1.8],
      [-1.1, -3.7],
      [-0.2, -3.9],
      'z',
    ],
  },
  {
    name: 'Sierra Leone',
    vertexPoint: [
      ['m', 919.4, 518.7],
      [-1.5, 0.3],
      [0, -2.3],
      [-0.9, -1.7],
      [0.2, -1.8],
      [-1.2, -2.7],
      [-1.5, -2.3],
      [-4.5, 0],
      [-1.3, 1.2],
      [-1.5, 0.2],
      [-1, 1.4],
      [-0.7, 1.7],
      [-3, 2.8],
      [0.7, 4.7],
      [0.9, 2.3],
      [2.9, 3.5],
      [4.1, 2.5],
      [1.5, 0.5],
      [1.3, -2],
      [0.3, -1.9],
      [2.6, -3.4],
      [2.6, -3],
      'z',
    ],
  },
  {
    name: 'El Salvador',
    vertexPoint: [
      ['m', 487.2, 487],
      [0.6, -2.5],
      [-0.7, -0.7],
      [-1.1, -0.5],
      [-2.5, 0.8],
      [-0.1, -0.9],
      [-1.6, -1],
      [-1.1, -1.3],
      [-1.5, -0.5],
      [-1.4, 0.4],
      [0.2, 0.7],
      [-1.1, 0.7],
      [-2.1, 1.6],
      [-0.2, 1],
      [1.4, 1.3],
      [3.1, 0.4],
      [2.2, 1.3],
      [1.9, 0.6],
      [3.3, 0.1],
      [0.7, -1.5],
      'z',
    ],
  },
  {
    name: 'Serbia',
    vertexPoint: [
      ['m', 1084.8, 285.2],
      [-3.2, -1.5],
      [-0.8, -1.9],
      [-2.9, -2.5],
      [-3.2, -0.2],
      [-3.7, 1.6],
      [0, 0],
      [1.5, 2.4],
      [1.7, 1.8],
      [-1.7, 2.3],
      [0, 0],
      [1.8, 0],
      [-1, 2.7],
      [2.7, 2.3],
      [-0.5, 2.9],
      [-1.2, 0.3],
      [1.5, 1.1],
      [0.8, 0.8],
      [1.8, 0.7],
      [2, 1.2],
      [-0.4, 0.6],
      [1.2, -0.5],
      [0.5, -2],
      [0.9, -0.4],
      [0.8, 0.9],
      [1, 0.4],
      [0.8, 1],
      [0.8, 0.3],
      [1.1, 1.1],
      [0.8, 0],
      [-0.5, 1.5],
      [-0.5, 0.7],
      [0.2, 0.5],
      [1.7, -0.4],
      [2.4, -0.1],
      [0.7, -0.9],
      [-0.6, -0.7],
      [0.7, -2],
      [1.7, -1.9],
      [-2.8, -2.6],
      [-0.7, -2.3],
      [1.1, -1.4],
      [-1, -1],
      [1.1, -1.1],
      [-1.4, -0.7],
      [-1.4, 1.3],
      [-3.1, -1.8],
      [-0.7, -2.5],
      'z',
    ],
  },
  {
    name: 'Suriname',
    vertexPoint: [
      ['m', 668, 533.8],
      [-4.6, 0.5],
      [-0.6, 1.1],
      [-6.7, -1.2],
      [-1, 5.7],
      [-3.5, 1.6],
      [0.3, 1.5],
      [-1.1, 3.3],
      [2.4, 4.6],
      [1.8, 0.1],
      [0.7, 3.5],
      [3.3, 5.6],
      [3.1, 0.5],
      [0.5, -1.3],
      [-0.9, -1.3],
      [0.5, -1.8],
      [2.3, 0.6],
      [2.7, -0.7],
      [3.2, 1.4],
      [1.4, -2.7],
      [0.6, -2.9],
      [1, -2.8],
      [-2.1, -3.7],
      [-0.4, -4.4],
      [3.1, -5.5],
      [-6, -1.7],
      'z',
    ],
  },
  {
    name: 'Slovakia',
    vertexPoint: [
      ['m', 1087.4, 260.9],
      [-4.9, -2.3],
      [-3.4, 0.8],
      [-2.4, -0.6],
      [-2.8, 1.3],
      [-2.7, -2.2],
      [-1.9, 0.9],
      [-0.3, -0.4],
      [-1.5, 0],
      [-0.6, 1.1],
      [-1.1, 0.3],
      [-0.2, 1.4],
      [-0.9, 0.3],
      [-0.1, 0.6],
      [-1.6, 0.6],
      [-2.2, -0.1],
      [-0.6, 1.4],
      [-0.3, 0.8],
      [0.7, 2.1],
      [2.6, 1.6],
      [1.9, 0.7],
      [4.1, -0.8],
      [0.3, -1.2],
      [1.9, -0.2],
      [2.3, -1],
      [0.6, 0.4],
      [2.2, -0.7],
      [1, -1.5],
      [1.6, -0.4],
      [5.5, 1.9],
      [1, -0.6],
      [0.7, -2.5],
      [1.1, -1.7],
      'z',
    ],
  },
  {
    name: 'Slovenia',
    vertexPoint: [
      ['m', 1059.4, 277],
      [-1.2, -2.1],
      [-0.8, -0.1],
      [-0.9, 1.1],
      [-4.3, 0.1],
      [-2.4, 1.4],
      [-4.2, -0.4],
      [-0.3, 3],
      [1.4, 2.7],
      [-1.1, 0.5],
      [3.5, 0.2],
      [0.8, -1],
      [1.8, 1],
      [2, 0.1],
      [-0.2, -1.7],
      [1.7, -0.6],
      [0.3, -2.5],
      [3.9, -1.7],
      'z',
    ],
  },
  {
    name: 'Sweden',
    vertexPoint: [
      ['m', 1077.7, 161.1],
      [-1.9, -2.2],
      [-1.7, -8.4],
      [-7.2, -3.7],
      [-5.9, -2.7],
      [-2.5, 0.3],
      [0, 3.5],
      [-7.9, -0.9],
      [-0.6, 3.1],
      [-4, -0.1],
      [-2.2, 3.9],
      [-3.4, 6.1],
      [-5.7, 7.9],
      [1.8, 1.9],
      [-1.3, 2.2],
      [-4.3, -0.1],
      [-2.3, 5.3],
      [1, 7.6],
      [3.1, 2.9],
      [-0.9, 6.9],
      [-3.4, 4],
      [-1.7, 3.3],
      [4.2, 8.4],
      [4.4, 6.7],
      [2, 5.7],
      [5.3, -0.3],
      [2.2, -4.7],
      [5.7, 0.5],
      [2, -5.5],
      [0.6, -10],
      [4.6, -1.3],
      [3.3, -6.6],
      [-4.8, -3.3],
      [-3.6, -4],
      [2.1, -8.1],
      [7.7, -4.9],
      [6.1, -4.5],
      [-1.2, -3.5],
      [3.4, -3.9],
      [7, -1.5],
      'z',
    ],
  },
  {
    name: 'Swaziland',
    vertexPoint: [
      ['m', 1150.5, 736.6],
      [-2.7, -1.2],
      [-1.6, 0.5],
      [-0.7, 1.8],
      [-1.6, 2.4],
      [-0.1, 2.2],
      [3, 3.5],
      [3.3, -0.7],
      [1.3, -2.8],
      [-0.3, -2.8],
      [-0.6, -2.9],
      'z',
    ],
  },
  {
    name: 'Switzerland',
    vertexPoint: [
      ['m', 1024.3, 270.6],
      [-5.4, -1.9],
      [-1, 1.4],
      [-4.2, 0],
      [-1.3, 1],
      [-2.3, -0.6],
      [0.2, 1.6],
      [-3.5, 3.5],
      [0, 2.8],
      [2.4, -0.9],
      [1.8, 2.7],
      [2.2, 1.3],
      [2.4, -0.3],
      [2.7, -2.1],
      [0.9, 1],
      [2.4, -0.2],
      [0.9, -2.5],
      [3.8, 0.8],
      [2.1, -1.1],
      [0.3, -2.5],
      [-2.6, -0.2],
      [-2.3, -1.1],
      [0.7, -1.6],
      [-0.2, -1.1],
      'z',
    ],
  },
  {
    name: 'Syria',
    vertexPoint: [
      ['m', 1183.5, 359.9],
      [11, -6.7],
      [0.9, -7.8],
      [-1.2, -4.7],
      [2.7, -1.6],
      [2.1, -4.1],
      [-5.9, 1.1],
      [-2.8, -0.2],
      [-5.7, 2.5],
      [-4.3, 0],
      [-3, -1.2],
      [-5.5, 1.8],
      [-1.9, -1.3],
      [0.1, 3.6],
      [-1.2, 1.5],
      [-1.2, 1.4],
      [-1, 2.6],
      [1.1, 5],
      [2.4, 0.3],
      [1.2, 2.5],
      [-2.6, 2.4],
      [-0.9, 3.5],
      [0.3, 2.6],
      [-0.6, 1],
      [0.1, 0],
      [6.3, 2.5],
      [9.6, -6.7],
      'z',
    ],
  },
  {
    name: 'Togo',
    vertexPoint: [
      ['m', 981.7, 502.2],
      [-4.9, -0.1],
      [-0.4, 1.9],
      [2.4, 3.3],
      [-0.1, 4.6],
      [0.6, 5.1],
      [1.4, 2.3],
      [-1.2, 5.7],
      [0.4, 3.2],
      [1.5, 4],
      [1.2, 2.2],
      [4.6, -1.3],
      [-1.4, -4.4],
      [0.2, -14.6],
      [-1.1, -1.3],
      [-0.2, -3.1],
      [-2, -2.3],
      [-1.7, -1.9],
      [0.7, -3.3],
      'z',
    ],
  },
  {
    name: 'Thailand',
    vertexPoint: [
      ['m', 1562.7, 481.4],
      [1.5, -2.9],
      [-0.5, -5.4],
      [-5.2, -5.5],
      [-1.3, -6.3],
      [-4.9, -5.2],
      [-4.3, -0.4],
      [-0.8, 2.2],
      [-3.2, 0.2],
      [-1.8, -1.2],
      [-5.3, 3.8],
      [-1, -5.7],
      [0.4, -6.7],
      [-3.8, -0.3],
      [-0.9, -3.8],
      [-2.6, -1.9],
      [-3, 1.4],
      [-2.8, 2.8],
      [-3.9, 0.3],
      [-1.5, 6.9],
      [-2.2, 1.1],
      [3.5, 5.6],
      [4.1, 4.6],
      [2.9, 4.2],
      [-1.4, 5.6],
      [-1.7, 1.1],
      [1.7, 3.2],
      [4.2, 5.1],
      [1, 3.5],
      [0.2, 3],
      [2.8, 5.8],
      [-2.6, 5.9],
      [-2.2, 6.6],
      [-1.3, 6.1],
      [-0.3, 3.9],
      [1.2, 3.6],
      [0.7, -3.8],
      [2.9, 3.1],
      [3.2, 3.5],
      [1.1, 3.2],
      [2.4, 2.4],
      [0.9, -1.1],
      [4.7, 2.8],
      [0.6, 3.3],
      [3.7, -0.8],
      [1.7, -2.6],
      [-3.1, -3.3],
      [-3.4, -0.8],
      [-3.3, -3.6],
      [-1.4, -5.5],
      [-2.6, -5.8],
      [-3.7, -0.2],
      [-0.7, -4.6],
      [1.4, -5.6],
      [2.2, -9.3],
      [-0.2, -7],
      [4.9, -0.1],
      [-0.3, 5],
      [4.7, -0.1],
      [5.3, 2.9],
      [-2.1, -7.7],
      [3, -5.2],
      [7.1, -1.3],
      [5.3, 1],
      'z',
    ],
  },
  {
    name: 'Tajikistan',
    vertexPoint: [
      ['m', 1344.1, 315.7],
      [-2.1, 0.2],
      [-1.3, -1.8],
      [0.2, -2.9],
      [-6.4, 1.5],
      [-0.5, 4],
      [-1.5, 3.5],
      [-4.4, -0.3],
      [-0.6, 2.8],
      [4.2, 1.6],
      [2.4, 4.7],
      [-1.3, 6.6],
      [1.8, 0.8],
      [3.3, -2.1],
      [2.1, 1.3],
      [0.9, -3],
      [3.2, 0.1],
      [0.6, -0.9],
      [-0.2, -2.6],
      [1.7, -2.3],
      [3.2, 1.5],
      [0, 2],
      [1.6, 0.3],
      [1, 5.4],
      [2.6, 2.1],
      [1.5, -1.3],
      [2.1, -0.7],
      [2.5, -2.9],
      [3.8, 0.5],
      [5.4, 0],
      [-1.8, -3.7],
      [-0.6, -2.5],
      [-3.5, -1.4],
      [-1.6, 0.6],
      [-3, -5.9],
      [-9.5, 0.9],
      [-7.1, -2],
      [-5.4, 0.5],
      [-0.6, -3.7],
      [5.9, 1.1],
      [1.4, -2],
      'z',
    ],
  },
  {
    name: 'Turkmenistan',
    vertexPoint: [
      ['m', 1325.6, 334.2],
      [-0.8, -4],
      [-7.7, -2.7],
      [-6.2, -3.2],
      [-4.2, -3],
      [-7, -4.4],
      [-4.3, -6.4],
      [-2, -1.2],
      [-5.5, 0.3],
      [-2.3, -1.3],
      [-1.9, -4.9],
      [-7.8, -3.3],
      [-3.3, 3.6],
      [-3.8, 2.2],
      [1.6, 3.1],
      [-5.8, 0.1],
      [-2.5, 0.3],
      [-4.9, -4.9],
      [-3.8, -1.7],
      [-5.5, 1.3],
      [-1.8, 2],
      [2.5, 4],
      [-0.5, -4.5],
      [3.7, -1.6],
      [2.4, 3.6],
      [4.6, 3.7],
      [-4, 2],
      [-5.3, -1.5],
      [0.1, 5.2],
      [3.5, 0.4],
      [-0.4, 4.4],
      [4.5, 2.1],
      [0.7, 6.8],
      [1.8, 4.5],
      [4.4, -1.2],
      [3, -3.7],
      [3.5, 0.2],
      [2.1, -1.2],
      [3.8, 0.6],
      [6.5, 3.3],
      [4.3, 0.7],
      [7.3, 5.7],
      [3.9, 0.2],
      [1.6, 5.5],
      [5.9, 2.4],
      [3.9, -0.8],
      [0.4, -3],
      [4, -0.9],
      [2.5, -2],
      [-0.1, -5.2],
      [4.1, -1.2],
      [0.3, -2.3],
      [2.9, 1.7],
      [1.6, 0.2],
      'z',
    ],
  },
  {
    name: 'Timor-Leste',
    vertexPoint: [
      ['m', 1676.8, 631.9],
      [4.9, -1.8],
      [6, -2.8],
      [2.2, -1.7],
      [-2, -0.8],
      [-1.8, 0.8],
      [-4, 0.2],
      [-4.9, 1.4],
      [-0.8, 1.5],
      [0.5, 1.3],
      [-0.1, 1.9],
      'z',
    ],
  },
  {
    name: 'Tunisia',
    vertexPoint: [
      ['m', 1038, 361.4],
      [-2, -1],
      [-1.5, -3],
      [-2.8, -0.1],
      [-1.1, -3.5],
      [3.4, -3.2],
      [0.5, -5.6],
      [-1.9, -1.6],
      [-0.1, -3],
      [2.5, -3.2],
      [-0.4, -1.3],
      [-4.4, 2.4],
      [0.1, -3.3],
      [-3.7, -0.7],
      [-5.6, 2.6],
      [-1, 3.3],
      [1, 6.2],
      [-1.1, 5.3],
      [-3.2, 3.6],
      [0.6, 4.8],
      [4.5, 3.8],
      [0, 1.5],
      [3.4, 2.6],
      [2.6, 11.3],
      [2.6, -1.4],
      [0.4, -2.7],
      [-0.7, -2.6],
      [3.7, -2.5],
      [1.5, -2],
      [2.6, -1.8],
      [0.1, -4.9],
      'z',
    ],
  },
  {
    name: 'Turkey',
    vertexPoint: [
      ['m', 1166.6, 308.9],
      [-9.7, -4.4],
      [-8.5, 0.2],
      [-5.7, 1.7],
      [-5.6, 4],
      [-9.9, -0.8],
      [-1.6, 4.8],
      [-7.9, 0.2],
      [-5.1, 6.1],
      [3.6, 3],
      [-2, 5],
      [4.2, 3.6],
      [3.7, 6.4],
      [5.8, -0.1],
      [5.4, 3.5],
      [3.6, -0.8],
      [0.9, -2.7],
      [5.7, 0.2],
      [4.6, 3.5],
      [8, -0.7],
      [3.1, -3.7],
      [4.6, 1.5],
      [3.2, -0.6],
      [-1.7, 2.4],
      [2.3, 3],
      [1.2, -1.4],
      [1.2, -1.5],
      [-0.1, -3.6],
      [1.9, 1.3],
      [5.5, -1.8],
      [3, 1.2],
      [4.3, 0],
      [5.7, -2.5],
      [2.8, 0.2],
      [5.9, -1.1],
      [2.1, -1],
      [6.2, 0.9],
      [2.1, 1.6],
      [2.3, -1.1],
      [0, 0],
      [-3.7, -5.2],
      [0.7, -2],
      [-2.9, -7.3],
      [3.3, -1.8],
      [-2.4, -1.9],
      [-4.2, -1.5],
      [0, -3.1],
      [-1.3, -2.2],
      [-5.6, -3],
      [-5.4, 0.3],
      [-5.5, 3.2],
      [-4.5, -0.6],
      [-5.8, 1],
      [-7.8, -2.4],
      'z',
      ['m', -49.6, 4],
      [2, -1.9],
      [6.1, -0.4],
      [0.7, -1.5],
      [-4.7, -2],
      [-0.9, -2.4],
      [-4.5, -0.8],
      [-5, 2],
      [2.7, 1.6],
      [-1.2, 3.9],
      [-1.1, 0.7],
      [0.1, 1.3],
      [1.9, 2.9],
      [3.9, -3.4],
      'z',
    ],
  },
  {
    name: 'Taiwan',
    vertexPoint: [
      ['m', 1642.3, 427.2],
      [1.2, -10.2],
      [0.1, -3.9],
      [-2.9, -1.9],
      [-3.3, 4.8],
      [-1.9, 6.3],
      [1.5, 4.7],
      [4, 5.4],
      [1.3, -5.2],
      'z',
    ],
  },
  {
    name: 'Tanzania',
    vertexPoint: [
      ['m', 1149.6, 578.6],
      [-2, 0.8],
      [2.3, 3.6],
      [-0.4, 3.7],
      [-1.6, 0.8],
      [0, 0],
      [0.3, 2.5],
      [1.2, 1.5],
      [0, 2],
      [-1.4, 1.4],
      [-2.2, 3.3],
      [-2.1, 2.3],
      [-0.6, 0.1],
      [-0.3, 2.7],
      [1.1, 0.9],
      [-0.2, 2.7],
      [1, 2.6],
      [-1.3, 2.4],
      [4.5, 4.3],
      [0.3, 3.9],
      [2.7, 6.5],
      [0, 0],
      [0.3, 0.2],
      [2.2, 1.1],
      [3.5, 1.1],
      [3.2, 1.9],
      [5.4, 1.2],
      [1.1, 1.7],
      [0, 0],
      [0.4, -1.2],
      [2.8, 3.4],
      [0.3, 6.7],
      [1.8, 2.4],
      [0, 0.1],
      [2.1, -0.3],
      [6.7, 1.8],
      [1.4, -0.8],
      [3.9, -0.1],
      [2.1, -1.9],
      [3.3, 0.1],
      [6.2, -2.5],
      [4.6, -3.7],
      [0, 0],
      [-2, -1.4],
      [-2.2, -6.3],
      [-1.8, -3.9],
      [0.4, -3.1],
      [-0.3, -1.9],
      [1.7, -3.9],
      [-0.2, -1.6],
      [-3.5, -2.3],
      [-0.3, -3.6],
      [2.8, -7.9],
      [-8, -6.3],
      [-0.4, -3.7],
      [-20.2, -13],
      [0, 0],
      [-2.8, 2.8],
      [-1.9, 2.9],
      [2.2, 2.2],
      [-3.2, 1.6],
      [-0.7, -0.8],
      [-3.2, 0.4],
      [-2.5, 1.4],
      [-1.6, -2.4],
      [1.1, -4.5],
      [0.2, -3.8],
      [0, 0],
      [0, 0],
      [-6.2, -0.1],
      'z',
    ],
  },
  {
    name: 'Uganda',
    vertexPoint: [
      ['m', 1167.6, 545.1],
      [-3.4, 3],
      [-4, -0.1],
      [-4.5, 1.5],
      [-3.5, -1.4],
      [-2.3, 1.7],
      [0, 0],
      [-0.3, 7.5],
      [2.3, 0.8],
      [-1.8, 2.3],
      [-2.2, 1.7],
      [-2.1, 3.3],
      [-1.2, 3],
      [-0.3, 5.1],
      [-1.3, 2.4],
      [-0.1, 4.8],
      [1.4, 0.6],
      [3.3, -1.9],
      [2, -0.8],
      [6.2, 0.1],
      [0, 0],
      [-0.3, -2.5],
      [2.6, -3.7],
      [3.5, -0.9],
      [2.4, -1.5],
      [2.9, 1.2],
      [0.3, 0.5],
      [0, -0.3],
      [1.6, -2.6],
      [2.7, -4.2],
      [2.1, -4.7],
      [-2.6, -7.3],
      [-0.7, -3.2],
      [-2.7, -4.4],
      'z',
    ],
  },
  {
    name: 'Ukraine',
    vertexPoint: [
      ['m', 1138.5, 241],
      [-4.8, 0.5],
      [-1.5, -0.3],
      [-1, 1.4],
      [-1.8, -0.2],
      [0, 0],
      [-4.1, 0.3],
      [-1.2, 1.4],
      [0.2, 3.1],
      [-2, -0.6],
      [-4.3, 0.3],
      [-1.5, -1.5],
      [-1.6, 1.1],
      [-2, -0.9],
      [-3.8, -0.1],
      [-5.6, -1.5],
      [-5, -0.5],
      [-3.7, 0.2],
      [-2.4, 1.6],
      [-2.2, 0.3],
      [3.1, 5.3],
      [-0.3, 1.8],
      [-2.3, 0.7],
      [-3.8, 5.1],
      [1.6, 2.8],
      [-1.1, -0.4],
      [-1.1, 1.7],
      [-0.7, 2.5],
      [2.9, 1.7],
      [0.6, 1.6],
      [1.9, -1.3],
      [3.2, 0.7],
      [3.2, 0],
      [2.4, 1.5],
      [1.6, -0.9],
      [3.6, -0.6],
      [1, -1.5],
      [2.1, 0],
      [1.1, -0.9],
      [3.2, -0.6],
      [3.9, 1.9],
      [2, 0.3],
      [2.5, 1.6],
      [0, 2.1],
      [1.9, 1.1],
      [1.1, 2.6],
      [2, 1.5],
      [-0.2, 1],
      [1, 0.6],
      [-1.2, 0.5],
      [-3, -0.2],
      [-0.6, -0.9],
      [-1, 0.5],
      [0.5, 1.1],
      [-1.1, 2],
      [-0.5, 2.1],
      [-1.2, 0.7],
      [2.4, 1.1],
      [2.2, -1],
      [2.4, 1.1],
      [3.3, -4.6],
      [1.3, -3.4],
      [4.5, -0.8],
      [0.7, 2.4],
      [8, 1.5],
      [1.7, 1.4],
      [-4.5, 2.1],
      [-0.7, 1.2],
      [5.8, 1.8],
      [-0.6, 2.9],
      [3, 1.3],
      [6.3, -3.6],
      [5.3, -1.1],
      [0.6, -2.2],
      [-5.1, 0.4],
      [-2.7, -1.5],
      [-1, -3.9],
      [3.9, -2.3],
      [4.6, -0.3],
      [3, -2],
      [3.9, -0.5],
      [-0.4, -2.8],
      [2.2, -1.7],
      [4.7, -0.5],
      [0.3, -2.1],
      [-1.8, -3.4],
      [1.3, -3.2],
      [-0.4, -1.9],
      [-7.6, -2],
      [-2.9, 0.1],
      [-3.6, -2.9],
      [-3.5, 1],
      [-6.6, -2.2],
      [-0.2, -1.2],
      [-2.2, -2.7],
      [-4, -0.2],
      [-0.7, -1.9],
      [0.9, -1.3],
      [-3.8, -3.4],
      'z',
    ],
  },
  {
    name: 'Uruguay',
    vertexPoint: [
      ['m', 692.5, 787],
      [-2.1, -3.7],
      [1.9, -3],
      [-3.8, -4.3],
      [-4.8, -3.5],
      [-6.2, -4.1],
      [-1.9, 0.2],
      [-6.2, -4.9],
      [-3.4, 0.7],
      [-0.5, 5.1],
      [-0.3, 6.5],
      [1.1, 6.3],
      [-0.9, 1.4],
      [0.4, 4.2],
      [3.9, 3.5],
      [3.6, -0.2],
      [5.4, 2.7],
      [2.7, -0.6],
      [4.2, 1.2],
      [5.3, -3.5],
      [1.6, -4],
      'z',
    ],
  },
  {
    name: 'United States',
    vertexPoint: [
      ['m', 116.7, 450.7],
      [2, -0.9],
      [2.5, -1.4],
      [0.2, -0.4],
      [-0.9, -2.2],
      [-0.7, -0.8],
      [-0.8, -0.6],
      [-1.9, -1.1],
      [-0.4, -0.1],
      [-0.4, 0.6],
      [0, 1.3],
      [-1.2, 1],
      [-0.4, 0.7],
      [0.4, 2.3],
      [-0.6, 1.8],
      [1.2, 0.9],
      [1, -1.1],
      'z',
      ['m', -0.6, -9.9],
      [0.6, -0.7],
      [-1.2, -1],
      [-1.8, -0.6],
      [-0.7, 0.5],
      [0, 0.4],
      [0.5, 0.5],
      [0.6, 1.4],
      [2, -0.5],
      'z',
      ['m', -3, -3.4],
      [-2.6, -0.2],
      [-0.6, 0.7],
      [2.9, 0.2],
      [0.3, -0.7],
      'z',
      ['m', -4.7, -0.9],
      [-1.1, -2.1],
      [-0.3, -0.4],
      [-1.7, 0.9],
      [0.1, 0.2],
      [0.4, 1.5],
      [1.8, 0.2],
      [0.4, 0.1],
      [0.4, -0.4],
      'z',
      ['m', -8.3, -4.2],
      [0.3, -1.5],
      [-1.3, -0.1],
      [-1, 0.6],
      [-0.4, 0.5],
      [1.6, 1.1],
      [0.8, -0.6],
      'z',
      ['m', 412.1, -173.2],
      [-1.6, 0],
      [-1.3, 2.4],
      [-10.1, 0],
      [-16.8, 0],
      [-16.7, 0],
      [-14.8, 0],
      [-14.7, 0],
      [-14.5, 0],
      [-15, 0],
      [-4.8, 0],
      [-14.6, 0],
      [-13.9, 0],
      [-1.6, 5.1],
      [-2.4, 5.1],
      [-2.3, 1.6],
      [1.1, -5.9],
      [-5.8, -2.1],
      [-1.4, 1.2],
      [-0.4, 2.9],
      [-1.8, 5.4],
      [-4.2, 8.3],
      [-4, 5.6],
      [-4, 5.6],
      [-5.4, 5.8],
      [-1.1, 4.7],
      [-2.8, 5.3],
      [-3.9, 5.2],
      [1, 3.4],
      [-1.9, 5.2],
      [1.5, 5.4],
      [1.3, 2.2],
      [-0.8, 1.5],
      [0.4, 9],
      [2.5, 6.5],
      [-0.8, 3.5],
      [1, 1],
      [4.6, 0.7],
      [1.3, 1.7],
      [2.8, 0.3],
      [-0.1, 1.9],
      [2.2, 0.7],
      [2.1, 3.7],
      [-0.3, 3.2],
      [6.3, -0.5],
      [7, -0.7],
      [-1, 1.3],
      [7.1, 3.1],
      [10.7, 4.4],
      [10.7, 0],
      [4.3, 0],
      [0.8, -2.6],
      [9.3, 0],
      [1.3, 2.2],
      [2.1, 2],
      [2.4, 2.8],
      [0.8, 3.3],
      [0.4, 3.5],
      [2.2, 1.9],
      [4, 1.9],
      [4.8, -5],
      [4.4, -0.1],
      [3.1, 2.5],
      [1.6, 4.4],
      [1, 3.7],
      [2.4, 3.6],
      [0.2, 4.5],
      [0.8, 3],
      [3.9, 2],
      [3.6, 1.4],
      [2.1, -0.2],
      [-0.6, -2.2],
      [0.4, -3.1],
      [1, -4.4],
      [1.9, -2.8],
      [3.7, -3.1],
      [6, -2.7],
      [6.1, -4.7],
      [4.9, -1.5],
      [3.5, -0.4],
      [3.5, 1.4],
      [4.9, -0.8],
      [3.3, 3.4],
      [3.8, 0.2],
      [2.4, -1.2],
      [1.7, 0.9],
      [1.3, -0.8],
      [-0.9, -1.3],
      [0.7, -2.5],
      [-0.5, -1.7],
      [2.4, -1],
      [4.2, -0.4],
      [4.7, 0.7],
      [6.2, -0.8],
      [3, 1.5],
      [2, 3],
      [0.9, 0.3],
      [6.1, -2.9],
      [1.9, 1],
      [3, 5.3],
      [0.8, 3.5],
      [-2, 4.2],
      [0.4, 2.5],
      [1.6, 4.9],
      [2, 5.5],
      [1.8, 1.4],
      [0.4, 2.8],
      [2.6, 0.8],
      [1.7, -0.8],
      [2, -3.9],
      [0.7, -2.5],
      [0.9, -4.3],
      [-1.2, -7.4],
      [0.5, -2.7],
      [-1.5, -4.5],
      [-0.7, -5.4],
      [0.1, -4.4],
      [1.8, -4.5],
      [3.5, -3.8],
      [3.7, -3],
      [6.9, -4.1],
      [1.3, -2.2],
      [3.3, -2.3],
      [2.8, -0.4],
      [4.4, -3.8],
      [6, -1.9],
      [4.6, -4.8],
      [0.9, -6.5],
      [0.1, -2.2],
      [-1.4, -0.4],
      [1.5, -6.2],
      [-3, -2.1],
      [3.2, 1],
      [0, -4.1],
      [1.9, -2.7],
      [-1, 5.3],
      [2, 2.5],
      [-2.9, 4.4],
      [0.4, 0.2],
      [4.4, -5.1],
      [2.4, -2.5],
      [0.6, -2.5],
      [-0.9, -1.1],
      [-0.1, -3.5],
      [1.2, 1.6],
      [1.1, 0.4],
      [-0.1, 1.6],
      [5.2, -4.9],
      [2.5, -4.5],
      [-1.4, -0.3],
      [2.1, -1.8],
      [-0.4, 0.8],
      [3.3, 0],
      [7.8, -1.9],
      [-1.1, -1.2],
      [-7.9, 1.2],
      [4.8, -1.8],
      [3.1, -0.3],
      [2.4, -0.3],
      [4.1, -1.1],
      [2.4, 0.1],
      [3.8, -1],
      [1, -1.7],
      [-1.1, -1.4],
      [-0.2, 2.2],
      [-2.1, -0.1],
      [-0.6, -3.3],
      [1.1, -3.3],
      [1.4, -1.3],
      [3.9, -3.7],
      [5.9, -1.8],
      [6, -2.1],
      [6.3, -3],
      [-0.2, -2],
      [-2.1, -3.5],
      [2.8, -8.5],
      [-1.5, -1.8],
      [-3.7, 1.1],
      [-1.1, -1.7],
      [-5.5, 4.7],
      [-3.2, 4.9],
      [-2.7, 2.8],
      [-2.5, 0.9],
      [-1.7, 0.3],
      [-1, 1.6],
      [-9.3, 0],
      [-7.7, 0],
      [-2.7, 1.2],
      [-6.7, 4.2],
      [0.2, 0.9],
      [-0.6, 2.4],
      [-4.6, 2],
      [-3.9, -0.5],
      [-4, -0.2],
      [-2.6, 0.7],
      [-0.3, 1.8],
      [0, 0],
      [-0.1, 0.6],
      [-5.8, 3.7],
      [-4.5, 1.8],
      [-2.9, 0.8],
      [-3.7, 1.7],
      [-4, 0.9],
      [-2.5, -0.3],
      [-2.7, -1.3],
      [2.7, -2.4],
      [0, 0],
      [2, -2.2],
      [3.7, -3.4],
      [0, 0],
      [0, 0],
      [0.7, -2.5],
      [0.5, -3.5],
      [-1.6, -0.7],
      [-4.3, 2.8],
      [-0.9, -0.1],
      [0.3, -1.5],
      [3.8, -2.5],
      [1.6, -2.8],
      [0.7, -2.8],
      [-2.7, -2.4],
      [-3.7, -1.3],
      [-1.7, 2.4],
      [-1.4, 0.6],
      [-2.2, 3.1],
      [0.4, -2.1],
      [-2.6, 1.5],
      [-2.1, 2],
      [-2.6, 3.1],
      [-1.3, 2.6],
      [0.1, 3.8],
      [-1.8, 4],
      [-3.3, 3],
      [-1.4, 0.9],
      [-1.6, 0.7],
      [-1.8, 0],
      [-0.3, -0.4],
      [-0.1, -3.3],
      [0.7, -1.6],
      [0.7, -1.5],
      [0.6, -3],
      [2.5, -3.5],
      [2.9, -4.3],
      [4.6, -4.7],
      [-0.7, 0],
      [-5.4, 4],
      [-0.4, -0.7],
      [2.9, -2.3],
      [4.7, -4],
      [3.7, -0.5],
      [4.4, -1.3],
      [3.7, 0.7],
      [0.1, 0],
      [4.7, -0.5],
      [-1.5, -2.5],
      [0, 0],
      [-1.2, -0.2],
      [0, 0],
      [0, 0],
      [-1.4, -0.3],
      [-0.4, -1.7],
      [-5.1, 0.5],
      [-5, 1.4],
      [-2.5, -2.3],
      [-2.5, -0.8],
      [3.1, -3.3],
      [-5.3, 2],
      [-4.9, 2.1],
      [-4.6, 1.5],
      [-2.1, -2.1],
      [-5.5, 1.3],
      [0.4, -0.9],
      [4.6, -2.6],
      [4.7, -2.5],
      [5.9, -2.1],
      [0, 0],
      [0, 0],
      [-5.3, -1.6],
      [-4.4, 0.8],
      [-3.8, -1.9],
      [-4.6, -1],
      [-3.2, -0.4],
      [-1, -1],
      [0.8, -3.4],
      'z',
      ['m', -240.6, -46.9],
      [6.9, -2.8],
      [0, -1.8],
      [-2.6, -0.4],
      [-3.4, 0.9],
      [-6.4, 2.1],
      [-2.2, 2.7],
      [0.7, 1.6],
      [7, -2.3],
      'z',
      ['m', -38.7, -16.4],
      [2.3, -2.3],
      [-2.9, -0.5],
      [-5.7, 1],
      [0.8, 1.6],
      [1.6, 1.1],
      [3.9, -0.9],
      'z',
      ['m', 1.2, -22.3],
      [-3.1, 2.2],
      [0.4, 0.5],
      [4.2, -0.4],
      [0.3, 1.1],
      [1.7, 1.2],
      [4.9, -1.2],
      [1.2, -0.6],
      [-3.3, -0.8],
      [-1.6, -1.5],
      [-3.4, 0.6],
      [-1.3, -1.1],
      'z',
      ['m', 124.9, -40.2],
      [-4.4, -1.1],
      [-10.2, 2.8],
      [-3.2, -0.3],
      [-11, 2.3],
      [-4.8, 0.6],
      [-7.8, 2.5],
      [-4.8, 2.6],
      [-8.6, 2.5],
      [-7.6, 0.1],
      [-6.3, 2.9],
      [3.2, 1.7],
      [0.7, 2.3],
      [-0.8, 2.7],
      [2.3, 2.1],
      [-1.2, 3.5],
      [-9.2, 0.2],
      [4.3, -2.8],
      [-3.4, 0],
      [-13.1, 2.7],
      [-9.1, 2.3],
      [1, 3.3],
      [-1.2, 2.2],
      [4.5, 1.4],
      [6.9, -0.7],
      [1.8, 1.3],
      [2.9, -1.3],
      [6.1, -1.2],
      [2.7, 0],
      [-5.9, 2.1],
      [1.1, 1],
      [-2.5, 2.6],
      [-5.5, 1.8],
      [-2.5, -0.5],
      [-7, 2.7],
      [-1.8, -0.9],
      [-4.1, 0.4],
      [-5.3, 3],
      [-7.6, 3.1],
      [-5.8, 3.4],
      [0.3, 2.4],
      [-4, 3.3],
      [1.4, 1.4],
      [0.5, 2.7],
      [7.2, -1.1],
      [0.4, 2.1],
      [-3.3, 2.1],
      [-3.6, 3.5],
      [2.8, 0],
      [7.2, -2.3],
      [-1.6, 2.9],
      [3.6, -2.1],
      [-0.4, 3],
      [4.8, -2.2],
      [0.4, 1.1],
      [7.2, -1.8],
      [-6.2, 3.4],
      [-5.7, 4.5],
      [-5.7, 2.1],
      [-2.3, 1.2],
      [-10.3, 3.6],
      [-4.9, 2.4],
      [-6.5, 0.7],
      [-8.5, 3.3],
      [-6.6, 1.8],
      [-8.1, 2.8],
      [-0.4, 1],
      [10, -1.7],
      [6, -2],
      [6.9, -2],
      [6.1, -1.7],
      [2.8, 0.5],
      [8.1, -2.6],
      [4.5, -2.8],
      [10.5, -3.1],
      [3.9, -2.6],
      [6.6, -1.8],
      [7.6, -2.5],
      [8.9, -4.2],
      [-0.2, -2.9],
      [11.1, -4.1],
      [7.4, -3.9],
      [9.2, -3.2],
      [-0.4, 1.4],
      [-6.7, 1.8],
      [-8.3, 5.7],
      [-3.2, 3.5],
      [6.4, -1.3],
      [6.1, -1.9],
      [6.5, -1.3],
      [2.9, -0.3],
      [3.5, -4.1],
      [6.3, -1.2],
      [2.6, 2.5],
      [6, 2.7],
      [6.7, -0.5],
      [5.7, 2],
      [3.2, 1.1],
      [3.3, 6.1],
      [3.7, 1.7],
      [7.1, 0.2],
      [4.1, 0.4],
      [-2.7, 5.5],
      [1.6, 4.9],
      [-3.3, 5.2],
      [2.5, 1.9],
      [0.6, 2.2],
      [0, 0],
      [5.1, -2.9],
      [3.1, -3.7],
      [-4.6, -3.8],
      [1.5, -6.8],
      [1.1, -4.2],
      [-1.7, -2.7],
      [-0.7, -2.4],
      [0.5, -3],
      [-6.4, 1.9],
      [-7.6, 3.3],
      [-0.2, -3.9],
      [-0.6, -2.6],
      [-2.7, -1.6],
      [-4.2, -0.1],
      [35.4, -32.4],
      [24.3, -20.2],
      [0, 0],
      [0, 0],
      [-3.5, -0.7],
      [-4.1, -1.6],
      [-6.5, 0.8],
      [-2.2, -0.7],
      [-7.1, -0.5],
      [-6.2, -1.6],
      [-4.8, 0.5],
      [-4.9, -0.9],
      [2, -1.2],
      [-6.3, -0.3],
      [-3.3, 1],
      [0.5, -2.4],
      'z',
    ],
  },
  {
    name: 'Uzbekistan',
    vertexPoint: [
      ['m', 1339.8, 303.1],
      [-2.5, 1.2],
      [-5.4, 4.3],
      [-0.9, 4.5],
      [-1.9, 0],
      [-2.3, -3],
      [-6.6, -0.2],
      [-2.6, -5],
      [-2.5, -0.1],
      [-1.5, -6.2],
      [-7.5, -4.5],
      [-8.6, 0.5],
      [-5.7, 0.9],
      [-6.5, -5.5],
      [-4.8, -2.3],
      [-9.1, -4.5],
      [-1.1, -0.5],
      [-11.9, 3.6],
      [6.2, 22.8],
      [5.8, -0.1],
      [-1.6, -3.1],
      [3.8, -2.2],
      [3.3, -3.6],
      [7.8, 3.3],
      [1.9, 4.9],
      [2.3, 1.3],
      [5.5, -0.3],
      [2, 1.2],
      [4.3, 6.4],
      [7, 4.4],
      [4.2, 3],
      [6.2, 3.2],
      [7.7, 2.7],
      [0.8, 4],
      [2.9, 0],
      [4.3, 1.4],
      [1.3, -6.6],
      [-2.4, -4.7],
      [-4.2, -1.6],
      [0.6, -2.8],
      [4.4, 0.3],
      [1.5, -3.5],
      [0.5, -4],
      [6.4, -1.5],
      [-0.2, 2.9],
      [1.3, 1.8],
      [2.1, -0.2],
      [4.1, 0.6],
      [5.2, -4.5],
      [-7.1, -3.3],
      [-3.2, 1.6],
      [-4.6, -2.3],
      [3.1, -4.1],
      [-1.8, -0.6],
      'z',
    ],
  },
  {
    name: 'South Africa',
    vertexPoint: [
      ['m', 1148.2, 713.7],
      [-2.9, -0.6],
      [-1.9, 0.8],
      [-2.6, -1.1],
      [-2.2, -0.1],
      [-8, 4.7],
      [-5.2, 4.7],
      [-2, 4.3],
      [-1.7, 2.4],
      [-3, 0.5],
      [-1.2, 3],
      [-0.6, 2],
      [-3.6, 1.5],
      [-4.4, -0.3],
      [-2.5, -1.8],
      [-2.3, -0.8],
      [-2.7, 1.5],
      [-1.5, 3.1],
      [-2.7, 1.9],
      [-2.8, 2.8],
      [-4, 0.7],
      [-1.1, -2.3],
      [0.7, -3.8],
      [-3, -6.1],
      [-1.4, -1],
      [-1.1, 23.6],
      [-5, 3.2],
      [-2.9, 0.5],
      [-3.3, -1.2],
      [-2.4, -0.5],
      [-0.8, -2.7],
      [-2.1, -1.8],
      [-2.7, 3.2],
      [3.5, 8.2],
      [0, 0.1],
      [2.5, 5.3],
      [3.2, 6],
      [-0.2, 4.8],
      [-1.7, 1.2],
      [1.4, 4.2],
      [-0.2, 3.8],
      [0.6, 1.7],
      [0.3, -0.9],
      [2.1, 2.9],
      [1.8, 0.1],
      [2.1, 2.3],
      [2.4, -0.2],
      [3.5, -2.4],
      [4.6, -1],
      [5.6, -2.5],
      [2.2, 0.3],
      [3.3, -0.8],
      [5.7, 1.2],
      [2.7, -1.2],
      [3.2, 1],
      [0.8, -1.8],
      [2.7, -0.3],
      [5.8, -2.5],
      [4.3, -2.9],
      [4.1, -3.8],
      [6.7, -6.5],
      [3.4, -4.6],
      [1.8, -3.2],
      [2.5, -3.3],
      [1.2, -0.9],
      [3.9, -3.2],
      [1.6, -2.9],
      [1.1, -5.2],
      [1.7, -4.7],
      [-4.1, 0],
      [-1.3, 2.8],
      [-3.3, 0.7],
      [-3, -3.5],
      [0.1, -2.2],
      [1.6, -2.4],
      [0.7, -1.8],
      [1.6, -0.5],
      [2.7, 1.2],
      [-0.4, -2.3],
      [1.4, -7.1],
      [-1.1, -4.5],
      [-2.2, -9],
      'z',
      ['m', -20.1, 52.8],
      [-2, 0.6],
      [-3.7, -4.9],
      [3.2, -4],
      [3.1, -2.5],
      [2.6, -1.3],
      [2.3, 2],
      [1.7, 1.9],
      [-1.9, 3.1],
      [-1.1, 2.1],
      [-3.1, 1],
      [-1.1, 2],
      'z',
    ],
  },
  {
    name: 'Somalia',
    vertexPoint: [
      ['m', 1223.4, 505.7],
      [-2.6, -2.7],
      [-1.2, -2.6],
      [-1.8, -1.2],
      [-2, 3.4],
      [-1.1, 2.3],
      [2.2, 3.5],
      [2.1, 3.1],
      [2.2, 2.2],
      [18.5, 7.6],
      [4.8, -0.1],
      [-15.4, 19.1],
      [-7.4, 0.3],
      [-4.9, 4.5],
      [-3.6, 0.1],
      [-1.5, 2],
      [-4.8, 7.2],
      [0.2, 23.2],
      [3.3, 5.3],
      [1.3, -1.5],
      [1.3, -3.4],
      [6.1, -7.7],
      [5.3, -4.8],
      [8.3, -6.4],
      [5.6, -5.1],
      [6.4, -8.7],
      [4.7, -7.1],
      [4.6, -9.3],
      [3.2, -8.2],
      [2.5, -7.1],
      [1.3, -6.8],
      [1.1, -2.3],
      [-0.2, -3.4],
      [0.4, -3.7],
      [-0.2, -1.7],
      [-2.1, 0],
      [-2.6, 2.2],
      [-2.9, 0.6],
      [-2.5, 0.9],
      [-1.8, 0.2],
      [0, 0],
      [-3.2, 0.2],
      [-1.9, 1.1],
      [-2.8, 0.5],
      [-4.8, 1.9],
      [-6.1, 0.8],
      [-5.2, 1.6],
      [-2.8, 0],
      'z',
    ],
  },
  {
    name: 'French Guiana',
    vertexPoint: [
      ['m', 681.4, 556.2],
      [1.8, -4.7],
      [3.5, -5.8],
      [-0.9, -2.6],
      [-5.8, -5.4],
      [-4.1, -1.5],
      [-1.9, -0.7],
      [-3.1, 5.5],
      [0.4, 4.4],
      [2.1, 3.7],
      [-1, 2.7],
      [-0.6, 2.9],
      [-1.4, 2.8],
      [2.4, 1.3],
      [1.8, -1.8],
      [1.2, 0.3],
      [0.8, 1.8],
      [2.7, -0.5],
      [2.1, -2.4],
      'z',
    ],
  },
  {
    name: 'France',
    vertexPoint: [
      ['m', 1025.7, 303.8],
      [-1.1, -5.2],
      [-3.2, 2.3],
      [-1, 2.3],
      [1.4, 4.2],
      [2.4, 1.2],
      [1.5, -4.8],
      'z',
      ['m', -31.5, -50.9],
      [-2.4, -2.4],
      [-2.2, -0.1],
      [-0.7, -2.2],
      [-4.3, 1.2],
      [-1.4, 5.1],
      [-11.3, 4.8],
      [-4.6, -2.6],
      [1.4, 7],
      [-8.2, -1.6],
      [-6.4, 1.3],
      [0.4, 4.6],
      [7.5, 2.4],
      [3.6, 3.1],
      [5.1, 6.5],
      [-1, 12.3],
      [-2.7, 3.7],
      [2, 2.4],
      [9.4, 2.8],
      [1.9, -1.3],
      [5.7, 2.8],
      [6, -0.8],
      [0.5, -3.7],
      [7.4, -2],
      [10, 1.6],
      [4.5, -3.4],
      [0.5, -2.7],
      [-2.7, -0.8],
      [-1.5, -4.8],
      [1.7, -1.8],
      [-1.6, -2.4],
      [0.2, -1.7],
      [-1.8, -2.7],
      [-2.4, 0.9],
      [0, -2.8],
      [3.5, -3.5],
      [-0.2, -1.6],
      [2.3, 0.6],
      [1.3, -1],
      [0.5, -4.5],
      [2.3, -4.2],
      [-7.1, -1.2],
      [-2.4, -1.6],
      [-1.4, 0.1],
      [-1.1, -0.5],
      [-4.4, -2.8],
      [-2.5, 0.4],
      [-3.4, -2.9],
      'z',
    ],
  },
  {
    name: 'Spain',
    vertexPoint: [
      ['m', 967, 296],
      [0, -0.2],
      [-0.5, 0],
      [-0.3, -0.4],
      [-0.1, 0.2],
      [-0.1, 0.2],
      [0, 0.2],
      [0.5, 0],
      [0.4, 0.1],
      [0.1, -0.1],
      'z',
      ['m', -0.8, -1.6],
      [0.3, 0],
      [0.6, -0.7],
      [0, -0.3],
      [-0.3, -0.2],
      [-1.1, 0.2],
      [-0.2, 0.3],
      [0, 0.3],
      [-0.3, 0.1],
      [-0.1, 0.4],
      [0.1, 0.2],
      [0.8, 0.1],
      [0.2, -0.4],
      [-8.2, -0.2],
      [-4.2, 0.3],
      [-5.4, -1],
      [-6.8, 0],
      [-6.2, -1.1],
      [-7.4, 4.5],
      [2, 2.6],
      [-0.4, 4.4],
      [1.9, -1.6],
      [2.1, -0.9],
      [1.2, 3.1],
      [3, 0],
      [0.9, -0.8],
      [3, 0.2],
      [1.3, 3.1],
      [-2.4, 1.7],
      [-0.2, 4.9],
      [-0.9, 0.9],
      [-0.3, 3],
      [-2.2, 0.5],
      [2, 3.8],
      [-1.6, 4.3],
      [1.8, 1.9],
      [-0.8, 1.7],
      [-2, 2.5],
      [0.4, 2.1],
      [4.8, 1],
      [1.4, 3.7],
      [2, 2.2],
      [2.5, 0.6],
      [2.1, -2.5],
      [3.3, -2.3],
      [5, 0.1],
      [6.7, 0],
      [3.8, -5],
      [3.9, -1.3],
      [1.2, -4.2],
      [3, -2.9],
      [-2, -3.7],
      [2, -5.1],
      [3.1, -3.5],
      [0.5, -2.1],
      [6.6, -1.3],
      [4.8, -4.2],
      [-0.3, -3.5],
      [-6, 0.8],
      [-5.7, -2.8],
      [-1.9, 1.3],
      [-9.4, -2.8],
      [-2, -2.4],
      'z',
      ['m', 26, 22.6],
      [0.1, -0.3],
      [0.1, -0.2],
      [0.1, -0.1],
      [-0.2, -0.2],
      [0, -0.1],
      [0.2, -0.2],
      [-0.2, -0.1],
      [-1.3, 0.4],
      [-0.7, 0.4],
      [-2.1, 1.5],
      [0, 0.3],
      [0.1, 0.2],
      [0.4, 0],
      [0.2, 0.4],
      [0.4, -0.4],
      [0.3, -0.1],
      [0.3, 0.1],
      [0.3, 0.2],
      [0.1, 0.6],
      [0.1, 0.2],
      [0.6, 0.1],
      [0.9, 0.4],
      [0.4, -0.2],
      [0.5, -0.3],
      [0.2, -0.6],
      [0.3, -0.5],
      [0.3, -0.5],
      [0.3, -0.4],
      [-0.1, -0.4],
      [-0.3, -0.1],
      [-0.3, -0.1],
      [-0.5, 0.2],
      [-0.5, -0.2],
      'z',
      ['m', 6, -0.3],
      [0.1, -0.4],
      [0, -0.1],
      [-0.5, -0.7],
      [-0.9, -0.3],
      [-1, 0.1],
      [-0.1, 0.1],
      [0, 0.4],
      [0.1, 0.1],
      [0.6, 0.1],
      [1.6, 0.7],
      [0.1, 0],
      'z',
    ],
  },
  {
    name: 'Falkland Is.',
    vertexPoint: [
      ['m', 690.3, 902.7],
      [-0.1, -0.3],
      [-0.4, -0.2],
      [-0.2, -0.1],
      [0.1, 0.2],
      [0.1, 0.3],
      [0.1, 0.2],
      [0.2, 0.1],
      [0.2, -0.2],
      'z',
      ['m', 5.5, -1.3],
      [-0.1, -0.1],
      [-0.2, 0],
      [-0.1, 0.2],
      [0.2, 0.3],
      [0.4, 0.1],
      [-0.2, -0.5],
      'z',
      ['m', -12.9, -1.4],
      [-0.1, 0.2],
      [-0.4, 0.1],
      [0.2, 0.3],
      [0.6, 0.4],
      [0.4, 0],
      [0.1, -0.3],
      [-0.1, -0.6],
      [-0.3, 0],
      [-0.4, -0.1],
      'z',
      ['m', 2.8, -2],
      [-0.9, -0.3],
      [-0.4, -0.3],
      [-0.3, 0],
      [0.4, 0.4],
      [0.1, 0.2],
      [0.1, 0.2],
      [0.6, 0.3],
      [0.6, 0.3],
      [0.4, 0.3],
      [-0.1, 0.1],
      [-0.8, 0.3],
      [-0.3, 0],
      [-0.2, 0.1],
      [0.4, 0.2],
      [0.6, -0.1],
      [0.2, -0.1],
      [0.2, 0],
      [0.3, 0.1],
      [0, 0.2],
      [-0.1, 0.2],
      [-0.2, 0.2],
      [-0.4, 0.3],
      [-0.6, 0.4],
      [-0.8, 0],
      [-0.7, 0.7],
      [0.9, 0.5],
      [0.7, 0.3],
      [0.9, 0],
      [0, -0.1],
      [0.2, -0.1],
      [0.3, 0],
      [0.1, -0.1],
      [0.2, -0.4],
      [0, -0.6],
      [0.2, 0],
      [0.3, 0.1],
      [0.7, -0.1],
      [0.3, -0.1],
      [0.6, -0.9],
      [0.4, -0.8],
      [0.2, -0.4],
      [0.3, -0.2],
      [0.1, -0.2],
      [0.1, -0.3],
      [0.3, -0.2],
      [0, -0.3],
      [-0.4, -0.2],
      [-0.3, -0.2],
      [-0.3, 0.3],
      [-0.2, -0.1],
      [-0.9, 0.3],
      [-0.4, 0],
      [-0.3, -0.2],
      [-0.4, -0.1],
      [-0.4, 0.1],
      [-0.5, 0.5],
      [-0.8, -0.2],
      'z',
      ['m', 0.7, -0.4],
      [0.1, -0.3],
      [-0.1, -0.2],
      [-0.5, -0.2],
      [-0.5, 0],
      [0.2, 0.5],
      [0.2, 0.2],
      [0.6, 0],
      'z',
      ['m', 5.9, -0.7],
      [-0.4, 0],
      [0.4, 0.5],
      [-0.8, 0.8],
      [0.2, 0.6],
      [0.3, 0.4],
      [0.1, 0.2],
      [-0.1, 0.1],
      [-0.4, 0.1],
      [-0.3, 0.1],
      [-0.2, 0.3],
      [-0.9, 0.9],
      [0.2, 0.2],
      [-0.3, 0.7],
      [0.2, 0.3],
      [0.8, 0.7],
      [0.8, 0.4],
      [0, -0.7],
      [0.4, -0.1],
      [0.4, 0.2],
      [0.4, -0.2],
      [-0.9, -1],
      [0.3, 0],
      [2.5, 0.5],
      [-0.1, -0.4],
      [-0.1, -0.2],
      [-0.3, -0.4],
      [1.5, -0.4],
      [0.5, -0.3],
      [0.2, -0.3],
      [0.6, -0.1],
      [0.8, -0.3],
      [-0.1, -0.1],
      [0.1, -0.3],
      [-0.4, -0.2],
      [-0.5, -0.1],
      [0.1, -0.3],
      [0.5, -0.1],
      [-0.8, -0.7],
      [-0.3, -0.1],
      [-1, 0.1],
      [-0.3, 0.1],
      [0, 0.2],
      [0.1, 0.3],
      [0.3, 0.3],
      [0.1, 0.2],
      [-0.2, -0.1],
      [-1.1, -0.4],
      [-0.2, -0.1],
      [-0.2, -0.4],
      [0.2, -0.1],
      [0.3, 0.1],
      [0.1, -0.3],
      [-0.4, -0.3],
      [-0.4, -0.1],
      [-0.9, 0.1],
      [-0.8, -0.3],
      'z',
    ],
  },
  {
    name: 'Faeroe Is.',
    vertexPoint: [
      ['m', 947, 186.9],
      [0, -0.3],
      [-0.1, -0.3],
      [0, -0.2],
      [-0.1, 0],
      [-0.5, -0.1],
      [-0.1, -0.2],
      [-0.1, 0],
      [0, 0.2],
      [0.1, 0.4],
      [0.5, 0.4],
      [0.3, 0.2],
      [0.1, 0],
      [-0.1, -0.1],
      'z',
      ['m', 0.5, -2.1],
      [0, -0.1],
      [-0.2, -0.2],
      [-0.5, -0.2],
      [-0.2, -0.1],
      [-0.2, 0.1],
      [0, 0.2],
      [0.1, 0.1],
      [0.4, 0.1],
      [0.4, 0.3],
      [0.1, 0],
      [0.1, -0.2],
      'z',
      ['m', -2.4, -1.9],
      [-0.2, -0.1],
      [-0.5, 0.1],
      [-0.3, 0],
      [0.1, 0.3],
      [0.6, 0.2],
      [0.3, 0],
      [0.3, 0],
      [0.2, -0.1],
      [-0.1, -0.2],
      [-0.4, -0.2],
      'z',
      ['m', 2.5, -0.5],
      [-0.8, -0.2],
      [-0.6, -0.3],
      [-1, 0.1],
      [0.7, 1.1],
      [0.8, 0.7],
      [0.4, 0.2],
      [0, -0.1],
      [0, -0.2],
      [-0.4, -0.5],
      [-0.1, -0.1],
      [0, -0.1],
      [0.1, -0.1],
      [0.2, 0],
      [0.3, 0.2],
      [0.2, 0],
      [0.2, -0.7],
      'z',
      ['m', 1, -0.2],
      [-0.3, -0.2],
      [-0.4, -0.4],
      [0, 0.5],
      [0, 0.3],
      [0, 0.1],
      [0.1, 0],
      [0.3, 0.1],
      [0.3, -0.4],
      'z',
    ],
  },
  {
    name: 'Grenada',
    vertexPoint: [
      ['m', 632.1, 495.7],
      [0.5, -0.2],
      [0.2, -1.1],
      [-0.3, -0.1],
      [-0.3, 0.3],
      [-0.3, 0.5],
      [0, 0.4],
      [-0.2, 0.3],
      [0.4, -0.1],
      'z',
    ],
  },
  {
    name: 'Hong Kong',
    vertexPoint: [
      ['m', 1604.9, 430.9],
      [0, -0.2],
      [0, -0.2],
      [-0.4, -0.2],
      [-0.3, 0],
      [0.1, 0.2],
      [0.4, 0.5],
      [0.2, -0.1],
      'z',
      ['m', -1.3, 0],
      [-0.1, -0.5],
      [0.2, -0.3],
      [-0.9, 0.3],
      [-0.1, 0.3],
      [0, 0.1],
      [0.2, 0.1],
      [0.7, 0],
      'z',
      ['m', 1.6, -1.2],
      [-0.1, -0.3],
      [-0.2, -0.1],
      [-0.1, -0.3],
      [-0.1, -0.2],
      [0, 0],
      [-0.3, -0.1],
      [-0.2, -0.1],
      [-0.4, 0],
      [-0.1, 0.1],
      [-0.2, 0],
      [-0.2, 0.2],
      [0, 0],
      [0, 0.2],
      [-0.5, 0.4],
      [0, 0.2],
      [0.3, 0.2],
      [0.5, -0.1],
      [0.6, 0.2],
      [0.8, 0.3],
      [0, -0.2],
      [0, -0.3],
      [0.2, -0.1],
      'z',
    ],
  },
  {
    name: 'St. Kitts and Nevis',
    vertexPoint: [
      ['m', 629.9, 463.2],
      [0, -0.3],
      [-0.2, -0.2],
      [-0.3, 0],
      [0, 0.5],
      [0.2, 0.2],
      [0.3, -0.2],
      'z',
      ['m', -0.5, -0.7],
      [-0.1, -0.2],
      [-0.1, -0.1],
      [-0.2, -0.4],
      [-0.4, -0.4],
      [-0.2, 0.1],
      [-0.1, 0.2],
      [0, 0.1],
      [0, 0],
      [0.3, 0.3],
      [0.4, 0.1],
      [0.2, 0.4],
      [0.2, -0.1],
      'z',
    ],
  },
  {
    name: 'St. Lucia',
    vertexPoint: [
      ['m', 637.4, 484.2],
      [0.1, -1.2],
      [-0.1, -0.5],
      [-0.2, 0.1],
      [-0.3, 0.4],
      [-0.4, 0.6],
      [-0.1, 0.3],
      [0, 0.6],
      [0.6, 0.4],
      [0.4, -0.7],
      'z',
    ],
  },
  {
    name: 'Liechtenstein',
    vertexPoint: [
      ['m', 1024.4, 273.6],
      [0, -0.2],
      [0.1, -0.2],
      [-0.1, -0.1],
      [-0.1, -0.2],
      [-0.1, -0.1],
      [0, -0.2],
      [-0.1, -0.1],
      [0, -0.2],
      [-0.1, -0.1],
      [-0.2, 0.6],
      [0, 0.5],
      [0.1, 0.2],
      [0.1, 0],
      [0.4, 0.1],
      'z',
    ],
  },
  {
    name: 'Maldives',
    vertexPoint: [
      ['m', 1389.1, 551.6],
      [0.1, -0.1],
      [0, -0.2],
      [-0.1, -0.1],
      [-0.1, 0],
      [-0.1, 0.2],
      [0, 0.1],
      [0, 0.1],
      [0.2, 0],
      'z',
      ['m', 0.3, -5.9],
      [0.1, -0.2],
      [0, -0.1],
      [0, -0.1],
      [0, -0.1],
      [0, -0.1],
      [-0.1, 0.1],
      [-0.1, 0.2],
      [0, 0.1],
      [-0.1, 0.1],
      [0, 0.1],
      [0.1, 0],
      [0.1, 0],
      'z',
    ],
  },
  {
    name: 'Malta',
    vertexPoint: [
      ['m', 1053.6, 344],
      [-0.2, -0.2],
      [-0.5, -0.5],
      [-0.5, -0.1],
      [0.1, 0.6],
      [0.4, 0.4],
      [0.5, 0],
      [0.2, -0.2],
      'z',
      ['m', -1.4, -1.2],
      [0, 0],
      [0, -0.2],
      [-0.3, -0.1],
      [-0.4, 0.1],
      [0.1, 0.1],
      [0.3, 0.2],
      [0.3, -0.1],
      'z',
    ],
  },
  {
    name: 'Montserrat',
    vertexPoint: [
      ['m', 631.8, 465.7],
      [-0.1, -0.5],
      [-0.1, 0],
      [-0.2, 0.4],
      [0, 0.3],
      [0.3, 0.1],
      [0.1, -0.3],
      'z',
    ],
  },
  {
    name: 'Mauritius',
    vertexPoint: [
      ['m', 1294.7, 702.5],
      [0.3, -0.3],
      [0.2, -0.4],
      [0.3, -0.3],
      [0.1, -0.7],
      [-0.2, -0.8],
      [-0.4, -0.7],
      [-0.5, 0.1],
      [-0.3, 0.4],
      [-0.2, 0.5],
      [-0.5, 0.3],
      [-0.1, 0.3],
      [-0.2, 0.7],
      [-0.1, 0.4],
      [-0.2, 0.1],
      [0, 0.2],
      [0.3, 0.3],
      [0.8, 0.1],
      [0.7, -0.2],
      'z',
    ],
  },
  {
    name: 'New Caledonia',
    vertexPoint: [
      ['m', 1897.3, 716.1],
      [0, -0.3],
      [-0.4, -0.2],
      [-0.2, 0.5],
      [0, 0.1],
      [0.2, 0.1],
      [0.2, 0],
      [0.2, -0.2],
      'z',
      ['m', 4.6, -7.6],
      [-0.1, -0.1],
      [0, -0.3],
      [0.1, -0.2],
      [-0.4, 0.2],
      [-0.6, 0.2],
      [0.1, 0.8],
      [-0.1, 0.4],
      [0.3, 0.1],
      [0.1, 0.3],
      [0.2, 0],
      [0.7, -0.2],
      [0.3, -1.1],
      [-0.4, 0],
      [-0.2, -0.1],
      'z',
      ['m', -3, -1.7],
      [0.3, -0.5],
      [0.1, -0.2],
      [-0.2, -0.7],
      [-0.3, -0.3],
      [0.3, -1],
      [-0.1, -0.2],
      [-0.4, -0.2],
      [-0.9, 0.3],
      [-0.1, 0.2],
      [0.5, 0.1],
      [0.2, 0.2],
      [-0.5, 0.7],
      [-0.5, 0.1],
      [0.1, 0.5],
      [0.2, 0.4],
      [0.7, 0.2],
      [0.3, 0.4],
      [0.3, 0],
      'z',
      ['m', -3.9, -2.9],
      [0.3, -0.3],
      [0.3, -0.4],
      [-0.1, -0.1],
      [0, -0.3],
      [0.2, -0.4],
      [0.3, -0.1],
      [-0.2, -0.2],
      [-0.2, -0.1],
      [0, 0.3],
      [-0.3, 0.7],
      [-0.1, 0.3],
      [-0.5, 0.6],
      [0.3, 0],
      'z',
      ['m', -12.3, -2.9],
      [-0.6, -0.7],
      [-0.1, 0.2],
      [-0.1, 0.4],
      [0, 0.3],
      [0.3, 0.2],
      [0.1, 0.2],
      [-0.1, 0.5],
      [0, 0.4],
      [0.6, 0.9],
      [0.1, 0.7],
      [0.3, 0.6],
      [0.5, 0.5],
      [0.4, 0.5],
      [0.8, 1.4],
      [0.2, 0.5],
      [0.4, 0.3],
      [1, 1.2],
      [0.4, 0.4],
      [0.4, 0.2],
      [0.9, 0.7],
      [0.6, 0.3],
      [0.3, 0.5],
      [0.6, 0.3],
      [0.8, 0.4],
      [0.1, 0.2],
      [0, 0.3],
      [0.1, 0.3],
      [0.5, 0.4],
      [0.6, 0.3],
      [0.1, 0.2],
      [0.1, 0.2],
      [0.3, -0.1],
      [0.3, 0.1],
      [0.9, 0.7],
      [0.4, -0.1],
      [0.3, 0],
      [0.5, -0.2],
      [0.3, -0.4],
      [-0.1, -1.1],
      [-0.5, -0.5],
      [-0.7, -0.4],
      [-0.4, -0.5],
      [-0.4, -0.5],
      [-0.8, -1],
      [-1.1, -1],
      [-0.5, -0.2],
      [-0.3, -0.4],
      [-0.3, -0.1],
      [-0.2, -0.3],
      [-0.5, -0.3],
      [-0.3, -0.6],
      [-0.6, -0.6],
      [-0.1, -0.3],
      [0.1, -0.3],
      [-0.1, -0.3],
      [-0.4, -0.3],
      [-0.2, -0.5],
      [-0.2, -0.3],
      [-0.4, -0.2],
      [-0.7, -0.4],
      [-1.6, -1.9],
      [-0.7, -0.6],
      [-0.7, 0.2],
      [-0.6, -0.4],
      'z',
      ['m', -22, -6],
      [0.2, -0.4],
      [0.1, -0.8],
      [-0.2, 0.4],
      [-0.2, 1],
      [0.1, -0.2],
      'z',
    ],
  },
  {
    name: 'Nauru',
    vertexPoint: [
      ['m', 1915, 575.5],
      [0, -0.2],
      [-0.1, 0],
      [-0.1, 0],
      [-0.1, 0.2],
      [0.1, 0.1],
      [0.1, 0.1],
      [0.1, -0.2],
      'z',
    ],
  },
  {
    name: 'Pitcairn Is.',
    vertexPoint: [
      ['m', 274.2, 727.4],
      [0, -0.2],
      [-0.1, -0.2],
      [-0.2, -0.1],
      [-0.1, 0.1],
      [0.1, 0.2],
      [0.2, 0.2],
      [0.1, 0.1],
      [0, -0.1],
      'z',
    ],
  },
  {
    name: 'Puerto Rico',
    vertexPoint: [
      ['m', 600.8, 457.3],
      [0, -0.1],
      [0, 0],
      [0.1, 0],
      [0, -0.1],
      [0.1, -0.1],
      [0, 0],
      [0, -0.1],
      [-0.1, 0],
      [0, 0],
      [-0.3, 0],
      [-0.1, 0],
      [0, 0.1],
      [0, 0.1],
      [0.2, 0.1],
      [0, 0],
      [0, 0.1],
      [0.1, 0],
      [0, 0],
      'z',
      ['m', 13.6, -0.3],
      [0.7, -0.2],
      [0, -0.1],
      [-0.4, -0.1],
      [-0.6, 0],
      [-0.5, 0.2],
      [0.1, 0.2],
      [0.2, 0],
      [0.5, 0],
      'z',
      ['m', -3.7, -2.2],
      [-0.1, -0.2],
      [-0.2, 0],
      [-3.5, -0.1],
      [-1.3, -0.2],
      [-0.3, 0.1],
      [-0.3, 0.1],
      [-0.1, 0.4],
      [-0.2, 0.2],
      [-0.3, 0.2],
      [0.1, 0.3],
      [0.1, 0.2],
      [0.2, 0.4],
      [-0.1, 0.5],
      [-0.2, 1],
      [0.3, 0.2],
      [0.7, -0.1],
      [0.3, 0.1],
      [0.3, 0.1],
      [0.4, -0.1],
      [0.4, -0.2],
      [0.9, 0.1],
      [0.5, -0.1],
      [0.6, 0.3],
      [0.4, -0.1],
      [0.2, 0.1],
      [0.3, 0],
      [0.6, 0],
      [0.9, -0.2],
      [0.8, -0.5],
      [0.3, -0.5],
      [0.4, -0.3],
      [0.6, -0.4],
      [0, -0.9],
      [-0.7, -0.1],
      [-0.6, -0.3],
      [-1.1, -0.1],
      [-0.1, 0],
      [0.1, 0.2],
      [-0.1, 0],
      [-0.2, -0.1],
      'z',
    ],
  },
  {
    name: 'Fr. Polynesia',
    vertexPoint: [
      ['m', 213.2, 704.9],
      [-0.1, -0.3],
      [-0.2, -0.3],
      [-0.1, 0.1],
      [0.1, 0.1],
      [0.2, 0.3],
      [0, 0.2],
      [0.1, -0.1],
      'z',
      ['m', 9.3, -14.7],
      [-0.2, -0.2],
      [-0.4, -0.2],
      [-0.2, -0.1],
      [-0.2, -0.1],
      [-0.1, 0.1],
      [0.1, 0.1],
      [0.1, 0],
      [0.3, 0.2],
      [0.3, 0.1],
      [0.2, 0.1],
      [0, 0.1],
      [0.1, -0.1],
      'z',
      ['m', -24.5, -1.1],
      [-0.6, -0.3],
      [0.1, 0.2],
      [0.4, 0.2],
      [0.2, 0.1],
      [-0.1, -0.2],
      'z',
      ['m', 20.5, -0.2],
      [-0.4, -0.5],
      [-0.3, 0],
      [0.7, 0.6],
      [0, -0.1],
      'z',
      ['m', -21.6, -1],
      [-0.4, -0.4],
      [-0.2, -0.3],
      [-0.3, -0.1],
      [0.1, 0.1],
      [0.4, 0.4],
      [0.3, 0.4],
      [0.2, 0.1],
      [-0.1, -0.2],
      'z',
      ['m', -0.3, -2.1],
      [-0.1, -0.1],
      [0, 0],
      [0, -0.3],
      [0.2, -0.3],
      [0.6, -0.4],
      [0, -0.1],
      [0, 0],
      [-0.2, 0.1],
      [-0.4, 0.2],
      [-0.2, 0.2],
      [-0.1, 0.2],
      [-0.1, 0.3],
      [0.1, 0.2],
      [0.1, 0.1],
      [0.2, 0],
      [-0.1, -0.1],
      'z',
      ['m', -47.4, -1.1],
      [-0.2, -0.6],
      [-0.3, -0.5],
      [-0.8, -0.1],
      [-0.5, 0.2],
      [-0.1, 0.2],
      [0.1, 0.4],
      [0.5, 0.7],
      [0.5, 0.1],
      [0.8, -0.1],
      [0.4, 0.6],
      [0.2, 0.1],
      [0.4, 0.1],
      [0.1, -0.3],
      [-0.2, -0.5],
      [-0.9, -0.3],
      'z',
      ['m', -2.9, -0.9],
      [0.1, -0.4],
      [-0.2, -0.1],
      [-0.5, 0],
      [0, 0.2],
      [0.1, 0.2],
      [0.1, 0.1],
      [0.3, 0.2],
      [0.1, -0.2],
      'z',
      ['m', -9.7, -4.3],
      [0.2, 0],
      [-0.4, -0.6],
      [-0.3, -0.2],
      [0, 0.1],
      [0, 0.7],
      [0.3, 0.1],
      [0.2, -0.1],
      'z',
      ['m', 43.9, -1.6],
      [-0.2, 0],
      [-0.3, 0],
      [-0.1, 0],
      [0.5, 0.1],
      [0.4, 0.2],
      [-0.3, -0.3],
      'z',
      ['m', -0.7, 0.1],
      [-0.3, -0.1],
      [-0.3, -0.2],
      [-0.3, 0],
      [0.7, 0.3],
      [0.2, 0],
      'z',
      ['m', -43.8, 0.1],
      [0.1, -0.2],
      [-0.1, -0.1],
      [-0.4, -0.2],
      [0.1, 0.3],
      [0, 0.2],
      [0.2, 0.1],
      [0.1, -0.1],
      'z',
      ['m', 32.8, -2],
      [-0.3, -0.4],
      [-0.2, -0.3],
      [-0.2, -0.4],
      [-0.4, -0.5],
      [0.1, 0.3],
      [0.1, 0.2],
      [0.2, 0.2],
      [0.2, 0.4],
      [0.1, 0.2],
      [0.3, 0.4],
      [0.1, 0],
      [0, -0.1],
      'z',
      ['m', 16.2, -1.5],
      [0.1, -0.5],
      [-0.2, 0],
      [0, 0.5],
      [0.1, 0],
      'z',
      ['m', -14.4, -1.6],
      [-0.6, -0.6],
      [-0.1, 0],
      [0.1, 0.2],
      [0.5, 0.5],
      [0.1, 0.2],
      [0, -0.3],
      'z',
      ['m', 30.8, -33.9],
      [0.1, -0.2],
      [0, -0.2],
      [-0.1, -0.1],
      [-0.3, -0.1],
      [0.1, 0.7],
      [0.2, -0.1],
      'z',
      ['m', -2.7, -3.7],
      [-0.1, -0.2],
      [-0.2, 0],
      [-0.1, 0.1],
      [0, 0.5],
      [0.4, -0.4],
      'z',
      ['m', 0.1, -1.6],
      [-0.8, 0.5],
      [0.2, 0.4],
      [0.4, 0.1],
      [0.2, -0.2],
      [0.8, -0.1],
      [0.3, -0.4],
      [-0.3, 0.1],
      [-0.8, -0.4],
      'z',
      ['m', -6.1, -1.7],
      [0.2, -0.5],
      [-0.2, -0.1],
      [-0.4, 0.2],
      [0, 0.2],
      [0.3, 0.4],
      [0.1, -0.2],
      'z',
      ['m', 2.6, -3.1],
      [0.3, -0.1],
      [0, -0.1],
      [-0.2, -0.2],
      [-0.3, -0.1],
      [-0.1, 0.1],
      [-0.1, 0.2],
      [0.1, 0.3],
      [0.3, -0.1],
      'z',
      ['m', -2.9, -0.1],
      [0.1, -0.3],
      [0, -0.2],
      [-0.1, -0.2],
      [-0.9, -0.2],
      [-0.1, 0.1],
      [0, 0.4],
      [0.2, 0.5],
      [0.3, 0],
      [0.5, -0.1],
      'z',
    ],
  },
  {
    name: 'Singapore',
    vertexPoint: [
      ['m', 1561, 563.7],
      [0.1, -0.2],
      [-0.2, -0.2],
      [-0.3, -0.1],
      [-0.5, -0.2],
      [-0.6, 0.1],
      [-0.3, 0.6],
      [0.9, 0.4],
      [0.9, -0.4],
      'z',
    ],
  },
  {
    name: 'Solomon Is.',
    vertexPoint: [
      ['m', 1909.1, 646.4],
      [-0.2, -0.2],
      [-0.1, -0.4],
      [-0.3, 0],
      [-0.3, 0.1],
      [0.2, 0.6],
      [0.2, 0],
      [0.5, -0.1],
      'z',
      ['m', -35.6, 0.8],
      [-0.1, -0.2],
      [-0.5, -0.4],
      [-1.9, -1.3],
      [-0.4, -0.1],
      [-0.1, 0.1],
      [-0.1, 0.3],
      [0.1, 0.2],
      [0.5, 0.1],
      [0, 0.1],
      [0.3, 0.2],
      [0.7, 0.2],
      [0.4, 0.3],
      [0.1, 0.5],
      [0.3, 0.1],
      [0.3, 0.1],
      [0.4, -0.2],
      'z',
      ['m', 32, -6.6],
      [0, -0.1],
      [0.2, -0.3],
      [-0.2, -0.1],
      [-0.5, -0.1],
      [-0.7, 0.1],
      [-0.3, 0.2],
      [-0.2, 0.3],
      [-0.2, 0],
      [0, 0.2],
      [0.1, 0.4],
      [0.2, -0.1],
      [0.2, 0.1],
      [0.5, -0.5],
      [0.3, 0],
      [0.1, 0],
      [0.5, -0.1],
      'z',
      ['m', -24.4, -2.3],
      [-0.1, -0.2],
      [-0.2, -0.1],
      [-0.9, -0.7],
      [-0.5, -0.2],
      [-0.5, 0],
      [-0.1, 0.5],
      [0, 0.3],
      [0.6, 0],
      [0.4, 0.2],
      [0, 0.6],
      [0.2, 0.2],
      [0, 0.5],
      [1.2, 0.9],
      [0.7, 0.4],
      [0.7, 0.1],
      [0.4, 0.2],
      [0.5, -0.1],
      [0.5, 0.2],
      [0.4, -0.1],
      [-0.4, -0.3],
      [0, -0.4],
      [-0.5, -1.3],
      [-0.3, -0.3],
      [-0.5, 0.1],
      [-0.5, -0.2],
      [-0.4, 0],
      [-0.7, -0.3],
      'z',
      ['m', -0.4, -4.9],
      [-0.6, -1.6],
      [-0.2, -0.1],
      [0.1, 0.6],
      [0.1, 0.4],
      [-0.1, 0.5],
      [-0.1, 0.6],
      [0.2, 0.2],
      [0.2, -0.2],
      [0.4, 0.5],
      [0, -0.2],
      [0, -0.7],
      'z',
      ['m', -9.8, -2.2],
      [-0.3, -0.1],
      [-0.4, 0.3],
      [-0.1, 0.3],
      [-0.1, 0.7],
      [0, 0.4],
      [0.3, 0.7],
      [0.3, 0.5],
      [0.3, 0.3],
      [0.2, 0.2],
      [0.9, 0.1],
      [1.7, 0.1],
      [0.9, 0.4],
      [0.9, 0.2],
      [0.4, -0.1],
      [0.5, -0.2],
      [0.1, -0.1],
      [-0.1, -0.6],
      [-0.2, -0.3],
      [-0.4, -0.2],
      [-0.2, -0.6],
      [-0.5, -0.4],
      [-0.9, -0.7],
      [-1.6, 0],
      [-0.6, 0.1],
      [-1.1, -1],
      'z',
      ['m', 2.6, -1.8],
      [-0.5, 0.2],
      [0, 0.3],
      [0.4, 0.1],
      [0.4, 0.2],
      [0.1, 0.3],
      [0, 0],
      [0.2, -0.1],
      [0.4, 0.2],
      [0.2, -0.3],
      [-0.4, -0.5],
      [-0.4, -0.3],
      [-0.1, 0],
      [-0.3, -0.1],
      'z',
      ['m', -5.6, 0.8],
      [0.3, -0.2],
      [0, -0.4],
      [-0.3, 0],
      [-0.1, -0.2],
      [-0.2, 0],
      [-0.3, 0.2],
      [-0.2, 0.3],
      [0.1, 0.2],
      [0.4, 0],
      [0.2, 0.1],
      [0.1, 0],
      'z',
      ['m', -8.4, -2.3],
      [-0.1, -0.2],
      [-0.3, -0.2],
      [-0.2, 0],
      [-0.5, 0.1],
      [0.1, 0.1],
      [0.6, 0.3],
      [0.3, 0.1],
      [0.1, -0.2],
      'z',
      ['m', 3.1, 0.4],
      [0.3, -0.2],
      [-0.1, -0.2],
      [-0.1, -0.5],
      [-0.4, 0.7],
      [0.1, 0.2],
      [0.2, 0],
      'z',
      ['m', -0.5, -0.9],
      [0, -0.2],
      [0, -0.2],
      [-0.2, -0.1],
      [0.4, -0.3],
      [-0.1, -0.1],
      [-0.6, -0.2],
      [-0.2, 0.2],
      [-0.2, 0.1],
      [-0.1, 0.1],
      [-0.1, 0.1],
      [-0.1, 0.5],
      [0.2, 0.4],
      [0.4, 0.2],
      [0.6, -0.5],
      'z',
      ['m', -4, 0.2],
      [-0.3, -0.4],
      [0.1, -0.5],
      [0.2, -0.1],
      [0.2, -0.5],
      [-0.1, -0.4],
      [-0.2, 0.1],
      [-0.7, 0.6],
      [-0.1, 0.3],
      [0.6, 0.8],
      [0.3, 0.2],
      [0, -0.1],
      'z',
      ['m', 13, -1.3],
      [-0.2, -0.4],
      [0, -0.2],
      [-0.3, -0.2],
      [-0.2, 0.1],
      [-0.1, 0.3],
      [0.1, 0.2],
      [0.4, 0.3],
      [0.3, -0.1],
      'z',
      ['m', 6, -1.2],
      [-0.2, 0],
      [-0.1, 0.1],
      [-0.2, 0],
      [-0.3, 0],
      [-0.1, 0.2],
      [0.6, 1.1],
      [-0.3, 0.5],
      [0.4, 2.2],
      [0.4, 1.2],
      [0.8, 0.8],
      [0, 0.2],
      [0.8, 0.5],
      [0.6, 1.3],
      [0.2, 0.1],
      [0.1, -0.2],
      [0, -0.6],
      [-0.5, -1.1],
      [0.1, -0.8],
      [-0.2, -0.3],
      [0, -0.3],
      [-0.2, -0.8],
      [-0.6, -0.7],
      [-0.3, -0.1],
      [-0.2, -0.3],
      [0.2, -0.6],
      [0.2, -0.2],
      [0.1, -0.3],
      [-1.3, -1.9],
      'z',
      ['m', -16.6, -0.5],
      [-0.6, -0.2],
      [-0.2, -0.3],
      [0, -1],
      [-0.6, -0.3],
      [-0.3, 0.2],
      [-0.6, 0.7],
      [-0.2, 0.4],
      [-0.5, 0.3],
      [-0.1, 0.3],
      [0, 0.4],
      [0.4, 0.1],
      [0.3, -0.4],
      [0.9, -0.1],
      [0.3, 0.1],
      [0, 0.4],
      [0.1, 0.7],
      [0.3, 0.3],
      [0.5, 0.2],
      [0.4, 0.6],
      [0.1, -0.3],
      [0.2, 0],
      [0.2, -0.4],
      [-0.3, -1.2],
      [-0.3, -0.5],
      'z',
      ['m', -6.5, -0.4],
      [0.1, -0.5],
      [-0.1, -0.9],
      [-0.2, 0.1],
      [0, 0.2],
      [-0.1, 0.4],
      [0.2, 0.8],
      [0.1, -0.1],
      'z',
      ['m', 3.2, -0.4],
      [0.2, -0.2],
      [0, -0.4],
      [0, -0.5],
      [-0.2, -0.4],
      [-0.2, -0.2],
      [-0.5, 0.1],
      [-0.4, 0.5],
      [0, 0.5],
      [0.4, 0.6],
      [0.6, 0],
      [0.1, 0],
      'z',
      ['m', -2.6, -1.2],
      [0.2, -0.3],
      [0.5, -0.7],
      [0.1, -0.3],
      [-0.5, -0.2],
      [-0.4, -0.5],
      [-0.4, -0.2],
      [-0.3, 0.4],
      [0, 0.4],
      [0.5, 0.6],
      [-0.1, 0.4],
      [0.2, 0.1],
      [0.1, 0.4],
      [0.1, -0.1],
      'z',
      ['m', 17.5, 3.9],
      [-0.1, -0.5],
      [-0.3, -0.4],
      [0.4, -0.5],
      [-2.2, -1.9],
      [-0.3, -0.2],
      [-0.4, -0.1],
      [-0.5, -0.4],
      [-0.5, -0.1],
      [-0.5, -0.4],
      [-0.2, -0.3],
      [-0.6, -0.4],
      [-0.6, -0.8],
      [-1.5, -0.3],
      [0.1, 0.2],
      [0.4, 0.4],
      [0.1, 0.7],
      [0.5, 0.4],
      [0.5, 0.6],
      [0.2, 0.1],
      [0.2, 0.2],
      [0.4, 0.5],
      [0.8, 0.4],
      [0.8, 0.6],
      [0.3, 0.1],
      [0.3, 0.3],
      [1.5, 0.7],
      [0.5, 0.7],
      [0.7, 0.5],
      [0, -0.1],
      'z',
      ['m', -21.8, -9.2],
      [0.2, -0.3],
      [-0.7, -0.5],
      [-0.2, 0.3],
      [-0.2, 0.5],
      [0.4, 0.2],
      [0.5, -0.2],
      'z',
      ['m', 9.1, 1.5],
      [-0.1, -0.1],
      [-0.3, 0],
      [-0.4, -0.2],
      [-0.7, -0.8],
      [-0.2, -0.3],
      [-0.2, -1],
      [-0.4, -0.4],
      [-1.4, -0.8],
      [-0.8, -0.8],
      [-0.7, -0.2],
      [-0.2, 0.2],
      [0, 0.5],
      [0.2, 0.3],
      [1, 0.9],
      [1.1, 1.7],
      [1, 1],
      [0.8, 0.1],
      [0.4, 0],
      [0, 0.1],
      [0.1, 0.2],
      [0.5, 0.2],
      [0.5, -0.4],
      [-0.2, -0.2],
      'z',
    ],
  },
  {
    name: 'São Tomé and Principe',
    vertexPoint: [
      ['m', 1014.1, 571.4],
      [0.5, -0.8],
      [0, -0.5],
      [-0.3, -0.5],
      [-0.4, 0],
      [-0.5, 0.4],
      [-0.3, 0.4],
      [0, 0.3],
      [0.1, 0.7],
      [0.1, 0.3],
      [0.3, 0.2],
      [0.5, -0.5],
      'z',
      ['m', 4.3, -9.2],
      [0.2, -0.4],
      [0, -0.2],
      [-0.1, -0.1],
      [-0.1, -0.1],
      [-0.2, 0.1],
      [-0.3, 0.5],
      [0.1, 0.2],
      [0.2, 0.2],
      [0.2, -0.2],
      'z',
    ],
  },
  {
    name: 'Sint Maarten',
    vertexPoint: [
      ['m', 627.1, 457.2],
      [0, 0],
      [0.2, 0.2],
      [0.3, 0.1],
      [0.1, -0.1],
      [0, -0.2],
      [-0.6, 0],
      'z',
    ],
  },
  {
    name: 'Seychelles',
    vertexPoint: [
      ['m', 1288.5, 602],
      [-0.5, -0.8],
      [-0.4, 0.3],
      [0.2, 0.3],
      [0.3, 0.2],
      [0.1, 0.4],
      [0.3, 0.2],
      [0, -0.6],
      'z',
    ],
  },
  {
    name: 'Turks and Caicos Is.',
    vertexPoint: [
      ['m', 578.7, 433.1],
      [-0.1, 0.4],
      [0, 0.2],
      [0.2, 0.1],
      [0.6, -0.1],
      [0.1, -0.1],
      [0.2, -0.1],
      [0, -0.1],
      [-0.4, 0.1],
      [-0.6, -0.4],
      'z',
      ['m', 3.6, 0.6],
      [0.2, -0.2],
      [-0.2, -0.2],
      [-0.7, -0.2],
      [-0.2, 0.1],
      [0, 0.3],
      [0.6, 0],
      [0.3, 0.3],
      [0, -0.1],
      'z',
      ['m', -1.1, -0.5],
      [-0.1, -0.1],
      [-0.1, -0.6],
      [-0.5, 0],
      [0, 0.2],
      [0.1, 0.2],
      [0.1, 0],
      [0.1, 0.2],
      [0.3, 0.2],
      [0.1, -0.1],
      'z',
    ],
  },
  {
    name: 'Tonga',
    vertexPoint: [
      ['m', 13.3, 707.7],
      [0, 0],
      [-0.2, 0.3],
      [0, 0.2],
      [0.4, 0.4],
      [-0.2, -0.9],
      'z',
      ['m', -1.6, -0.9],
      [-0.2, 0],
      [0.2, -0.1],
      [-0.4, -0.2],
      [-0.4, 0],
      [-0.2, -0.1],
      [0, -0.2],
      [-0.2, 0.3],
      [0.2, 0.3],
      [0.9, 0.4],
      [0.3, 0.2],
      [0.2, -0.6],
      [0, -0.2],
      [-0.3, 0.1],
      [0, 0.1],
      [-0.1, 0],
      'z',
      ['m', 2.5, -16],
      [0.1, -0.2],
      [0, -0.2],
      [-0.3, -0.1],
      [-0.1, 0],
      [-0.3, 0.5],
      [0.1, 0.1],
      [0.3, 0.2],
      [0.1, 0],
      [0.1, -0.3],
      'z',
    ],
  },
  {
    name: 'Trinidad and Tobago',
    vertexPoint: [
      ['m', 635.4, 507.7],
      [0.1, -0.2],
      [0, -0.6],
      [0.2, -0.4],
      [-0.2, -0.4],
      [-0.1, -0.6],
      [0.1, -0.5],
      [0, -0.7],
      [0.2, -0.3],
      [0.5, -0.8],
      [-0.9, 0],
      [-0.6, 0.2],
      [-1.1, 0.1],
      [-0.5, 0.2],
      [-0.7, 0.1],
      [-0.4, 0.2],
      [0.1, 0.1],
      [0.5, 0.2],
      [0.2, 0.2],
      [0.1, 0.2],
      [0.1, 0.4],
      [-0.3, 1.7],
      [-0.1, 0.1],
      [-0.6, 0.1],
      [-0.2, 0.3],
      [-1.4, 0.8],
      [0.8, -0.1],
      [0.9, 0.1],
      [2.4, -0.1],
      [0.9, -0.3],
      'z',
      ['m', 1.8, -6.7],
      [1.2, -0.5],
      [0.1, -0.4],
      [-0.2, 0],
      [-0.8, 0.3],
      [-0.6, 0.5],
      [0, 0.2],
      [0.3, -0.1],
      'z',
    ],
  },
  {
    name: 'St. Vincent and the Grenadines',
    vertexPoint: [
      ['m', 634.5, 491.4],
      [0, 0],
      [0, -0.1],
      [0.1, 0],
      [0, -0.1],
      [0, 0],
      [0, -0.1],
      [-0.1, 0],
      [0, 0.1],
      [0, 0],
      [0, 0.1],
      [-0.1, 0],
      [0, 0.1],
      [0, 0],
      [0, 0],
      [0.1, 0],
      'z',
      ['m', 0.7, -1.9],
      [0.1, -0.2],
      [0.1, -0.1],
      [0, 0],
      [0, 0],
      [-0.1, -0.1],
      [0, 0],
      [0, 0.1],
      [-0.2, 0.1],
      [0, 0],
      [0, 0.1],
      [0, 0],
      [0, 0.1],
      [-0.1, 0],
      [-0.1, 0],
      [0, 0],
      [0.1, 0],
      [0, 0],
      [0.1, 0.1],
      [0, 0],
      [0, 0],
      [0, 0],
      [0.1, -0.1],
      'z',
      ['m', 0.3, -1.1],
      [0.3, -0.2],
      [0.1, -0.6],
      [-0.1, -0.4],
      [-0.2, 0],
      [-0.3, 0.1],
      [-0.2, 0.3],
      [-0.1, 0.5],
      [0.4, 0.4],
      [0.1, -0.1],
      'z',
    ],
  },
  {
    name: 'British Virgin Is.',
    vertexPoint: [
      ['m', 619.2, 455.1],
      [0.3, -0.2],
      [-0.2, -0.1],
      [-0.4, 0],
      [-0.3, 0.2],
      [0.1, 0.1],
      [0.5, 0],
      'z',
      ['m', 1.1, -0.4],
      [0.4, -0.4],
      [-0.5, 0.1],
      [-0.2, 0.2],
      [0.1, 0.1],
      [0.1, 0],
      [0.1, 0],
      'z',
      ['m', 0.8, -1.8],
      [-0.2, 0],
      [-0.5, 0],
      [0, 0],
      [0.1, 0.1],
      [0.3, 0],
      [0.3, 0.1],
      [0, 0],
      [0, -0.2],
      'z',
    ],
  },
  {
    name: 'U.S. Virgin Is.',
    vertexPoint: [
      ['m', 617.9, 458.9],
      [-0.7, 0.2],
      [-0.1, 0.4],
      [1.1, 0],
      [0.7, -0.3],
      [-0.6, 0],
      [-0.4, -0.3],
      'z',
      ['m', 0.9, -3.5],
      [-0.5, -0.1],
      [-0.2, 0.2],
      [0, 0],
      [0.3, 0.1],
      [0.4, -0.2],
      'z',
      ['m', -1.1, 0.1],
      [-0.2, -0.2],
      [-0.3, -0.1],
      [-0.4, 0.1],
      [0.5, 0.3],
      [0.4, -0.1],
      'z',
    ],
  },
  {
    name: 'Mayotte',
    vertexPoint: [
      ['m', 1228.7, 654.7],
      [0, -0.3],
      [0.2, -0.5],
      [0, -0.1],
      [0.1, -0.5],
      [-0.3, -0.3],
      [-0.2, 0],
      [-0.2, -0.3],
      [-0.3, 0.3],
      [0.3, 0.5],
      [-0.1, 0.3],
      [-0.1, 0.4],
      [0.1, 0.4],
      [0.2, 0.2],
      [0.3, -0.1],
      'z',
    ],
  },
  {
    name: 'Martinique',
    vertexPoint: [
      ['m', 638, 479.9],
      [-0.2, -0.7],
      [-0.1, -0.2],
      [-0.2, -0.3],
      [0.1, -0.3],
      [0, -0.1],
      [-0.2, 0],
      [-0.3, -0.5],
      [-0.6, -0.3],
      [-0.3, 0],
      [-0.2, 0.2],
      [0, 0.3],
      [0.3, 0.9],
      [0.2, 0.2],
      [0.5, 0.2],
      [-0.4, 0.4],
      [0, 0.1],
      [0.1, 0.3],
      [0.9, 0],
      [0.2, 0.3],
      [0.1, -0.1],
      [0.1, -0.4],
      'z',
    ],
  },
  {
    name: 'Reunion Islands',
    vertexPoint: [
      ['m', 1284, 707.9],
      [0.2, -0.4],
      [0.1, -0.8],
      [-0.4, -0.8],
      [-0.4, -0.7],
      [-0.4, -0.2],
      [-0.8, -0.1],
      [-0.7, 0.3],
      [-0.4, 0.6],
      [-0.2, 0.3],
      [0.4, 1.1],
      [0.2, 0.3],
      [1.1, 0.6],
      [0.5, 0],
      [0.8, -0.2],
      'z',
    ],
  },
  {
    name: 'Venezuela',
    vertexPoint: [
      ['m', 642, 518.9],
      [-2.2, -1.5],
      [-2.9, 0.2],
      [-0.7, -5.1],
      [-4.1, -3.2],
      [-4.4, -0.4],
      [-1.8, -3],
      [4.8, -1.9],
      [-6.7, 0.1],
      [-6.9, 0.4],
      [-0.2, 1.6],
      [-3.2, 1.9],
      [-4.2, -0.7],
      [-3.1, -2.9],
      [-6, 0.7],
      [-5, -0.1],
      [-0.1, -2.1],
      [-3.5, -3.5],
      [-3.9, -0.1],
      [-1.7, -4.5],
      [-2.1, 2],
      [0.6, 3],
      [-7.1, 2.6],
      [0, 4.8],
      [1.6, 2.2],
      [-1.5, 4.6],
      [-2.4, 0.4],
      [-1.9, -5],
      [2.7, -3.7],
      [0.3, -3.3],
      [-1.7, -2.9],
      [3.3, -0.8],
      [0.3, -1.5],
      [-3.7, 1.1],
      [-1.6, 3.2],
      [-2.2, 1.8],
      [-1.8, 2.4],
      [-0.9, 4.5],
      [-1.8, 3.7],
      [2.9, 0.5],
      [0.6, 2.9],
      [1.1, 1.4],
      [0.4, 2.5],
      [-0.8, 2.4],
      [0.2, 1.3],
      [1.3, 0.6],
      [1.3, 2.2],
      [7.2, -0.6],
      [3.2, 0.8],
      [3.8, 5.5],
      [2.3, -0.7],
      [4, 0.3],
      [3.2, -0.7],
      [2, 1.1],
      [-1.2, 3.4],
      [-1.3, 2.1],
      [-0.5, 4.6],
      [1, 4.2],
      [1.5, 1.9],
      [0.2, 1.5],
      [-2.9, 3.1],
      [2, 1.4],
      [1.4, 2.2],
      [1.7, 6.4],
      [3, 3.4],
      [4.4, -0.5],
      [1.1, -1.9],
      [4.2, -1.5],
      [2.3, -1],
      [0.7, -2.7],
      [4.1, -1.8],
      [-0.3, -1.4],
      [-4.8, -0.5],
      [-0.7, -4],
      [0.3, -4.3],
      [-2.4, -1.6],
      [1, -0.6],
      [4.2, 0.8],
      [4.4, 1.6],
      [1.7, -1.5],
      [4, -1],
      [6.4, -2.4],
      [2.1, -2.4],
      [-0.7, -1.8],
      [-3.7, -4.8],
      [1.6, -1.8],
      [0, -2.9],
      [3.4, -1.1],
      [1.5, -1.2],
      [-1.9, -2.3],
      [0.6, -2.3],
      [4.6, -3.8],
      'z',
    ],
  },
  {
    name: 'Vietnam',
    vertexPoint: [
      ['m', 1571.6, 435],
      [-5.9, -1.6],
      [-3, -2.6],
      [0.2, -3.7],
      [-5.2, -1.1],
      [-3, -2.4],
      [-4.1, 3.4],
      [-5.3, 0.7],
      [-4.3, 0],
      [-2.7, 1.5],
      [4, 5.1],
      [3.4, 5.7],
      [6.8, 0.1],
      [3, 5.5],
      [-3.3, 1.7],
      [-1.3, 2.3],
      [7.3, 3.8],
      [5.7, 7.5],
      [4.3, 5.6],
      [4.8, 4.4],
      [2, 4.5],
      [-0.2, 6.4],
      [1.8, 4.2],
      [0.1, 7.7],
      [-8.9, 4.9],
      [2.8, 3.8],
      [-5.8, 0.5],
      [-4.7, 2.5],
      [4.5, 3.7],
      [-1.3, 4.3],
      [2.3, 4],
      [6.6, -5.9],
      [4.1, -5.3],
      [6.1, -4.1],
      [4.3, -4.2],
      [-0.4, -11.2],
      [-4, -11.7],
      [-4.1, -5.1],
      [-5.6, -4],
      [-6.4, -8.3],
      [-5.3, -6.7],
      [0.5, -4.4],
      [3.7, -6],
      [6.5, -5.5],
      'z',
    ],
  },
  {
    name: 'Vanuatu',
    vertexPoint: [
      ['m', 1908.6, 676.9],
      [-2.7, -3.6],
      [-0.6, 1.7],
      [1.3, 2.8],
      [2, -0.9],
      'z',
      ['m', -2, -9.7],
      [-2.3, -2],
      [-0.9, 4.9],
      [0.5, 1.8],
      [1.2, -0.4],
      [1.3, 0.8],
      [0.2, -5.1],
      'z',
    ],
  },
  {
    name: 'Yemen',
    vertexPoint: [
      ['m', 1271.5, 466.2],
      [-2.1, -4.4],
      [-5.2, -10.5],
      [-15.7, 2.4],
      [-5, 2.9],
      [-3.5, 6.7],
      [-2.5, 1],
      [-1.6, -2.1],
      [-2.1, 0.3],
      [-5.4, -0.6],
      [-1, -0.7],
      [-6.4, 0.2],
      [-1.5, 0.6],
      [-2.4, -1.7],
      [-1.2, 3.1],
      [0.7, 2.7],
      [-2.3, 2.1],
      [0.4, 2.7],
      [-0.6, 1.3],
      [0.7, 2.9],
      [-1.1, 0.3],
      [1.7, 2.6],
      [1.3, 4.7],
      [1, 1.9],
      [0, 3.4],
      [1.6, 3.8],
      [3.9, 0.3],
      [1.8, -0.9],
      [2.7, 0.2],
      [0.8, -1.7],
      [1.5, -0.4],
      [1.1, -1.7],
      [1.4, -0.4],
      [4.7, -0.3],
      [3.5, -1.2],
      [3.1, -2.7],
      [1.7, 0.4],
      [2.4, -0.3],
      [4.7, -4.5],
      [8.8, -3],
      [5.3, -2.7],
      [0, -2.1],
      [0.9, -2.9],
      [3.9, -1.7],
      'z',
    ],
  },
  {
    name: 'Zambia',
    vertexPoint: [
      ['m', 1149.2, 626.7],
      [-1.9, -0.5],
      [0.4, -1.3],
      [-1, -0.3],
      [-7.5, 1.1],
      [-1.6, 0.7],
      [-1.6, 4.1],
      [1.2, 2.8],
      [-1.2, 7.5],
      [-0.8, 6.4],
      [1.4, 1.1],
      [3.9, 2.5],
      [1.5, -1.2],
      [0.3, 6.9],
      [-4.3, 0],
      [-2.1, -3.5],
      [-2, -2.8],
      [-4.3, -0.8],
      [-1.2, -3.4],
      [-3.4, 2],
      [-4.5, -0.9],
      [-1.8, -2.8],
      [-3.5, -0.6],
      [-2.6, 0.1],
      [-0.3, -2],
      [-1.9, -0.1],
      [0.5, 2],
      [-0.7, 3],
      [0.9, 3],
      [-0.9, 2.4],
      [0.5, 2.2],
      [-11.6, -0.1],
      [-0.8, 20.3],
      [3.6, 5.2],
      [3.5, 4],
      [4.6, -1.5],
      [3.6, 0.4],
      [2.1, 1.4],
      [0, 0.5],
      [1, 0.5],
      [6.2, 0.7],
      [1.7, 0.7],
      [1.9, -0.1],
      [3.2, -4.1],
      [5.1, -5.3],
      [2, -0.5],
      [0.7, -2.2],
      [3.3, -2.5],
      [4.2, -0.9],
      [-0.3, -4.5],
      [17.1, -5.2],
      [-2.9, -1.7],
      [1.9, -5.9],
      [1.8, -2.2],
      [-0.9, -5.3],
      [1.2, -5.1],
      [1, -1.8],
      [-1.2, -5.4],
      [-2.6, -2.8],
      [-3.2, -1.9],
      [-3.5, -1.1],
      [-2.2, -1.1],
      [-0.3, -0.2],
      [0, 0],
      [0.5, 1.1],
      [-1, 0.4],
      [-1.2, -1.4],
      'z',
    ],
  },
  {
    name: 'Zimbabwe',
    vertexPoint: [
      ['m', 1148.2, 713.7],
      [6.2, -7.2],
      [1.6, -4.6],
      [0.9, -0.6],
      [0.8, -3.7],
      [-0.8, -1.9],
      [0.5, -4.7],
      [1.3, -4.4],
      [0.3, -8.1],
      [-2.8, -2],
      [-2.6, -0.5],
      [-1.1, -1.6],
      [-2.6, -1.3],
      [-4.6, 0.1],
      [-0.3, -2.4],
      [-4.2, 0.9],
      [-3.3, 2.5],
      [-0.7, 2.2],
      [-2, 0.5],
      [-5.1, 5.3],
      [-3.2, 4.1],
      [-1.9, 0.1],
      [-1.7, -0.7],
      [-6.2, -0.7],
      [1.9, 5.1],
      [1.1, 1.1],
      [1.6, 3.7],
      [6, 7],
      [2.3, 0.7],
      [-0.1, 2.2],
      [1.5, 4.1],
      [4.2, 0.9],
      [3.4, 2.9],
      [2.2, 0.1],
      [2.6, 1.1],
      [1.9, -0.8],
      [2.9, 0.6],
      'z',
    ],
  },
]
