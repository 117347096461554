export const TEAM = [
  {
    name: 'Luke Zirngibl',
    title: 'Founder & CEO',
    image: 'Portrait_Luke.jpg',
    description:
      'Luke likes building things at the intersection of code, art, and his 3D printer. He has more ideas than time and needs help with the ones worth pursuing.',
  },
  {
    name: 'Isabell Fink',
    title: 'Partner',
    image: 'Portrait_Isi.jpg',
    description: `
      Izzy strives to create the best possible UX in the products we build. She is always on the hunt for new design challenges and loves to explore different colors, shapes and fonts to create meaningful solutions to unleash her creativity. 
    `,
  },
  {
    name: 'Matteo Gamba',
    image: 'Portrait_Matteo.jpg',
    title: 'Partner',
    description: `
    In programming, the hard part is not to solve problems, but to decide what problems to solve. Matteo's passion lies in identifying real-life problems and solving them with code.
    `,
  },
  // {
  //   name: 'Luisa Stückelberger',
  //   image: 'Portrait_Luisa.jpg',
  //   title: 'Developer',
  //   description: `
  //     Luisa is our volleyball-hacker gürl. There is nothing she can't build.
  //   `,
  // },
  // {
  //   name: 'Corey Bothwell',
  //   title: 'Senior Developer',
  //   image: 'Portrait_Corey.jpg',
  //   description: `
  //     Corey is the "Network Vis" guy. Have an NP-hard graph problem? No, problem. Corey is our man with the network visualization plan. When he's not busy LARP-ing as a Math Professor, he can be found somewhere writing elegant algorithms on random window panes.
  //   `,
  // },
  // {
  //   name: 'Chiara Turel',
  //   title: 'Digital Artist',
  //   image: 'Portrait_Chiara.jpg',
  //   description: `
  //      Chiara has a passion for art, design, and anything related to games and puzzles. If she isn’t designing the look and feel of F1R’s latest app, you’ll find her doodling or secretly planting the clues for her next scavenger hunt.
  //   `,
  // },
  // {
  //   name: 'Tomás Massano',
  //   title: 'Graphic Designer',
  //   image: 'Portrait_Tomas.jpg',
  //   description: `
  //   Not Swiss, but “s Schwizer Sackmässer” of creativity. Large headphones, camera addict and apple enthusiast. Tomás became addicted to the creative process and possibilities that Photoshop CC 2014 allowed him to create (At least before it crashes…)
  //   `,
  // },
  {
    name: 'Maria Pavlou',
    title: 'UI/UX Designer',
    image: 'Portrait_Maria.jpg',
    description: `
    Maria longs to live in a world of enchantment and magic. But, alas such places aren't exactly accessible, so she takes matters into her own hands and creates her own magical kingdom through her art. Her default search engine is YouTube and usually you will find her reading a book...
    `,
  },
  {
    name: 'Annika Berger',
    title: '3D Designer',
    image: 'Portrait_Annika.jpg',
    description: `
    Whether analog or digital, Annika loves design in all its facets. However, she is particularly fond of the 3D Design world and usually experiments with animations and new textures for her models. She gets her inspiration from her everyday life and always comes up with new design ideas.
    `,
  },
]

export const OFFICE = [
  {
    image: '/images/office-1.jpeg',
    title: 'Programming Den',
    description: `
      The typical vibe in the den is that of keyboard crunching and up-and-coming german mumble rappers or local classical music. In short, this is where serious work gets done. This 7-workstation room is superbly equipped and optimized for peak coding & design performance.
    `,
    list: [
      '7 x Dope monitors',
      '4 x Standing desks',
      '1 x Office treadmill',
      '1 x Astronaut',
    ],
  },
  {
    image: '/images/office-2.jpg',
    title: 'Meeting Room',
    description: `
      Colloquially known as the "Fun Room" or "Rümli", this room is where you can enjoy a cup of Joe, private calls in the phone booth (wage cage), or meetings on our luscious long wood table. Late in the evenings, you may even find some Mario Kart racing and VR shenanigans.
    `,
    list: [
      '1 x Nespresso',
      '1 x Wage cage',
      '1 x Nintendo switch',
      '1 x Oculus Quest',
    ],
  },
  {
    image: '/images/office-3.jpg',
    title: 'Prototyping Lab',
    description: `
      This is most recent addition to the 41R facility. Its 4-workstation capacity is partly meant as an extension of the Programming Den. But towering on the back wall is the prototyping lab where you have all the tools to build anything you can possibly imagine -- if what you're imagining can be built by a drill, screws, an Ultimaker, and a Beamo Laser.
    `,
    list: [
      `2 x Hand drills`,
      '13 x 3D printer ',
      '1 x Laser cutter',
      `1 x Child's couch`,
    ],
  },
]

export const CULTURE = [
  {
    image: '/images/culture-2.jpeg',
    title: 'Build. Make. Create.',
    description: `
      Making prototypes & products quickly and creatively is our bread & butter. We work primarily in the office where we can collaborate over whiteboards or pen & paper. We iterate and test things as fast as possible. 
    `,
  },
  {
    image: '/images/culture-1.jpeg',
    title: 'Work hard. Play hard.',
    description: `
      After we finally deploy and launch the product, which is invariably on Friday afternoon, we take the time to relax as a team. This could be in the form of after-hours
      mario-karting, panache by the river, or our award winning team offsite trips. 
    `,
  },
  {
    image: '/images/incubator.jpg',
    title: 'Passion projects',
    description: `
      Creativity and motivation are tidal. To balance out client work, we encourage
      everyone at For One Red to pursue personal projects. A healthy amount of 
      context-switching keeps people fresh, focused, and ready for each day's challenges.  
    `,
  },
]
