import styled from 'styled-components'
import { Section } from '../../components/Section'
import { SectionHeader } from '../../components/SectionHeader'
import { LOGOS } from '../../data/logos.data'
import { SERVICES } from '../../data/services.data'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { useViewport } from '../../scaffold/providers/ViewportProvider'
import { Scaffold } from '../../scaffold/Scaffold'
import { Featured } from './Featured'
import { ServiceBlockComponent } from './ServiceBlock'

export const Home = () => {
  const viewport = useViewport()

  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'For One Red',
        description: 'Creative design and dev agency in Zürich Switzerland',
      }}
    >
      <Wrapper>
        <Content>
          <Featured />

          <ClientWrapper>
            <SectionHeader title="Brands we've worked with" description="" />
            <ClientSection>
              {LOGOS.map((c, k) => (
                <ClientItem key={k}>
                  <ClientLogo src={`/logos/${c.logo}`} style={c.logoStyle} />
                </ClientItem>
              ))}
            </ClientSection>
          </ClientWrapper>

          {/* <SectionHeader title="Project explorer" description="" />
          <ProjectsBlock /> */}

          <SectionHeader title="Our services" description="" />
          {SERVICES.map((s, k) => (
            <ServiceBlockComponent key={k} {...s} />
          ))}
        </Content>
      </Wrapper>
    </Scaffold>
  )
}

const ClientWrapper = styled.div``

const ClientSection = styled(Section)`
  display: grid;

  ${DESKTOP_STYLE} {
    grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
    gap: 2px;
    padding-bottom: 128px;
  }

  ${TABLET_STYLE} {
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 2px;
    padding-bottom: 128px;
  }

  ${MOBILE_STYLE} {
    grid-template-columns: 33.33% 33.33% 33.33%;
    gap: 2px;
    padding-bottom: 64px;
    margin-left: -16px;

    & > div {
      transform: scale(0.7);
    }
  }
`

const ClientLogo = styled.img`
  width: 128px;
`

const ClientItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${DESKTOP_STYLE} {
    height: 144px;
  }

  ${TABLET_STYLE} {
    height: 144px;
  }

  ${MOBILE_STYLE} {
    height: 96px;
  }
`

const Content = styled.div`
  margin: 0 auto;
  padding-bottom: 128px;
`

const Wrapper = styled.div`
  ${DESKTOP_STYLE} {
    padding: 0 64px;
  }

  ${TABLET_STYLE} {
    padding: 0 28px;
  }

  ${MOBILE_STYLE} {
    padding: 0 24px;
  }
`
