/* eslint-disable */
/* tslint:disable */

import { country } from './countries'

export const sketch = (p5: any) => {
  const size = 0.6
  let countryPolygons: any = []

  function convertPathToPolygons(path: any) {
    let coord_point = [0, 0]
    let polygons = []
    let currentPolygon = []

    //For loop para calcular os pontos do vertex
    for (const node of path) {
      if (node[0] == 'm') {
        coord_point[0] += node[1] * size
        coord_point[1] += node[2] * size
        currentPolygon = []
      } else if (node[0] == 'M') {
        coord_point[0] = node[1] * size
        coord_point[1] = node[2] * size
        currentPolygon = []
      } else if (node == 'z') {
        currentPolygon.push([...coord_point])
        polygons.push(currentPolygon)
      } else {
        currentPolygon.push([...coord_point])
        coord_point[0] += node[0] * size
        coord_point[1] += node[1] * size
      }
    }

    return polygons
  }

  const canvasDiv: any = document.getElementById('canvas')

  const width = canvasDiv.offsetWidth
  const height = canvasDiv.offsetHeight

  p5.setup = () => {
    p5.createCanvas(width, height)

    for (let i = 0; i < country.length; i++) {
      countryPolygons.push(convertPathToPolygons(country[i].vertexPoint))
    }

    p5.stroke(60)
    p5.strokeWeight(1)
    for (let i = 0; i < countryPolygons.length; i++) {
      p5.fill(10)

      for (const poly of countryPolygons[i]) {
        p5.beginShape()
        for (const vert of poly) {
          p5.vertex(...vert)
        }
        p5.endShape()
      }
    }
  }

  p5.draw = () => {}
}
