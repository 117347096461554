import { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'

const compute = (num: number) => {
  let done = false
  let acc = 0
  let i = 0
  while (!done) {
    acc += i * 2 + i
    i++

    if (acc > num) {
      done = true
    }
  }
  return i + 4
}

export const Bars = () => {
  const [num, setNum] = useState(0)

  useLayoutEffect(() => {
    setNum(compute(window.innerWidth))
  }, [])

  const resize = () => {
    setNum(compute(window.innerWidth))
  }

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <BarsWrapper>
      {new Array(Math.floor(num)).fill(0).map((_, i) => (
        <Bar
          style={{ width: i * 2, marginLeft: i }}
          width={i * 2}
          delay={1 / i}
          key={i}
        />
      ))}
    </BarsWrapper>
  )
}

const BarsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Bar = styled.div<{ delay: number; width: number }>`
  @keyframes size {
    0% {
      transform: scale(1.55);
    }

    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.55);
    }
  }

  animation: size 7s ease-in-out infinite;

  height: 100%;
  background: #000000;
`
