import { useState } from 'react'
import styled from 'styled-components'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Button, LinkButton } from '../../components/Button'
import { Page } from '../../components/Page'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Scaffold } from '../../scaffold/Scaffold'
import { Helmet } from 'react-helmet'

export const Contact = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState<boolean>()

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&')
  }

  const handleSubmit = (e: any) => {
    fetch('/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', name: email, message }),
    })
      .then(() => {
        setSuccess(true)
        setMessage('')
        setEmail('')
      })
      .catch((error) => {
        setSuccess(false)
        alert(error)
      })
    e.preventDefault()
  }

  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'Contact',
        path: 'contact',
      }}
    >
      <Page
        title="Contact us"
        description="Interested in working with us? Reach out."
      >
        <Content>
          {success ? (
            <p
              style={{
                color: 'white',
              }}
            >
              Thank you. We have received your message and will be in touch.
            </p>
          ) : (
            <>
              <ContactForm>
                <input type="hidden" name="form-name" value="contact" />
                <FormWrapper>
                  <Input
                    placeholder="Email address"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                  <TextArea
                    placeholder="Your message"
                    value={message}
                    required
                    onChange={(e) => {
                      setMessage(e.target.value)
                    }}
                  />
                  <LinkButton
                    label="Submit"
                    onClick={handleSubmit}
                    textColor="rgba(255,255,255,1)"
                    style={{
                      width: 256,
                      marginTop: 24,
                    }}
                  />
                </FormWrapper>
              </ContactForm>
              <ImageWrapper>
                <Screen>
                  <Oval
                    style={
                      success
                        ? {
                            background:
                              'linear-gradient(-50deg,#78f599, #34ed65)',
                          }
                        : email === ''
                        ? {
                            background:
                              'linear-gradient(-50deg,#727375, #abafb8)',
                          }
                        : {
                            background:
                              'linear-gradient(-50deg,#ffffff, #fdfff0)',
                            opacity: 1,
                          }
                    }
                  />
                </Screen>
                <Image
                  src="/images/astronaut.png"
                  alt="astronaut"
                  style={
                    success
                      ? {
                          filter: 'grayscale(0%) hue-rotate(90deg)',
                        }
                      : email === ''
                      ? {
                          filter: `grayscale(100%)`,
                        }
                      : {
                          filter: `grayscale(0%)`,
                        }
                  }
                />
              </ImageWrapper>
            </>
          )}
        </Content>
      </Page>
    </Scaffold>
  )
}

const Screen = styled.div`
  position: absolute;

  ${DESKTOP_STYLE} {
    top: -40px;
    left: 3px;
  }

  ${TABLET_STYLE} {
    top: -40px;
    left: 3px;
  }

  ${MOBILE_STYLE} {
    top: -40px;
    left: -47px;
  }
`

const Oval = styled.div`
  width: 240px;
  height: 165px;
  border-radius: 50%;
  transform: rotate(56deg);
  transition: all ease 0.5s;
`

const TextArea = styled.textarea`
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 256px;
  padding: 16px;
  font-size: 16px;
  background: transparent;
  font-family: 'Inter';
  cursor: pointer;
  color: white;
  margin-top: 32px;

  &:focus {
    outline: none;
  }
`

const Input = styled.input`
  border: 1px solid rgba(255, 255, 255, 0.5);
  height: 64px;
  padding-left: 16px;
  font-size: 16px;
  background: transparent;
  font-family: 'Inter';
  cursor: pointer;
  color: white;

  ${DESKTOP_STYLE} {
    width: 400px;
  }

  ${TABLET_STYLE} {
    width: 400px;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 500px;

  ${DESKTOP_STYLE} {
    padding: 32px 0;
  }

  ${TABLET_STYLE} {
    padding: 32px;
  }

  ${MOBILE_STYLE} {
    padding: 24px;
    width: calc(100% + 48px);
    margin: 0 -24px;
  }
`

const ContactForm = styled.div`
  flex: 1;
  width: 100%;
`

const Content = styled.div`
  display: flex;

  position: relative;
  width: 100%;

  ${DESKTOP_STYLE} {
    padding-bottom: 128px;
    min-height: 800px;
  }

  ${TABLET_STYLE} {
    padding-bottom: 128px;
    min-height: 800px;
  }

  ${MOBILE_STYLE} {
    padding-bottom: 64px;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  ${DESKTOP_STYLE} {
    flex: 0 0 400px;
  }

  ${TABLET_STYLE} {
    flex: 0 0 400px;
  }

  ${MOBILE_STYLE} {
    flex: 0 0 0px;
    display: none;
  }
`

const Image = styled.img`
  position: absolute;
  top: -100px;

  width: 350px;
  z-index: 1;
  margin-left: -16px;

  ${DESKTOP_STYLE} {
    left: 0;
  }

  ${TABLET_STYLE} {
    left: 0;
  }

  ${MOBILE_STYLE} {
    left: -50px;
  }
`
