import styled from 'styled-components'
import { LogoSvg } from './LogoSvg'

const DURATION = 2

const LoaderWrapper = styled.div`
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  left: 0;
  position: fixed;
  top: 0;
  opacity: 1;
  width: 100dvw;
  z-index: 99999;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: fadeOut;
  animation-duration: 4s;
  animation-fill-mode: forwards;

  @keyframes blurIn {
    0% {
      filter: blur(100px);
      opacity: 0;
    }
    80% {
      filter: blur(0px);
      opacity: 1;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #F1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  #O2 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(1 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #R2 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(2 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #O1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(3 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #R1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(4 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #N1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(5 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #E2 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(6 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #E1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(7 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  #D1 {
    opacity: 0;
    animation: appear 1s ease;
    animation-delay: ${(8 / 8) * DURATION}s;
    animation-fill-mode: forwards;
  }

  & > div {
    opacity: 0;
    animation-name: blurIn;
    animation-delay: 0.6s;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
  }
`

export const Loader = (props: { initialized: boolean }) => {
  if (props.initialized) {
    return <></>
  }
  return (
    <LoaderWrapper style={{ zIndex: props.initialized ? -1 : 9999 }}>
      <LogoSvg />
    </LoaderWrapper>
  )
}
