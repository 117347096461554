import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'

export const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 16px 32px;
  color: white;
  background: ${({ theme }) => theme.colors.secondary};
  margin-top: 32px;
  cursor: pointer;
  transition: all ease 0.7s;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 14px;

  ${MOBILE_STYLE} {
    width: 100%;
  }

  &:disabled {
    pointer-events: none;
    background: ${({ theme }) => theme.colors.bg2};
    border-color: ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    opacity: 0.8;
  }
`

export const LinkButton = (props: {
  label: string
  textColor: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  link?: string
  style?: CSSProperties
}) => {
  const { textColor, link, label } = props
  const navigate = useNavigate()
  return (
    <LinkButtonWrapper
      style={props.style}
      color={textColor}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e)
        } else if (link) {
          navigate(link)
        }
      }}
    >
      <LinkButtonText style={{ color: textColor }}>{label}</LinkButtonText>
      <BsArrowRight className="arrow" size={24} color={textColor} />
    </LinkButtonWrapper>
  )
}

const LinkButtonWrapper = styled.div<{ color: string }>`
  width: 100%;
  line-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: all ease 0.2s;
  height: 64px;
  border: 1px solid ${({ color }) => 'rgba(255, 255, 255, 0.15)'};

  .arrow {
    transition: all ease 0.4s;
    opacity: 0.3;
  }

  margin: 8px 0;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid ${({ color }) => 'rgba(255, 255, 255, 0.4)'};

    .arrow {
      transform: translateX(16px);
      opacity: 1;
    }
  }

  ${DESKTOP_STYLE} {
    padding: 0 32px;
  }

  ${TABLET_STYLE} {
    padding: 0 32px;
  }

  ${MOBILE_STYLE} {
    padding: 0 16px;
  }
`

const LinkButtonText = styled.p`
  ${DESKTOP_STYLE} {
    font-size: 16px;
  }

  ${TABLET_STYLE} {
    font-size: 16px;
  }

  ${MOBILE_STYLE} {
    font-size: 14px;
  }
`
