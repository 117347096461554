import { useEffect, useState } from 'react'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Image } from '../../components/Image'
import { Page } from '../../components/Page'
import { WORK, WorkT } from '../../data/work.data'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import {
  useViewport,
  Viewport,
} from '../../scaffold/providers/ViewportProvider'
import { Scaffold } from '../../scaffold/Scaffold'
import { Helmet } from 'react-helmet'

const mapViewportalToImagePadding: Record<Viewport, number> = {
  [Viewport.Desktop]: 128,
  [Viewport.Tablet]: 64,
  [Viewport.Mobile]: 32,
}

export const Detail = () => {
  const navigate = useNavigate()
  const viewport = useViewport()

  const [imageIndex, setImageIndex] = useState(0)

  const projectId = window.location.pathname.split('/').pop() || 'not found'

  const work = (WORK as any)[projectId] as WorkT | undefined

  useEffect(() => {
    if (!work) {
      navigate('/work')
    }

    window.scrollTo(0, 0)
  }, [work])

  if (!work) {
    return <></>
  }

  const { title, tagline, description, images, text, stats } = work

  const padding = mapViewportalToImagePadding[viewport]

  let imageWidth = window.innerWidth - padding * 2
  imageWidth = imageWidth > 1200 ? 1200 : imageWidth

  return (
    <Scaffold
      bgColor={'#000000'}
      helmet={{
        title: title
          .split(' ')
          .map((t) => t[0].toUpperCase() + t.slice(1))
          .join(' '),
        path: `detail/${projectId}`,
        description: tagline,
      }}
    >
      <Page title={''} description={''}>
        {/* <NavigationHeader>
          {index !== 1 && (
            <NavButton
              onClick={() => {
                const item = Object.entries(WORK).find(
                  ([key, value]) => value.index === index - 1,
                )
                // navigate(`/work/${item[0]}`)
              }}
            >
              <AiOutlineArrowLeft size={24} color="white" className="left" />
            </NavButton>
          )}
          <NavText>
            {index}{' '}
            <span style={{ opacity: 0.5 }}>/ {Object.keys(WORK).length}</span>
          </NavText>
          {index !== Object.keys(WORK).length && (
            <NavButton
              onClick={() => {
                const item = Object.entries(WORK).find(
                  ([key, value]) => value.index === index + 1,
                )
                // navigate(`/work/${item[0]}`)
              }}
            >
              <AiOutlineArrowRight size={24} color="white" className="right" />
            </NavButton>
          )}
        </NavigationHeader> */}
        <ProjectHeader>
          <ProjectTitle>{title}</ProjectTitle>
          <ProjectBox>
            <ProjectBoxLeft>
              <ProjectTagline>{tagline}</ProjectTagline>
              <ProjectIcons>
                {/* <ProjectIcon>
                  <TbWorldLongitude color="white" size={24} />
                </ProjectIcon>
                <ProjectIcon>
                  <TbWorldLongitude color="white" size={24} />
                </ProjectIcon>
                <ProjectIcon>
                  <TbWorldLongitude color="white" size={24} />
                </ProjectIcon>
                <ProjectIcon>
                  <TbWorldLongitude color="white" size={24} />
                </ProjectIcon> */}
              </ProjectIcons>
            </ProjectBoxLeft>
            <ProjectBoxRight>
              <ProjectDescription>{description}</ProjectDescription>
            </ProjectBoxRight>
          </ProjectBox>
        </ProjectHeader>

        <ImageSection>
          <ImageWrapper
            style={{
              width: imageWidth,
              height: imageWidth / 1.5 + 64,
            }}
          >
            <ImageHeader
              style={{
                width: imageWidth,
                marginBottom: 32,
              }}
            >
              <ImageControls>
                <NavButton
                  isDisabled={imageIndex !== 0}
                  onClick={() => {
                    setImageIndex((v) => v - 1)
                  }}
                >
                  <AiOutlineArrowLeft
                    size={24}
                    color="white"
                    className="left"
                  />
                </NavButton>

                <NavText>
                  {imageIndex + 1}{' '}
                  <span style={{ opacity: 0.5 }}>/ {images.length}</span>
                </NavText>

                <NavButton
                  isDisabled={imageIndex !== images.length - 1}
                  onClick={() => {
                    setImageIndex((v) => v + 1)
                  }}
                >
                  <AiOutlineArrowRight
                    size={24}
                    color="white"
                    className="right"
                  />
                </NavButton>
              </ImageControls>
            </ImageHeader>

            <Image src={images[imageIndex].src} key={imageIndex} />
          </ImageWrapper>
          {images[imageIndex].caption && (
            <ImageCaption
              style={{
                width: imageWidth,
              }}
            >
              {images[imageIndex].caption}
            </ImageCaption>
          )}
        </ImageSection>

        {text.map((block, index) => (
          <TextSectionWrapper key={index}>
            <TextSection>
              <QuoteTitle>{block.tag}</QuoteTitle>
              <QuoteText>{block.quote}</QuoteText>
              <QuoteDescription>
                {block.text.split('\n').map((t) => (
                  <>
                    {t}
                    <br />
                  </>
                ))}
              </QuoteDescription>
            </TextSection>
          </TextSectionWrapper>
        ))}

        <StatsWrapper>
          {stats.map((stat, index) => (
            <StatItem key={index}>
              <StatLabel>{stat.label}</StatLabel>
              <StatValue>{stat.value}</StatValue>
            </StatItem>
          ))}
        </StatsWrapper>
        <div
          style={{
            width: '100%',
            height: 256,
          }}
        />
      </Page>
    </Scaffold>
  )
}

const NavText = styled.p`
  color: white;
  text-align: center;
  font-size: 18px;
  user-select: none;
`

const NavButton = styled.button<{ isDisabled: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.isDisabled ? 1 : 0.3)};
  background: none;
  pointer-events: ${(props) => (props.isDisabled ? 'all' : 'none')};
  border: 0;

  svg {
    transition: all ease 0.3s;
  }

  &:hover {
    .left {
      transform: translateX(-4px);
    }

    .right {
      transform: translateX(4px);
    }
  }
`

const ImageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ImageControls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

// const NavigationHeader = styled.div`
// display: flex;
// align-items: center;
// justify-content: flex-end;
// `

// const ProjectIcon = styled.div`
//   width: 48px;
//   height: 48px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px solid rgba(255, 255, 255, 0.4);
//   cursor: pointer;
// `

const ProjectIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
`

const ProjectBox = styled.div`
  display: flex;
  flex: 0 0 200px;
  margin-top: 64px;
  width: 100%;

  ${MOBILE_STYLE} {
    flex-direction: column;
  }
`

const ProjectBoxLeft = styled.div`
  flex: 1;
`

const ProjectBoxRight = styled.div`
  ${DESKTOP_STYLE} {
    flex: 0 0 500px;
  }

  ${TABLET_STYLE} {
    flex: 0 0 500px;
  }

  ${MOBILE_STYLE} {
    margin-bottom: 64px;
    flex-direction: column;
  }
`

const StatLabel = styled.h6`
  color: white;
  font-family: Menlo;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
`

const StatValue = styled.p`
  color: white;
  font-weight: 700;
  font-size: 35px;

  ${MOBILE_STYLE} {
    font-size: 28px;
  }
`

const StatItem = styled.div`
  width: 50%;
  display: flex;
  height: 192px;
  padding: 24px 0;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  /* &:last-child {
    border-bottom: 0;
  } */

  ${MOBILE_STYLE} {
    height: 144px;
  }
`

const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const QuoteTitle = styled.h3`
  font-family: Menlo;
  opacity: 0.6;
  color: white;
  font-size: 14px;
  font-weight: 300;
`

const QuoteText = styled.p`
  color: white;
  font-size: 32px;
  max-width: 800px;
  margin-top: 32px;
`

const QuoteDescription = styled.p`
  font-size: 16px;
  margin-top: 32px;
  line-height: 24px;
  max-width: 800px;
  color: white;
  opacity: 0.6;
`

const TextSection = styled.div`
  margin-bottom: 128px;
`

const TextSectionWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 24px 0;
`

const ProjectTitle = styled.h2`
  color: white;
  font-size: 48px;
  letter-spacing: -0.02em;
  font-family: 'Major Mono Display', monospace;
  font-weight: 600;
`

const ProjectTagline = styled.h4`
  color: white;
  font-size: 24px;
  font-weight: 600;
`

const ProjectDescription = styled.p`
  color: white;
  font-size: 15px;
  line-height: 22px;
  font-weight: 300;
  max-width: 600px;
`

const ImageSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 128px;
`

const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
`

const Video = styled.video`
  width: 100%;
  object-fit: cover;
`

const ImageCaption = styled.div`
  max-width: 1200px;
  color: white;
  font-size: 12px;
  padding: 16px 0;
  font-family: Menlo;
  margin: 0 auto;
  opacity: 0.7;
  font-weight: 300;
`
