import { FaApple } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'
import { FaGooglePlay } from 'react-icons/fa'

export enum Platform {
  Apple = 'apple',
  Google = 'google',
  Web = 'web',
}

export const platformToIcon: Record<
  Platform,
  (params: { color: string; size: number }) => JSX.Element
> = {
  [Platform.Apple]: (params) => <FaApple {...params} />,
  [Platform.Google]: (params) => <FaGooglePlay {...params} size={18} />,
  [Platform.Web]: (params) => <TbWorld {...params} />,
}

export enum App {
  Bloobi = 'bloobi',
  ForOneRed = 'forOneRed',
  Mmyu = 'mmyu',
  Mafia = 'mafia',
  Bloozy = 'bloozy',
  WasteMyTime = 'wasteMyTime',
  Sigma4 = 'sigma4',
}

export const appToColor: Record<App, string> = {
  [App.Bloobi]: '#0080f0',
  [App.ForOneRed]: '#e30000',
  [App.Mmyu]: '#06bf00',
  [App.Mafia]: '#edc009',
  [App.Bloozy]: '#0036d6',
  [App.Sigma4]: '#969696',
  [App.WasteMyTime]: '#d400b0',
}

export const apps = [
  {
    id: App.Bloobi,
    name: 'Bloobi',
    description: 'Unscrambling word game',
    icon: '/noise/bloobi.png',
    links: {
      [Platform.Apple]: 'https://apps.apple.com/us/app/bloobi/id6504361069',
      [Platform.Google]:
        'https://play.google.com/store/apps/details?id=com.foronered.teamb',
    },
  },
  {
    id: App.ForOneRed,
    name: 'For One Red',
    description: 'This website',
    icon: '/logo.svg',
    links: {
      [Platform.Web]: 'https://foronered.com',
    },
  },
  {
    id: App.Mmyu,
    name: 'Mymu',
    description: 'Apartment organization',
    icon: '/noise/mymu.png',
    links: {
      [Platform.Apple]: 'https://apps.apple.com/us/app/mymu/id1502890177',
      [Platform.Google]:
        'https://play.google.com/store/apps/details?id=com.foronered.mymuapp',
    },
  },
  {
    id: App.Mafia,
    name: 'Mafia',
    description: 'Classic party game',
    icon: '/noise/mafia.png',
    links: {
      [Platform.Apple]: 'https://apps.apple.com/app/mafia-classic/id6499224709',
      [Platform.Google]:
        'https://play.google.com/store/apps/details?id=com.foronered.mafia',
    },
  },
  {
    id: App.Bloozy,
    name: 'Bloozy',
    description: 'Daily word game',
    icon: '/noise/bloozy.png',
    links: {
      [Platform.Web]: 'https://bloozy.app',
    },
  },
  {
    id: App.WasteMyTime,
    name: 'Waste My Time',
    description: 'Anywhere, anytime.',
    icon: '/noise/wmt.png',
    links: {
      [Platform.Apple]:
        'https://apps.apple.com/us/app/waste-my-time-app/id1612962487',
      [Platform.Google]:
        'https://play.google.com/store/apps/details?id=com.foronered.wmt',
    },
  },
  {
    id: App.Sigma4,
    name: 'Sigma4',
    description: 'Statistics calculator',
    icon: '/noise/sigma.png',
    links: {
      [Platform.Apple]: 'https://apps.apple.com/us/app/sigma4/id6475413398',
    },
  },
]
