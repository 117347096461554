/* eslint-disable */
/* tslint:disable */

let angleStep = 0

export const sketch = (p5: any) => {
  const canvasDiv: any = document.getElementById('canvas')

  const width = canvasDiv.offsetWidth
  const height = canvasDiv.offsetHeight

  p5.setup = () => {
    p5.createCanvas(width, height)
  }

  p5.draw = () => {
    p5.background('#12c5db')
    p5.frameRate(30)
    p5.stroke('#06272b')
    // Let's pick an angle 0 to 90 degrees based on the mouse position
    // let a = (p5.mouseY / height) * 90
    // Convert it to radians
    theta = p5.radians(angleStep)
    // Start the tree from the bottom of the screen
    p5.translate(width / 2, height)
    // Draw a line 120 pixels
    p5.line(0, 0, 0, -240)
    // Move to the end of that line
    p5.translate(0, -240)
    // Start the recursive branching!
    branch(240)

    angleStep += 0.01
  }

  let theta: any

  function branch(h: any) {
    // Each branch will be 2/3rds the size of the previous one
    h *= 0.66

    // All recursive functions must have an exit condition!!!!
    // Here, ours is when the length of the branch is 2 pixels or less
    if (h > 2) {
      p5.push() // Save the current state of transformation (i.e. where are we now)
      p5.rotate(theta) // Rotate by theta
      p5.line(0, 0, 0, -h) // Draw the branch
      p5.translate(0, -h) // Move to the end of the branch
      branch(h) // Ok, now call myself to draw two new branches!!
      p5.pop() // Whenever we get back here, we "pop" in order to restore the previous matrix state

      // Repeat the same thing, only branch off to the "left" this time!
      p5.push()
      p5.rotate(-theta)
      p5.line(0, 0, 0, -h)
      p5.translate(0, -h)
      branch(h)
      p5.pop()
    }
  }
}
