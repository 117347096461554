import { useState } from 'react'
import { styled } from 'styled-components'

export const Image = (props: {
  src: string
  className?: string
  hideLoading?: boolean
}) => {
  const [loading, setLoading] = useState(true)

  return (
    <ImageInnerWrapper>
      {loading && !props.hideLoading && (
        <ImageLoadingWrapper>
          <Loader />
        </ImageLoadingWrapper>
      )}

      {loading && props.hideLoading && (
        <ImageLoadingWrapper></ImageLoadingWrapper>
      )}
      {props.src.includes('.mp4') ? (
        <video
          src={props.src}
          autoPlay
          muted
          loop
          onLoadedData={() => {
            setLoading(false)
          }}
          style={{
            width: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <ImageRaw
          className={props.className}
          src={props.src}
          onLoad={() => {
            setLoading(false)
          }}
        />
      )}
    </ImageInnerWrapper>
  )
}

const Loader = styled.div`
  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }

  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-right-color: white;
  animation: l2 2s infinite linear;
`

const ImageLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: black;
  z-index: 1;
`

const ImageInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ImageRaw = styled.img`
  width: 100%;
  object-fit: cover;
`
