import { useState } from 'react'
import { Page } from '../../components/Page'
import { P } from '../../components/Text'
import { Scaffold } from '../../scaffold/Scaffold'

export const PrivacyPolicy = () => {
  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'Privacy Policy',
      }}
    >
      <Page title="Privacy Policy" description="Last updated Jan 10, 2024">
        <P
          style={{
            maxWidth: 600,
          }}
        >
          The privacy policy is simple: no personal data shared with us will be
          given to any third party, under any circumstances. Your data will also
          never be used by us for any purpose without specific permission. The
          app engages in no ad targeting, data mining, or other activities that
          may compromise your privacy, and we do not affiliate ourselves with
          any third parties that do so.
        </P>
      </Page>
    </Scaffold>
  )
}
