import { styled } from 'styled-components'
import { Page } from '../../components/Page'
import { Scaffold } from '../../scaffold/Scaffold'
import { apps, appToColor, Platform, platformToIcon } from './constants'
import { WorldMap } from './WorldMap'

export const Noise = () => {
  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'Contact',
        path: 'contact',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            paddingTop: 192,
            maxWidth: 1200,
            width: '100%',
          }}
        >
          <div
            style={{
              padding: 32,
            }}
          >
            <img
              src="/noise-lab.svg"
              style={{
                width: 256,
              }}
            ></img>
          </div>
          <WorldMap />
          <AppsWrapper>
            {apps.map((app, index) => (
              <AppItem
                style={{
                  borderLeft:
                    index % 5 === 0
                      ? '1px solid rgba(255,255,255,0.1)'
                      : 'none',
                }}
              >
                <AppIcon src={app.icon} />

                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#ffffff',
                    marginTop: 16,
                  }}
                >
                  {app.name}
                </p>

                <p
                  style={{
                    color: '#ffffff',
                    fontSize: 12,
                    opacity: 0.4,
                  }}
                >
                  {app.description}
                </p>

                <AppPlatforms>
                  {Object.entries(app.links as any).map(
                    ([key, link], index) => (
                      <PlatformItem
                        key={index}
                        onClick={() => {
                          window.open(link as string, '_blank')
                        }}
                      >
                        {platformToIcon[key as Platform]({
                          size: 20,
                          color: 'white',
                        })}
                      </PlatformItem>
                    ),
                  )}
                </AppPlatforms>
              </AppItem>
            ))}
          </AppsWrapper>
        </div>
      </div>
    </Scaffold>
  )
}

const PlatformItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  cursor: pointer;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0.3;
  }
`

const AppPlatforms = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 8px;
`

const AppIcon = styled.img`
  width: 64px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 64px;
  border-radius: 8px;
  margin-right: 16px;
`

const AppItem = styled.div`
  width: 20%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px;
  gap: 8px;
  position: relative;
`

const AppsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 256px;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`
