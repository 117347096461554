export const Colors = {
  YELLOW_DARK: '#261a02',
  YELLOW_LIGHT: '#fad655',

  GREEN_DARK: '#011c08',
  GREEN_LIGHT: '#46f273',

  RED_DARK: '#260202',
  RED_LIGHT: '#e62e2e',

  BLUE_DARK: '#06272b',
  BLUE_LIGHT: '#12c5db',
}
