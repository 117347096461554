import React, { useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { MOBILE_STYLE } from '../../../responsive'

import { ReactP5Wrapper } from '@p5-wrapper/react'

const Canvas = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    position: relative;
  }

  ${MOBILE_STYLE} {
    padding-bottom: 96px;
  }
`

export const P5Canvas = (props: { sketch: any }) => {
  const [show, setShow] = React.useState(false)

  const [num, setNum] = React.useState(0)

  useLayoutEffect(() => {
    setNum(window.innerWidth)
  }, [])

  const resize = () => {
    setNum(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  React.useLayoutEffect(() => {
    setShow(true)
  }, [])

  if (!show) {
    return <></>
  }

  return (
    <Canvas id="canvas">
      <ReactP5Wrapper sketch={props.sketch} key={num} />
    </Canvas>
  )
}
