import { MOBILE_BREAKPOINT_N } from '../../../responsive'

let angle = 0
let angleStep = 1
let step = 0.3
let zoom = 40
let dotRadius = 8

let initialXAngle = -75
let initialYAngle = 0
let initialZAngle = -75

let minAmplitude = 20
let maxAmplitude = 40

export const sketch = (p5: any) => {
  p5.setup = () => {
    const canvasDiv: any = document.getElementById('canvas')

    const width = canvasDiv.offsetWidth
    const height = canvasDiv.offsetHeight

    p5.frameRate(16)
    p5.createCanvas(width, height, p5.WEBGL)
  }

  p5.draw = () => {
    p5.background('rgb(230,46,46)')
    p5.scale(1)
    p5.translate(0, 0, 0)
    p5.ortho()
    p5.rectMode(p5.CENTER)
    p5.rotateX(p5.radians(initialXAngle + angle))
    p5.rotateY(p5.radians(initialYAngle + angle))
    p5.rotateZ(p5.radians(80))

    for (let x = -8; x < 8; x += step) {
      for (let y = -8; y < 8; y += step) {
        p5.push()

        const a = 2
        const c = 3

        const z = Math.sqrt(
          Math.pow(a, 2) -
            Math.pow(c - Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)), 2),
        )

        if (!isNaN(z)) {
          p5.translate(x * zoom, y * zoom, z * -zoom)
          p5.noStroke()
          p5.fill(255)
          p5.circle(0, 0, Math.abs(z / 3))
          p5.pop()
          p5.push()
          p5.translate(x * zoom, y * zoom, z * zoom)
          p5.fill('rgb(38, 2, 2)')
          p5.circle(0, 0, dotRadius * Math.abs(z / 2))
        }

        p5.pop()
      }
    }
    angle += angleStep
  }
}
