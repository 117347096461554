import { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Bars } from '../../components/Bars'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Colors } from './constants'

const colorOptions = [
  [Colors.BLUE_DARK, Colors.BLUE_LIGHT],
  [Colors.YELLOW_DARK, Colors.YELLOW_LIGHT],
  // [Colors.GREEN_DARK, Colors.GREEN_LIGHT],
  [Colors.RED_DARK, Colors.RED_LIGHT],
]

const LetterComponent = (props: { letter: string }) => {
  const letter = props.letter

  const [index, setIndex] = useState<undefined | number>()

  return (
    <Letter
      style={{
        opacity: letter === ' ' ? 0 : 1,
        background: index !== undefined ? colorOptions[index][0] : 'none',
        color: index !== undefined ? colorOptions[index][1] : 'white',
      }}
      onMouseEnter={() => {
        if (letter !== ' ') {
          setIndex(Math.floor(Math.random() * colorOptions.length))
        }
      }}
      onMouseLeave={() => {
        if (letter !== ' ') {
          setIndex(undefined)
        }
      }}
    >
      {index === undefined
        ? letter === ' '
          ? ':'
          : letter
        : Math.random() < 0.5
        ? 0
        : 1}
    </Letter>
  )
}

export const Featured = () => {
  return (
    <div>
      <FeaturedWrapper>
        <ExhibitionWrapper>
          <Bars />
        </ExhibitionWrapper>
      </FeaturedWrapper>
      <Tagline>
        <div>
          {'We design & code'.split('').map((l, i) => (
            <LetterComponent key={i} letter={l} />
          ))}
        </div>
        {'your digital products.'.split('').map((l, i) => (
          <LetterComponent key={i} letter={l} />
        ))}
      </Tagline>
    </div>
  )
}

const Letter = styled.span`
  color: white;
  margin: 0 -2px 0 -2px;
  cursor: pointer;
  display: inline-block;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &:hover {
    animation: rotate 0.2s ease;
    animation-fill-mode: forwards;
  }
`

const Tagline = styled.h3`
  ${DESKTOP_STYLE} {
    line-height: 96px;
    font-family: 'Major Mono Display', monospace;
    font-size: 72px;
    letter-spacing: -0.02em;
    text-align: left;

    padding: 64px 0 0 0;
    margin: 0 0 64px 0;
  }

  ${TABLET_STYLE} {
    line-height: 72px;
    font-family: 'Major Mono Display', monospace;
    font-size: 64px;
    letter-spacing: -0.02em;
    text-align: left;

    padding: 64px 0 0 0;
    margin: 0 0 64px 0;
  }

  ${MOBILE_STYLE} {
    line-height: 36px;
    font-family: 'Major Mono Display', monospace;
    font-size: 23px;
    letter-spacing: 0.05em;
    text-align: left;

    padding: 48px 0 0 0;
    margin: 0 0 48px 0;
  }
`

const FeaturedWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${DESKTOP_STYLE} {
    padding: 164px 0 0 0;
  }

  ${TABLET_STYLE} {
    padding: 124px 0 0 0;
  }

  ${MOBILE_STYLE} {
    padding: 112px 0 0 0;
    margin-right: -16px;
  }
`

const ExhibitionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  background: white;

  ${DESKTOP_STYLE} {
    height: 400px;
  }

  ${TABLET_STYLE} {
    height: 400px;
  }

  ${MOBILE_STYLE} {
    height: calc(100svh - 270px);
  }
`
