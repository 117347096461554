/* eslint-disable */

const spacing = 12
const radius = 4

let angle = 0

let offset = 0

let x = 0

const TICK_COUNT = 32
const TICK_GUTTER = 24

export const sketch = (p5: any) => {
  p5.setup = () => {
    const canvasDiv: any = document.getElementById('canvas')

    const width = canvasDiv.offsetWidth
    const height = canvasDiv.offsetHeight

    p5.createCanvas(width, height)
    p5.background('rgb(70, 242, 115)')
    // p5.stroke(50)
    // p5.line(0, height / 2, width, height / 2)

    // p5.stroke(50)
    // for (let i = 0; i < TICK_COUNT - 1; i++) {
    //   p5.ellipse((width / TICK_COUNT) * (i + 1), TICK_GUTTER, 2, 2)
    //   p5.ellipse((width / TICK_COUNT) * (i + 1), height - TICK_GUTTER, 2, 2)
    // }
  }

  let lastX = 0
  let lastY = 0

  p5.draw = () => {
    const val = p5.randomGaussian()

    if (x < p5.width) {
      const rand = Math.random()

      const randomWidth = Math.random() * 150 + (x % p5.width)

      let struck = false
      if (rand > 0.35 && rand < 0.37 && randomWidth > lastX) {
        struck = true
        p5.stroke(1)
        p5.strokeWeight(1)
        p5.noFill(0)

        const ny = p5.height / 2 + val * 50
        const nx = randomWidth
        const r = Math.random() * 32 + 12
        p5.ellipse(nx, ny, r, r)
        p5.line(lastX, lastY === 0 ? p5.height / 2 : lastY, nx, ny)
        lastX = nx
        lastY = ny
      }

      p5.noStroke()
      p5.fill(struck ? 1 : 'rgba(0,0,0,0.8)')
      p5.ellipse(randomWidth, p5.height / 2 + val * 50, 4, 4)
      p5.fill(0)
      x++
    } else {
      p5.background('rgb(70, 242, 115)')
      x = 0
      lastY = 0
      lastX = 0

      // p5.stroke(50)
      // p5.line(0, p5.height / 2, p5.width, p5.height / 2)

      // p5.fill(0)
      // p5.stroke(50)
      // for (let i = 0; i < TICK_COUNT - 1; i++) {
      //   p5.ellipse((p5.width / TICK_COUNT) * (i + 1), TICK_GUTTER, 2, 2)
      //   p5.ellipse(
      //     (p5.width / TICK_COUNT) * (i + 1),
      //     p5.height - TICK_GUTTER,
      //     2,
      //     2,
      //   )
      // }
    }
  }
}
