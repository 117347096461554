export const LOGOS = [
  {
    logo: 'Logo-SimSpace.svg',
    logoStyle: {
      width: 200,
      height: 200,
    },
    bgStyle: {},
  },
  {
    logo: 'Logo-bob.svg',
    logoStyle: {
      width: 200,
    },
    bgStyle: {
      background: '#0091b2',
    },
  },
  {
    logo: 'Logo-PostFinance.svg',
    logoStyle: {
      width: 160,
      height: 160,
    },
    bgStyle: {
      background: '#FFCB28',
    },
  },
  {
    logo: 'Logo-breitling.svg',
    logoStyle: {
      width: 250,
      transform: 'scale(1.3)',
    },
    bgStyle: {
      background: 'black',
    },
  },
  {
    logo: 'Logo-Github.svg',
    logoStyle: {
      width: 140,
    },
  },
  {
    logo: 'Logo-IDG.svg',
    logoStyle: {
      width: 212,
    },
    bgStyle: {},
  },
  {
    logo: 'Logo-Valdon.svg',
    logoStyle: {
      width: 200,
      transform: 'scale(1.3)',
    },
    bgStyle: {
      background: 'black',
    },
  },
  {
    logo: 'Logo-HederaStarter.svg',
    logoStyle: {
      width: 180,
    },
    bgStyle: {
      background: 'white',
    },
  },
  {
    logo: 'Logo-Photobastei.svg',
    logoStyle: {
      width: 240,
      transform: 'scale(0.6)',
    },
    bgStyle: {
      background: 'white',
    },
  },
  {
    logo: 'Logo-Danfoss.svg',
    logoStyle: {
      width: 240,
      transform: 'scale(1.3)',
    },
  },
  {
    logo: 'Logo-Transa.svg',
    logoStyle: {
      width: 240,
    },
  },
  {
    logo: 'Logo-Distran.svg',
    logoStyle: {
      width: 240,
    },
  },
  {
    logo: 'Logo-CBank.svg',
    logoStyle: {
      width: 240,
    },
  },
  {
    logo: 'Logo-EatByAlex.svg',
    logoStyle: {
      width: 80,
    },
  },
  {
    logo: 'Logo-SeebadEnge.svg',
    logoStyle: {
      width: 240,
    },
  },
  // ASE
  {
    logo: 'Logo-ASE.svg',
    logoStyle: {
      width: 70,
    },
  },
  // Remotion
  {
    logo: 'Logo-Remotion.svg',
    logoStyle: {
      width: 100,
    },
  },
  {
    logo: 'Logo-Joro.svg',
    logoStyle: {
      width: 140,
      height: 140,
    },
    bgStyle: {
      background: '#FFBA05',
    },
  },
  // {
  //   logo: 'Logo-Kanton-Zurich.svg',
  //   logoStyle: {
  //     width: 100,
  //   },
  // },
]
