import { useState } from 'react'
import { Page } from '../../components/Page'
import { Scaffold } from '../../scaffold/Scaffold'

export const NotFound = () => {
  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: '404',
      }}
    >
      <Page
        title="404. Page not found."
        description="The page you are looking for does not exist."
      >
        <></>
      </Page>
    </Scaffold>
  )
}
