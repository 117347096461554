import React, { useState } from 'react'
import styled from 'styled-components'
import { FiMenu } from 'react-icons/fi'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'
import { useViewport, Viewport } from './providers/ViewportProvider'
import { useLocation, useNavigate } from 'react-router-dom'

export const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const navItems = [
    {
      label: 'Work',
      link: '/work',
    },
    {
      label: 'Team',
      link: '/team',
    },
    // {
    //   label: (
    //     <span>
    //       <span style={{ fontStyle: 'italic', marginRight: 4 }}>Noise</span> Lab
    //     </span>
    //   ),
    //   link: '/noise',
    // },
    {
      label: 'Contact',
      link: '/contact',
    },
  ]

  const viewport = useViewport()

  return (
    <HeaderWrapper>
      <HeaderInnerWrapper>
        <LeftWrapper
          onClick={() => {
            navigate('/')
          }}
        >
          <Logo src="/logo.svg" alt="logo" />
        </LeftWrapper>

        {open && (
          <PopoverContainer>
            <NavigationPopover>
              <Logo
                src="/logo-black.svg"
                alt="logo"
                style={{
                  position: 'absolute',
                  top: 24,
                  left: 24,
                }}
                onClick={(e) => {
                  navigate('/')
                  setOpen(false)
                }}
              />
              {navItems.map((item, index) => (
                <MobileNavItem
                  key={index}
                  onClick={() => {
                    navigate(item.link)
                    setOpen(false)
                  }}
                >
                  <p>{item.label}</p>
                </MobileNavItem>
              ))}
            </NavigationPopover>
            <CloseWrapper
              onClick={() => {
                setOpen(false)
              }}
            ></CloseWrapper>
          </PopoverContainer>
        )}

        {viewport === Viewport.Mobile ? (
          <MenuButton
            onClick={(e) => {
              e.stopPropagation()
              setOpen(true)
            }}
          >
            <FiMenu size={24} color="white" />
          </MenuButton>
        ) : (
          <Navigation>
            <ol
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {navItems.map((item, index) => (
                <NavItem
                  active={location.pathname === item.link}
                  style={{
                    opacity: location.pathname === item.link ? 1 : 1,
                    fontSize: 12,
                  }}
                  key={index}
                >
                  <a
                    href={item.link}
                    style={{
                      height: '100%',
                      display: 'block',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(item.link)
                    }}
                  >
                    {item.label}
                  </a>
                </NavItem>
              ))}
            </ol>
          </Navigation>
        )}
      </HeaderInnerWrapper>
    </HeaderWrapper>
  )
}

const CloseWrapper = styled.div`
  width: 100%;
  flex: 1;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  margin-top: -1;
`

const PopoverContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const MobileNavItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    p {
      opacity: 0.7;
    }
  }

  p {
    width: 100%;
    color: white;
    padding: 16px 0;
    font-size: 32px;
    text-align: right;
  }
`

const NavigationPopover = styled.div`
  padding: 32px;
  background: black;
`

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 12px;

  ${DESKTOP_STYLE} {
  }

  ${TABLET_STYLE} {
  }

  ${MOBILE_STYLE} {
    display: none;
  }
`

const NavItem = styled.li<{ active: boolean }>`
  margin: 0 24px;
  height: 28px;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  transition: all ease 0.3s;
  border-bottom: 1px solid ${({ active }) => (active ? 'white' : 'transparent')};

  a {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.7;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }
`

const LeftWrapper = styled.div`
  display: flex;
  cursor: pointer;

  ${DESKTOP_STYLE} {
    align-items: center;
    margin-right: auto;
  }

  ${TABLET_STYLE} {
    align-items: center;
    margin-right: auto;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
  }
`

const Logo = styled.img`
  width: 64px;
  height: 64px;
  cursor: pointer;
  margin-left: -12px;
`

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;

  padding: ${({ theme }) => theme.gutter}px;

  h1 {
    height: 28px;
  }

  ${DESKTOP_STYLE} {
    align-items: center;
  }

  ${TABLET_STYLE} {
    align-items: center;
  }

  ${MOBILE_STYLE} {
    align-items: center;
    justify-content: space-between;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`

const MenuButton = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${MOBILE_STYLE} {
    margin-right: -20px;
  }
`
