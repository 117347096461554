import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'

export const Section = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-start;
  padding-bottom: 256px;

  ${DESKTOP_STYLE} {
  }

  ${TABLET_STYLE} {
  }

  ${MOBILE_STYLE} {
    padding-bottom: 128px;
    flex-direction: column;
  }
`
