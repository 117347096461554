import { ReactNode } from 'react'
import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'
import { SectionHeader } from './SectionHeader'

export const Page = (props: {
  children: ReactNode
  title: string
  description: string
}) => {
  return (
    <Wrapper>
      {props.title ? (
        <SectionHeader {...props} />
      ) : (
        <div style={{ marginTop: 144 }} />
      )}
      {props.children}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  min-height: 100vh;

  ${DESKTOP_STYLE} {
    padding: ${({ theme }) => `144px ${theme.gutter}px 0 ${theme.gutter}px `};
  }

  ${TABLET_STYLE} {
    padding: ${({ theme }) => `144px ${theme.gutter}px 0 ${theme.gutter}px `};
  }

  ${MOBILE_STYLE} {
    padding: ${({ theme }) => `112px ${theme.gutter}px 0 ${theme.gutter}px `};
  }
`
