import { ReactNode } from 'react'
import styled from 'styled-components'
import { LinkButton } from '../../components/Button'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import {
  useViewport,
  Viewport,
} from '../../scaffold/providers/ViewportProvider'

export const ServiceBlockComponent = (props: {
  title: string
  description: string
  textColor: string
  bgColor: string
  animation: ReactNode
  caseStudies: Array<{
    text: string
    link?: string
  }>
}) => {
  const viewport = useViewport()

  const { textColor, bgColor } = props
  return (
    <ServiceBlock>
      <ServiceLeft>
        <ServiceTitle style={{ color: 'white' }}>{props.title}</ServiceTitle>
        <ServiceDescription style={{ color: 'white' }}>
          {props.description}
        </ServiceDescription>
        <ServiceSubtitle style={{ color: 'white' }}>
          Case studies
        </ServiceSubtitle>

        {props.caseStudies.map((c, k) => (
          <LinkButton
            key={k}
            label={c.text}
            textColor={'white'}
            link={c.link}
          />
        ))}
      </ServiceLeft>
      <ServiceRight>
        {typeof window !== 'undefined' &&
          (viewport === Viewport.Desktop || viewport === Viewport.Tablet) &&
          props.animation}
      </ServiceRight>
    </ServiceBlock>
  )
}

const ServiceBlock = styled.div`
  display: flex;
  overflow: hidden;

  ${DESKTOP_STYLE} {
    background-color: rgba(255, 255, 255, 0.08);
    height: 800px;
    flex-direction: row;
    margin-bottom: 64px;
  }

  ${TABLET_STYLE} {
    background-color: rgba(255, 255, 255, 0.08);
    height: 800px;
    flex-direction: row;
    margin-bottom: 64px;
  }

  ${MOBILE_STYLE} {
    background-color: none;
    flex-direction: column-reverse;
    margin-bottom: 96px;
  }
`

const ServiceLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${DESKTOP_STYLE} {
    padding: 64px;
    flex: 0 0 600px;
  }

  ${TABLET_STYLE} {
    padding: 64px;
    flex: 0 0 600px;
  }

  ${MOBILE_STYLE} {
    padding: 0;
    flex: 1;
  }
`

const ServiceRight = styled.div`
  ${DESKTOP_STYLE} {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }

  ${TABLET_STYLE} {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }

  ${MOBILE_STYLE} {
    display: none;
  }
`

const ServiceTitle = styled.h3`
  font-family: 'Major Mono Display', monospace;

  ${DESKTOP_STYLE} {
    font-size: 48px;
    letter-spacing: -0.04em;
    line-height: 64px;
  }

  ${TABLET_STYLE} {
    font-size: 48px;
    letter-spacing: -0.04em;
    line-height: 64px;
  }

  ${MOBILE_STYLE} {
    font-size: 32px;
    letter-spacing: -0.04em;
    line-height: 40px;
  }
`

const ServiceSubtitle = styled.h4`
  margin: 48px 0 24px; 0;
  font-size: 16px;
`

const ServiceDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 32px;
  max-width: 600px;
`
