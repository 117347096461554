import styled from 'styled-components'
import { P } from '../../components/Text'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Scaffold } from '../../scaffold/Scaffold'

export const Impressum = () => {
  return (
    <Scaffold bgColor="#000000">
      <Wrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h1
            style={{
              color: 'white',
              width: '100%',
              textAlign: 'center',
              marginTop: 64,
            }}
          >
            Impressum
          </h1>
        </div>

        <Group>
          <h2 style={{ color: 'white' }}>UNTERNEHMEN</h2>
          <ContentRow>
            <ContentGroup>
              <h3 style={{ color: 'white', marginBottom: 16 }}>Domicile</h3>
              <P>For One Red AG</P>
              <P>Pflugstrasse 19</P>
              <P>8006 Zürich, Switzerland</P>
            </ContentGroup>
            <ContentGroup>
              <h3 style={{ color: 'white', marginBottom: 16 }}>Office</h3>
              <P>For One Red AG</P>
              <P>Hohlstrasse 186</P>
              <P>8004 Zürich, Switzerland</P>
            </ContentGroup>
          </ContentRow>
        </Group>
        <Group>
          <h2 style={{ color: 'white' }}>GESCHÄFTSFÜHRUNG</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                a) Lukas Zirngibl <br /> <br />
              </P>

              <P>Sitz: Zürich</P>
              <P>Gründungsjahr: 2021</P>
              <P>Referenz im Handelsregister: CH-020.4.075.498-9</P>
              <P>Unternehmens-Identifikationsnummer: CHE-278.664.204</P>
              <P>Gesellschaftsform: Aktiengesellschaft</P>
            </ContentGroup>
          </ContentRow>
        </Group>
        <Group>
          <h2 style={{ color: 'white' }}>KONTAKT</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                E-Mail:{' '}
                <a href="mailto:hello@foronred.com" style={{ color: 'white' }}>
                  hello@foronered.com
                </a>
              </P>
              <P>Internetadresse: www.foronered.com</P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>
            VERANTWORTLICH IM SINNE DES PRESSERECHTS
          </h2>
          <ContentRow>
            <ContentGroup>
              <P>Lukas Zirngibl</P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>RECHTSHINWEIS / LEGAL NOTE</h2>
          <ContentRow>
            <ContentGroup>
              <P>Rechtliche Hinweise</P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>HAFTUNGSAUSSCHLUSS</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                Eine Haftung jeglicher Art für die Inhalte dieser
                Internetpräsenz und deren Nutzung durch die For One Red AG sowie
                Haftungsansprüche und Schadenersatz wegen fehlerhafter oder
                fehlender Angaben sind grundsätzlich ausgeschlossen. Es besteht
                keine Gewähr für Aktualität, Korrektheit oder Vollständigkeit
                der gemachten Angaben. Alle Angaben erfolgen nach bestem Wissen
                und Gewissen. Die Unwirksamkeit einzelner Formulierungen berührt
                andere Formulierungen in Bezug auf Inhalt und Gültigkeit nicht.
                Die Verwendung von Ausdrücken wie "For One Red" innerhalb dieser
                Internetpräsenz bezieht sich auf die For One Red AG in Zürich am
                Main im Allgemeinen.
              </P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>LINKS UND VERWEISE</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                Für die Inhalte verlinkter Seiten (externer Links) sind
                ausschliesslich deren Betreiber verantwortlich.
                Haftungsansprüche für Links sind grundsätzlich ausgeschlossen,
                sofern seitens der For One Red AG kein vorsätzliches oder grob
                fahrlässiges Verschulden vorliegt.
              </P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>URHEBERRECHT</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                Die Inhalte auf dieser Internetpräsenz der For One Red AG sind
                urheberrechtlich geschützt. Sofern nicht anders vermerkt, ist
                für die Verwendung, Weitergabe oder Auswertung der Texte, der
                Grafiken und Logos, der Designs und anderer Inhalte eine
                schriftliche Genehmigung durch die For One Red AG erforderlich.
              </P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>DATENSCHUTZERKLÄRUNG</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                Alle Daten werden gemäss den Bestimmungen des deutschen
                Datenschutzrechts verarbeitet und geschützt.
              </P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>DATENSCHUTZERKLÄRUNG</h2>
          <ContentRow>
            <ContentGroup>
              <P>
                Im Internet gibt es keine absolute Sicherheit bei der
                Datenübertragung. Dies gilt insbesondere für die Übertragung und
                das Versenden von E-Mails. Schicken sie uns daher bitte unter
                keinen Umständen vertrauliche Daten über das Internet. Für
                Schäden, die durch den Missbrauch von vertraulichen Daten durch
                unbefugte Dritte entstehen, übernimmt die For One Red AG keine
                Haftung.
              </P>
            </ContentGroup>
          </ContentRow>
        </Group>

        <Group>
          <h2 style={{ color: 'white' }}>GERICHTSSTAND</h2>
          <ContentRow>
            <ContentGroup>
              <P>Es gilt Schweizer Recht. Gerichtsstand ist Zürich.</P>
            </ContentGroup>
          </ContentRow>
        </Group>
      </Wrapper>
    </Scaffold>
  )
}

const ContentGroup = styled.div`
  ${DESKTOP_STYLE} {
    margin-right: 48px;
  }
`

const ContentRow = styled.div`
  display: flex;
  padding-top: 48px;

  ${DESKTOP_STYLE} {
    flex-direction: row;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
  }
`

const Group = styled.div`
  margin-top: 96px;
`

const Logo = styled.img`
  width: 124px;
  height: 124px;
  cursor: pointer;
`

const Wrapper = styled.div`
  min-height: 800px;
  max-width: 800px;
  margin: 212px auto 0 auto;

  ${DESKTOP_STYLE} {
    padding: 0 72px 256px 72px;
  }

  ${TABLET_STYLE} {
    padding: 0 28px 128px 28px;
  }

  ${MOBILE_STYLE} {
    padding: 0 24px 96px 24px;
  }
`
