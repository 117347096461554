import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'

export const P = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 24px;
  font-size: 16px;
  font-weight: 200;
`

export const Paragraph = styled(P)`
  ${DESKTOP_STYLE} {
    height: 200px;
  }

  ${TABLET_STYLE} {
    height: 200px;
  }

  ${MOBILE_STYLE} {
    height: auto;
  }
`

export const ParagraphTitle = styled.h3`
  color: white;
  margin-bottom: 16px;
`
