/* eslint-disable */

let NUMSINES = 10 // how many of these things can we do at once?
let sines = new Array(NUMSINES) // an array to hold all the current angles
let rad: any // an initial radius value for the central sine
let i // a counter variable

// play with these to get a sense of what's going on:
let fund = 0.001 // the speed of the central sine
let ratio = 1 // what multiplier for speed is each additional sine?
let alpha = 50 // how opaque is the tracing system

let trace = false // are we tracing?

export const sketch = (p5: any) => {
  const canvasDiv: any = document.getElementById('canvas')

  const width = canvasDiv.offsetWidth
  const height = canvasDiv.offsetHeight

  p5.setup = () => {
    p5.createCanvas(width, height)

    rad = height / 4 // compute radius for central circle

    for (let i = 0; i < sines.length; i++) {
      sines[i] = p5.PI // start EVERYBODY facing NORTH
    }

    p5.createCanvas(width, height)
  }

  p5.draw = () => {
    if (!trace) {
      p5.background('#fad655') // clear screen if showing geometry
      p5.stroke(0, '#7a4806') // black pen
      p5.noFill() // don't fill
    }

    // MAIN ACTION
    p5.push() // start a transformation matrix
    p5.translate(width / 2, height / 2) // move to middle of screen

    for (let i = 0; i < sines.length; i++) {
      let erad = 0 // radius for small "point" within circle... this is the 'pen' when tracing
      // setup for tracing
      if (trace) {
        p5.stroke(0, 0, 255 * (p5.float(i) / sines.length), alpha) // blue
        p5.fill(0, 0, 255, alpha / 2) // also, um, blue
        erad = 5.0 * (1.0 - p5.float(i) / sines.length) // pen width will be related to which sine
      }
      let radius = rad / (i + 1) // radius for circle itself
      p5.rotate(sines[i]) // rotate circle
      if (!trace) p5.ellipse(0, 0, radius * 2, radius * 2) // if we're simulating, draw the sine
      p5.push() // go up one level
      p5.translate(0, radius) // move to sine edge
      if (!trace) p5.ellipse(0, 0, 5, 5) // draw a little circle
      if (trace) p5.ellipse(0, 0, erad, erad) // draw with erad if tracing
      p5.pop() // go down one level
      p5.translate(0, radius) // move into position for next sine
      sines[i] = (sines[i] + (fund + fund * i * ratio)) % p5.TWO_PI // update angle based on fundamental
    }

    p5.pop() // pop down final transformation
  }

  function keyReleased() {
    if (p5.key == ' ') {
      trace = !trace
      p5.background(255)
    }
  }
}
