import React, { ReactNode } from 'react'
import { MOBILE_BREAKPOINT_N, TABLET_BREAKPOINT_N } from '../../responsive'

export enum Viewport {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

const ViewportContext = React.createContext<Viewport>(Viewport.Desktop)

ViewportContext.displayName = 'ViewportContext'

export const useViewport = (): Viewport => {
  const viewport = React.useContext(ViewportContext)
  return viewport
}

export const ViewportProvider = (props: { children: ReactNode }) => {
  const [viewport, setViewport] = React.useState<Viewport>(Viewport.Desktop)

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT_N) {
      setViewport(Viewport.Mobile)
    } else if (window.innerWidth < TABLET_BREAKPOINT_N) {
      setViewport(Viewport.Tablet)
    } else {
      setViewport(Viewport.Desktop)
    }
  }

  React.useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ViewportContext.Provider value={viewport}>
      {props.children}
    </ViewportContext.Provider>
  )
}
