import styled, { useTheme } from 'styled-components'
import { AiFillInstagram } from 'react-icons/ai'
import { FaLinkedinIn, FaTiktok } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../responsive'
import { useNavigate } from 'react-router-dom'
import { Bars } from '../components/Bars'

export const Footer = (props: { bgColor?: string }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const social = [
    {
      link: 'https://www.instagram.com/foronered',
      icon: <AiFillInstagram color="white" size={24} />,
    },
    {
      link: 'https://www.tiktok.com/@foronered',
      icon: <FaTiktok color="white" size={20} />,
    },
    {
      link: 'https://www.linkedin.com/company/for-one-red',
      icon: <FaLinkedinIn color="white" size={20} />,
    },
    {
      link: 'mailto:hello@foronered.com',
      icon: <MdEmail color="white" size={24} />,
    },
  ]

  return (
    <FooterWrapper
      style={{
        backgroundColor: props.bgColor || '#01031a',
      }}
    >
      <Top>
        <Copyright>
          For One Red - ©{new Date().getFullYear()}{' '}
          <Impressum
            onClick={() => {
              navigate('/impressum')
            }}
          >
            Impressum
          </Impressum>
        </Copyright>
        <SwissMadeWrapper>
          <a
            href="https://www.swissmadesoftware.org"
            onClick={(e) => {
              e.preventDefault()
              window.open('https://www.swissmadesoftware.org', '_blank')
            }}
          >
            <SwissMadeSoftware
              alt="Swiss made software"
              src="/swiss-made-software.png"
            />
          </a>
        </SwissMadeWrapper>
        <SocialWrapper>
          {social.map((s, k) => (
            <SocialItem
              onClick={(e) => {
                e.preventDefault()
                window.open(s.link, '_blank')
              }}
              href={s.link}
              key={k}
            >
              {s.icon}
            </SocialItem>
          ))}
        </SocialWrapper>
      </Top>
      {/* <Bottom>
        <Bars />
      </Bottom> */}
    </FooterWrapper>
  )
}

const Top = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.gutter}px;

  ${DESKTOP_STYLE} {
    height: 64px;
    align-items: center;
  }

  ${TABLET_STYLE} {
    height: 64px;
    align-items: center;
  }

  ${MOBILE_STYLE} {
    height: 192px;
    padding: 32px;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
`

const Bottom = styled.div`
  height: 112px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: white;
`

const SwissMadeWrapper = styled.div`
  ${DESKTOP_STYLE} {
    margin-right: auto;
  }

  ${TABLET_STYLE} {
    margin-right: auto;
  }

  ${MOBILE_STYLE} {
    margin: 16px 0;
    padding-left: 2px;
  }
`

const SwissMadeSoftware = styled.img`
  height: 24px;
  margin-top: 8px;
`

const Impressum = styled.a`
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;

  ${DESKTOP_STYLE} {
    padding-right: 16px;
    margin-left: 24px;
  }

  ${TABLET_STYLE} {
    padding-right: 16px;
  }

  ${MOBILE_STYLE} {
    padding-left: 2px;
  }
`

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;

  ${DESKTOP_STYLE} {
    padding-right: 16px;
  }

  ${TABLET_STYLE} {
    padding-right: 16px;
  }

  ${MOBILE_STYLE} {
    padding-left: 2px;
  }
`

const FooterWrapper = styled.div``

const SocialItem = styled.a`
  opacity: 0.4;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    opacity: 1;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
