import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Colors } from '../home/constants'
import { TEAM } from '../../data/team.data'
import { Image } from '../../components/Image'

const colors = [
  Colors.BLUE_LIGHT,
  Colors.GREEN_LIGHT,
  Colors.RED_LIGHT,
  Colors.YELLOW_LIGHT,
]

export const TeamSection = () => {
  return (
    <TeamWrapper>
      {TEAM.map((member, i) => {
        return (
          <TeamMemberItem key={i}>
            <HeadshotWrapper>
              <HeadshotOverlay
                className="overlay"
                style={{
                  background: colors[Math.floor(Math.random() * colors.length)],
                }}
              />
              <Image src={`/headshots/${member.image}`} hideLoading />
            </HeadshotWrapper>
            <TeamMemberName>{member.name}</TeamMemberName>
            <TeamMemberRole>{member.title}</TeamMemberRole>
            <TeamMemberDescription>{member.description}</TeamMemberDescription>
          </TeamMemberItem>
        )
      })}
    </TeamWrapper>
  )
}

const HeadshotOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
`

const HeadshotWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Headshot = styled.img`
  width: 100%;
  opacity: 1;
  height: 100%;
  object-fit: cover;
`

const TeamMemberRole = styled.h5`
  color: white;
  font-weight: 300;
  margin-top: 4px;
  font-size: 18px;
`

const TeamMemberDescription = styled.p`
  color: white;
  font-weight: 300;
  line-height: 23px;
  font-size: 16px;
  margin-top: 24px;
  opacity: 0.8;
`

const TeamMemberName = styled.p`
  color: white;
  font-weight: 600;
  font-size: 28px;
  margin-top: 20px;
  transition: all ease 0.3s;
`

const TeamMemberItem = styled.div`
  flex: 0 0 256px;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  transition: all ease 0.3s;
  padding: 0 12px;
  margin-bottom: 64px;
  cursor: pointer;
`

const TeamWrapper = styled.div`
  padding-bottom: 128px;
  display: grid;

  ${DESKTOP_STYLE} {
    grid-template-columns: 25% 25% 25% 25%;
    gap: 2px;
    padding-top: 48px;
  }

  ${TABLET_STYLE} {
    grid-template-columns: 33.33% 33.33% 33.33%;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 16px;
  }

  ${MOBILE_STYLE} {
    width: 312px;
    margin: 0 auto;
    flex-direction: column;
    margin-bottom: 64px;
  }
`
