import { Colors } from '../pages/home/constants'
import { P5Canvas } from '../pages/home/p5/CanvasWrapper'
import { sketch as EnterpriseAnimation } from '../pages/home/p5/Flock'
import { sketch as MVPAnimation } from '../pages/home/p5/Penrose'
import { sketch as UXAnimation } from '../pages/home/p5/Spiro'
import { sketch as InnovationAnimation } from '../pages/home/p5/GameOfLife'
import { sketch as DonutAnimation } from '../pages/home/p5/Torus'
import { Project } from './work.data'

export const SERVICES = [
  {
    title: 'Enterprise software',
    label: 'Enterprise software',
    description: `
    We build products that solve immediate problems and scale to meet future needs. Our team has no problem starting from scratch or jumping into an existing project. We work with you to understand your business and build a product that meets its needs. We will see the product through every step of the maturation process, from concept to launch.
    `,
    animation: <P5Canvas sketch={EnterpriseAnimation} />,
    bgColor: Colors.BLUE_DARK,
    textColor: Colors.BLUE_LIGHT,
    caseStudies: [
      {
        text: 'Distran Fix Detectors',
        link: `/detail/${Project.distranFixDetectors}`,
      },
      {
        text: 'Bob Finance Privatkredite',
        link: `/detail/${Project.bobWhitelabel}`,
      },
      {
        text: 'Rapid Range',
        link: `/detail/${Project.rapidRange}`,
      },
    ],
  },
  {
    title: 'UX design',
    label: 'UX design',
    description: `
    We design products that are intuitive and easy to use. Our team of designers and researchers work together to understand user needs and create a product that intuitive, modern, and beautiful.
    `,
    animation: <P5Canvas sketch={UXAnimation} />,
    bgColor: Colors.YELLOW_DARK,
    textColor: Colors.YELLOW_LIGHT,
    caseStudies: [
      {
        text: '#GitHubUnwrapped',
        link: `/detail/${Project.githubUnwrapped}`,
      },
      {
        text: 'Rapid Range',
        link: `/detail/${Project.rapidRange}`,
      },
      {
        text: 'Distran Camera UI',
        link: `/detail/${Project.distranTouchUI}`,
      },
    ],
  },
  {
    title: 'Strategy & branding',
    label: 'Branding',
    description: `
   We help you build a brand that resonates with your target audience. Our team of strategic designers will work closely with you to develop a unique brand identity that captures your essence and values. This includes crafting a compelling brand story, designing a memorable logo and visual system, and developing messaging that connects with your customers.
    `,
    animation: <P5Canvas sketch={InnovationAnimation} />,
    bgColor: Colors.GREEN_DARK,
    textColor: Colors.GREEN_LIGHT,
    caseStudies: [
      {
        text: 'Eat by Alex',
        link: `/detail/${Project.eatByAlex}`,
      },
      {
        text: 'SimSpace',
        link: `/detail/${Project.simspace}`,
      },
    ],
  },
  {
    title: 'mvps & Prototypes',
    label: 'Innovation teams',
    description: `
   We act as your trusted innovation partner, sparking creativity and propelling your business forward. Our team combines design thinking with cutting-edge research to help you identify opportunities, develop new products and services, and improve existing processes. We don’t just brainstorm - we function as your product incubator team, fostering and nurturing breakthrough ideas from concept to launch.

    `,
    animation: <P5Canvas sketch={DonutAnimation} />,
    bgColor: Colors.GREEN_DARK,
    textColor: Colors.GREEN_LIGHT,

    caseStudies: [
      {
        text: 'Cyber Quest',
        link: `/detail/${Project.cyberQuest}`,
      },
      {
        text: 'Cyber World',
        link: `/detail/${Project.cyberWorld}`,
      },
      {
        text: 'SOC Daily',
        link: `/detail/${Project.socDaily}`,
      },
    ],
  },
]
