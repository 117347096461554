import { P5Canvas } from '../home/p5/CanvasWrapper'
import { sketch } from './Globe'
import { io } from 'socket.io-client'
import { useEffect, useMemo, useState } from 'react'
import { App, appToColor } from './constants'
import { styled } from 'styled-components'

const API_URL = process.env.REACT_APP_API_URL

const MAP_WIDTH = 1200
const MAP_HEIGHT = 680

var p0: any = {
  scrX: 0, // Minimum X position on screen
  scrY: 0, // Minimum Y position on screen
  lat: 90, // Latitude
  lng: -180, // Longitude
}

//bottom-right reference point
var p1: any = {
  scrX: MAP_WIDTH, // Maximum X position on screen
  scrY: MAP_HEIGHT, // Maximum Y position on screen
  lat: -90, // Latitude
  lng: 180, // Longitude
}

var radius = 6371 //Earth Radius in Km

function latlngToGlobalXY(latlng: { lat: number; lng: number }) {
  //Calculates x based on cos of average of the latitudes
  let x = radius * latlng.lng * Math.cos((p0.lat + p1.lat) / 2)
  //Calculates y based on latitude
  let y = radius * latlng.lat
  return { x: x, y: y }
}

// Calculate global X and Y for top-left reference point
p0.pos = latlngToGlobalXY({ lat: p0.lat, lng: p0.lng })
// Calculate global X and Y for bottom-right reference point
p1.pos = latlngToGlobalXY({ lat: p1.lat, lng: p1.lng })

// This function converts lat and lng coordinates to SCREEN X and Y positions
function latlngToScreenXY({ lat, lng }: { lat: number; lng: number }) {
  //Calculate global X and Y for projection point
  let pos: any = latlngToGlobalXY({ lat, lng })
  //Calculate the percentage of Global X position in relation to total global width
  pos.perX = (pos.x - p0.pos.x) / (p1.pos.x - p0.pos.x)
  //Calculate the percentage of Global Y position in relation to total global height
  pos.perY = (pos.y - p0.pos.y) / (p1.pos.y - p0.pos.y)

  //Returns the screen position based on reference points
  return {
    x:
      p0.scrX +
      (p1.scrX - p0.scrX) * pos.perX +
      Math.pow(1 + Math.abs(lng / 180) * 4.8, 2) * (lng < 0 ? 1 : -1) -
      12,
    y: p0.scrY + (p1.scrY - p0.scrY) * pos.perY,
  }
}

const computeApp = (path: string): App => {
  if (path.includes('bloobi')) {
    return App.Bloobi
  }

  if (path.includes('wmt')) {
    return App.WasteMyTime
  }

  if (path.includes('for')) {
    return App.ForOneRed
  }

  if (path.includes('mymu')) {
    return App.Mmyu
  }

  if (path.includes('mafia')) {
    return App.Mafia
  }

  if (path.includes('bloozy')) {
    return App.Bloozy
  }

  if (path.includes('sigma4')) {
    return App.Sigma4
  }

  return App.ForOneRed
}

export const WorldMap = () => {
  const socket = useMemo(() => {
    let apiURL = API_URL!

    return io(apiURL || '', {
      autoConnect: false,
    })
  }, [])

  const [isConnected, setIsConnected] = useState(socket.connected)

  const [data, setData] = useState<
    Array<{
      x: number
      y: number
      created: number
      app: App
    }>
  >([])

  useEffect(() => {
    function onConnect() {
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    function onData(payload: { latlng: [number, number]; path: string }) {
      const { x, y } = latlngToScreenXY({
        lat: payload.latlng[0],
        lng: payload.latlng[1],
      })

      setData((d) => [
        ...d,
        {
          x,
          y,
          created: Date.now(),
          app: computeApp(payload.path),
        },
      ])
    }

    function onError(err: any) {
      console.error(err)
    }

    socket.on('data', onData)

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('connect_error', onError)

    // socket.onAny((event, ...args) => {
    //   console.log('any', event, args)
    // })

    // console.log('connecting...')

    socket.auth = {
      type: 'for',
    }

    setTimeout(() => {
      socket.connect()
    }, 1000)

    return () => {
      socket.off('data', onData)

      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('connect_error', onError)
      socket.disconnect()
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: 620,
        borderTop: '1px solid rgba(255,255,255,0.1)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          position: 'absolute',
          bottom: 12,
          right: 0,
          zIndex: 10,
          gap: 8,
        }}
      >
        <p
          style={{
            fontSize: 12,
            color: 'rgba(255,255,255,0.2)',
          }}
        >
          {isConnected ? 'Connected' : 'Disconnected'}
        </p>
        <div
          style={{
            width: 6,
            height: 6,
            borderRadius: '50%',
            backgroundColor: isConnected ? 'green' : 'red',
          }}
        />
      </div>
      <div
        style={{
          width: 1200,
          height: 800,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        {data.map((d) => (
          <Dot
            key={d.created}
            style={{
              left: d.x,
              top: d.y,

              backgroundColor: appToColor[d.app] || 'red',
            }}
          ></Dot>
        ))}
      </div>
      <div
        style={{
          width: MAP_WIDTH,
          height: MAP_HEIGHT,
          //   marginLeft: -60,
        }}
      >
        <P5Canvas sketch={sketch}></P5Canvas>
      </div>
    </div>
  )
}

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;

  @keyframes appear {
    0% {
      transform: scale(6);
      opacity: 0.2;
    }

    10% {
      transform: scale(1);
      opacity: 0.6;
    }

    95% {
      transform: scale(1);
      opacity: 0.6;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  animation: appear 60s linear;
  animation-fill-mode: forwards;
`
