import styled from 'styled-components'
import { Page } from '../../components/Page'
import { Section } from '../../components/Section'
import { SectionHeader } from '../../components/SectionHeader'
import { P, Paragraph, ParagraphTitle } from '../../components/Text'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Scaffold } from '../../scaffold/Scaffold'
import { CULTURE, OFFICE } from '../../data/team.data'
import { TeamSection } from './TeamSection'
import { Helmet } from 'react-helmet'

export const Team = () => {
  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'Team',
        path: 'team',
      }}
    >
      <Page title="Team" description={`Meet the For One Red team`}>
        <TeamSection />
        <SectionHeader
          title="Office"
          description="Our office is located in the heart of Zürich's Kreis 6. We're a stone's throw away from Hardbrücke and a bunch of trendy (hipster) bars & restaurants."
        />
        <Section>
          {OFFICE.map((o, k) => (
            <Column key={k}>
              <Square>
                <Image src={o.image} alt="foronered office" />
              </Square>
              <ParagraphTitle>{o.title}</ParagraphTitle>
              <Paragraph>{o.description}</Paragraph>
              <List>
                {o.list.map((i) => (
                  <ListItem key={i}>{i}</ListItem>
                ))}
              </List>
            </Column>
          ))}
        </Section>
        <SectionHeader
          title="Culture"
          description={`First and foremost, we all enjoy building things. There is nothing better than basking in the glory of our compiling code and componentized Figma design.`}
        />
        <Section>
          {CULTURE.map((o, k) => (
            <Column key={k}>
              <Square>
                <Image src={o.image} alt="foronered office" />
              </Square>
              <ParagraphTitle>{o.title}</ParagraphTitle>
              <Paragraph>{o.description}</Paragraph>
            </Column>
          ))}
        </Section>
        {/* <SectionHeader
        title="3D Printing Farm"
        description={`
          We also have one of Zürich's finest 3D printing farms. It's our robot sweatshop that runs 24/7.
        `}
      /> */}
        {/* <Section>
        <MachinesWrapper>
          {[
            'Vreni',
            'Urs',
            'Hans',
            'Heidi',
            'Peter',
            'Dorli',
            'Beat',
            'Emma',
            'Gritli',
            'Ueli',
            'Bethli',
            'Reto',
          ].map((name, index) => (
            <MachineItem key={index}>
              <MachineImage src="/images/printer.png" />
              <MachineScreen>
                <Circle />
              </MachineScreen>
              <MachineName>{name}</MachineName>
            </MachineItem>
          ))}
        </MachinesWrapper>
      </Section> */}
        {/* <SectionHeader
        title="Technology"
        description="We are constantly experimenting with new technology. Here are some of our favorites."
      />
      <Technologies></Technologies> */}
      </Page>
    </Scaffold>
  )
}

const ListItem = styled.p`
  color: white;
  line-height: 24px;
  font-weight: 200;
  width: 50%;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const List = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`

const Column = styled.div`
  ${DESKTOP_STYLE} {
    width: 33.33%;
  }

  ${TABLET_STYLE} {
    width: 33.33%;
  }

  ${MOBILE_STYLE} {
    width: 100%;
    margin-bottom: 48px;
  }
`

const Square = styled.div`
  width: 100%;
  height: 380px;
  margin-bottom: 32px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
