import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Home } from './pages/home/Home'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { ViewportProvider } from './scaffold/providers/ViewportProvider'
import { ThemeProvider } from './scaffold/providers/ThemeProvider'
import { Loader } from './scaffold/Loading'
import { Work } from './pages/work/Work'
import { Team } from './pages/team/Team'
import { Contact } from './pages/contact/Contact'
import { Impressum } from './pages/impressum/Impressum'
import { Detail } from './pages/work/Detail'
import { NotFound } from './pages/404/404'
import { PrivacyPolicy } from './pages/privacyPolicy/PrivacyPolicy'
import { Noise } from './pages/noise/Noise'

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    element: <Home />,
  },
  {
    path: '/work',
    element: <Work />,
  },
  {
    path: '/team',
    element: <Team />,
  },
  {
    path: '/noise',
    element: <Noise />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/detail/:id',
    element: <Detail />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/impressum',
    element: <Impressum />,
  },
])

const Root = () => {
  const _initialized =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('initialized')
      : 'false'

  const [initialized, setInitialized] = React.useState(_initialized === 'true')

  React.useEffect(() => {
    if (!initialized) {
      setTimeout(() => {
        sessionStorage.setItem('initialized', 'true')
        setInitialized(true)
      }, 5000)
    }
  }, [initialized])

  return (
    <ViewportProvider>
      <ThemeProvider>
        {typeof window !== 'undefined' && <Loader initialized={initialized} />}
        <RouterProvider router={router} />
      </ThemeProvider>
    </ViewportProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
)
