export const LogoSvg = () => (
  <svg width="64" height="64" viewBox="0 0 828 828">
    <path d="M828 0H0V828H828V0Z" fill="#000000" />
    <path
      id="O1"
      d="M158 378.842C158 373.596 158.915 368.96 160.745 364.934C162.575 360.908 165.015 357.553 168.065 354.869C170.993 352.307 174.287 350.355 177.947 349.013C181.729 347.671 185.511 347 189.293 347C193.075 347 196.796 347.671 200.456 349.013C204.238 350.355 207.654 352.307 210.704 354.869C213.632 357.553 216.011 360.908 217.841 364.934C219.671 368.96 220.586 373.596 220.586 378.842V447.65C220.586 453.14 219.671 457.837 217.841 461.741C216.011 465.645 213.632 468.878 210.704 471.44C207.654 474.124 204.238 476.137 200.456 477.479C196.796 478.821 193.075 479.492 189.293 479.492C185.511 479.492 181.729 478.821 177.947 477.479C174.287 476.137 170.993 474.124 168.065 471.44C165.015 468.878 162.575 465.645 160.745 461.741C158.915 457.837 158 453.14 158 447.65V378.842ZM176.666 447.65C176.666 452.164 177.886 455.519 180.326 457.715C182.888 459.789 185.877 460.826 189.293 460.826C192.709 460.826 195.637 459.789 198.077 457.715C200.639 455.519 201.92 452.164 201.92 447.65V378.842C201.92 374.328 200.639 371.034 198.077 368.96C195.637 366.764 192.709 365.666 189.293 365.666C185.877 365.666 182.888 366.764 180.326 368.96C177.886 371.034 176.666 374.328 176.666 378.842V447.65Z"
      fill="white"
    />
    <path
      d="M381.186 477.394V347.098H399.12L427.302 425.605H427.668V347.098H446.334V477.394H428.766L400.218 399.07H399.852V477.394H381.186Z"
      fill="white"
      id="N1"
    />
    <path
      id="E2"
      d="M604.708 479.394V349.098H660.34V366.666H623.374V404.913H655.582V422.481H623.374V460.728H660.34V479.394H604.708Z"
      fill="white"
    />
    <path
      id="F1"
      d="M162.748 256.394V126.098H218.38V143.666H181.414V183.194H213.622V200.762H181.414V256.394H162.748Z"
      fill="white"
    />
    <path
      id="O2"
      d="M382.46 157.842C382.46 152.596 383.375 147.96 385.205 143.934C387.035 139.908 389.475 136.553 392.525 133.869C395.453 131.307 398.747 129.355 402.407 128.013C406.189 126.671 409.971 126 413.753 126C417.535 126 421.256 126.671 424.916 128.013C428.698 129.355 432.114 131.307 435.164 133.869C438.092 136.553 440.471 139.908 442.301 143.934C444.131 147.96 445.046 152.596 445.046 157.842V226.65C445.046 232.14 444.131 236.837 442.301 240.741C440.471 244.645 438.092 247.878 435.164 250.44C432.114 253.124 428.698 255.137 424.916 256.479C421.256 257.821 417.535 258.492 413.753 258.492C409.971 258.492 406.189 257.821 402.407 256.479C398.747 255.137 395.453 253.124 392.525 250.44C389.475 247.878 387.035 244.645 385.205 240.741C383.375 236.837 382.46 232.14 382.46 226.65V157.842ZM401.126 226.65C401.126 231.164 402.346 234.519 404.786 236.715C407.348 238.789 410.337 239.826 413.753 239.826C417.169 239.826 420.097 238.789 422.537 236.715C425.099 234.519 426.38 231.164 426.38 226.65V157.842C426.38 153.328 425.099 150.034 422.537 147.96C420.097 145.764 417.169 144.666 413.753 144.666C410.337 144.666 407.348 145.764 404.786 147.96C402.346 150.034 401.126 153.328 401.126 157.842V226.65Z"
      fill="white"
    />
    <path
      id="R2"
      d="M621.374 143.666V184.292H631.988C635.282 184.292 637.905 183.865 639.857 183.011C641.809 182.035 643.334 180.693 644.432 178.985C645.408 177.277 646.079 175.203 646.445 172.763C646.811 170.201 646.994 167.273 646.994 163.979C646.994 160.685 646.811 157.818 646.445 155.378C646.079 152.816 645.347 150.62 644.249 148.79C641.931 145.374 637.539 143.666 631.073 143.666H621.374ZM602.708 256.394V126.098H632.72C654.68 126.098 665.66 138.847 665.66 164.345C665.66 172.031 664.44 178.558 662 183.926C659.682 189.294 655.534 193.625 649.556 196.919L669.686 256.394H649.922L632.537 200.762H621.374V256.394H602.708Z"
      fill="white"
    />
    <path
      id="R1"
      d="M177.954 587.666V628.292H188.568C191.862 628.292 194.485 627.865 196.437 627.011C198.389 626.035 199.914 624.693 201.012 622.985C201.988 621.277 202.659 619.203 203.025 616.763C203.391 614.201 203.574 611.273 203.574 607.979C203.574 604.685 203.391 601.818 203.025 599.378C202.659 596.816 201.927 594.62 200.829 592.79C198.511 589.374 194.119 587.666 187.653 587.666H177.954ZM159.288 700.394V570.098H189.3C211.26 570.098 222.24 582.847 222.24 608.345C222.24 616.031 221.02 622.558 218.58 627.926C216.262 633.294 212.114 637.625 206.136 640.919L226.266 700.394H206.502L189.117 644.762H177.954V700.394H159.288Z"
      fill="white"
    />
    <path
      id="E1"
      d="M386.688 699.394V569.098H442.32V586.666H405.354V624.913H437.562V642.481H405.354V680.728H442.32V699.394H386.688Z"
      fill="white"
    />
    <path
      id="D1"
      d="M603.708 701.394V571.098H631.341C642.077 571.098 650.19 574.026 655.68 579.882C661.292 585.738 664.098 594.034 664.098 604.77V665.709C664.098 677.909 661.109 686.937 655.131 692.793C649.275 698.527 640.796 701.394 629.694 701.394H603.708ZM622.374 588.666V683.826H630.975C636.221 683.826 639.942 682.545 642.138 679.983C644.334 677.299 645.432 673.151 645.432 667.539V604.77C645.432 599.646 644.395 595.681 642.321 592.875C640.247 590.069 636.465 588.666 630.975 588.666H622.374Z"
      fill="white"
    />
  </svg>
)
