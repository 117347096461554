import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Image } from '../../components/Image'
import { Page } from '../../components/Page'
import { ORDER, projectsDisabled, WORK } from '../../data/work.data'
import { DESKTOP_STYLE, MOBILE_STYLE, TABLET_STYLE } from '../../responsive'
import { Scaffold } from '../../scaffold/Scaffold'

export const Work = () => {
  const navigate = useNavigate()
  return (
    <Scaffold
      bgColor="#000000"
      helmet={{
        title: 'Work',
        path: 'work',
      }}
    >
      <Page title="Our work" description="">
        <WorkItems>
          {ORDER.filter((k) => !projectsDisabled[k])
            .map((key) => ({ key, work: WORK[key] }))
            .map(({ key, work }) => {
              const previewSrc = work.images.find(
                (i) => !i.src.endsWith('.mp4'),
              )
              return (
                <WorkItemWrapper
                  key={key}
                  style={
                    {
                      // background: 'rgba(255, 255, 255, 0.05)',
                    }
                  }
                  onClick={() => {
                    navigate(`/detail/${key}`)
                  }}
                >
                  <Header>
                    <Left>
                      <WorkTitle>{work.title}</WorkTitle>
                      <WorkSubtitle>{work.tagline}</WorkSubtitle>
                    </Left>
                    <Right>
                      <BsArrowRight className="arrow" color="white" />
                    </Right>
                  </Header>

                  <WorkPreviewWrapper>
                    <Image
                      className="preview"
                      src={previewSrc?.src || ''}
                      hideLoading
                    />
                  </WorkPreviewWrapper>
                </WorkItemWrapper>
              )
            })}
        </WorkItems>
      </Page>
    </Scaffold>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const WorkPreviewWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`

const WorkPreview = styled.img`
  width: 100%;
  transition: all ease 0.2s;
  border: 1px solid transparent;
`

const WorkItems = styled.div`
  display: grid;
  width: 100%;
  padding-bottom: 256px;

  ${DESKTOP_STYLE} {
    grid-template-columns: 50% 50%;
  }

  ${TABLET_STYLE} {
    grid-template-columns: 50% 50%;
  }
`

const WorkItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all ease 0.2s;

  .preview {
    transition: all ease 0.2s;
  }

  &:hover {
    .preview {
      transform: scale(1.02);
      /* border: 1px solid rgba(255, 255, 255, 0.2); */
    }
  }

  ${DESKTOP_STYLE} {
    padding: 32px;

    &:nth-child(odd) {
      margin-left: -32px;
    }

    &:nth-child(even) {
      margin-right: -32px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .arrow {
      transform: translateX(-16px);
      transition: all ease 0.4s;
      opacity: 0;
    }

    &:hover {
      .arrow {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }

  ${TABLET_STYLE} {
    padding: 32px;

    svg {
      width: 32px;
      height: 32px;
    }

    .arrow {
      transform: translateX(-16px);
      transition: all ease 0.4s;
      opacity: 0;
    }

    &:hover {
      .arrow {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }

  ${MOBILE_STYLE} {
    margin-bottom: 64px;

    .arrow {
      transform: scale(1.5);
    }
  }
`

const Left = styled.div`
  flex: 1;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${DESKTOP_STYLE} {
    flex: 0 0 48px;
  }

  ${TABLET_STYLE} {
    flex: 0 0 48px;
  }
`

const WorkTitle = styled.h4`
  font-family: 'Major Mono Display', monospace;
  color: white;
  width: 100%;

  ${DESKTOP_STYLE} {
    font-size: 24px;
    letter-spacing: -0.03em;
  }

  ${TABLET_STYLE} {
    font-size: 24px;
    letter-spacing: -0.03em;
  }

  ${MOBILE_STYLE} {
    font-size: 24px;
    letter-spacing: -0.03em;
  }
`

const WorkSubtitle = styled.h6`
  color: white;
  margin: 12px 0 0 0;
  width: 100%;
  opacity: 0.7;
  font-weight: normal;

  ${DESKTOP_STYLE} {
    font-size: 16px;
  }

  ${TABLET_STYLE} {
    font-size: 16px;
  }

  ${MOBILE_STYLE} {
    margin-top: 8px;
    font-size: 16px;
  }
`
